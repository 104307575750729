import { NgModule } from "@angular/core";
import { PurchaseGoodsFormComponent } from "./goods/purchase-goods-form/warehouse-goods-form.component";
import { PurchaseGoodsListComponent } from "./goods/purchase-goods-list/purchase-goods-list.component";
import { PurchaseGoodsPrintComponent } from "./goods/purchase-goods-print/purchase-goods-print.component";
import { MultifunctionalPurchaseFormComponent } from "./multifunctional-purchase/multifunctional-purchase-form/multifunctional-purchase-form.component";
import { MultifunctionalPurchaseGoodsReceiptPrintComponent } from "./multifunctional-purchase/multifunctional-purchase-goods-receipt-print/multifunctional-purchase-goods-receipt-print.component";
import { MultifunctionalPurchaseListComponent } from "./multifunctional-purchase/multifunctional-purchase-list/multifunctional-purchase-list.component";
import { MultifunctionalPurchasePrintComponent } from "./multifunctional-purchase/multifunctional-purchase-print/multifunctional-purchase-print.component";
import { MultifunctionalPurchaseSupplierPrintComponent } from "./multifunctional-purchase/multifunctional-purchase-supplier-print/multifunctional-purchase-supplier-print.component";
import { MultifunctionalPurchaseTransportPrintComponent } from "./multifunctional-purchase/multifunctional-purchase-transport-print/multifunctional-purchase-transport-print.component";
import { PurchaseOrderVoucherFormComponent } from "./order/purchase-order-voucher-form/purchase-order-voucher-form.component";
import { PurchaseOrderVoucherListComponent } from "./order/purchase-order-voucher-list/purchase-order-voucher-list.component";
import { PurchaseOrderVoucherPrintComponent } from "./order/purchase-order-voucher-print/purchase-order-voucher-print.component";
import { PurchasePriceTableFormComponent } from "./price-table/purchase-price-table-form/purchase-price-table-form.component";
import { PurchasePriceTableImportComponent } from "./price-table/purchase-price-table-import/purchase-price-table-import.component";
import { PurchasePriceTableListComponent } from "./price-table/purchase-price-table-list/purchase-price-table-list.component";
import { PurchasePriceTablePrintComponent } from "./price-table/purchase-price-table-print/purchase-price-table-print.component";
import { PurchaseProductFormComponent } from "./product/purchase-product-form/purchase-product-form.component";
import { PurchaseProductListComponent } from "./product/purchase-product-list/purchase-product-list.component";
import { PurchaseDashboardComponent } from "./purchase-dashboard/purchase-dashboard.component";
import { PurchaseSimpleVoucherFormComponent } from "./voucher/purchase-simple-voucher-form/purchase-simple-voucher-form.component";
import { PurchaseVoucherFormComponent } from "./voucher/purchase-voucher-form/purchase-voucher-form.component";
import { PurchaseVoucherListComponent } from "./voucher/purchase-voucher-list/purchase-voucher-list.component";
import { PurchaseVoucherPrintComponent } from "./voucher/purchase-voucher-print/purchase-voucher-print.component";
import { PurchaseComponent } from "./purchase.component";
import { PurchaseRoutingModule } from "./purchase-routing.module";
import { commonModules, commonProviders } from "../../common-modules";

export const purchaseComponents = [
    PurchaseComponent,
    PurchasePriceTableListComponent,
    PurchasePriceTableFormComponent,
    PurchasePriceTablePrintComponent,
    PurchasePriceTableImportComponent,
    PurchaseVoucherListComponent,
    PurchaseVoucherFormComponent,
    PurchaseVoucherPrintComponent,
    PurchaseSimpleVoucherFormComponent,
    PurchaseOrderVoucherListComponent,
    PurchaseOrderVoucherFormComponent,
    PurchaseOrderVoucherPrintComponent,
    PurchaseGoodsFormComponent,
    PurchaseGoodsListComponent,
    PurchaseGoodsPrintComponent,
    PurchaseDashboardComponent,
    PurchaseProductListComponent,
    PurchaseProductFormComponent,
    MultifunctionalPurchaseListComponent,
    MultifunctionalPurchaseFormComponent,
    MultifunctionalPurchasePrintComponent,
    MultifunctionalPurchaseTransportPrintComponent,
    MultifunctionalPurchaseSupplierPrintComponent,
    MultifunctionalPurchaseGoodsReceiptPrintComponent,
];

@NgModule({
    declarations: purchaseComponents,
    exports: purchaseComponents,
    imports: [
        ...commonModules,
        PurchaseRoutingModule,
    ],
    bootstrap: [PurchaseComponent],
    providers: [
        ...commonProviders,
    ],
})
export class PurchaseModule { }