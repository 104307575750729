import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const notificationRoutes: Routes = [
  
];

@NgModule({
    imports: [RouterModule.forChild(notificationRoutes)],
    exports: [RouterModule],
})
export class NotificationRoutingModule {
}