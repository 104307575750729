import { Component, OnInit } from '@angular/core';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { HelpdeskTicketModel } from '../../../../models/helpdesk.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ContactFormComponent } from '../../../contact/contact/contact-form/contact-form.component';
import { HelpdeskTicketFormComponent } from '../helpdesk-ticket-form/helpdesk-ticket-form.component';

@Component({
  selector: 'ngx-helpdesk-ticket-list',
  templateUrl: './helpdesk-ticket-list.component.html',
  styleUrls: ['./helpdesk-ticket-list.component.scss'],
})
export class HelpdeskTicketListComponent extends AgGridDataManagerListComponent<HelpdeskTicketModel, HelpdeskTicketFormComponent> implements OnInit {

  componentName: string = 'HelpdeskTicketListComponent';
  formPath = '/helpdesk/ticket/form';
  apiPath = '/helpdesk/tickets';
  idKey = ['Code'];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<HelpdeskTicketListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.columnDefs = this.configSetting([
      {
        headerName: '#',
        width: 52,
        valueGetter: 'node.data.No',
        cellRenderer: 'loadingCellRenderer',
        sortable: false,
        pinned: 'left',
      },
      {
        headerName: 'Mã',
        field: 'Code',
        width: 130,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Tiêu đề',
        field: 'Title',
        width: 300,
        filter: 'agTextColumnFilter',
        pinned: 'left',
        autoHeight: true,
      },
      {
        headerName: 'Người yêu cầu',
        field: 'SupportedPersonName',
        width: 200,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Số điện thoại yêu cầu',
        field: 'SupportedPersonPhone',
        width: 200,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Công ty',
        field: 'OrganizationName',
        width: 200,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SDT Công ty',
        field: 'OrganizationPhone',
        width: 200,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'ĐC Công ty',
        field: 'OrganizationAddress',
        width: 200,
        filter: 'agTextColumnFilter',
      },
    ]);

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: HelpdeskTicketModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(ContactFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: HelpdeskTicketModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }
}
