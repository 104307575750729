import { RouterModule, Routes } from '@angular/router';

import { HumanResourceComponent } from './human-resource.component';
import { AuthGuardService } from '../../services/auth-guard.service';
import { NgModule } from '@angular/core';

export const humanResourceRoutes: Routes = [{
  path: '',
  component: HumanResourceComponent,
  children: [
    {
      path: 'employees',
      canActivate: [AuthGuardService],
      // component: EmployeesComponent,
      loadChildren: () => import('./employees/employees.module')
        .then(m => m.EmployeesModule),
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(humanResourceRoutes)],
  exports: [RouterModule],
})
export class HumanResouceRoutingModule {
}
