<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Network.Proxy.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật proxy
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">Description (*)</span><span class="invalid">Description là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="Description">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Host'), 'warning')">
                  <label class="label"><span class="valid">Host (*)</span><span class="invalid">Host là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Host" placeholder="Host">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Protocol'), 'warning')">
                  <label class="label"><span class="valid">Protocol (*)</span><span class="invalid">Protocol là bắt buộc
                      (*)</span></label>
                      <nb-select size="medium" formControlName="Protocol" fullWidth selected="http">
                        <nb-option value="http">http</nb-option>
                        <nb-option value="https">https</nb-option>
                      </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Port'), 'warning')">
                  <label class="label"><span class="valid">Port (*)</span><span class="invalid">Port là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Port" placeholder="Port">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Username'), 'warning')">
                  <label class="label"><span class="valid">Username</span><span class="invalid">Username là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Username" placeholder="Username">
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Password'), 'warning')">
                  <label class="label"><span class="valid">Password</span><span class="invalid">Password là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Password" placeholder="Password">
                </div>
              </div>
              <div class="col-sm-1">
                <div class="form-group align-right" [attr.state]="formControlValidate(formItem.get('Enabled'), 'warning')">
                  <label class="label"><span class="valid">Enabled</span><span class="invalid">Enabled là bắt buộc
                      (*)</span></label>
                      <nb-checkbox formControlName="Enabled"></nb-checkbox>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
