import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { HumanResouceRoutingModule } from './human-resource-routing.module';
import { HumanResourceComponent } from './human-resource.component';

// @NgModule({
//   declarations: [
//     HumanResourceComponent,
//   ],
//   imports: [
//     FormsModule,
//     ReactiveFormsModule,
//     ThemeModule,
//     NbTabsetModule,
//     NbStepperModule,
//     NbCardModule,
//     NbButtonModule,
//     NbListModule,
//     NbAccordionModule,
//     NbUserModule,
//     NbRouteTabsetModule,
//     HumanResouceRoutingModule,
//   ],
//   entryComponents: [
//     SmartTableFilterComponent,
//   ],
// })
// export class HumanResourceModule { }

export const humanResourceComponents = [
    HumanResourceComponent,
];

@NgModule({
    declarations: humanResourceComponents,
    // exports: humanResourceComponents,
    imports: [
        ...commonModules,
        HumanResouceRoutingModule,
    ],
    bootstrap: [HumanResourceComponent],
    providers: [
        ...commonProviders,
    ],
})
export class HumanResourceModule { }