import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { WordpressOrderFormComponent } from './order/order-form/order-form.component';
import { WordpressOrderListComponent } from './order/order-list/order-list.component';
import { WordpressOrderPrintComponent } from './order/order-print/order-print.component';
import { WordpressProductFormComponent } from './product/product-form/product-form.component';
import { WordpressProductListComponent } from './product/product-list/product-list.component';
import { SyncFormComponent } from './sync-form/sync-form.component';
import { WordpressSyncProfileFormComponent } from './sync-profile/sync-profile-form/sync-profile-form.component';
import { WordpressSyncProfileListComponent } from './sync-profile/sync-profile-list/sync-profile-list.component';
import { WordpressSyncProfilePreviewComponent } from './sync-profile/sync-profile-preview/sync-profile-preview.component';
import { WordpressComponent } from './wordpress.component';
import { WpSiteFormComponent } from './wp-site/wp-site-form/wp-site-form.component';
import { WpSiteListComponent } from './wp-site/wp-site-list/wp-site-list.component';
import { WordpressRoutingModule } from './wordpress-routing.module';
// import { DialogModule } from '../dialog/dialog.module';

// @NgModule({
//   declarations: [
//     WordpressComponent,
//     WpSiteListComponent,
//     WpSiteFormComponent,
//     SyncFormComponent,
//   ],
//   imports: [
//     CommonModule,
//     NbTabsetModule,
//     WordpressRoutingModule,
//     NbCardModule,
//     Ng2SmartTableModule,
//     CustomElementModule,
//     NbIconModule,
//     NbInputModule,
//     NbCheckboxModule,
//     NbRouteTabsetModule,
//     NbStepperModule,
//     NbButtonModule,
//     NbListModule,
//     NbAccordionModule,
//     NbUserModule,
//     NbSelectModule,
//     NbActionsModule,
//     NbRadioModule,
//     NbDatepickerModule,
//     CurrencyMaskModule,
//     FormsModule,
//     ReactiveFormsModule,
//     // DialogModule,
//     NbProgressBarModule,
//     AgGridModule,
//     TranslateModule,
//     NbDialogModule.forChild(),
//     SortablejsModule.forRoot({
//       animation: 200,
//     }),
//   ],
//   entryComponents: [
//     WpSiteFormComponent,
//     SyncFormComponent,
//     SmartTableFilterComponent,
//   ],
// })
// export class WordpressModule { }


export const wordpressComponents = [
  WordpressComponent,
  WpSiteListComponent,
  WpSiteFormComponent,
  SyncFormComponent,
  WordpressSyncProfileListComponent,
  WordpressSyncProfileFormComponent,
  WordpressOrderListComponent,
  WordpressOrderFormComponent,
  WordpressOrderPrintComponent,
  WordpressProductListComponent,
  WordpressProductFormComponent,
  WordpressSyncProfilePreviewComponent,
];

@NgModule({
  declarations: wordpressComponents,
  exports: wordpressComponents,
  imports: [
    ...commonModules,
    WordpressRoutingModule,
  ],
  bootstrap: [WordpressComponent],
  providers: [
    ...commonProviders,
  ],
})
export class WordpressModule { }