<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Extension.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật số nội bộ
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá" [disabled]="isProcessing">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại" [disabled]="isProcessing">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()"
              [disabled]="!canUndo || isProcessing" style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về"
              [disabled]="isProcessing">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="extension_uuid">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('extension'), 'warning')">
                  <label class="label"><span class="valid">Số nội bộ (*)</span><span class="invalid">Số nội bộ là bắt
                      buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="extension" placeholder="Số nội bộ (Ext)">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Nhóm</span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'call_group')" pack="eva"
                      icon="copy-outline"></nb-icon>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="call_group" placeholder="Nhóm">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Thời gian đỗ chuông</span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'call_timeout')" pack="eva"
                      icon="copy-outline"></nb-icon>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="call_timeout" placeholder="Thời gian đỗ chuông">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('enabled'), 'warning')">
                  <label class="label"><span class="valid">Kích hoạt (*)</span><span class="invalid"></span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'enabled')" pack="eva" icon="copy-outline">
                    </nb-icon>
                  </label>
                  <nb-checkbox formControlName="enabled">Kích hoạt</nb-checkbox>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Mật khẩu</span></label>
                  <input type="text" nbInput fullWidth formControlName="password"
                    placeholder="Bỏ trống để tạo mật khẩu ngẫu nhiên">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('domain_uuid'), 'warning')">
                  <label class="label"><span class="valid">Domain</span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'domain_uuid')" pack="eva"
                      icon="copy-outline"></nb-icon>
                  </label>
                  <ngx-select2 [data]="privateDmainList" formControlName="domain_uuid"
                    [select2Option]="privateDmainListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('user_record'), 'warning')">
                  <label class="label"><span class="valid">Ghi âm</span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'user_record')" pack="eva"
                      icon="copy-outline"></nb-icon>
                  </label>
                  <!-- <ngx-select2 [data]="userRecordActionList" formControlName="user_record"
                    [select2Option]="select2OptionForUserRecordActionList">
                  </ngx-select2> -->
                  <nb-select size="medium" formControlName="user_record" fullWidth>
                    <nb-option *ngFor="let userRecordAction of userRecordActionList" [value]="userRecordAction.id">{{ userRecordAction.text }}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group">
                  <label class="label"><span class="valid">Mô tả</span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'description')" pack="eva"
                      icon="copy-outline"></nb-icon>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="description" placeholder="Mô tả">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_all_enabled">
                    <label class="label"><span class="valid">Chuyển cuộc gọi</span></label>
                  </nb-checkbox>
                  <input type="text" nbInput fullWidth formControlName="forward_all_destination"
                    placeholder="Số chuyển tới">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_busy_enabled">
                    <label class="label"><span class="valid">Chuyển cuộc gọi khi bận</span></label>
                  </nb-checkbox>
                  <input type="text" nbInput fullWidth formControlName="forward_busy_destination"
                    placeholder="Số chuyển tới">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_no_answer_enabled">
                    <label class="label"><span class="valid">Khi không bắt máy</span></label>
                  </nb-checkbox>
                  <input type="text" [tabindex]="i*10 + 8" nbInput fullWidth formControlName="forward_no_answer_destination"
                    placeholder="Số chuyển tới">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_user_not_registered_enabled">
                    <label class="label"><span class="valid">Khi ngoại tuyến</span></label>
                  </nb-checkbox>
                  <input type="text" [tabindex]="i*10 + 8" nbInput fullWidth formControlName="forward_user_not_registered_destination"
                    placeholder="Số chuyển tới">
                </div>
              </div>
            </div>

            <hr>
            <label class="label">Danh sách thiết bị voip</label>
            <div class="row">
              <div class="col-sm-1 label">STT</div>
              <div class="col-sm-11">
                <div class="row">
                  <div class="col-sm-3 label">Mã mac</div>
                  <div class="col-sm-4 label">Template</div>
                  <div class="col-sm-5 label">Diễn giải</div>
                </div>
              </div>
              <!-- <div class="col-sm-1" style="text-align: center;">
                <button nbButton status="success" (click)="addDeviceFormGroup(i)" size="tiny"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div> -->
            </div>

            <hr>
            <div formArrayName="devices" class="form-details">
              <div class="form-detail-item" *ngFor="let device of getDevices(i).controls; let ic=index"
                [formGroup]="device">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-11">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Mã mac (*)</label>
                          <input type="text" nbInput fullWidth formControlName="device_mac_address"
                            placeholder="Mã mac">
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="label">Template (*)</label>
                          <ngx-select2 [data]="templateList" formControlName="device_template"
                            [select2Option]="templateListConfig">
                          </ngx-select2>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label class="label">Diễn giải</label>
                          <input type="text" nbInput fullWidth formControlName="device_description"
                            placeholder="Diễn giải">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-1">
                    <div class="form-group">
                      <label class="label">CM</label>
                      <button nbButton status="danger" hero size="tiny" (click)="removeDeviceGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div> -->
                </div>

              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero
              [disabled]="isProcessing">Thêm</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" hero>Lưu</button>
            <button nbButton status="danger" [disabled]="!form.valid || this.id.length > 0 || isProcessing"
              (click)="onAutoGenrateExtensionClick(i)" hero>Tạo tự động</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
