import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { SmartBotRoutingModule } from './smart-bot-routing.module';
import { SmartBotComponent } from './smart-bot.component';

export const smartBotComponents = [
  SmartBotComponent,
];

@NgModule({
  declarations: smartBotComponents,
  exports: smartBotComponents,
  imports: [
    ...commonModules,
    SmartBotRoutingModule,
  ],
  bootstrap: [SmartBotComponent],
  providers: [
    ...commonProviders,
  ],
})
export class SmartBotModule { }