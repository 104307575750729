import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { AdminProductRoutingModule } from "./admin-product-routing.module";
import { ProductBrandFormComponent } from "./brand/product-brand-form/product-brand-form.component";
import { ProductBrandListComponent } from "./brand/product-brand-list/product-brand-list.component";
import { ProductCategoryFormComponent } from "./category/product-category-form/product-category-form.component";
import { ProductCategoryListComponent } from "./category/product-category-list/product-category-list.component";
import { ProductKeywordFormComponent } from "./keyword/product-keyword-form/product-keyword-form.component";
import { ProductKeywordListComponent } from "./keyword/product-keyword-list/product-keyword-list.component";
import { ProductGroupFormComponent } from "./product-group/product-group-form/product-group-form.component";
import { ProductGroupListComponent } from "./product-group/product-group-list/product-group-list.component";
import { ProductObjectReferenceFormComponent } from "./product-object-reference/product-object-reference-form/product-object-reference-form.component";
import { ProductObjectReferenceListComponent } from "./product-object-reference/product-object-reference-list/product-object-reference-list.component";
import { AssignCategoriesFormComponent } from "./product/assign-categories-form/assign-categories-form.component";
import { ImportProductMapFormComponent } from "./product/import-product-map-form/import-product-map-form.component";
import { ImportProductDialogComponent } from "./product/import-products-dialog/import-products-dialog.component";
import { ProductFormComponent } from "./product/product-form/product-form.component";
import { ProductListV1Component } from "./product/product-list-v1/product-list.component";
import { ProductListComponent } from "./product/product-list/product-list.component";
import { ProductPropertyFormComponent } from "./property/product-property-form/product-property-form.component";
import { ProductPropertyListComponent } from "./property/product-property-list/product-property-list.component";
import { TaxRateUpdateNoteFormComponent } from "./tax-rate-table-update-note/tax-rate-table-update-note-form/tax-rate-table-update-note-form.component";
import { TaxRateUpdateNoteListComponent } from "./tax-rate-table-update-note/tax-rate-table-update-note-list/tax-rate-table-update-note-list.component";
import { TaxRateUpdateNotePrintComponent } from "./tax-rate-table-update-note/tax-rate-table-update-note-print/tax-rate-table-update-note-print.component";
import { ProductTaxRateTableComponent } from "./tax-rate-table/tax-rate-table/tax-rate-table.component";
import { ProductUnitFormComponent } from "./unit/product-unit-form/product-unit-form.component";
import { ProductUnitListComponent } from "./unit/product-unit-list/product-unit-list.component";
import { AdminProductComponent } from "./admin-product.component";

export const adminProductComponents = [
    AdminProductComponent,
    ProductListComponent,
    ProductFormComponent,
    ProductCategoryListComponent,
    ProductCategoryFormComponent,
    ProductUnitListComponent,
    ProductUnitFormComponent,
    AssignCategoriesFormComponent,
    ImportProductDialogComponent,
    ProductGroupListComponent,
    ProductGroupFormComponent,
    ProductBrandListComponent,
    ProductBrandFormComponent,
    ProductPropertyListComponent,
    ProductPropertyFormComponent,
    ImportProductMapFormComponent,
    ProductObjectReferenceListComponent,
    ProductObjectReferenceFormComponent,
    ProductKeywordListComponent,
    ProductKeywordFormComponent,
    ProductListV1Component,

    ProductTaxRateTableComponent,
    TaxRateUpdateNoteListComponent,
    TaxRateUpdateNoteFormComponent,
    TaxRateUpdateNotePrintComponent,
];

@NgModule({
    declarations: adminProductComponents,
    exports: adminProductComponents,
    imports: [
        ...commonModules,
        AdminProductRoutingModule,
    ],
    bootstrap: [AdminProductComponent],
    providers: [
        ...commonProviders,
    ],
})
export class AdminProductModule { }