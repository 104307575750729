import { SmsComponent } from './sms.component';
import { SmsTemplateListComponent } from './sms-template/sms-template-list/sms-template-list.component';
import { SmsTemplateFormComponent } from './sms-template/sms-template-form/sms-template-form.component';
import { SmsSentListComponent } from './sms-sent/sms-sent-list/sms-sent-list.component';
import { SmsSentFormComponent } from './sms-sent/sms-sent-form/sms-sent-form.component';
import { SmsDashboardComponent } from './sms-dashboard/sms-dashboard.component';
// import { CurrencyMaskModule } from 'ng2-currency-mask';
// import { DialogModule } from '../dialog/dialog.module';
import { SmsGatewayListComponent } from './sms-gateway/sms-gateway-list/sms-gateway-list.component';
import { SmsGatewayFormComponent } from './sms-gateway/sms-gateway-form/sms-gateway-form.component';
import { SmsAdvertisementListComponent } from './sms-advertisement/sms-advertisement-list/sms-advertisement-list.component';
import { SmsAdvertisementFormComponent } from './sms-advertisement/sms-advertisement-form/sms-advertisement-form.component';
import { SmsPhoneNumberListComponent } from './phone-number/sms-phone-number-list/sms-phone-number-list.component';
import { SmsPhoneNumberFormComponent } from './phone-number/sms-phone-number-form/sms-phone-number-form.component';
import { SmsSentStatsListComponent } from './sms-sent-stats-list/sms-sent-stats-list.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { SmsRoutingModule } from './sms-routing.module';

// @NgModule({
//   declarations: [SmsComponent, SmsTemplateListComponent,
//     SmsTemplateFormComponent,
//     SmsSentListComponent, SmsSentFormComponent,
//     SmsDashboardComponent, SmsGatewayListComponent,
//     SmsGatewayFormComponent, SmsAdvertisementListComponent,
//     SmsAdvertisementFormComponent,
//     SmsPhoneNumberListComponent,
//     SmsPhoneNumberFormComponent,
//     SmsSentStatsListComponent],
//   imports: [
//     CommonModule,
//     SmsRoutingModule,
//     NbTabsetModule,
//     NbCardModule,
//     Ng2SmartTableModule,
//     CustomElementModule,
//     NbIconModule,
//     NbInputModule,
//     NbCheckboxModule,
//     NbRouteTabsetModule,
//     NbStepperModule,
//     NbButtonModule,
//     NbListModule,
//     NbAccordionModule,
//     NbUserModule,
//     NbSelectModule,
//     NbActionsModule,
//     NbRadioModule,
//     NbDatepickerModule,
//     // CurrencyMaskModule,
//     FormsModule,
//     ReactiveFormsModule,
//     // DialogModule,
//     NbProgressBarModule,
//     AgGridModule,
//     NgxUploaderModule,
//     TranslateModule,
//     NbDialogModule.forChild(),
//     SortablejsModule.forRoot({
//       animation: 200,
//     }),
//   ],
//   entryComponents: [
//     SmartTableCheckboxComponent,
//     SmsTemplateFormComponent,
//     SmsGatewayFormComponent,
//     SmsPhoneNumberFormComponent,
//     SmsAdvertisementFormComponent,
//     SmsSentStatsListComponent,
//     SmartTableFilterComponent,
//   ],
// })
// export class SmsModule { }


export const smsComponents = [SmsComponent, SmsTemplateListComponent,
  SmsTemplateFormComponent,
  SmsSentListComponent, SmsSentFormComponent,
  SmsDashboardComponent, SmsGatewayListComponent,
  SmsGatewayFormComponent, SmsAdvertisementListComponent,
  SmsAdvertisementFormComponent,
  SmsPhoneNumberListComponent,
  SmsPhoneNumberFormComponent,
  SmsSentStatsListComponent,
];

@NgModule({
  declarations: smsComponents,
  // exports: smsComponents,
  imports: [
    ...commonModules,
    SmsRoutingModule,
  ],
  bootstrap: [SmsComponent],
  providers: [
    ...commonProviders,
  ],
})
export class SmsModule { }