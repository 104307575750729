import { B2bProductCategoryFormComponent } from "./product/category/product-category-form/product-category-form.component";
import { B2bProductCategoryListComponent } from "./product/category/product-category-list/product-category-list.component";
import { B2bCenterPageFormComponent } from "./page/b2b-server-page-form/b2b-server-page-form.component";
import { B2bServerPageListComponent } from "./page/b2b-server-page-list/b2b-server-page-list.component";
import { B2bProductFormComponent } from "./product/product/product-form/product-form.component";
import { B2bProductListComponent } from "./product/product/product-list/product-list.component";
import { B2bProductGroupFormComponent } from "./product/group/product-group-form/product-group-form.component";
import { B2bProductGroupListComponent } from "./product/group/product-group-list/product-group-list.component";
import { B2bProductPropertyFormComponent } from "./product/property/product-property-form/product-property-form.component";
import { B2bProductPropertyListComponent } from "./product/property/product-property-list/product-property-list.component";
import { B2bProductBrandFormComponent } from "./product/brand/product-brand-form/product-brand-form.component";
import { B2bProductBrandListComponent } from "./product/brand/product-brand-list/product-brand-list.component";
import { B2bProductKeywordFormComponent } from "./product/keyword/product-keyword-form/product-keyword-form.component";
import { B2bProductKeywordListComponent } from "./product/keyword/product-keyword-list/product-keyword-list.component";
import { B2bProductUnitFormComponent } from "./product/unit/product-unit-form/product-unit-form.component";
import { B2bProductUnitListComponent } from "./product/unit/product-unit-list/product-unit-list.component";
import { B2bOrderFormComponent } from "./order/b2b-order-form/b2b-order-form.component";
import { B2bOrderListComponent } from "./order/b2b-order-list/b2b-order-list.component";
import { B2bOrderPrintComponent } from "./order/b2b-order-print/b2b-order-print.component";
import { B2bBasicCommissionConfigFormComponent } from "./commission/basic-config/form/basic-commission-config-form.component";
import { B2bBasicCommissionConfigListComponent } from "./commission/basic-config/list/basic-commission-config-list.component";
import { B2bBasicCommissionConfigProductFormComponent } from "./commission/basic-config/product-form/basic-commission-product-form.component";
import { B2bBasicCommissionConfigPrintComponent } from "./commission/basic-config/print/basic-commission-config-print.component";
import { B2bServerComponent } from "./b2b-server.component";
import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { B2bServerRoutingModule } from "./b2b-server-routing.module";

export const b2bServerComponents = [
    B2bServerComponent,

    B2bServerPageListComponent,
    B2bCenterPageFormComponent,

    B2bProductListComponent,
    B2bProductFormComponent,
    B2bProductCategoryListComponent,
    B2bProductCategoryFormComponent,

    B2bProductGroupListComponent,
    B2bProductGroupFormComponent,

    B2bProductPropertyListComponent,
    B2bProductPropertyFormComponent,

    B2bProductBrandListComponent,
    B2bProductBrandFormComponent,

    B2bProductKeywordListComponent,
    B2bProductKeywordFormComponent,

    B2bProductUnitListComponent,
    B2bProductUnitFormComponent,

    B2bOrderListComponent,
    B2bOrderFormComponent,
    B2bOrderPrintComponent,

    B2bBasicCommissionConfigListComponent,
    B2bBasicCommissionConfigFormComponent,
    B2bBasicCommissionConfigPrintComponent,
    B2bBasicCommissionConfigProductFormComponent,

];

@NgModule({
    declarations: b2bServerComponents,
    exports: b2bServerComponents,
    imports: [
        ...commonModules,
        B2bServerRoutingModule,
    ],
    bootstrap: [B2bServerComponent],
    providers: [
        ...commonProviders,
    ],
})
export class B2bServerModule { }