import { Routes } from "@angular/router";
import { CollaboratorPageListComponent } from "./collaborator-page-list/collaborator-page-list.component";

export const pageRoutes: Routes = [
    {
        path: 'collaborator/page/page-list',
        component: CollaboratorPageListComponent,
        data: {
            reuse: true,
        },
    },
];