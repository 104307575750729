<div id="stringee_clound" class="overflow-hidden">
  <div id="app-minimize" class="cursor-pointer">
    <div class="wrap-info-minimize display-flex justify-content-space-between font-size-16">
      <div class="time bg-pink border-radius-16 color-white display-none">00:28</div>
      <div class="line-vertical display-none"></div>
      <div class="phone line-height-26 display-none">0966050828</div>
      <div class="min-no-calls" data-translate="no_calls">Không có cuộc gọi</div>
    </div>
  </div>
  <div id="app">
    <!--  TOP TOAST -->
    <section class="wrap-top-bar height-40 position-absolute top-0 width-100-percent">
      <div id="toast-area" class="toast display-none"> toast message </div>
    </section><!--  END TOAST  -->
    <!--  TOP BAR -->
    <section class="wrap-top-bar height-40 position-absolute top-0 width-100-percent">
      <div class="top-bar-status color-green" data-translate="not_connected">demo_7088</div>
      <div class="text-center top-bar-title"></div>
      <div class="wrap-action-top-right float-right mr position-absolute right-15 top-10">
        <div class="wrapper-dropdown wrapper-dropdown-call">
          <div id="dropdown-option-call" class="wrapper-dropdown-3" tabindex="1"> <span
              id="routingTypeLabelActived"><img
                src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-browser.png"
                class="icon-option-active" title="undefined"></span>
            <ul class="dropdown">
              <li data-value="1"><a href="javascript:void(0)"><img
                    src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-browser.png"
                    class="icon-option" title="Đổ cuộc gọi đến app và sipphone"><span
                    data-translate="route_to_app_and_sipphone">Đổ cuộc gọi đến app và sipphone</span></a></li>
              <li data-value="2"><a href="javascript:void(0)"><img
                    src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.png"
                    class="icon-option" title="Đổ cuộc gọi đến số điện thoại"><span
                    data-translate="route_to_phone_number">Đổ cuộc gọi đến số điện thoại</span></a></li>
              <li data-value="3"><a href="javascript:void(0)"><img
                    src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-app.png"
                    class="icon-option" title="Đổ cuộc gọi đến app"><span data-translate="route_to_app">Đổ cuộc gọi đến
                    app</span></a></li>
              <li data-value="4"><a href="javascript:void(0)"><img
                    src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-ipphone.png"
                    class="icon-option" title="Đổ cuộc gọi đến ipphone"><span data-translate="route_to_ipphone">Đổ cuộc
                    gọi đến ipphone</span></a></li>
            </ul>
          </div> ​
        </div> <button id="btnMinimize" class="btn-minimize border-none bg-transparent" title="Minimize"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-minimize.svg"> </button>
        <div class="wrapper-dropdown wrapper-dropdown-call auto" style="float:right">
          <div id="dropdown-auto-receive-call" class="wrapper-dropdown-3" tabindex="3"> <span id="settingAgent"> <img
                src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-setting-gray.svg"
                class="icon-option-active" title="personal settings"> </span>
            <ul class="dropdown setting-agent">
              <li data-value="1" class="auto-pick-call"> <label> <img
                    src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-setting-gray.svg"
                    class="icon-option" title="Auto Pick Call"> <span data-translate="auto_pick_call">Tự động nghe
                    máy</span> </label> <input type="checkbox" id="autopickcheckbox"> </li>
              <li data-value="2" class="item-transfer-ipphone"> <label> <img
                    src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-setting-gray.svg"
                    class="icon-option" title="Auto Pick Call"> <span data-translate="transfer_ip_phone">Gọi ra qua IP
                    Phone</span> </label> <input type="checkbox" id="transfer-ip-phone"> </li>
            </ul>
          </div> ​
        </div>
      </div> <!-- <input type="checkbox" id="autopickcheckbox" > -->
    </section><!--  END TOP BAR  -->
    <!-- PAGE CALLING  -->
    <section id="page-calling" class="page" [ngClass]="{'display-none': state != 'calling' && state != 'incomming-accept'}">
      <section class="wrap-status p-l-r-20 top-0 position-relative display-flex">
        <div class="wrap-status-text line-height-30"> <span class="status-text" data-translate="currently_in_call">Đang
            trong cuộc gọi..</span>
          <div class="line-red-short"></div>
        </div>
        <div class="wrap-status-time line-height-30 position-absolute right-0"> <span class="status-time"> </span>
        </div>
      </section>
      <section class="wrap-info p-20">
        <div class="info-name pb-10"> {{partnerName}} </div>
        <div class="wrap-location"> <span class="location-via" data-translate="via">thông qua</span> <span
            class="location-text" data-translate="location">Vietnam</span> {{partnerNumber}}</div>
      </section>
      <div class="line-dotted"></div>
      <section class="wrap-action display-flex justify-content-space-between"> <button id="btnMic"
          class="action-call text-center flex-basis p-10 bg-white border-none"> <img class="icon"
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-mic.svg"> <img
            class="icon-on display-none"
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-mic-off.svg"> </button> <button
          id="btnHold" class="action-call text-center flex-basis p-10 bg-white border-none"> <img class="icon"
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-pause.svg"> <img
            class="icon-on display-none"
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-pause-red.svg"> </button> <button
          id="btnKeypadInCall" class="action-call text-center flex-basis p-10 bg-white border-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-pad-gray.svg"> </button> <button
          id="btnTransfer" class="action-call text-center flex-basis p-10 bg-white border-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-back.svg"> </button> <button
          id="btnAddToCall" class="action-call text-center flex-basis p-10 bg-white border-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-add-to-call.svg"> </button>
        <!--        <button id="btnMore" class="action-call text-center flex-basis p-10 bg-white border-none position-relative">            <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-more.svg" />            <span class="drop-down-action display-none">                <span class="drop-down-rectangle"></span>                <ul>                    <li>                        <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-assign-call.svg" class="icon" />                        <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-assign-call-purple.svg" class="icon-hover display-none" />                        <span>Assign this call</span>                    </li>                    <li>                        <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-add-tag.svg" class="icon" />                        <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-add-tag-purple.svg" class="icon-hover display-none" />                        <span>Add a tag</span>                    </li>                    <li>                        <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-comment.svg" class="icon" />                        <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-comment-purple.svg" class="icon-hover display-none" />                        <span>Add a comment</span>                    </li>                </ul>            </span>        </button>-->
      </section>
      <section class="wrap-background bg-gradient-purple height-350 width-100-percent">
        <div class="wrap-avatar-round text-center"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/avatar.png" class="mt-80">
          <div class="callstart-time" style="margin-top: 35px;">
            <div class="location-queue"> </div>
          </div>
        </div>
      </section>
      <section class="wrap-diapad-when-calling display-none">
        <div class="wrap-typing-number pl-15 pr-15 mt-10 mb-10 display-flex justify-content-space-between"> <input
            type="text" value="" placeholder="*101#" class="font-size-24 color-black border-none"> <button
            class="btn-close border-none bg-transparent"> <img
              src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-close.svg"> </button> </div>
        <div class="wrap-diapad mb-20 diapad-size-medium">
          <div class="diapad-row"> <button (click)="keypress('1')" class="diapad-key" id="diapad-key-dtmf-1"> <span
                class="diapad-key-number"> 1
              </span> </button> <button (click)="keypress('2')" class="diapad-key" id="diapad-key-dtmf-2"> <span
                class="diapad-key-number"> 2
              </span> </button> <button (click)="keypress('3')" class="diapad-key" id="diapad-key-dtmf-3"> <span
                class="diapad-key-number"> 3
              </span> </button> </div>
          <div class="diapad-row"> <button (click)="keypress('4')" class="diapad-key" id="diapad-key-dtmf-4"> <span
                class="diapad-key-number"> 4
              </span> </button> <button (click)="keypress('5')" class="diapad-key" id="diapad-key-dtmf-5"> <span
                class="diapad-key-number"> 5
              </span> </button> <button (click)="keypress('6')" class="diapad-key" id="diapad-key-dtmf-6"> <span
                class="diapad-key-number"> 6
              </span> </button> </div>
          <div class="diapad-row"> <button (click)="keypress('7')" class="diapad-key" id="diapad-key-dtmf-7"> <span
                class="diapad-key-number"> 7
              </span> </button> <button (click)="keypress('8')" class="diapad-key" id="diapad-key-dtmf-8"> <span
                class="diapad-key-number"> 8
              </span> </button> <button (click)="keypress('9')" class="diapad-key" id="diapad-key-dtmf-9"> <span
                class="diapad-key-number"> 9
              </span> </button> </div>
          <div class="diapad-row">
            <button class="diapad-key" id="diapad-key-dtmf-star">
              <span class="diapad-key-number"> * </span>
            </button>
            <button (click)="keypress('*')" class="diapad-key" id="diapad-key-dtmf-0">
              <span class="diapad-key-number"> 0 </span>
            </button>
            <button (click)="keypress('0')" class="diapad-key" id="diapad-key-dtmf-sharp">
              <span class="diapad-key-number" (click)="keypress('#')"> # </span>
            </button>
          </div>
        </div>
      </section>
    </section><!-- END PAGE CALLING  -->
    <!--  PAGE INCOMMING CALL  -->
    <section id="page-incomming-call" class="page" [ngClass]="{'display-none': state != 'incomming'}">
      <div class="incomming-call-info box-shadow1 border-radius-18 bg-gradient-purple overflow-hidden mb-20">
        <div class="wrap-info-text bg-white pt-20 pb-20">
          <section class="wrap-status p-l-r-20 top-0 position-relative display-flex ">
            <div class="wrap-status-text line-height-30"> <span class="status-text"
                data-tranlate="currently_in_call">Currently in call..</span>
              <div class="line-red-short"></div>
            </div>
            <div class="wrap-agent-time line-height-30 position-absolute right-0"> <span class="agent-time"> </span>
            </div>
          </section>
          <section class="wrap-info p-20">
            <div class="info-name pb-10"> Pattrick Penna </div>
            <div class="wrap-location"> <span class="location-via" data-translate="via">thông qua</span> <span
                class="location-text" data-translate="location">Vietnam</span> </div>
          </section>
        </div>
        <section class="wrap-background bg-gradient-purple height-350 width-100-percent">
          <div class="wrap-avatar-round text-center"> <img
              src="https://static.stringee.com/stringeex/web_phone/lastest/images/avatar.png" class="mt-50"> </div>
          <div class="callstart-time">
            <div class="location-queuetime"> </div>
            <div class="location-queue"> </div>
          </div>
        </section>
      </div>
      <div class="incomming-call-action display-flex justify-content-space-between">
        <button id="btn-incomming-decline" (click)="reject()"
          class="btn-action-incomming btn-round btn-red btn-size-55 display-table-cell border-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg" class="icon"
            style="transform: rotate(135deg)"> </button> <img
          src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-dotted-end.svg"> <img
          src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-dotted-call.svg">
          <button (click)="accept()"
          id="btn-incomming-accept"
          class="btn-action-incomming btn-round btn-green btn-size-55 display-table-cell border-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg" class="icon"> </button>
      </div>
    </section><!-- END PAGE INCOMMING CALL -->
    <!-- PAGE DIAPAD -->
    <section id="page-diapad" class="page" [ngClass]="{'display-none': state != 'normal'}">
      <div class="wrap-typing-number pl-15 pr-15 mt-10 mb-30 display-flex justify-content-space-between"> <input
          type="text" [value]="phonenumber" placeholder="+84966050926" class="font-size-24 color-black border-none">
        <button (click)="backspacekey()" class="btn-close border-none bg-transparent"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-close.svg"> </button> </div>
      <div class="wrap-diapad mb-10">
        <div class="diapad-row">
          <button (click)="keypress('1')" class="diapad-key" id="diapad-key-1">
            <span class="diapad-key-number"> 1 </span>
            <span class="diapad-key-text"> </span>
          </button>
          <button (click)="keypress('2')" class="diapad-key" id="diapad-key-2">
            <span class="diapad-key-number"> 2 </span>
            <span class="diapad-key-text"> ABC </span>
          </button>
          <button (click)="keypress('3')" class="diapad-key" id="diapad-key-3">
            <span class="diapad-key-number"> 3 </span>
            <span class="diapad-key-text"> DEF </span>
          </button>
        </div>
        <div class="diapad-row">
          <button (click)="keypress('4')" class="diapad-key" id="diapad-key-4">
            <span class="diapad-key-number"> 4 </span>
            <span class="diapad-key-text"> GHI </span>
          </button>
          <button (click)="keypress('5')" class="diapad-key" id="diapad-key-5">
            <span class="diapad-key-number"> 5 </span>
            <span class="diapad-key-text"> JKL </span>
          </button>
          <button (click)="keypress('6')" class="diapad-key" id="diapad-key-6">
            <span class="diapad-key-number"> 6 </span>
            <span class="diapad-key-text"> MNO </span>
          </button>
        </div>
        <div class="diapad-row">
          <button (click)="keypress('7')" class="diapad-key" id="diapad-key-7">
            <span class="diapad-key-number"> 7 </span>
            <span class="diapad-key-text"> PQRS </span>
          </button>
          <button (click)="keypress('8')" class="diapad-key" id="diapad-key-8">
            <span class="diapad-key-number"> 8 </span>
            <span class="diapad-key-text"> TUV </span>
          </button>
          <button (click)="keypress('9')" class="diapad-key" id="diapad-key-9">
            <span class="diapad-key-number"> 9 </span>
            <span class="diapad-key-text"> WXYZ </span>
          </button>
        </div>
        <div class="diapad-row">
          <button (click)="keypress('*')" class="diapad-key" id="diapad-key-star">
            <span class="diapad-key-number"> * </span>
            <span class="diapad-key-text"> </span>
          </button>
          <button (click)="keypress('0')" class="diapad-key" id="diapad-key-0">
            <span class="diapad-key-number"> 0 </span>
            <span class="diapad-key-text" style="font-size: 18px;">
              <span> <!-- + --> </span>
            </span>
          </button>
          <button (click)="keypress('#')" class="diapad-key" id="diapad-key-sharp">
            <span class="diapad-key-number"> # </span>
            <span class="diapad-key-text"> </span>
          </button> </div>
      </div>
      <div class="wrap-call-using-dropdown position-relative display-none">
        <div id="list-from-numbers" class="call-using-dropdown box-shadow3 border-radius-8 bg-white">
          <div class="call-using-dropdown-item cursor-pointer p-15 pt-10 pb-10">
            <div><span class="call-using-text-name display-block">Not available</span><span
                class="call-using-text-phone display-block">Not available</span></div>
          </div>
        </div>
        <div class="icon-dropdown right-40"> </div>
      </div>
      <div class="wrap-call-using pl-20 pr-20 position-relative cursor-pointer">
        <div class="call-using-text mb-5" data-translate="using_number">Sử dụng số</div>
        <div class="call-using-select p-15 display-flex justify-content-space-between">
          <div> <span id="from-number-callout-alias" class="call-using-text-name display-block">Not available</span>
            <span id="from-number-callout" class="call-using-text-phone display-block">Not available</span> </div> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-dropdown.svg">
        </div>
      </div>
    </section><!-- END PAGE DIAPAD --->
    <!-- PAGE CONTACT -->
    <section id="page-contact" class="page" [ngClass]="{'display-none': !contactScreenActivated}">
      <div class="wrap-contact-filter p-l-r-20 mb-20 display-table">
        <div class="contact-filter display-flex font-size-12"> <button
            class="contact-filter-item active p-5 p-l-r-10 mr-10 mt-5 border-none">Tất cả</button> <button
            class="contact-filter-item p-5 p-l-r-10 mt-5 border-none">Nhóm</button> </div>
        <div class="wrap-input-search"> <input type="text" id="inputSearchContact"
            class="input-search position-absolute font-size-18 pl-15 right-0 top-0 display-none border-none outline-none">
          <button id="btnSearchContact"
            class="bg-transparent border-none position-absolute btn-finter-search right-15 top-5 p-10 outline-none cursor-pointer"><img
              class="pointer-events-none"
              src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-search.svg"></button> </div>
      </div>
      <div class="line-dotted"></div>
      <div class="wrap-contact-list">
        <div class="wrap-contact-list-content">
          <div class="wrap-contact-group display-flex pl-15 pt-20">
            <div class="contact-group-name"> A </div>
            <div class="contact-group-item width-100-percent">
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-purple border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-offline"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-light-green border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-online"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-light-blue border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-offline"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap-contact-group display-flex pl-15 pt-20">
            <div class="contact-group-name"> B </div>
            <div class="contact-group-item width-100-percent">
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-purple border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-offline"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-light-green border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-online"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-light-blue border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-offline"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap-contact-group display-flex pl-15 pt-20">
            <div class="contact-group-name"> C </div>
            <div class="contact-group-item width-100-percent">
              <div class="contact-item display-flex"> <button
                  class="wrap-contact-item-avatar bg-purple border-radius-12 width-35 height-35 text-center"> <span
                    class="contact-item-avatar"><img
                      src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg"></span> <span
                    class="contact-item-status status-offline"></span> </button>
                <div class="wrap-contact-item-info">
                  <div class="contact-item-name"> Pattrick Penna </div>
                  <div class="contact-item-phone"> Stringee </div>
                  <div class="contact-item-call visibility-hidden"> <button
                      class="btn-round btn-green btn-call-item display-table-cell border-none cursor-pointer"> <img
                        src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg"
                        class="icon"> </button> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- END PAGE CONTACT -->
    <!-- PAGE ACTIVITY -->
    <section id="page-activity" class="page" [ngClass]="{'display-none': !activeityScreenActivated}">
      <!-- <div class="wrap-contact-filter p-l-r-20 mb-20 display-table">        <div class="contact-filter display-flex font-size-12">            <button class="contact-filter-item active p-5 p-l-r-10 mt-5 mr-10 border-none">Inbox</button>            <button class="contact-filter-item p-5 p-l-r-10 mt-5 border-none">All</button>        </div>    </div> -->
      <div class="line-dotted"></div>
      <div class="wrap-activity-list">
        <div class="wrap-activity-list-content">
          <div id="list-recent-calls"> </div>
        </div>
      </div>
    </section><!-- <div class="call-wrapper">            </div> -->
    <!-- <div class="activity-date">20/06/2018</div>                <div  class="wrap-activity-group" >                    <div class="wrap-activity-item display-flex">                        <div class="activity-icon">                            <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-call-out.svg" />                        </div>                        <div class="activity-info">                            <div class="activity-phone">+84 966050824</div>                            <div class="activity-via">Giangle via Stringee</div>                        </div>                        <div class="activity-time">                            6:40 PM                        </div>                        <div class="activity-more">                            <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-arrow-point-to-right.svg" />                        </div>                     </div>                </div> -->
    <!-- END PAGE ACTIVITY -->
    <!-- TOOLBAR FOOTER -->
    <section
      class="wrap-toolbar-bottom position-absolute bottom-0 height-100 width-100-percent box-shadow2 z-index-100 bg-white-x"
      [ngClass]="{'bg-gradient-purple': state == 'calling', 'bg-white': state == 'normal', 'display-none': state == 'incomming'}">
      <div class="wrap-toolbar text-center display-flex justify-content-space-evenly"> <button id="btnToolActivity"
          class="toolbar-item display-table-cell border-none bg-transparent"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-list-gray.svg" class="icon-gray">
          <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-list.svg" class="icon">
        </button>
        <button id="btnToolSetting"
          class="toolbar-item display-table-cell border-none bg-transparent display-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-setting-gray.svg"
            class="icon-gray"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-setting.svg" class="icon">
        </button>
        <button id="btnToolCall" (click)="call()"
          class="btn-action-incomming btn-round btn-green btn-size-55 display-table-cell border-none mt-15-negative"
          [ngClass]="{'btn-green': state == 'normal', 'btn-red': state == 'calling' || state == 'incomming-accept'}">
          <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg" class="icon-gray" [ngStyle]="{'transform': state == 'calling' || state == 'incomming-accept' ? 'rotate(135deg)' : 'rotate(0deg)'}">
          <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg" class="icon">
        </button>
        <!--		<button id="btnToolEndCall" class="btn-action-incomming btn-round btn-red btn-size-55 display-table-cell border-none mt-15-negative">            <img src="images/icon-phone.svg" class="icon-gray" />            <img src="images/icon-phone.svg" class="icon" />        </button>-->
        <button id="btnToolPad"
          class="btn-action-incomming btn-round btn-green btn-size-55 display-table-cell border-none mt-15-negative display-none">
          <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-pad.svg" class="icon-gray"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-pad.svg" class="icon"> </button>
        <button id="btnToolContact" class="toolbar-item display-table-cell border-none bg-transparent"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user-gray.svg" class="icon-gray">
          <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-user.svg" class="icon">
        </button> <button id="btnToolAdd"
          class="toolbar-item display-table-cell border-none bg-transparent display-none"> <img
            src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-add-gray.svg" class="icon-gray">
          <img src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-add.svg" class="icon"> </button>
      </div>
    </section><!-- END TOOLBAR FOOTER -->
    <!-- Select call type -->
    <section class="wrap-option-call position-absolute z-index-100 display-none"> <button
        class="btn-close-option-call border-none bg-transparent float-right"> <img
          src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-close.svg"> </button>
      <div class="mt-150"> <button class="btn-otption-call btn-free-voice-call"> <span class="btn-icon"> <img
              src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg" class="icon-gray"
              width="15"> </span> <span data-translate="free_voice_call">Gọi thoại miễn phí</span> </button> <button
          class="btn-otption-call btn-free-video-call"> <span class="btn-icon"> <img
              src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-videocall.svg" class="icon-gray"
              width="15"> </span> <span data-translate="free_video_call">Gọi video miễn phí</span> </button> <button
          class="btn-otption-call btn-free-callout"> <span class="btn-icon"> <img
              src="https://static.stringee.com/stringeex/web_phone/lastest/images/icon-phone.svg" class="icon-gray"
              width="15"> </span> <span data-translate="call_out">Gọi ra</span> </button> </div>
    </section><!-- Select call type -->
  </div>
  <audio preload="auto" id="ringtonePlayer" playsinline="" style="width: 1px"
    src="https://static.stringee.com/stringeex/web_phone/lastest/audio/Antique-Phone5.mp3" loop=""></audio>
  <video id="remoteVideo" playsinline="" autoplay="" style="width: 1px"></video>


</div>
<audio id="remoteVideo" style="height: 1px; height: 1px;"></audio>
<video id="localVideo" muted="muted" style="height: 1px; height: 1px;"></video>
