<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon"
            [title]="title ? title : ('Helpdesk.UserPhoneExtension.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
            [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Bạn phải nhập
                      tên</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                </div>
              </div>
              <!-- <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Email'), 'warning')">
                  <label class="label"><span class="valid">Email (*)</span><span class="invalid">Bạn phải nhập email</span></label>
                  <input type="text" nbInput fullWidth formControlName="Email" placeholder="Email">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Phone'), 'warning')">
                  <label class="label"><span class="valid">Số điện thoại (*)</span><span class="invalid">Bạn phải nhập số điện thoại</span></label>
                  <input type="text" nbInput fullWidth formControlName="Phone" placeholder="Số điện thoại">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Password'), 'warning')">
                  <label class="label"><span class="valid">Mật khẩu (*)</span><span class="invalid">Bạn phải nhập mật khẩu</span></label>
                  <input type="password" nbInput fullWidth formControlName="Password" placeholder="Mật khẩu" autocomplete="new-password">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formItem.get('Password').value && formItem.get('Password').value !== formItem.get('RePassword').value ? 'warning' : ''">
                  <label class="label"><span class="valid">Nhập lại mật khẩu (*)</span><span class="invalid">Bạn phải nhập lại mật khẩu (*)</span></label>
                  <input type="password" nbInput fullWidth formControlName="RePassword" placeholder="Mật khẩu" autocomplete="new-password">
                </div>
              </div> -->
            </div>



            <hr>
            <label class="label">Danh sách số mở rộng</label>
            <div class="row">
              <!-- <div class="col-sm-1">STT</div> -->
              <div class="col-sm-11">
                <div class="row">
                  <div class="col-sm-3"><label class="label">Domain</label></div>
                  <div class="col-sm-3"><label class="label">Extension</label></div>
                  <div class="col-sm-3">Ext</div>
                  <div class="col-sm-3">Password</div>
                </div>
              </div>
              <div class="col-sm-1" style="text-align: right;">
                <nb-icon pack="eva" icon="plus-circle-outline" (click)="addUserPhoneExtensionFormGroup(i)"
                  status="success" style="margin-right: 0.8rem;"></nb-icon>
              </div>
            </div>

            <div formArrayName="Extensions" class="form-details">
              <div class="form-detail-item" *ngFor="let phoneExt of getUserPhoneExtensions(i).controls; let ir=index"
                [formGroup]="phoneExt">
                <hr>
                <div class="row">
                  <!-- <div class="col-sm-1">
                    <div class="form-group">
                      <label class="label">Stt</label>
                      <input nbInput fullWidth disabled="true" value="{{ ir + 1 }}" style="text-align: center;">
                    </div>
                  </div> -->
                  <div class="col-sm-11">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Domain</label>
                          <ngx-select2 [data]="domainList" formControlName="DomainUuid"
                            [select2Option]="select2OptionPbxDomain" (selectChange)="onPbxDomainChange($event, phoneExt)">
                          </ngx-select2>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Extension</label>
                          <ngx-select2 [data]="phoneExt['extensionList']" formControlName="Extension"
                            [select2Option]="extensionListConfig">
                          </ngx-select2>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Ext</label>
                          <input type="text" nbInput fullWidth formControlName="Extension" placeholder="Ext">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Password</label>
                          <input type="text" nbInput fullWidth formControlName="Password" placeholder="Password">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Dispplay name</label>
                          <input type="text" nbInput fullWidth formControlName="DisplayName" placeholder="Display name">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Host</label>
                          <input type="text" nbInput fullWidth formControlName="Host" placeholder="Host">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Port</label>
                          <input type="text" nbInput fullWidth formControlName="Port" placeholder="Port">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Transport</label>
                          <input type="text" nbInput fullWidth formControlName="Transport" placeholder="Transport">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="row" style="text-align: right;">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label class="label">CM</label>
                          <button nbButton status="danger" hero size="medium"
                            (click)="removeUserPhoneExtensionGroup(i, ir)" style="margin-bottom: 3px;">
                            <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <button nbButton status="success" (click)="addUserPhoneExtensionFormGroup(i)" size="medium"
                          style="margin-bottom: 3px;" hero>
                          <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>



          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)"
              hero>{{'Common.addOne' | translate | headtitlecase}}</button>
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero
              (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;"
              hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
