import { CurrencyPipe } from "@angular/common";
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NbThemeService, NbDialogRef } from "@nebular/theme";
import { GridApi, ColumnApi, IDatasource, ColDef, SuppressKeyboardEventParams, IRowNode, RowNode, CellDoubleClickedEvent, IGetRowsParams } from "@ag-grid-community/core";
import { takeUntil, filter, take } from "rxjs/operators";
import { BaseComponent } from "../../../../lib/base-component";
import { ActionControl } from "../../../../lib/custom-element/action-control-list/action-control.interface";
import { AgCheckboxCellRenderer } from "../../../../lib/custom-element/ag-list/cell/checkbox.component";
import { AgTextCellRenderer } from "../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeSelectionColDef } from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import { ContactModel, ContactGroupModel } from "../../../../models/contact.model";
import { FileModel } from "../../../../models/file.model";
import { ApiService } from "../../../../services/api.service";
import { CommonService } from "../../../../services/common.service";
import { RootServices } from "../../../../services/root.services";
import { AdminProductService } from "../../../admin-product/admin-product.service";
import { DialogFormComponent } from "../../../../lib/component/dialog/dialog-form/dialog-form.component";
import { AgDynamicListComponent } from "../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component";
import { Module } from "@ag-grid-community/core";
import * as XLSX from 'xlsx';
import { AgDateCellRenderer } from "../../../../lib/custom-element/ag-list/cell/date.component";
import { AgCurrencyCellRenderer } from "../../../../lib/custom-element/ag-list/cell/currency.component";
import { AgNumberCellRenderer } from "../../../../lib/custom-element/ag-list/cell/number.component";
import { Model } from "../../../../models/model";

@Component({
  selector: 'ngx-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
  providers: [CurrencyPipe],
})
export class ImportDataComponent extends BaseComponent implements OnInit, AfterViewInit {

  componentName: string = 'ImportDataComponent';
  @Input() inputMode: 'dialog' | 'page' | 'inline';
  @Input() inputProducts: ContactModel[];
  @Input() onDialogSave: (newData: ContactModel[]) => void;
  @Input() onDialogClose: () => void;

  @ViewChild('agProductList') agProductList: AgDynamicListComponent<any>;

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public themeService: NbThemeService,
    public formBuilder: FormBuilder,
    public adminProductService: AdminProductService,
    public currencyPipe: CurrencyPipe,
    public ref?: NbDialogRef<ImportDataComponent>,
  ) {
    super(rsv, cms, router, apiService);

    /** AG-Grid */
    this.updateGridColumn();

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = this.cacheBlockSize = 1000;
    /** End AG-Grid */

    // this.array = this.formBuilder.control([]);

  }

  configFormGroup: FormGroup = null;
  config = { IsMapByContactName: false };
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  processing = false;
  array: ContactModel[];

  ngOnInit() {
    super.ngOnInit();
    this.configFormGroup = this.formBuilder.group<any>({
      IsMapByProductName: [false],
    });
    this.configFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(config => this.config = config);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  contactGroupList: ContactGroupModel[] = [];
  async init() {
    await this.adminProductService.unitList$.pipe(filter(f => !!f), take(1)).toPromise();
    this.contactGroupList = await this.apiService.getPromise<ContactGroupModel[]>('/contact/groups', { includeIdText: true });
    return super.init().then(rs => {
      // this.actionButtonList.unshift({
      //   name: 'chooseFile',
      //   status: 'primary',
      //   label: this.cms.textTransform(this.cms.translate.instant('Choose file'), 'head-title'),
      //   icon: 'copy-outline',
      //   title: this.cms.textTransform(this.cms.translate.instant('Choose file'), 'head-title'),
      //   size: 'medium',
      //   disabled: () => false,
      //   hidden: () => false,
      //   click: () => {

      //     return false;
      //   },
      // });
      return rs;
    });
  }

  /** AG-Grid */
  productActionButtonList: ActionControl[] = [];
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public modules: Module[] = [];
  public dataSource: IDatasource;
  public columnDefs: ColDef[];
  public rowSelection = 'single';
  // public rowModelType = 'infinite';
  public rowModelType = 'clientSide';
  public paginationPageSize: number;
  public cacheOverflowSize = 2;
  public maxConcurrentDatasourceRequests = 2;
  public infiniteInitialRowCount = 1;
  public maxBlocksInCache: number;
  public cacheBlockSize: number;
  public rowData: ContactModel[];
  public gridParams;
  public multiSortKey = 'ctrl';
  public rowDragManaged = false;
  public getRowHeight;
  public rowHeight: number = 50;
  public hadRowsSelected = false;
  public pagination: boolean;
  public emailAddressListDetails: ContactModel[] = [];
  // public suppressKeyboardEvent = (event) => {
  //   console.log(event);
  // };
  public defaultColDef = {
    sortable: true,
    resizable: true,
    // suppressSizeToFit: true,

    suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
      if (!params.editing) {

        let isDeleteKey = params.event.key === 'Delete';

        // Delete selected rows with back space
        if (isDeleteKey) {
          // const selectedRows: RowNode[] = params.api.getSelectedRows();
          const selectedNodes: IRowNode[] = params.api.getSelectedNodes();
          const currentIndex = selectedNodes[0].rowIndex;
          let prevNode: IRowNode, prevIndex: number, nextNode: IRowNode, nextIndex: number, wasFoundCurrnet = false, wasFoundPrevNode = false;

          // Find Next and Prev Node
          params.api.forEachNode((node, index) => {
            if (wasFoundCurrnet === true) {
              nextNode = node;
              nextIndex = index;
              wasFoundCurrnet = null;
              // wasFoundPrevNode = true;
            }
            if (index === currentIndex) {
              wasFoundCurrnet = true;
            }
            if (wasFoundCurrnet === false) {
              prevNode = node;
              prevIndex = index;
            }
            // nextId = index;
          });

          // Remove
          params.api.applyTransaction({ remove: [selectedNodes[0].data] });

          // Select alternate node
          if (nextNode) {
            nextNode.setSelected(true, true);
            params.api.ensureIndexVisible(nextIndex);
          } else if (prevNode) {
            prevNode.setSelected(true, true);
            params.api.ensureIndexVisible(prevIndex);
          }
          return true;
        }

        // Barcode scan detative
        // this.barcodeScanDetective(params.event.key, barcode => {
        //   this.barcodeProcess(barcode);
        // });

        return false;
      }
    }

  };
  public getRowNodeId = (item: ContactModel) => {
    // return this.cms.getObjectId(item.Sku) + '-' + this.cms.getObjectId(item.WarehouseUnit);
    return item.No;
  }
  public getRowStyle = (params: { node: RowNode }) => {
    // if (params.node.rowIndex == this.activeDetailIndex) {
    //   return { background: '#ffc107' };
    // }
  };

  public cellDoubleClicked = (params: CellDoubleClickedEvent) => {
    console.log(params);

    if (params.colDef.field == 'duplicate') {
      this.cms.showDialog('Xử lý trùng', 'Bạn có muốn xác nhận lại trạng thái trùng không ?', [
        {
          label: 'Trùng',
          action: () => {
            params.data.duplicate = true;
            params.api.applyTransaction({ update: [params.data] });
          }
        },
        {
          label: 'Không trùng',
          action: () => {
            params.data.duplicate = false;
            params.api.applyTransaction({ update: [params.data] });

          }
        }
      ]);
    }
  };


  public components = {
    // loadingCellRenderer: (params) => {
    //   if (params.value) {
    //     return params.value;
    //   } else {
    //     return '<img src="assets/images/loading.gif">';
    //   }
    // },
    // textRender: (params) => {

    //   if (params.colDef.field == 'duplicate') {
    //     return params.value && 'Trùng ?' || '';
    //   }
    //   if (Array.isArray(params.value)) {
    //     return params.value.map(m => this.cms.getObjectText(m)).join(', ');
    //   }
    //   return this.cms.getObjectText(params.value);
    //   // }
    // },
    // idRender: (params) => {
    //   if (Array.isArray(params.value)) {
    //     return params.value.map(m => this.cms.getObjectId(m)).join(', ');
    //   } else {
    //     return this.cms.getObjectId(params.value);
    //   }
    // },
    // numberRender: (params) => {
    //   return params.value;
    // },
    // imageRender: (params) => {
    //   let image = params.value;
    //   if (Array.isArray(params.value)) {
    //     image = params.value[0];
    //   }
    //   if (typeof image == 'string') {
    //     return '<img style="height: 45px" src="' + image + '">';
    //   }
    //   return image && image?.Thumbnail ? ('<img style="height: 45px" src="' + image?.Thumbnail + '">') : '';
    // },

    // btnCellRenderer: AgButtonCellRenderer,
    // ckbCellRenderer: AgCheckboxCellRenderer,
    // agColumnHeader: CustomHeader,
  };
  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.updateGridColumn();
    this.productActionButtonList = [];
    this.loadList();

  }
  onColumnResized() {
    this.gridApi.resetRowHeights();
  }
  onRowSelected() {
    this.updateActionState();
  }
  updateActionState() {
    this.hadRowsSelected = this.getSelectedRows().length > 0;
  }
  getSelectedRows() {
    return this.gridApi.getSelectedRows();
  }

  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getAllColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });

    this.gridColumnApi!.autoSizeColumns(allColumnIds, skipHeader);
  }

  loadList(callback?: (list: ContactModel[]) => void) {

    if (this.gridApi) {


      let details: ContactModel[] = (this.array || []).map((detail: ContactModel) => {

        return detail;
      });
      this.gridApi.setRowData(details);

      this.autoSizeAll(false)

    }

  }

  initDataSource() {
    this.dataSource = {
      rowCount: null,
      getRows: (getRowParams: IGetRowsParams) => {
        console.info('asking for ' + getRowParams.startRow + ' to ' + getRowParams.endRow);

        let details: ContactModel[] = (this.array as []).slice(getRowParams.startRow, getRowParams.endRow);

        let lastRow = -1;
        if (details.length < this.paginationPageSize) {
          lastRow = getRowParams.startRow + details.length;
        }
        getRowParams.successCallback(details, lastRow);
        this.gridApi.resetRowHeights();

      },
    };
  }
  /** End AG-Grid */

  updateGridColumn() {
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'ID',
        field: 'Id',
        width: 100,
        valueGetter: 'node.data.No',
        // sortingOrder: ['desc', 'asc'],
        // initialSort: 'desc',
      },
      {
        headerName: 'Mã đơn mua',
        field: 'PurchaseCode',
        width: 200,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
      },
      {
        headerName: 'Mã đơn bán',
        field: 'SalesCode',
        width: 200,
        filter: 'agTextColumnFilter',
        // pinned: 'left',
      },
      {
        cellRenderer: AgDateCellRenderer,
        headerName: 'Ngày mua',
        field: 'DateOfPurchase',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        cellRenderer: AgDateCellRenderer,
        headerName: 'Ngày bán',
        field: 'DateOfSale',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'Nhà cung cấp',
        field: 'Supplier',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'Tên NCC',
        field: 'SupplierName',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'SĐT NCC',
        field: 'SupplierPhone',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'Khách hàng',
        field: 'Customer',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'Tên khách hàng',
        field: 'CustomerName',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'SĐT Khách hàng',
        field: 'CustomerPhone',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'ID Sản phẩm',
        field: 'Product',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'Tên sản phẩm',
        field: 'ProductName',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        headerName: 'ĐVT',
        field: 'Unit',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        cellRenderer: AgNumberCellRenderer,
        headerName: 'SL',
        field: 'Quantity',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        // pinned: 'left',
      },
      {
        cellRenderer: AgCurrencyCellRenderer,
        headerName: 'Giá mua',
        field: 'PurchasePrice',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        cellRenderer: AgCurrencyCellRenderer,
        headerName: 'Giá bán',
        field: 'SalesPrice',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        cellRenderer: AgTextCellRenderer,
        headerName: 'Nghiệp vụ mua',
        field: 'PurchaseBusiness',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        cellRenderer: AgTextCellRenderer,
        headerName: 'Nghiệp vụ bán',
        field: 'SalesBusiness',
        width: 200,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        headerName: 'Import',
        field: 'IsImport',
        width: 90,
        filter: 'agTextColumnFilter',
        pinned: 'right',
        // headerCheckboxSelection: true,
        // agColumnHeader: CustomHeader,
        cellRenderer: AgCheckboxCellRenderer,
        cellRendererParams: {
          changed: (checked: boolean, params: { node: RowNode, data: ContactModel, api: GridApi } & { [key: string]: any }) => {
            // alert(`${field} was clicked`);
            console.log(params);

            if (checked) {
            } else {
            }
          },
        },
        headerComponentParams: { enabledCheckbox: true }
      },
      {
        cellRenderer: AgCheckboxCellRenderer,
        headerComponentParams: { enabledCheckbox: false },
        headerName: 'Mua hàng',
        field: 'IsPurchase',
        width: 150,
        filter: 'agTextColumnFilter',
        pinned: 'right',
        autoHeight: true,
      },
      {
        cellRenderer: AgCheckboxCellRenderer,
        headerComponentParams: { enabledCheckbox: false },
        headerName: 'Bán hàng',
        field: 'IsSales',
        width: 150,
        filter: 'agTextColumnFilter',
        pinned: 'right',
        autoHeight: true,
      },
      {
        headerName: 'Result',
        field: 'Result',
        width: 150,
        filter: 'agTextColumnFilter',
        pinned: 'right',
        autoHeight: true,
      },
      {
        headerName: 'Message',
        field: 'Message',
        width: 150,
        filter: 'agTextColumnFilter',
        pinned: 'right',
        autoHeight: true,
      },
      // {
      //   headerName: 'Người tạo',
      //   field: 'Creator',
      //   // pinned: 'left',
      //   width: 200,
      //   cellRenderer: AgTextCellRenderer,
      //   filter: AgSelect2Filter,
      //   filterParams: {
      //     select2Option: {
      //       ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
      //         placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
      //           item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
      //           return item;
      //         }
      //       }),
      //       multiple: true,
      //       logic: 'OR',
      //       allowClear: true,
      //     }
      //   },
      // },
      // {
      //   headerName: 'Ngày tạo',
      //   field: 'Created',
      //   width: 180,
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     inRangeFloatingFilterDateFormat: 'DD/MM/YY',
      //   },
      //   cellRenderer: AgDateCellRenderer,
      // },
    ];

  }

  generateUnitConversionControls(index: number, columnList: any[]) {
    return [
      {
        name: 'UnitConversion' + index,
        label: 'Đơn vị tính chuyển đổi (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'UnitConversion' + index),
        // focus: true,
        class: 'col-md-2',
        option: {
          data: columnList,
          placeholder: 'Chọn đơn vị tính chuyển đổi (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
      {
        name: 'ConversionRatio' + index,
        label: 'Tỷ lệ chuyển đổi (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'ConversionRatio' + index),
        // focus: true,
        class: 'col-md-2',
        option: {
          data: columnList,
          placeholder: 'Chọn tỷ lệ chuyển đổi (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
      {
        name: 'IsDefaultSales' + index,
        label: 'Mặc định bán (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'IsDefaultSales' + index),
        // focus: true,
        class: 'col-md-2',
        option: {
          data: columnList,
          placeholder: 'Chọn cột mặc định bán (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
      {
        name: 'IsDefaultPurchase' + index,
        label: 'Mặc định mua (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'IsDefaultPurchase' + index),
        // focus: true,
        class: 'col-md-2',
        option: {
          data: columnList,
          placeholder: 'Chọn cột mặc định mua (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
      {
        name: 'IsManageByAccessNumber' + index,
        label: 'Quản lý theo số truy xuất (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'IsManageByAccessNumber' + index),
        // focus: true,
        class: 'col-md-2',
        option: {
          data: columnList,
          placeholder: 'Chọn cột quản lý theo số truy xuất (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
      {
        name: 'IsAutoAdjustInventory' + index,
        label: 'Tự trừ kho tự động (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'IsAutoAdjustInventory' + index),
        // focus: true,
        class: 'col-md-1',
        option: {
          data: columnList,
          placeholder: 'Chọn cột trừ kho tự động (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
      {
        name: 'IsExpirationGoods' + index,
        label: 'Có hạn sử dụng (' + index + ')',
        placeholder: '',
        type: 'select2',
        initValue: columnList.find(f => this.cms.getObjectId(f) == 'IsExpirationGoods' + index),
        // focus: true,
        class: 'col-md-1',
        option: {
          data: columnList,
          placeholder: 'Chọn cột có hạn sử dụng (' + index + ')...',
          allowClear: true,
          width: '100%',
          dropdownAutoWidth: true,
          minimumInputLength: 0,
          withThumbnail: false,
          keyMap: {
            id: 'id',
            text: 'text',
          },
        }
      },
    ];
  }

  compareContact(oldProduct: ContactModel, newProduct: ContactModel) {
    if (this.config.IsMapByContactName) {
      return newProduct.Code &&
        (oldProduct.Code?.toLowerCase() == newProduct.Code?.toLowerCase())
        || (oldProduct.Name && newProduct.Name
          && (this.cms.smartFilter(oldProduct.Name, newProduct.Name) || this.cms.smartFilter(newProduct.Name, oldProduct.Name)));
    } else {
      return (oldProduct.Code && newProduct.Code) ? (oldProduct.Code.trim().toLowerCase() == newProduct.Code.trim().toLowerCase()) : (
        (oldProduct.Phone && newProduct.Phone) ? ((oldProduct.Phone + '').replace(/[^0-9]/, '').toLowerCase() == (newProduct.Phone + '').replace(/[^0-9]/, '').toLowerCase()) : false
      );
    }
  }

  workBook: XLSX.WorkBook = null;
  jsonData = null;
  sheets: string[] = null;
  sheet: any[] = null;
  chooseSheet: string = null;
  fileName: string;
  mapping: { [key: string]: any };

  contactMap: { [key: string]: ContactModel };

  onFileChange(ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    this.fileName = file.name;
    reader.onload = async (event) => {
      try {
        this.processing = true;
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.jsonData = this.workBook.SheetNames.reduce((initial, name) => {
          const sheet = this.workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          return initial;
        }, {});
        this.processing = false;

        this.sheets = Object.keys(this.jsonData);
        if (this.sheets.length > 1) {
          this.sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  // const sheet = form.get('Sheet');
                  // const description = form.get('Description');
                  // sheet.setValue(null);
                  // description.setValue(parseFloat(activeDetail.get('Description').value));
                  return true;
                },
                onClose: async (form, dialog) => {
                  // ev.target.
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: this.sheets[0],
                    // focus: true,
                    option: {
                      data: this.sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      this.chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(this.jsonData[this.chooseSheet]);

                      // formDialogConpoent.dismiss();

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          this.sheet = this.jsonData[this.sheets[0]];
          this.chooseSheet = this.sheets[0];
        }

        // Confirm mapping
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(this.workBook.Sheets[this.chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string, index) => {
          const id = m.split('/')[0];
          const colindex = index;
          const text = m;
          return { id, text, colindex };
        });

        try {

          this.mapping = columnList;
          for (const i in this.mapping) {
            // this.mapping[i] = this.cms.getObjectText(this.mapping[i]);
            if (i !== 'UnitConversions' && i !== 'Properties') {
              this.mapping[this.cms.getObjectId(this.mapping[i])] = this.mapping[i]?.colindex;
              delete this.mapping[i];
            }
          }

          this.processing = true;

          const currentContactList = await this.apiService.getPromise<ContactModel[]>('/contact/contacts', { limit: 'nolimit' });
          this.contactMap = currentContactList.reduce((result, current, index) => {
            result[current.Phone] = current;
            return result;
          }, {});
          const businessList = await this.apiService.getPromise<ContactModel[]>('/accounting/business', { limit: 'nolimit' });
          const businessMap = businessList.reduce((result, current, index) => {
            result[current.Code] = {
              id: current.Code,
              text: current.Name,
              type: current.Type,
            };
            return result;
          }, {});
          this.array = this.sheet.filter((f, index) => index > 0).map((row: any, index: number) => {
            const mappedRow: any = {
              No: index + 1,
              IsUpdatePrice: false,
            };

            for (const column in this.mapping) {
              // if (typeof this.mapping[column] == 'string') {
              mappedRow[column] = row[this.mapping[column]] || null;
              if (typeof mappedRow[column] == 'string') {
                mappedRow[column] = mappedRow[column].trim();
              }
              // }
            }
            if (mappedRow.DateOfSale) {
              mappedRow.DateOfSale = new Date(mappedRow.DateOfSale);
              mappedRow.DateOfSale.setHours(14);
            }
            if (mappedRow.DateOfPurchase) {
              mappedRow.DateOfPurchase = new Date(mappedRow.DateOfPurchase);
              mappedRow.DateOfPurchase.setHours(7);
            }
            const customer = this.contactMap[mappedRow.CustomerPhone];
            if (customer) {
              mappedRow.Customer = customer.Code;
            }
            const supplier = this.contactMap[mappedRow.SupplierPhone];
            if (supplier) {
              mappedRow.Supplier = supplier.Code;
            }

            let duplicate = false;
            mappedRow.IsNew = false;
            mappedRow.IsImport = true;

            mappedRow.IsSales = mappedRow.IsSales ? true : false;
            mappedRow.IsPurchase = mappedRow.IsPurchase ? true : false;

            mappedRow.Pictures = new String(mappedRow.Pictures || '').replace('\r\n', '\n').split('\n').filter(f => !!f);
            mappedRow.SameProducts = [];

            mappedRow.SalesBusiness = businessMap[mappedRow.SalesBusiness];
            mappedRow.PurchaseBusiness = businessMap[mappedRow.PurchaseBusiness];

            return mappedRow;

          });

          console.log(this.array);
          this.updateGridColumn();
          setTimeout(() => {
            this.loadList();
          }, 300);

          this.processing = false;
        } catch (err) {
          console.error(err);
          this.processing = false;
        }

      } catch (err) {
        this.processing = false;
      }
    };
    reader.readAsBinaryString(file);
  }

  progress = 0;
  progressStatus = 'primary';
  progressLabel = '';
  uploadedImages: { [key: string]: FileModel } = {};
  async generateVouchers() {
    this.processing = true;
    try {
      // const newProductList: Model[] = [];
      // const updatePriceProductList: ContactModel[] = [];
      const allVoucherList: Model[] = [];
      this.gridApi.forEachNode(async (rowNode, index) => {
        // console.log(rowNode, index);
        const newVoucher: Model = rowNode.data;
        newVoucher.index = index;
        newVoucher.SalesVoucher = {
          Object: newVoucher.Customer,
          ObjectName: newVoucher.CustomerName,
          ObjectPhone: newVoucher.CustomerPhone,
          DateOfSale: newVoucher.DateOfSale,
          Title: newVoucher.ProductName,
          Thread: 'KHACHLEIT',
          Details: [
            {
              Type: 'PRODUCT',
              Product: { id: newVoucher.Product, text: newVoucher.ProductName },
              Description: newVoucher.ProductName,
              Unit: newVoucher.Unit,
              Quantity: newVoucher.Quantity,
              Price: newVoucher.SalesPrice,
              Business: [
                newVoucher.SalesBusiness,
              ]
            }
          ],
        };
        newVoucher.PurchaseVoucher = {
          Object: newVoucher.Supplier,
          ObjectName: newVoucher.SupplierName,
          ObjectPhone: newVoucher.SupplierPhone,
          DateOfPurchase: newVoucher.DateOfPurchase,
          Title: newVoucher.ProductName,
          Thread: 'KHACHLEIT',
          Details: [
            {
              Type: 'PRODUCT',
              Product: { id: newVoucher.Product, text: newVoucher.ProductName },
              Description: newVoucher.ProductName,
              Unit: newVoucher.Unit,
              Quantity: newVoucher.Quantity,
              Price: newVoucher.PurchasePrice,
              Business: [
                newVoucher.PurchaseBusiness,
              ]
            }
          ],
        };
        allVoucherList.push(newVoucher);

        // if (newVoucher.WarehouseUnit && this.cms.getObjectId(newVoucher.WarehouseUnit)) {
        //   newVoucher.UnitConversions = [];
        //   newVoucher.Properties = [];
        //   if (newVoucher.IsImport) {
        //     newProductList.push(newVoucher);
        //   }
        // }
      });

      const total = allVoucherList.length;
      let loaded = 0;
      this.progressStatus = 'success';
      for (const newVoucher of allVoucherList) {
        loaded++;
        this.progress = loaded / total * 100;
        this.progressLabel = newVoucher.ProductName + ' (' + this.progress.toFixed(2) + '%)';
        if (newVoucher.IsImport) {
          const node = this.gridApi.getDisplayedRowAtIndex(newVoucher.index);
          try {

            if (!newVoucher.IsPurchase) {

              // Generate purchase voucher
              if (!newVoucher.Supplier) {
                let createdContact = this.contactMap[newVoucher.SupplierPhone];
                if (!createdContact) {
                  createdContact = await this.apiService.postPromise<Model[]>('/contact/contacts', {}, [{
                    Name: newVoucher.SupplierName,
                    Phone: newVoucher.SupplierPhone,
                    Groups: ['SUPPLIER'],
                  }]).then(rs => rs[0]);
                  this.contactMap[createdContact.Phone] = createdContact;
                }
                newVoucher.Supplier = createdContact.Code;
                node.setDataValue('Supplier', createdContact.Code);

                newVoucher.PurchaseVoucher.Object = newVoucher.Supplier;
                newVoucher.PurchaseVoucher.ObjectName = newVoucher.SupplierName;
                newVoucher.PurchaseVoucher.ObjectPhone = newVoucher.SupplierPhone;
              }
              const createdPurchaseVoucher = await this.apiService.postPromise<Model[]>('/purchase/vouchers', {}, [newVoucher.PurchaseVoucher]).then(rs => rs[0]);
              newVoucher.PurchaseCode = createdPurchaseVoucher.Code;
              await this.apiService.putPromise<Model[]>('/purchase/vouchers/' + createdPurchaseVoucher.Code, { changeState: 'APPROVED' }, [{ Code: createdPurchaseVoucher.Code }]);
              node.setDataValue('Result', 'Success');
              node.setDataValue('PurchaseCode', createdPurchaseVoucher.Code);
              node.setDataValue('DateOfPurchase', createdPurchaseVoucher.DateOfPurchase);
              node.setDataValue('IsImport', false);
              node.setDataValue('IsPurchase', true);

              console.log(createdPurchaseVoucher);
            }
            if (!newVoucher.IsSales) {
              // Generate sales voucher
              if (!newVoucher.Customer) {
                let createdContact = this.contactMap[newVoucher.CustomerPhone];
                if (!createdContact) {
                  createdContact = await this.apiService.postPromise<Model[]>('/contact/contacts', {}, [{
                    Name: newVoucher.CustomerName,
                    Phone: newVoucher.CustomerPhone,
                    Groups: ['CUSTOMER'],
                  }]).then(rs => rs[0]);
                  this.contactMap[createdContact.Phone] = createdContact;
                }
                newVoucher.Customer = createdContact.Code;
                node.setDataValue('Customer', createdContact.Code);

                newVoucher.SalesVoucher.Object = newVoucher.Customer;
                newVoucher.SalesVoucher.ObjectName = newVoucher.CustomerName;
                newVoucher.SalesVoucher.ObjectPhone = newVoucher.CustomerPhone;
              }
              const createdSalesVoucher = await this.apiService.postPromise<Model[]>('/sales/sales-vouchers', {}, [newVoucher.SalesVoucher]).then(rs => rs[0]);
              newVoucher.SalesCode = createdSalesVoucher.Code;
              await this.apiService.putPromise<Model[]>('/sales/sales-vouchers/' + createdSalesVoucher.Code, { changeState: 'APPROVED' }, [{ Code: createdSalesVoucher.Code }]);
              node.setDataValue('Result', 'Success');
              node.setDataValue('SalesCode', createdSalesVoucher.Code);
              node.setDataValue('DateOfSale', createdSalesVoucher.DateOfSale);
              node.setDataValue('IsImport', false);
              node.setDataValue('IsSales', true);
            }
          } catch (err) {
            console.error(err);
            const message = Array.isArray(err?.error?.logs) && err.error.logs.join(',') || err;
            node.setDataValue('Result', 'Error');
            node.setDataValue('Message', message);
            this.cms.toastService.show(newVoucher.Name + message, 'Import thất bại', { status: 'danger', duration: 60000 });
          }
        }

      }
      this.progress = 0;

      // Update price
      this.progressStatus = 'primary';

      // Export update data
      for (const i in allVoucherList) {
        const realIndex = parseInt(i) + 1;
        this.sheet[realIndex][this.mapping['Phone']] = allVoucherList[i].Phone;
        this.sheet[realIndex][this.mapping['Code']] = allVoucherList[i].Code;
        this.sheet[realIndex][this.mapping['SalesCode']] = allVoucherList[i].SalesCode;
        this.sheet[realIndex][this.mapping['PurchaseCode']] = allVoucherList[i].PurchaseCode;
        this.sheet[realIndex][this.mapping['Customer']] = allVoucherList[i].Customer;
        this.sheet[realIndex][this.mapping['Supplier']] = allVoucherList[i].Supplier;
        this.sheet[realIndex][this.mapping['IsSales']] = allVoucherList[i].IsSales ? 1 : 0;
        this.sheet[realIndex][this.mapping['IsPurchase']] = allVoucherList[i].IsPurchase ? 1 : 0;

      }

      // Include column name into header
      // Export mapping file
      this.workBook.Sheets[this.chooseSheet] = XLSX.utils.json_to_sheet(this.sheet, { skipHeader: true });
      XLSX.writeFile(this.workBook, this.fileName);

      this.cms.toastService.show('Import liên hệ hoàn tất', 'Import thành công', { status: 'success' });

      this.processing = false;
    } catch (err) {
      this.processing = false;
      console.error(err);
      this.cms.toastService.show('Đã xảy ra lỗi trong quá trình import', 'Lỗi import', { status: 'danger' });
    }
  }

  close() {
    this.ref.close();
  }

  /** Ag dynamic list */
  syncTaskDetailsApiPath = '/wordpress/sync-task-details';
  // syncTaskDetailsApiPath = '/commerce-pos/orders';
  prepareSyncTaskDetailsParams = (params: any, getRowParams: IGetRowsParams) => {
    params['includeIdText'] = true;
    return params;
  };
  /** End Ag dynamic list */


  async exportTemplateFile() {
    const sheetData: any[] = [
      {
        Code: 'ID Liên hệ',
        Name: 'Tên liên hệ',
        Groups: 'ID nhóm, cách nhau dấu phẩy (,)',
        Title: 'Danh xưng: Anh, Chị, ...',
        ShortName: 'Tên rút gọn',
        Email: 'Email chính',
        Phone: 'Số điện thoại chính',
        Address: 'Địa chỉ chính số nhà tên đường',
        MapLink: 'Link bản đồ',
        TaxCode: 'Mã số thuế',
        IdentifiedNumber: 'Số định danh',
        Avatar: 'Link avatar',
        Birthday: 'Ngày sinh',
        PlaceOfBirth: 'Nơi sinh',
        Location: 'Khu vực hành chính: ID Phường/Xã',
      }
    ];

    const groupList = await this.apiService.getPromise<ContactGroupModel[]>('/contact/groups', { select: 'Code,Name,Description', limit: 'nolimit' });

    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(sheetData, { skipHeader: false });
    const sheet2 = XLSX.utils.json_to_sheet(groupList, { skipHeader: false });
    XLSX.utils.book_append_sheet(workbook, sheet, 'Liên hệ: DS sẽ import');
    XLSX.utils.book_append_sheet(workbook, sheet2, 'Nhóm: dùng đề lấy ID');

    XLSX.writeFile(workbook, 'contact-import-template.xlsx');
    return true;
  }

  checkImportAll() {
    this.gridApi.forEachNodeAfterFilter((node, index) => {
      node.setDataValue('IsImport', true);
    });
    return true;
  }
}
