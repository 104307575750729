import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { DeploymentComponent } from "../deployment/deployment.component";
import { CommerceServiceByCycleRoutingModule } from "./commerce-service-by-cycle.routing";
import { CommerceServiceByCycleFormComponent } from "./service-by-cycle/commerce-service-by-cycle-form/commerce-service-by-cycle-form.component";
import { CommerceServiceByCycleListComponent } from "./service-by-cycle/commerce-service-by-cycle-list/commerce-service-by-cycle-list.component";

export const commonServiceByCycleComponents = [
    CommerceServiceByCycleListComponent,
    CommerceServiceByCycleFormComponent,
];

@NgModule({
    declarations: commonServiceByCycleComponents,
    // exports: commonServiceByCycleComponents,
    imports: [
        ...commonModules,
        CommerceServiceByCycleRoutingModule,
    ],
    bootstrap: [DeploymentComponent],
    providers: [
        ...commonProviders,
    ],
})
export class CommerceServiceByCycleModule { }