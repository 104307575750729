import { BehaviorSubject } from 'rxjs';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { ProductModel } from '../../../../models/product.model';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { WpSiteModel } from '../../../../models/wordpress.model';
import { AgCurrencyCellInput } from '../../../../lib/custom-element/ag-list/cell/input/curency.component';
import { MasterPriceTableFormComponent } from '../master-price-table-form/master-price-table-form.component';
import { MasterPriceTableUpdateNoteModel, SalesMasterPriceTableModel } from '../../../../models/sales.model';
import { AgDynamicListComponent } from '../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { AgCurrencyCellRenderer } from '../../../../lib/custom-element/ag-list/cell/currency.component';
import { MasterPriceTableUpdateNoteFormComponent } from '../../master-price-table-update-note/master-price-table-update-note-form/master-price-table-update-note-form.component';
import { RootServices } from '../../../../services/root.services';
import { Model } from '../../../../models/model';
import * as XLSX from 'xlsx';
import { DialogFormComponent } from '../../../../lib/component/dialog/dialog-form/dialog-form.component';
import { FormGroup } from '@angular/forms';
import { MasterPriceTableUpdateNotePrintComponent } from '../../master-price-table-update-note/master-price-table-update-note-print/master-price-table-update-note-print.component';
import { UnitModel } from '../../../../models/unit.model';
import { AdminProductService } from '../../../admin-product/admin-product.service';
import { ProductListComponent } from '../../../admin-product/product/product-list/product-list.component';
import { ProductUnitListComponent } from '../../../admin-product/unit/product-unit-list/product-unit-list.component';
import {MasterPriceTableProduct} from "../../sales.model";

@Component({
  selector: 'ngx-master-price-table',
  templateUrl: './master-price-table.component.html',
  styleUrls: ['./master-price-table.component.scss']
})
export class SalesMasterPriceTableComponent extends AgGridDataManagerListComponent<MasterPriceTableProduct, MasterPriceTableFormComponent> implements OnInit {

  componentName: string = 'SalesMasterPriceTableComponent';
  formPath = '';
  apiPath = '/sales/master-price-table-details';
  idKey = ['Code', 'Unit'];
  // formDialog = WordpressProductFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';
  refCategoryList = [];
  siteList: WpSiteModel[];
  refCategoriesLoading = false;

  masterPriceTable$ = new BehaviorSubject<SalesMasterPriceTableModel>(null);
  masterPriceTableList: SalesMasterPriceTableModel[] = [];

  @ViewChild('chooseProductIdsFile') chooseProductIdsFile: ElementRef;
  @ViewChild('chooseProductListedPriceFile') chooseProductListedPriceFile: ElementRef;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<SalesMasterPriceTableComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {

      this.actionButtonList.unshift({
        type: 'button',
        name: 'createMasterPriceTableUpdateNote',
        status: 'danger',
        label: 'Tạo phiếu cập nhật giá',
        title: 'Tạo phiếu cập nhật giá',
        size: 'medium',
        icon: 'file-text-outline',
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          this.cms.openDialog(MasterPriceTableUpdateNoteFormComponent, {
            context: {
              data: [
                {
                  Title: 'Cập nhật giá ' + new Date().toUTCString(),
                  Details: this.selectedItems.map(m => ({
                    Image: m.Pictures,
                    Product: { id: m.Code, text: `${m.Sku}:${m.Code} - ${m.Name}` },
                    Unit: m.Unit as any,
                    Description: m.Name,
                    OldPrice: m.Price,
                    Price: m.Price,
                    // PurchasePrice: m.PurchasePrice
                  })) as any
                }
              ],
              onDialogSave: (newData) => {
                if (newData[0].State === 'APPROVED') {
                  this.apiService.putPromise<any>('/sales/master-price-tables/' + this.cms.getObjectId(this.masterPriceTable$.value), { rebuildCache: true }, [{ Code: this.cms.getObjectId(this.masterPriceTable$.value) }]).then(rs => {
                    this.cms.showToast('Đã cập nhật cache bảng giá !', 'Cập nhật cache bảng giá thành công', { status: 'success' });
                    this.refresh();
                  });
                }
              },
            }
          });
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'rebuildCache',
        status: 'primary',
        label: 'Cập nhật cache',
        title: 'Bảng giá sử dụng bộ nhớ cache để tăng tốc độ truy vấn, nếu có sai lệch về giá bạn hãy cập nhật lại cache !',
        size: 'medium',
        icon: 'sync-outline',
        disabled: () => !this.masterPriceTable$.value,
        click: () => {
          this.cms.showDialog('Cập nhật cache bảng giá', 'Bạn có muốn cập nhật cache cho bảng giá ' + this.cms.getObjectText(this.masterPriceTable$.value) + '?\n Bảng giá sử dụng bộ nhớ cache để tăng tốc độ truy vấn, nếu có sai lệch về giá bạn hãy cập nhật lại cache !', [
            {
              label: 'Trở về',
              status: 'basic',
              action: () => {
                return true;
              }
            },
            {
              label: 'Cập nhật',
              status: 'primary',
              action: () => {
                this.apiService.putPromise<any>('/sales/master-price-tables/' + this.cms.getObjectId(this.masterPriceTable$.value), { rebuildCache: true }, [{ Code: this.cms.getObjectId(this.masterPriceTable$.value) }]).then(rs => {
                  this.cms.showToast('Đã cập nhật cache bảng giá !', 'Cập nhật cache bảng giá thành công', { status: 'success' });
                  this.refresh();
                });
              }
            },
          ]);
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'exportPriceTable',
        title: 'Xuất bảng giá niêm yết theo danh sách ID có sẵn',
        status: 'info',
        label: 'Xuất',
        iconPack: 'eva',
        icon: 'cloud-upload-outline',
        size: 'medium',
        disabled: () => !this.cms.getObjectId(this.masterPriceTable$.value),
        click: (event) => {
          this.chooseProductIdsFile.nativeElement.click();
        },
      });
      this.actionButtonList.unshift({
        type: 'button',
        name: 'importPriceTable',
        title: 'Nhập bảng giá niêm yết',
        status: 'danger',
        label: 'Nhập',
        iconPack: 'eva',
        icon: 'cloud-download-outline',
        size: 'medium',
        disabled: () => !this.cms.getObjectId(this.masterPriceTable$.value),
        click: (event) => {
          this.chooseProductListedPriceFile.nativeElement.click();
        },
      });


      // const processingMap = AppModule.processMaps['purchaseOrder'];
      this.masterPriceTableList = await this.apiService.getPromise<SalesMasterPriceTableModel[]>('/sales/master-price-tables', { includeIdText: true });
      if (this.masterPriceTableList.length > 0) {
        this.masterPriceTable$.next(this.cms.getObjectId(this.masterPriceTableList[0]));
      }

      this.actionButtonList.unshift({
        type: 'select2',
        name: 'masterpriceTable',
        status: 'success',
        label: 'Select page',
        icon: 'plus',
        title: 'Site',
        size: 'medium',
        select2: {
          option: {
            placeholder: 'Chọn bảng giá...',
            allowClear: false,
            width: '100%',
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            keyMap: {
              id: 'id',
              text: 'text',
            },
            data: this.masterPriceTableList,
          }
        },
        asyncValue: this.masterPriceTable$,
        change: async (value: any, option: any) => {
          this.onChangePage(value);
        },
        disabled: () => {
          return this.loading;
        },
        click: () => {
          // this.gotoForm();
          return false;
        },
      });

      await this.cms.waitForLanguageLoaded();

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          headerName: 'ID',
          field: 'Code',
          pinned: 'left',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Sku',
          field: 'Sku',
          pinned: 'left',
          width: 120,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Sản phẩm',
          field: 'Name',
          width: 400,
          // pinned: 'left',
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'ĐVT',
          field: 'Unit',
          width: 130,
          // filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          autoHeight: true,
          // valueGetter: (params: { data: ProductModel }) => {
          //   const baseUnitId = this.cms.getObjectId(params.data?.WarehouseUnit);
          //   const baseUnitText = this.cms.getObjectText(params.data?.WarehouseUnit);
          //   return params.data?.Units?.map(unit => {
          //     let text = '';
          //     if (baseUnitId == unit?.id) {
          //       text = unit.text;
          //     } else {
          //       text = `${unit.text} = ${unit.ConversionRatio} ${baseUnitText}`;
          //     }
          //     unit.toolTip = `${text} (${unit.IsAutoAdjustInventory ? 'Trừ kho tự động' : 'Không tự động trừ kho'}, ${unit.IsManageByAccessNumber ? 'Quản lý theo số truy xuất' : 'Không quản lý theo số truy xuất'})`;
          //     if (unit.IsManageByAccessNumber) {
          //       unit.status = 'danger';
          //     }
          //     if (!unit.IsAutoAdjustInventory) {
          //       unit.status = 'warning';
          //     }
          //     unit.label = `${unit.text} (${unit.ConversionRatio})`;
          //     return unit;
          //   });
          // },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/units', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn ĐVT...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Danh mục',
          field: 'Categories',
          // pinned: 'left',
          width: 350,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 250,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/groups', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Mô tả',
          field: 'Description',
          width: 1024,
          // pinned: 'left',
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Cập nhật cuối',
          field: 'LastUpdate',
          width: 180,
          filter: 'agDateColumnFilter',
          pinned: 'right',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Giá EU',
          field: 'Price',
          pinned: 'right',
          width: 150,
        },
        // {
        //   headerName: 'Giá bán',
        //   field: 'SalePrice',
        //   width: 150,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'right',
        //   type: 'rightAligned',
        //   cellClass: ['ag-cell-items-center', 'ag-cell-justify-end'],
        //   cellRenderer: AgCurrencyCellInput,
        //   cellStyle: { border: "none" },
        //   cellRendererParams: {
        //     changed: (value, params) => {
        //       // this.apiService.putPromise<any[]>('/wordpress/products/' + params.node.data.Id, {}, [{
        //       //   Id: params.node.data.Id,
        //       //   SalePrice: value,
        //       // }]).then(rs => {
        //       //   params.status = 'success';
        //       // });
        //     }
        //   }
        // },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'historyUpdate',
              // label: 'Lịch sử cập nhật',
              status: 'info',
              outline: false,
              icon: 'external-link-outline',
              appendTo: 'tail',
              action: async (params) => {
                this.cms.openDialog(AgDynamicListComponent, {
                  context: {
                    title: 'Lịch sử cập nhật giá',
                    width: '90%',
                    height: '95vh',
                    apiPath: '/sales/master-price-table-entries',
                    idKey: ['Id'],
                    // getRowNodeId: (item) => {
                    //   return item.Product + '-' + item.Unit;
                    // },
                    // rowMultiSelectWithClick: true,
                    // suppressRowClickSelection: false,
                    prepareApiParams: (exParams, getRowParams) => {
                      exParams['eq_MasterPriceTable'] = this.cms.getObjectId(this.masterPriceTable$.value);
                      exParams['eq_Product'] = params.node.data.Code;
                      exParams['eq_Unit'] = this.cms.getObjectId(params.node.data.Unit);
                      exParams['includeUnit'] = true;
                      exParams['includeProduct'] = true;
                      exParams['includeRequestBy'] = true;
                      exParams['includeApprovedBy'] = true;
                      return exParams;
                    },
                    onDialogChoose: (chooseItems) => {

                    },
                    columnDefs: [
                      {
                        ...agMakeSelectionColDef(this.cms),
                        headerName: 'STT',
                        // width: 52,
                        field: 'Id',
                        valueGetter: 'node.data.Id',
                      },
                      {
                        headerName: 'Ngày cập nhật',
                        field: 'NoteDate',
                        // pinned: 'left',
                        width: 180,
                        filterParams: {
                          inRangeFloatingFilterDateFormat: 'DD/MM/YY',
                        },
                        cellRenderer: AgDateCellRenderer,
                        filter: 'agDateColumnFilter',
                        initialSort: 'asc'
                      },
                      {
                        headerName: 'Sản phẩm',
                        field: 'Product',
                        // pinned: 'left',
                        width: 300,
                        filter: 'agTextColumnFilter',
                        cellRenderer: AgTextCellRenderer,
                        headerComponentParams: { enableMenu: true, menuIcon: 'fa-external-link-alt' },
                        filterParams: {
                          filterOptions: ['contains'],
                          textMatcher: ({ value, filterText }) => {
                            var literalMatch = this.cms.smartFilter(value, filterText);
                            return literalMatch;
                          },
                          trimInput: true,
                          debounceMs: 1000,
                        }
                      },
                      {
                        headerName: 'ĐVT',
                        field: 'Unit',
                        width: 150,
                        // pinned: 'left',
                        cellRenderer: AgTextCellRenderer,
                        // valueFormatter: 'node.data.Unit',
                        filter: AgSelect2Filter,
                        filterParams: {
                          select2Option: {
                            placeholder: 'Chọn...',
                            allowClear: true,
                            width: '100%',
                            dropdownAutoWidth: true,
                            minimumInputLength: 0,
                            withThumbnail: false,
                            keyMap: {
                              id: 'id',
                              text: 'text',
                            },
                            // data: this.prds.unitList$.value,
                            multiple: true,
                            logic: 'OR',
                          }
                        },
                      },
                      {
                        headerName: 'Phiếu cập nhật giá',
                        field: 'Note',
                        // pinned: 'right',
                        width: 200,
                        filter: 'agTextColumnFilter',
                        headerComponentParams: { enableMenu: true, menuIcon: 'fa-external-link-alt' },
                        filterParams: {
                          filterOptions: ['contains'],
                          textMatcher: ({ value, filterText }) => {
                            var literalMatch = this.cms.smartFilter(value, filterText);
                            return literalMatch;
                          },
                          trimInput: true,
                          debounceMs: 1000,
                        },
                        cellRenderer: AgTextCellRenderer,
                      },
                      {
                        ...agMakeTagsColDef(this.cms, (tag) => {
                          this.cms.previewVoucher(tag.type, tag.id);
                        }),
                        headerName: 'Chứng từ liên quan',
                        field: 'Note',
                        width: 330,
                        valueGetter: (params) => params.node.data?.Note ? [{ id: params.node.data.Note, label: 'PCNG:' + params.node.data.Note, status: 'success' }] : null,
                      },
                      {
                        headerName: 'Yêu cầu bởi',
                        field: 'RequestBy',
                        // pinned: 'left',
                        width: 250,
                        cellRenderer: AgTextCellRenderer,
                        filter: AgSelect2Filter,
                        filterParams: {
                          select2Option: {
                            ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true }, {
                              placeholder: 'Chọn...', limit: 10, prepareReaultItem: (item) => {
                                return item;
                              }
                            }),
                            multiple: true,
                            logic: 'OR',
                            allowClear: true,
                          }
                        },
                      },
                      {
                        headerName: 'Phê duyệt bởi',
                        field: 'ApprovedBy',
                        // pinned: 'left',
                        width: 250,
                        cellRenderer: AgTextCellRenderer,
                        filter: AgSelect2Filter,
                        filterParams: {
                          select2Option: {
                            ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true }, {
                              placeholder: 'Chọn...', limit: 10, prepareReaultItem: (item) => {
                                return item;
                              }
                            }),
                            multiple: true,
                            logic: 'OR',
                            allowClear: true,
                          }
                        },
                      },
                      {
                        headerName: 'Mô tả',
                        field: 'ProductName',
                        // pinned: 'left',
                        width: 1024,
                        filter: 'agTextColumnFilter',
                        cellRenderer: AgTextCellRenderer,
                        headerComponentParams: { enableMenu: true, menuIcon: 'fa-external-link-alt' },
                        filterParams: {
                          filterOptions: ['contains'],
                          textMatcher: ({ value, filterText }) => {
                            var literalMatch = this.cms.smartFilter(value, filterText);
                            return literalMatch;
                          },
                          trimInput: true,
                          debounceMs: 1000,
                        }
                      },
                      {
                        ...agMakeCurrencyColDef(this.cms),
                        headerName: 'Giá',
                        field: 'Price',
                        pinned: 'right',
                        width: 150,
                      },
                      {
                        ...agMakeCommandColDef(this, this.cms, false, false, false, [
                          {
                            name: 'extend',
                            // label: 'In',
                            status: 'danger',
                            outline: false,
                            icon: 'external-link-outline',
                            action: async (params: any) => {
                              this.cms.previewVoucher(null, params.node.data.Note);
                              return true;
                            }
                          }])
                      }
                    ],
                    onInit: (component) => {
                      component.actionButtonList = component.actionButtonList.filter(f => ['close', 'choose', 'preview', 'refresh'].indexOf(f.name) > -1);
                    }
                  }
                });
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['masterPriceTable'] = this.masterPriceTable$.value ? this.cms.getObjectId(this.masterPriceTable$.value) : '-1';
    params['includeCategories'] = true;
    params['includeGroups'] = true;
    params['includeUnit'] = true;
    params['includeFeaturePicture'] = true;
    params['sort_Id'] = 'desc';
    // params['group_Unit'] = true;
    params['includeContainers'] = true;
    params['includeNumberOfProducts'] = true;
    // params['sort_Id'] = 'desc';
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: MasterPriceTableProduct[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(MasterPriceTableFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: MasterPriceTableProduct[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(masterPriceTable: Model) {
    if (masterPriceTable !== null) {
      this.masterPriceTable$.next(this.cms.getObjectId(masterPriceTable));
      this.cms.takeOnce(this.componentName + '_on_master_price_table_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  onProductIdsFileChange(ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    // file.name;
    reader.onload = async (event) => {
      try {
        // this.processing = true;
        const data = reader.result;
        const workBook = XLSX.read(data, { type: 'binary' });
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          return initial;
        }, {});

        let sheet = null;
        let chooseSheet = null;

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      // formDialogConpoent.dismiss();

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        console.log(sheet);
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string, index) => {
          const id = m.split('/')[0];
          const colindex = index;
          const text = m;
          return { id, text, colindex };
        });

        this.cms.openDialog(DialogFormComponent, {
          context: {
            width: '512px',
            controls: [
              {
                label: 'Cột chứa ID sản phẩm',
                name: 'IdColumn',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  data: columnList,
                },
              }
            ],
            actions: [
              {
                label: 'Trở về',
                status: 'basic',
                outline: true,
                action: async () => true,
              },
              {
                label: 'Xuất',
                status: 'success',
                outline: true,
                action: async (form, dialog) => {
                  const idColumn = form.get('IdColumn').value;
                  if (!idColumn) {
                    return false;
                  }

                  const idColIndex = idColumn.colindex;
                  const productIds = [];
                  for (const i in sheet) {
                    console.log(sheet[i][idColIndex]);
                    productIds.push(sheet[i][idColIndex]);
                  }

                  let chooseRefProducts = [];
                  const limit = 20;
                  let offset = 0;
                  // let toast = null;
                  do {
                    const ids = productIds.slice(offset, offset + limit);
                    // toast = this.cms.showToast(offset + '-' + (offset + limit) + ' sản phẩm', 'Đang thêm sản phẩm', { status: 'info', duration: 0 });
                    const rs = await this.apiService.getPromise<any[]>(this.apiPath, {
                      // limit: 100,
                      // offset: 0,
                      // sort_Id: 'desc',
                      eq_Code: '[' + ids.join(',') + ']',
                      masterPriceTable: this.cms.getObjectId(this.masterPriceTable$.value),
                      includeCategories: true,
                      includeGroups: true,
                      includeUnit: true,
                      includeFeaturePicture: true,
                      includeContainers: true,
                      includeNumberOfProducts: true,
                    });
                    // console.log(chooseRefProducts);
                    rs.map(m => m.Price > 0 && chooseRefProducts.push({
                      Code: m.Code,
                      Sku: m.Sku,
                      Unit: this.cms.getObjectId(m.Unit),
                      Price: m.Price,
                    }));
                    // await this.addItemToList(chooseRefProducts);

                    // this.progressStatus = 'warning';
                    // this.progress = parseInt((((offset + 1) / productIds.length) * 100) as any);
                    // this.progressLabel = offset + '/' + productIds.length + ' - ' + this.progress + '%';

                    offset += limit;
                    // if (toast) {
                    //   toast.close();
                    // }
                  } while (offset < productIds.length);

                  const exworkbook = XLSX.utils.book_new();
                  const exsheet = XLSX.utils.json_to_sheet(chooseRefProducts);
                  XLSX.utils.book_append_sheet(exworkbook, exsheet, 'Gia_Niem_Yet');
                  XLSX.writeFile(exworkbook, 'Listed_Price_Table_' + this.cms.getObjectId(this.masterPriceTable$.value) + '.xlsx');

                  return true;
                },
              },
            ]
          }
        });

        // this.processing = false;
      } catch (err) {

      }
    };
    reader.readAsBinaryString(file);
  }

  onProductListedPriceFileChange(ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    // file.name;
    reader.onload = async (event) => {
      try {
        // this.processing = true;
        const data = reader.result;
        const workBook = XLSX.read(data, { type: 'binary' });
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          return initial;
        }, {});

        let sheet = null;
        let chooseSheet = null;

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      // formDialogConpoent.dismiss();

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        console.log(sheet);
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string, index) => {
          const id = m.split('/')[0];
          const colindex = index;
          const text = m;
          return { id, text, colindex };
        });

        this.cms.openDialog(DialogFormComponent, {
          context: {
            width: '512px',
            controls: [
              {
                label: 'Cột chứa Sku',
                name: 'Sku',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  placeholder: 'Chọn Sku',
                  data: columnList,
                },
                initValue: columnList.find(f => f.id == 'Sku'),
              },
              {
                label: 'Cột chứa ĐVT',
                name: 'Unit',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  placeholder: 'Chọn ĐVT',
                  data: columnList,
                },
                initValue: columnList.find(f => f.id == 'Unit'),
              },
              {
                label: 'Cột chứa Giá',
                name: 'Price',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  placeholder: 'Chọn Giá',
                  data: columnList,
                },
                initValue: columnList.find(f => f.id == 'Price'),
              },
            ],
            actions: [
              {
                label: 'Trở về',
                status: 'basic',
                outline: true,
                action: async () => true,
              },
              {
                label: 'Nhập',
                status: 'danger',
                outline: true,
                action: async (form, dialog) => {

                  const unitMap = await this.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdtext: true, limit: 'nolimit' }).then(rs => {
                    return rs.reduce((result, curr, index) => { result[curr.Code] = curr; return result; }, {});
                  });

                  const map = {
                    // Code: form.value.Code.colindex,
                    Sku: form.value.Sku.colindex,
                    Unit: form.value.Unit.colindex,
                    Price: form.value.Price.colindex,
                  };

                  console.log(form.value);
                  const mapCheckExists = {};
                  const updateNote = {
                    Title: 'Import giá niêm yết ' + new Date().toLocaleDateString(),
                    Details: [],
                  };

                  // Check map product and unit
                  for (const r in sheet) {
                    if (r == '0') continue;
                    const row = sheet[r];
                    let existsProduct = this.adminProductService.skuMap[row[map.Sku]];
                    if (!existsProduct) {
                      console.warn('Sku không tồn tại');
                      existsProduct = await new Promise<MasterPriceTableProduct>((resolve, reject) => {
                        this.cms.openDialog(ProductListComponent, {
                          context: {
                            title: 'Chọn sản phẩm tương ứng với Sku ' + row[map.Sku],
                            width: '90vw',
                            height: '90vh',
                            onDialogChoose: (chooseItems) => {
                              if (chooseItems && chooseItems[0]) {
                                resolve(chooseItems[0]);
                              }
                            },
                            onDialogClose: () => {
                              resolve(null);
                            },
                          }
                        });
                      });
                      if (!existsProduct) {
                        continue;
                      }
                    }
                    if (!unitMap[row[map.Unit]]) {
                      console.warn('ĐVT không tồn tại');
                      const existsUnit = await new Promise<MasterPriceTableProduct>((resolve, reject) => {
                        this.cms.openDialog(ProductUnitListComponent, {
                          context: {
                            title: 'Chọn ĐVT tương ứng với ' + row[map.Unit],
                            width: '90vw',
                            height: '90vh',
                            onDialogChoose: (chooseItems) => {
                              resolve(chooseItems[0]);
                            },
                            onDialogClose: () => {
                              resolve(null);
                            },
                          }
                        });
                      });
                      if (!unitMap) {
                        continue;
                      }
                      unitMap[row[map.Unit]] = existsUnit;
                    }

                    if (existsProduct) {
                      if (!mapCheckExists[existsProduct.Code + '-' + unitMap[row[map.Unit]].Code]) {
                        updateNote.Details.push({
                          PriceType: 'REGULAR',
                          Product: existsProduct.Code,
                          Sku: existsProduct.Sku,
                          Unit: unitMap[row[map.Unit]].Code,
                          Price: row[map.Price],
                        });
                        mapCheckExists[existsProduct.Code + '-' + unitMap[row[map.Unit]].Code] = true;
                      }
                    }

                  }

                  console.log(updateNote);
                  const toast = this.cms.showToast('Đang tạo phiếu cập nhật giá, vui lòng chờ trong giây lát', 'Đang tạo phiếu cập nhật giá', {duration: 0, status: 'warning'});
                  this.apiService.postPromise<MasterPriceTableUpdateNoteModel[]>('/sales/master-price-table-notes', {}, [updateNote]).then(rs => {
                    console.log(rs);
                    toast.close();
                    this.cms.openDialog(MasterPriceTableUpdateNotePrintComponent, {
                      context: {
                        id: [rs[0].Code],
                      }
                    });
                  });
                  return true;
                },
              },
            ]
          }
        });

        // this.processing = false;
      } catch (err) {

      }
    };
    reader.readAsBinaryString(file);
  }
}
