<form [formGroup]="form" (ngSubmit)="onSubmit()"
  (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)"
  >
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('EmailMarketing.EmailSent.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            <nb-icon icon="chatbox-working" pack="ion"></nb-icon> Email mới
            <ngx-action-control-list style="float: right;" [list]="actionControlList" [option]="{formIndex: i, form: formItem}"></ngx-action-control-list>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Id">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Contact'), 'warning')">
                  <label class="label"><span class="valid">Liên hệ</span><span class="invalid">Liên hệ là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="Contact" [select2Option]="select2ContactOption"
                    (selectChange)="onObjectChange($event, i)"></ngx-select2>
                </div>
              </div>
              <!-- <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ContactGroups'), 'warning')">
                  <label class="label"><span class="valid">Nhóm liên hệ</span><span class="invalid">Nhóm liên hệ là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="ContactGroups" [select2Option]="select2ContactGroupOption"></ngx-select2>
                </div>
              </div> -->
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Phone'), 'warning')">
                  <label class="label"><span class="valid">Số điện thoại: $so_dien_thoai</span><span class="invalid">Số điện thoại là
                      bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Phone" placeholder="Số điện thoại">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên: $ten</span><span class="invalid">Tên liên hệ
                      là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên liên hệ">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Address'), 'warning')">
                  <label class="label"><span class="valid">Địa chỉ: $dia_chi</span><span class="invalid">Địa chỉ
                      là bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Address" placeholder="Địa chỉ">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Email'), 'warning')">
                  <label class="label"><span class="valid">Email: $email</span><span class="invalid">Email là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Email" placeholder="Email">
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Template'), 'warning')">
                  <label class="label"><span class="valid">Mẫu SMS</span><span class="invalid">Mẫ SMS là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="Template" (selectChange)="onTemplateChanged($event, formItem)" [select2Option]="select2TempateOption"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Gateway'), 'warning')">
                  <label class="label"><span class="valid">Gateway (*)</span><span class="invalid">Gateway là
                      bắt
                      buộc
                      (*)</span></label>
                      <ngx-select2 formControlName="Gateway" [select2Option]="select2GatewayOption" (selectChange)="onGatewayChanged($event, formItem)"></ngx-select2>
                </div>
              </div>
              <!-- <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Brandname'), 'warning')">
                  <label class="label"><span class="valid">Brandname (*)</span><span class="invalid">Brandname là
                      bắt
                      buộc
                      (*)</span></label>
                      <ngx-select2 formControlName="Brandname" [select2Option]="select2BrandnameOption" (selectChange)="onBrandnameChanged($event, formItem)" [data]="formItem.get('Gateway').value?.Brandnames"></ngx-select2>
                </div>
              </div> -->
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var1'), 'warning')">
                  <label class="label"><span class="valid">Tham số 1: $tham_so_1</span><span class="invalid">Tham số 1 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var1" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var2'), 'warning')">
                  <label class="label"><span class="valid">Tham số 2: $tham_so_2</span><span class="invalid">Tham số 2 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var2" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var3'), 'warning')">
                  <label class="label"><span class="valid">Tham số 3: $tham_so_3</span><span class="invalid">Tham số 3 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var3" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var4'), 'warning')">
                  <label class="label"><span class="valid">Tham số 4: $tham_so_4</span><span class="invalid">Tham số 4 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var4" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Subject'), 'warning')">
                  <label class="label"><span class="valid">Subject</span><span class="invalid">Subject là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Subject" placeholder="Tiêu đề">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SubjectPreview'), 'warning')">
                  <label class="label"><span class="valid">Xem trước tiêu đề</span><span class="invalid"></span></label>
                  <input type="text" nbInput fullWidth formControlName="SubjectPreview" placeholder="Xem trước tiêu đề">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Content'), 'warning')">
                  <label class="label"><span class="valid">Nội dung</span><span class="invalid">Nội dung mẫu
                      là bắt
                      buộc
                      (*)</span></label>
                      <!-- <ckeditor formControlName="Content" [config]="{ extraPlugins: 'divarea', height: '320' }"></ckeditor> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Preview'), 'warning')">
                  <label class="label"><span class="valid">Xem trước</span><span class="invalid">Xem trước
                      là bắt
                      buộc
                      (*)</span></label>
                    <!-- <ckeditor formControlName="Preview" [config]="{ extraPlugins: 'divarea', height: '320' }"></ckeditor> -->
                </div>
              </div>

            </div>
          </div>
        </nb-card-body>
        <!-- <nb-card-footer> -->
          <!-- <div class="buttons-row" style="text-align: right;"> -->
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <!-- <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button> -->
          <!-- </div> -->
        <!-- </nb-card-footer> -->
      </nb-card>

    </div>
  </div>
</form>
