<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">
  <div formArrayName="array" class="popup">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.CallCenter.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật trung tâm cuộc gọi
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="call_center_queue_uuid">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('queue_name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="queue_name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('domain_uuid'), 'warning')">
                  <label class="label"><span class="valid">Domain (*)</span><span class="invalid">Domain là bắt buộc
                      (*)</span></label>
                  <ngx-select2 [data]="privateDmainList" formControlName="domain_uuid"
                    [select2Option]="privateDmainListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('queue_extension'), 'warning')">
                  <label class="label"><span class="valid">Số đại diện (*)</span><span class="invalid">Số đại diện là
                      bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="queue_extension"
                    placeholder="Số nội bộ đại diện">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('queue_timeout_action'), 'warning')">
                  <label class="label"><span class="valid">Hành động khi kết thúc</span><span class="invalid">Hành động
                      khi kết thúc là bắt buộc
                      (*)</span></label>
                  <ngx-select2 [data]="actionList" formControlName="queue_timeout_action"
                    [select2Option]="actionListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('queue_greeting'), 'warning')">
                  <label class="label"><span class="valid">Lời chào</span><span class="invalid">Lời chào
                      (*)</span></label>
                  <ngx-select2 [data]="greetingList" formControlName="queue_greeting" [select2Option]="greetingListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('queue_moh_sound'), 'warning')">
                  <label class="label"><span class="valid">Nhạc chờ</span><span class="invalid"> Nhạc
                      chờ(*)</span></label>
                  <ngx-select2 [data]="ringbackList" formControlName="queue_moh_sound" [select2Option]="ringbackListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('queue_max_wait_time'), 'warning')">
                  <label class="label"><span class="valid">Thời gian chờ tối đa (*)</span><span class="invalid">Thời
                      gian chờ tối đa là bắt buộc</span></label>
                  <input type="text" nbInput fullWidth formControlName="queue_max_wait_time"
                    placeholder="Thời gian chờ tối đa">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('queue_max_wait_time_with_no_agent'), 'warning')">
                  <label class="label"><span class="valid">TG tối đa không có agent (*)</span><span class="invalid">TG
                      tối đa không có agent là bắt buộc</span></label>
                  <input type="text" nbInput fullWidth formControlName="queue_max_wait_time_with_no_agent"
                    placeholder="TG tối đa không có agent">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('queue_max_wait_time_with_no_agent_time_reached'), 'warning')">
                  <label class="label"><span class="valid">Max Wait Time with No Agent Time Reached (*)</span><span
                      class="invalid">Max Wait Time with No Agent Time Reached là bắt buộc</span></label>
                  <input type="text" nbInput fullWidth formControlName="queue_max_wait_time_with_no_agent_time_reached"
                    placeholder="TG tối đa không có agent">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('queue_description'), 'warning')">
                  <label class="label"><span class="valid">Mô tả</span><span class="invalid">Mô tả là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="queue_description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('queue_record_template'), 'warning')">
                  <label class="label"><span class="valid">Ghi âm</span><span class="invalid"></span></label>
                  <nb-checkbox formControlName="queue_record_template">Ghi âm</nb-checkbox>
                </div>
              </div>
            </div>
            <hr>
            <label class="label">Danh sách bị trí trục điện thoại</label>
            <div class="row">
              <div class="col-sm-1 label">STT</div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12 label">Vị trí trực điện thoại</div>
                </div>
              </div>
              <div class="col-sm-1 label" style="text-align: center;">
                Lệnh
              </div>
            </div>
            <hr>
            <div formArrayName="call_center_tiers" class="form-details">
              <div class="form-detail-item"
                *ngFor="let callCenterAgent of getCallCenterAgents(i).controls; let ic=index"
                [formGroup]="callCenterAgent">
                <input type="hidden" formControlName="call_center_tier_uuid">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group"
                          [attr.state]="formControlValidate(callCenterAgent.get('call_center_agent_uuid'), 'warning')">
                          <label class="label"><span class="valid">Chuyển tới (*)</span><span class="invalid">Chuyển tới
                              (*)</span></label>
                          <nb-select size="medium" formControlName="call_center_agent_uuid" selected="" fullWidth>
                            <nb-option value="">Chọn trị trí trực điện thoại...</nb-option>
                            <nb-option *ngFor="let agent of agentList" [value]="agent.id">{{agent.text}}</nb-option>
                          </nb-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group" style="text-align: right;">
                      <!-- <label class="label">CM</label> -->
                      <button nbButton status="danger" hero size="medium" (click)="removeCallCenterAgentGroup(i, ic)"
                        style="margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <hr> -->

              </div>
              <div class="row">
                <div class="col-sm-11">
                  <i>Để thêm vị trí trực điện thoại bạn truy cập Tổng đài điện thoại > Trung tâm cuộc gọi > Vị trí trực máy.</i>
                </div>
                <div class="col-sm-1" style="text-align: right">
                  <button nbButton status="success" (click)="addCallCenterAgentGroup(i)" size="medium"
                    style="margin-bottom: 3px;" hero>
                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
