import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-deployment',
  template: `<router-outlet></router-outlet>`,
})
export class CollaboratorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
