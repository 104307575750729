import { RouterModule, Routes } from '@angular/router';
import { RoutingResolve } from '../../app-routing.module';
import { MobileAppComponent } from './mobile-app.component';
import { NgModule } from '@angular/core';

export const mobileAppRoutes: Routes = [
  {
    path: '',
    component: MobileAppComponent,
    // canActivate: [AuthGuardService],
    // resolve: {
    //   configuration: RoutingResolve,
    // },
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(mobileAppRoutes)],
  exports: [RouterModule],
})
export class MobileAppRoutingModule {
}
