import { AccMasterBookModel } from './../../../models/accounting.model';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbColorHelper, NbDialogRef, NbThemeService } from '@nebular/theme';
import { filter, take, takeUntil, takeWhile } from 'rxjs/operators';
import { SolarData } from '../../../@core/data/solar';
import { ApiService } from '../../../services/api.service';
import { Icon } from '../../../lib/custom-element/card-header/card-header.component';
import { ActionControl } from '../../../lib/custom-element/action-control-list/action-control.interface';
import { PageModel } from '../../../models/page.model';
import { AccountingService } from '../accounting.service';
import { BehaviorSubject, from } from 'rxjs';
import { lab } from 'd3-color';
import { BaseComponent } from '../../../lib/base-component';
import { RootServices } from '../../../services/root.services';
import { Router } from '@angular/router';
interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}
@Component({
  selector: 'ngx-accounting-dashboard',
  templateUrl: './accounting-dashboard.component.html',
  styleUrls: ['./accounting-dashboard.component.scss'],
  providers: [CurrencyPipe]
})
export class AccountingDashboardComponent extends BaseComponent implements OnDestroy {

  componentName = 'AccountingDashboardComponent';

  // groupList: ProductGroupModel[];
  formItem: FormGroup;

  size?: string = 'medium';
  favicon: Icon = { pack: 'eva', name: 'list', size: 'medium', status: 'primary' };
  title?: string = 'Phát sinh doanh thu/hoa hồng';
  actionButtonList: ActionControl[];

  defaultReportType = 'MONTH';
  allReady$ = new BehaviorSubject<boolean>(false);
  // filter: any;

  costAndRevenueStatisticsData: {};
  goodsStatisticsData: {};
  debtCompareStatisticsData: {};
  cashFlowStatisticsData: {};
  debtStatisticsData: {};
  investStatisticsData: {};
  profitStatisticsData: {};
  orderStatisticsData: {};
  publisherRegisteredStatisticsData: {};
  options: any;
  costAndRevenueStatisticsDataOptions: any;
  colors: any;
  chartjs: any;

  summaryReport: {
    Cash?: number,
    Gold?: number,
    CashInBank?: number,
    Revenues?: number,
    DecreaseRevenues?: number,
    Cost?: number,
    CustomerReceivableDebt?: number,
    LiabilitiesDebt?: number,
    Profit?: number,
  };

  customerReceivableDebt = [];
  liabilitityDebt = [];

  masterBook: AccMasterBookModel;

  constructor(
    public rsv: RootServices,
    public router: Router,
    private themeService: NbThemeService,
    private solarService: SolarData,
    public cms: CommonService,
    public formBuilder: FormBuilder,
    public accountingService: AccountingService,
    public apiService: ApiService,
    public currencyPipe: CurrencyPipe,
    public ref: NbDialogRef<AccountingDashboardComponent>,
  ) {
    super(rsv, cms, router, apiService, ref);
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.statusCards = this.statusCardsByThemes[theme.name];

        this.colors = theme.variables;
        this.chartjs = theme.variables.chartjs;

        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'bottom',
            labels: {
              fontColor: this.chartjs.textColor,
            },
          },
          hover: {
            mode: 'index',
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Thời gian',
                },
                gridLines: {
                  display: true,
                  color: this.chartjs.axisLineColor,
                },
                ticks: {
                  fontColor: this.chartjs.textColor
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Giá trị',
                },
                gridLines: {
                  display: true,
                  color: this.chartjs.axisLineColor,
                },
                ticks: {
                  fontColor: this.chartjs.textColor,
                  callback: (value, index, values) => {
                    return this.currencyPipe.transform(value || 0, 'VND')
                  }
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                return this.currencyPipe.transform(tooltipItem.yLabel, 'VND');
              }
            }
          }
        };
        // this.costAndRevenueStatisticsDataOptions = {
        //   ...this.options,

        // };
      });

    this.solarService.getSolarData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.solarValue = data;
      });

    // this.apiService.getPromise<ProductGroupModel[]>('/collaborator/product-groups', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
    //   this.groupList = [{ id: '', text: '' }, ...rs];
    // });

    // const currentDate = new Date();
    this.formItem = this.formBuilder.group<any>({
      DateReport: [],
      DateRange: [],
      Page: [[]],
      // ProductGroup: { value: '', disabled: true },
    });
    // this.formItem.get('DateReport').valueChanges.subscribe(value => {
    //   console.log(value);
    //   this.formItem.get('DateRange').setValue(this.dateReportList.find(f => f.id === this.cms.getObjectId(value)).range);
    // });

    // setTimeout(() => {
    //   this.refresh();
    // }, 1000);

    this.formItem.valueChanges.subscribe((value) => {
      this.cms.takeUntil('acc_dashboard_filter_change', 300).then(() => {
        const filter = this.formItem.value;
        if (filter['DateReport'] && filter['DateRange']) {
          this.refresh();
        }
      });
    });

    this.cms.waitForReady().then(() => {
      this.actionButtonList = [
        {
          name: 'refresh',
          status: 'success',
          label: this.cms.textTransform(this.cms.translate.instant('Common.refresh'), 'head-title'),
          icon: 'sync',
          title: this.cms.textTransform(this.cms.translate.instant('Common.refresh'), 'head-title'),
          size: 'medium',
          disabled: () => {
            return false;
          },
          click: () => {
            this.refresh();
            return false;
          },
        },
      ];

      this.apiService.getPromise<AccMasterBookModel[]>('/accounting/master-books/current', {}).then(rs => {
        this.masterBook = rs[0];
        const current = new Date();
        const previousMonth = new Date(current.getTime() - 31 * 24 * 60 * 60 * 1000);
        let fromDate = new Date(this.masterBook.DateOfBeginning);
        this.dateReportList = [
          { id: 'DAY', text: 'Phân tích theo ngày', range: [new Date(previousMonth.getFullYear(), previousMonth.getMonth(), previousMonth.getDate(), 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), current.getDate(), current.getHours(), current.getMinutes(), current.getSeconds(), current.getMilliseconds())] },
          { id: 'MONTH', text: 'Phân tích theo tháng', range: [new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()), new Date()] },
          { id: 'DAYOFWEEK', text: 'Phân tích theo tuần', range: [this.getUpcomingMonday(), this.getUpcomingSunday()] },
          { id: 'HOUR', text: 'Phân tích theo giờ', range: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)] },
        ];
        this.formItem.get('DateReport').valueChanges.subscribe(value => {
          // console.log(value);
          if (value) {
            this.formItem.get('DateRange').setValue(this.dateReportList.find(f => f.id === this.cms.getObjectId(value)).range);
          }
        });
        this.formItem.get('DateReport').setValue(this.defaultReportType);
        this.formItem.get('DateRange').setValue(this.dateReportList.find(f => f.id === this.defaultReportType).range);
        this.allReady$.next(true);
      });
    });
  }

  select2OptionForPage = {
    placeholder: 'Tất cả chi nhánh...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  select2DateReportOption = {
    placeholder: 'Chọn thời gian...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  dateReportList = [
    // { id: 'DAY', text: 'Phân tích theo ngày', range: [new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), 31, 23, 59, 59)] },
    // { id: 'MONTH', text: 'Phân tích theo tháng', range: [new Date(new Date().getFullYear(), 0, 1), new Date()] },
    // { id: 'DAYOFWEEK', text: 'Phân tích theo tuần', range: [this.getUpcomingMonday(), this.getUpcomingSunday()] },
    // { id: 'HOUR', text: 'Phân tích theo giờ', range: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)] },
  ];

  dayLabel = {
    "1": "Chủ nhật",
    "2": "Thứ hai",
    "3": "Thứ ba",
    "4": "Thứ tư",
    "5": "Thứ năm",
    "6": "Thứ sáu",
    "7": "Thứ bảy",
  };

  onDateReportChange(dateReport: any) {
    console.log(dateReport);
  }

  // select2ProductGroup = {
  //   placeholder: 'Tất cả nhóm sản phẩm...',
  //   allowClear: false,
  //   width: '100%',
  //   dropdownAutoWidth: true,
  //   multiple: true,
  //   minimumInputLength: 0,
  //   keyMap: {
  //     id: 'id',
  //     text: 'text',
  //   },
  // };

  private alive = true;

  solarValue: number;
  lightCard: CardSettings = {
    title: 'Đơn hàng phát sinh',
    iconClass: 'nb-lightbulb',
    type: 'primary',
  };
  rollerShadesCard: CardSettings = {
    title: 'Đơn hàng đã duyệt',
    iconClass: 'nb-roller-shades',
    type: 'success',
  };
  wirelessAudioCard: CardSettings = {
    title: 'Doanh thu phát sinh',
    iconClass: 'nb-audio',
    type: 'info',
  };
  coffeeMakerCard: CardSettings = {
    title: 'Hoa hồng phát sinh',
    iconClass: 'nb-coffee-maker',
    type: 'warning',
  };

  statusCards: string;

  commonStatusCardsSet: CardSettings[] = [
    this.lightCard,
    this.rollerShadesCard,
    this.wirelessAudioCard,
    this.coffeeMakerCard,
  ];

  statusCardsByThemes: {
    default: CardSettings[];
    cosmic: CardSettings[];
    corporate: CardSettings[];
    dark: CardSettings[];
  } = {
      default: this.commonStatusCardsSet,
      cosmic: this.commonStatusCardsSet,
      corporate: [
        {
          ...this.lightCard,
          type: 'warning',
        },
        {
          ...this.rollerShadesCard,
          type: 'primary',
        },
        {
          ...this.wirelessAudioCard,
          type: 'danger',
        },
        {
          ...this.coffeeMakerCard,
          type: 'info',
        },
      ],
      dark: this.commonStatusCardsSet,
    };

  ngOnDestroy() {
    this.alive = false;
  }

  onChangePage(page: PageModel) {

  }

  makeStaticLabel(item: any, reportType: string) {
    if (reportType === 'MONTH') {
      return (item['Month']).toString().padStart(2, "0") + '/' + (item['Year']).toString().padStart(2, "0");
    }
    if (reportType === 'DAY') {
      return (item['Day']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
    }
    if (reportType === 'HOUR') {
      return (item['Hour']).toString().padStart(2, "0");
    }
    return (item['DayOfWeek']).toString().padStart(2, "0");
  }

  makeTimeline(item: any, reportType: string) {
    if (reportType === 'MONTH') {
      return (item['Year']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
    }
    if (reportType === 'DAY') {
      return item['Year'] + '/' + (item['Month']).toString().padStart(2, "0") + '/' + (item['Day']).toString().padStart(2, "0");
    }
    if (reportType === 'HOUR') {
      return (item['Hour']).toString().padStart(2, "0");
    }
    return (item['DayOfWeek']).toString().padStart(2, "0");
  }

  async refresh() {
    await this.allReady$.pipe(takeUntil(this.destroy$), filter(f => f === true), take(1)).toPromise();
    const reportType = this.cms.getObjectId(this.formItem.get('DateReport').value);
    let pages = this.formItem.get('Page').value;
    if (pages) {
      pages = pages.map(page => this.cms.getObjectId(page));
      pages = pages.join(',');
    }
    const dateRange: Date[] = this.formItem.get('DateRange').value;
    const fromDate = dateRange && dateRange[0] && (new Date(dateRange[0].getFullYear(), dateRange[0].getMonth(), dateRange[0].getDate(), 0, 0, 0, 0)).toISOString() || null;
    const toDate = dateRange && dateRange[1] && new Date(dateRange[1].getFullYear(), dateRange[1].getMonth(), dateRange[1].getDate(), 23, 59, 59, 999).toISOString() || null;

    const promise1 = new Promise((resolve, reject) => {
      this.apiService.getPromise<any[]>('/accounting/reports', { reportSummaryx: true, eq_Accounts: "111,112,511,521,515,521,632,635,642,641,623,2288,711,811,131,331", groupBy: 'Account', skipHeaderx: true, branch: pages, toDate: toDate, fromDate: fromDate, limit: 'nolimit' }).then(summaryReport => {
        console.log(summaryReport);

        this.summaryReport = {
          Cash: summaryReport.filter(f => /^1111/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
          Gold: summaryReport.filter(f => /^1113/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
          CashInBank: summaryReport.filter(f => /^112/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
          Revenues: summaryReport.filter(f => /^511|515|711/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
          DecreaseRevenues: summaryReport.filter(f => /^521/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
          Cost: summaryReport.filter(f => /^632|642|635|623|641|811|521/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
          CustomerReceivableDebt: summaryReport.filter(f => /^131/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
          LiabilitiesDebt: summaryReport.filter(f => /^331/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
          // Profit: summaryReport.filter(f => /^4212/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
        };
        this.summaryReport.Profit = this.summaryReport.Revenues - this.summaryReport.Cost;
      });
      this.apiService.getPromise<any>('/accounting/reports', { reportReceivablesFromCustomerx: true, eq_Accounts: '131', groupBy: 'Object', branch: pages, toDate: toDate, sort_TailDebit: 'desc' }).then(customerReceivableDebt => {
        this.customerReceivableDebt = customerReceivableDebt;
        console.log(customerReceivableDebt);
      });
      this.apiService.getPromise<any>('/accounting/reports', { reportLiabilitiesz: true, eq_Accounts: '331', groupBy: 'Object', branch: pages, toDate: toDate, sort_TailCredit: 'desc' }).then(liabilitityDebt => {
        this.liabilitityDebt = liabilitityDebt;
        console.log(liabilitityDebt);
      });
    });

    let pointRadius: number = 1;
    if (reportType == 'MONTH') {
      pointRadius = 3;
    }

    if (reportType == 'DAY') {
      pointRadius = 2;
    }

    const promise2 = new Promise(async (resolve, reject) => {
      let line1Data: any[], line2Data: any[], line3Data: any[], line4Data: any[], line5Data: any[], labels: any[], timeline: any[], mergeData: any[];

      /** Load data */
      let revenueStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[511,515,521,711]", statisticsRevenue: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      let costStatistics632 = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[632]", statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      let costStatistics641 = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[641,642,811]", statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });

      /** Prepare data */
      line1Data = revenueStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
      line2Data = costStatistics632.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      line3Data = costStatistics641.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      timeline = [...new Set([
        ...line1Data.map(item => item['Timeline']),
        ...line2Data.map(item => item['Timeline']),
        ...line3Data.map(item => item['Timeline']),
      ].sort())];
      labels = [];
      mergeData = timeline.map(t => {
        const point1 = line1Data.find(f => f.Timeline == t);
        const point2 = line2Data.find(f => f.Timeline == t);
        const point3 = line3Data.find(f => f.Timeline == t);
        labels.push(point1?.Label || point2?.Label);
        return {
          Label: t,
          Line1: point1 || { Value: 0 },
          Line2: point2 || { Value: 0 },
          Line3: point3 || { Value: 0 },
        };
      });


      this.costAndRevenueStatisticsData = {
        labels: labels,
        datasets: [
          {
            label: 'Doanh thu',
            data: mergeData.map(point => point.Line1['Value']),
            borderColor: this.colors.success,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.success, 1),
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Giá vốn',
            data: mergeData.map(point => point.Line2['Value']),
            borderColor: this.colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.danger, 1),
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Chi phí',
            data: mergeData.map(point => point.Line3['Value']),
            borderColor: this.colors.warning,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.warning, 1),
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
        ],
      };
    });

    const promise3 = new Promise(async (resolve, reject) => {

      let cashFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1111]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const cashInBankFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1121]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const goldFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1113]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      // const voucherFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1114]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });

      /** Prepare data */
      // line1Data = voucherFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line2Data = goldFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line3Data = cashInBankFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line4Data = cashFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const timeline = [...new Set([
        // ...line1Data.map(item => item['Timeline']),
        ...line2Data.map(item => item['Timeline']),
        ...line3Data.map(item => item['Timeline']),
        ...line4Data.map(item => item['Timeline']),
      ].sort())];
      const labels = [];
      const mergeData = timeline.map(t => {
        // const point1 = line1Data.find(f => f.Timeline == t);
        const point2 = line2Data.find(f => f.Timeline == t);
        const point3 = line3Data.find(f => f.Timeline == t);
        const point4 = line4Data.find(f => f.Timeline == t);
        labels.push(
          // point1?.Label || 
          point2?.Label || point3?.Label || point4?.Label);
        return {
          Label: t,
          // Line1: point1 || { Value: 0 },
          Line2: point2 || { Value: 0 },
          Line3: point3 || { Value: 0 },
          Line4: point4 || { Value: 0 },
        };
      });

      this.debtCompareStatisticsData = {
        labels: labels,
        datasets: [
          {
            label: 'Tiền mặt',
            // data: cashFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line4['Value']),
            borderColor: this.colors.danger,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.danger, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Tiền trong ngân hàng',
            // data: cashInBankFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line3['Value']),
            borderColor: this.colors.info,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.info, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Vàng',
            // data: goldFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line2['Value']),
            borderColor: this.colors.warning,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.warning, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Tổng tiền',
            // data: cashFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line4['Value'] + point.Line3['Value'] + point.Line2['Value']),
            borderColor: this.colors.success,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.success, 0.1),
            // fill: true,
            borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
        ],
      };
      /** End */
    });

    const promise4 = new Promise(async (resolve, reject) => {
      /** Prepare data */
      // cashFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1111,1121,1113]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const businessCashFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1111,1121]", eq_ContraAccount: "[511,521,641,642,627,635,811,331,131,334,3334,242,133,333,141,136,336,821]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const envestmentCashFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1111,1121]", eq_ContraAccount: "[128,711,211,515,241]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const financyCashFlowStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1111,1121]", eq_ContraAccount: "[341]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });

      // line1Data = voucherFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      // line1Data = cashFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line2Data = businessCashFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line3Data = envestmentCashFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line4Data = financyCashFlowStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const timeline = [...new Set([
        // ...line1Data.map(item => item['Timeline']),
        ...line2Data.map(item => item['Timeline']),
        ...line3Data.map(item => item['Timeline']),
        ...line4Data.map(item => item['Timeline']),
      ].sort())];
      const labels = [];
      const mergeData = timeline.map(t => {
        const point2 = line2Data.find(f => f.Timeline == t);
        const point3 = line3Data.find(f => f.Timeline == t);
        const point4 = line4Data.find(f => f.Timeline == t);
        // const point1;
        labels.push(point2?.Label || point3?.Label || point4?.Label);
        return {
          Label: t,
          Line1: { Value: ((point2.Value || 0) + (point3.Value || 0) + (point3.Value + 0) | (point4.Value | 0)) },
          Line2: point2 || { Value: 0 },
          Line3: point3 || { Value: 0 },
          Line4: point4 || { Value: 0 },
        };
      });


      this.cashFlowStatisticsData = {
        labels: labels,
        datasets: [
          {
            label: 'Tổng tiền',
            // data: cashFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line1['Value']),
            borderColor: this.colors.info,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.info, 0.1),
            // fill: true,
            borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Kinh doanh',
            // data: cashFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line2['Value']),
            borderColor: this.colors.success,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.success, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Đầu tư',
            // data: cashInBankFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line3['Value']),
            borderColor: this.colors.primary,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.primary, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Tài chính',
            // data: goldFlowStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line4['Value']),
            borderColor: this.colors.warning,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.warning, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
        ],
      };
      /** End */
    });

    const promise5 = new Promise(async (resolve, reject) => {
      const customerReceivableStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[131]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const liabilitiesStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[331]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const loadStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[3411]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const financialLeasingDebtStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[3412]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });

      /** Prepare data */
      const line1Data = customerReceivableStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line2Data = liabilitiesStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
      const line3Data = loadStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
      const line4Data = financialLeasingDebtStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
      const timeline = [
        ...new Set([
          ...line1Data.map(item => item['Timeline']),
          ...line2Data.map(item => item['Timeline']),
          ...line3Data.map(item => item['Timeline']),
          ...line4Data.map(item => item['Timeline']),
        ].sort())
      ];
      const labels = [];
      const mergeData = timeline.map(t => {
        const point1 = line1Data.find(f => f.Timeline == t);
        const point2 = line2Data.find(f => f.Timeline == t);
        const point3 = line3Data.find(f => f.Timeline == t);
        const point4 = line4Data.find(f => f.Timeline == t);
        labels.push(point1?.Label || point2?.Label || point3?.Label || point4?.Label);
        return {
          Label: t,
          Line1: point1 || { Value: 0 },
          Line2: point2 || { Value: 0 },
          Line3: point3 || { Value: 0 },
          Line4: point4 || { Value: 0 },
        };
      });

      this.debtStatisticsData = {
        labels,
        datasets: [
          {
            label: 'Công nợ phải thu',
            // data: customerReceivableStatistics.map(statistic => statistic.SumOfDebit - statistic.SumOfCredit),
            data: mergeData.map(point => point.Line1['Value']),
            borderColor: this.colors.danger,
            // backgroundColor: colors.danger,
            // backgroundColor: NbColorHelper.hexToRgbA(this.colors.success, 0.3),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Công nợ phải trả',
            // data: liabilitiesStatistics.map(statistic => statistic.SumOfCredit - statistic.SumOfDebit),
            data: mergeData.map(point => point.Line2['Value']),
            borderColor: this.colors.warning,
            // backgroundColor: colors.primary,
            // backgroundColor: NbColorHelper.hexToRgbA(this.colors.danger, 0.3),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Các khoản vay',
            // data: loadStatistics.map(statistic => statistic.SumOfCredit - statistic.SumOfDebit),
            data: mergeData.map(point => point.Line3['Value']),
            borderColor: this.colors.primary,
            // backgroundColor: colors.primary,
            // backgroundColor: NbColorHelper.hexToRgbA(this.colors.warning, 0.3),
            // fill: true,
            borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Nợ thuê tài chính',
            // data: financialLeasingDebtStatistics.map(statistic => statistic.SumOfCredit - statistic.SumOfDebit),
            data: mergeData.map(point => point.Line4['Value']),
            borderColor: this.colors.info,
            // backgroundColor: colors.primary,
            // backgroundColor: NbColorHelper.hexToRgbA(this.colors.warning, 0.3),
            // fill: true,
            borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
        ],
      };
      /** End */
    });

    const promise6 = new Promise(async (resolve, reject) => {
      const a1288Statistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[1288]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const a411Statistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[4111]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const line1Data = a1288Statistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
      const line2Data = a411Statistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
      const timeline = [
        ...new Set([
          ...line1Data.map(item => item['Timeline']),
          ...line2Data.map(item => item['Timeline']),
        ].sort())
      ];
      const labels = [];
      const mergeData = timeline.map(t => {
        const point1 = line1Data.find(f => f.Timeline == t);
        const point2 = line2Data.find(f => f.Timeline == t);
        labels.push(point1?.Label);
        return {
          Label: t,
          Line1: point1 || { Value: 0 },
          Line2: point2 || { Value: 0 },
        };
      });
      this.investStatisticsData = {
        labels,
        datasets: [
          {
            label: 'Đầu tư khác',
            // data: financialLeasingDebtStatistics.map(statistic => statistic.SumOfCredit - statistic.SumOfDebit),
            data: mergeData.map(point => point.Line1['Value']),
            borderColor: this.colors.success,
            // backgroundColor: colors.primary,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.success, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
          {
            label: 'Góp vốn',
            // data: financialLeasingDebtStatistics.map(statistic => statistic.SumOfCredit - statistic.SumOfDebit),
            data: mergeData.map(point => point.Line2['Value']),
            borderColor: this.colors.primary,
            // backgroundColor: colors.primary,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.primary, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
        ],
      };

      /** End */
    });
    const promise7 = new Promise(async (resolve, reject) => {
      const profitStatistics = await this.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[632,641,642,811,511,512,515,711]", statisticsProfit: true, increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit' });
      const line1Data = profitStatistics.map(statistic => { statistic.Label = this.makeStaticLabel(statistic, reportType); statistic.Timeline = this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
      const timeline = [...new Set([
        ...line1Data.map(item => item['Timeline']),
      ].sort())];
      const labels = [];
      const mergeData = timeline.map(t => {
        const point = line1Data.find(f => f.Timeline == t);
        labels.push(point?.Label);
        return {
          Label: t,
          Line1: point || { Value: 0 },
        };
      });

      this.profitStatisticsData = {
        labels,
        datasets: [
          {
            label: 'Lợi nhuận sau thuế',
            // data: profitStatistics.map(statistic => statistic.SumOfCredit - statistic.SumOfDebit),
            data: mergeData.map(point => point.Line1['Value']),
            borderColor: this.colors.success,
            // backgroundColor: colors.danger,
            backgroundColor: NbColorHelper.hexToRgbA(this.colors.primary, 0.1),
            // fill: true,
            // borderDash: [5, 5],
            pointRadius: pointRadius,
            pointHoverRadius: 10,
          },
        ],
      };
    });

    await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7]);
    return true;

  }

  getUpcomingMonday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 1);
    const result = new Date(newDate);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    return result;
  }
  getUpcomingSunday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 7);
    const result = new Date(newDate);
    result.setHours(23);
    result.setMinutes(59);
    result.setSeconds(59);
    return result;
  }
}
