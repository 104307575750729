import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { CommerceServiceByCycleListComponent } from "./service-by-cycle/commerce-service-by-cycle-list/commerce-service-by-cycle-list.component";
import { NgModule } from "@angular/core";

export const commonServiceByCycleRoutes: Routes = [
  {
    path: 'service-by-cycle/list',
    canActivate: [AuthGuardService],
    component: CommerceServiceByCycleListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(commonServiceByCycleRoutes)],
  exports: [RouterModule],
})
export class CommerceServiceByCycleRoutingModule {
}
