import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { ContractBusinessInfoFormComponent } from "./business-info/business-info-form/business-info-form.component";
import { ContractBusinessInfoListComponent } from "./business-info/business-info-list/business-info-list.component";
import { ContractBusinessInfoPrintComponent } from "./business-info/business-info-print/business-info-print.component";
import { ContractRoutingModule } from "./contract-routing.module";
import { ContractComponent } from "./contract.component";
import { ContractFormComponent } from "./contract/contract-form/contract-form.component";
import { ContractListComponent } from "./contract/contract-list/contract-list.component";
import { ContractPrintComponent } from "./contract/contract-print/contract-print.component";
import { ContractTemplateFormComponent } from "./template/contract-template-form/contract-template-form.component";
import { ContractTemplateListComponent } from "./template/contract-template-list/contract-template-list.component";
import { ContractTemplatePrintComponent } from "./template/contract-template-print/contract-template-print.component";

export const contractComponents = [
    ContractComponent,
    ContractListComponent,
    ContractPrintComponent,
    ContractFormComponent,
    ContractTemplateListComponent,
    ContractTemplateFormComponent,
    ContractTemplatePrintComponent,

    ContractBusinessInfoListComponent,
    ContractBusinessInfoFormComponent,
    ContractBusinessInfoPrintComponent,

];

@NgModule({
    declarations: contractComponents,
    exports: contractComponents,
    imports: [
        ...commonModules,
        ContractRoutingModule,
    ],
    bootstrap: [ContractComponent],
    providers: [
        ...commonProviders,
    ],
})
export class ContractModule { }