import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { ClusterAuthorizedKeyListComponent } from "./authorized-key/cluster-authorized-key-list/cluster-authorized-key-list.component";
import { NgModule } from "@angular/core";

export const clusterRoutes: Routes = [
  {
    path: 'authorized-key/list',
    canActivate: [AuthGuardService],
    component: ClusterAuthorizedKeyListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(clusterRoutes)],
  exports: [RouterModule],
})
export class ClusterRoutingModule {
}
