import { Component, OnInit } from '@angular/core';
import { ProductCategoryModel } from '../../../../models/product.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ProductCategoryFormComponent } from '../product-category-form/product-category-form.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { AdminProductService } from '../../admin-product.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { DialogSortingFormComponent } from '../../../../lib/component/dialog/sorting-form/sorting-form.component';

@Component({
  selector: 'ngx-product-category-list',
  templateUrl: './product-category-list.component.html',
  styleUrls: ['./product-category-list.component.scss'],
})
export class ProductCategoryListComponent extends AgGridDataManagerListComponent<ProductCategoryModel, ProductCategoryFormComponent> implements OnInit {

  componentName: string = 'ProductCategoryListComponent';
  formPath = '/admin-product/category/form';
  apiPath = '/admin-product/categories';
  idKey = ['Code'];
  formDialog = ProductCategoryFormComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public prds: AdminProductService,
    public _http: HttpClient,
    public ref: NbDialogRef<ProductCategoryListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      const categoryList = await this.prds.categoryList$.pipe(takeUntil(this.destroy$), filter(f => !!f), take(1)).toPromise();
      categoryList.unshift({
        id: 'NULL',
        text: 'Gốc',
      });
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 450,
        },
        {
          headerName: 'Danh mục cha',
          field: 'Parent',
          // pinned: 'left',
          width: 300,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              // ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { includeIdText: true, sort_Name: 'asc' }, {
              //   placeholder: 'Chọn danh mục cha...', limit: 10, prepareReaultItem: (item) => {
              //     // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
              //     return item;
              //   }
              // }),
              ...this.cms.select2OptionForTemplate,
              data: this.prds.categoryList$.value,
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 1024,
        },
        {
          headerName: 'Sắp xếp',
          field: 'Sequence',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'right',
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      this.actionButtonList.unshift({
        type: 'button',
        name: 'sortBtn',
        status: 'info',
        label: 'Sắp xếp',
        title: 'Sắp xếp các mục đã chọn',
        size: 'medium',
        icon: 'list-outline',
        hidden: () => this.isChoosedMode,
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          this.cms.openDialog(DialogSortingFormComponent, {
            context: {
              title: 'Cập nhật thứ tự',
              data: this.selectedItems.map(m => ({ id: m.Code, text: this.cms.translateText(m.Name), no: parseInt(m.No) })).sort((a, b) => a.no - b.no),
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: async (form) => {
                    return true;
                  },
                },
                {
                  label: 'Cập nhật',
                  icon: 'generate',
                  status: 'success',
                  action: async (form) => {
                    const data = (form.value['array'] || []).map((m, i) => {
                      const item = this.selectedItems.find(f => f.Code == m.id);
                      return { Code: item.Code, Sequence: parseInt(i) + 1 };
                    });
                    if (data) {
                      this.loading = true;
                      this.apiService.putPromise<ProductCategoryModel[]>(this.apiPath, {}, data).then(rs => {
                        this.cms.showToast('Đã cập nhật lại thứ tự thành công', 'Cập nhật thứ tự', { status: 'success' });
                        this.refresh().then(() => {
                          this.loading = false;
                        }).catch(err => {
                          this.loading = false;
                        });
                      }).catch(err => {
                        this.loading = false;
                      });;
                    }
                    return true;
                  },
                },
              ],
            },
          });
        }
      });

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<ProductCategoryModel, ProductCategoryFormComponent>) {
    params['includeParent'] = true;
    return params;
  }

}

