import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbThemeService, NbToastrService, NbDialogRef } from '@nebular/theme';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { Model } from '../../../../models/model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { CodeTemplateStateMap } from '../../../code-template/code-template.state.map';
import { SimpleDataManagerFormComponent } from '../../../code-template/simple-data-manager/simple-data-manager-form/simple-data-manager-form.component';
import { InputInvoiceForwardLoginComponent } from '../input-invoice-forward-login/input-invoice-forward-login.component';
import { InputInvoiceFormComponent } from '../input-invoice-form/input-invoice-form.component';
import { InputInvoicePrintComponent } from '../input-invoice-print/input-invoice-print.component';
import { agMakeDatetimeColDef } from '../../../../lib/custom-element/ag-list/column-define/datetime.define';

@Component({
  selector: 'ngx-input-invoice-list',
  templateUrl: './input-invoice-list.component.html',
  styleUrls: ['./input-invoice-list.component.scss']
})
export class InputInvoiceListComponent extends AgGridDataManagerListComponent<Model, SimpleDataManagerFormComponent> implements OnInit {

  componentName: string = 'InputInvoiceListComponent';
  apiPath = '/tax/input-invoices';
  formPath = '/tax/input-invoice/form';
  idKey = ['Id'];
  formDialog = InputInvoiceFormComponent;
  printDialog = InputInvoicePrintComponent;

  editing = {};
  rows = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public ref: NbDialogRef<InputInvoiceListComponent>,
    public _http: HttpClient,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      this.actionButtonList = this.actionButtonList.filter(f => ['add', 'edit'].indexOf(f.name) < 0);

      this.actionButtonList.unshift({
        name: 'login',
        status: 'danger',
        label: 'Tải về',
        outline: true,
        title: 'Sử dụng tài khoản đăng nhập của cục thuế cấp để tải về hóa đơn điện tử',
        type: 'button',
        size: 'medium',
        icon: 'cloud-download-outline',
        click: async (event, option, context, controlEle) => {
          let toast = null;
          try {
            this.loading = true;
            toast = this.cms.showToast('Hãy chờ trong giây lát...', 'Đang tải hóa đơn VAT', { status: 'info', duration: 0 });
            const resp = await this.apiService.putPromise<any[]>(this.apiPath, { download: true, silent: true }, []);
            await this.refresh();
            toast.close();
            toast = this.cms.showToast('Hóa đơn VAT đã được tài xuống thành công', 'Tải hóa đơn VAT thành công', { status: 'success' });
            this.loading = false;
          } catch (err) {
            this.loading = false;
            console.error(err);
            if (err.error?.errorCode == 4001) {
              // Show login
              toast.close();
              toast = this.cms.showToast('Bạn cần đăng nhập thuế lại để tiếp tục', 'Phiên đã hết hạn, hãy đăng nhập lại', { status: 'danger' });
              this.cms.openDialog(InputInvoiceForwardLoginComponent, {
                context: {
                  width: '420px',
                  onDialogSave: async loginData => {
                    this.loading = true;
                    const resp = await this.apiService.putPromise<any[]>(this.apiPath, { download: true, silent: true }, []);
                    await this.refresh();
                    toast.close();
                    toast = this.cms.showToast('Hóa đơn VAT đã được tài xuống thành công', 'Tải hóa đơn VAT thành công', { status: 'success' });
                    this.loading = false;
                  }
                },
              });
            }
          }

        },
      });

      const processingMap = CodeTemplateStateMap.simpleDataManagerStateMap;
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tiêu đề',
          field: 'Title',
          // pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 350,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày hóa đơn',
          field: 'DateOfInvoice',
          width: 180,
          pinned: 'right',
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['useBaseTimezone'] = true;
    return params;
  }

}
