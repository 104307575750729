import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaxRoutingModule } from './tax-routing.module';
import { TaxComponent } from '../tax/tax.component';
import { InputInvoiceListComponent } from './input-invoice/input-invoice-list/input-invoice-list.component';
import { InputInvoiceFormComponent } from './input-invoice/input-invoice-form/input-invoice-form.component';
import { InputInvoicePrintComponent } from './input-invoice/input-invoice-print/input-invoice-print.component';
import { commonModules } from '../../common-modules';
import { InputInvoiceForwardLoginComponent } from './input-invoice/input-invoice-forward-login/input-invoice-forward-login.component';
import { OutputInvoiceListComponent } from './output-invoice/output-invoice-list/output-invoice-list.component';


@NgModule({
  declarations: [
    TaxComponent,
    InputInvoiceListComponent,
    InputInvoiceFormComponent,
    InputInvoicePrintComponent,
    InputInvoiceForwardLoginComponent,
    OutputInvoiceListComponent
  ],
  imports: [
    CommonModule,
    TaxRoutingModule,
    commonModules,
  ]
})
export class TaxModule { }
