import { ExtraOptions, RouterModule, Routes, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NgModule, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './services/common.service';
import { AdminProductRoutingModule } from './modules/admin-product/admin-product-routing.module';
import { ContactRoutingModule } from './modules/contact/contact-routing.module';
import { FileRoutingModule } from './modules/file/file-routing.module';
import { AccManagerRoutingModule } from './modules/acc-manager/acc-manager-routing.module';
import { AccountingRoutingModule } from './modules/accounting/accounting-routing.module';
import { AdsRoutingModule } from './modules/ads/ads-routing.module';
import { B2bCenterRoutingModule } from './modules/b2b-center/b2b-center-routing.module';
import { B2bServerRoutingModule } from './modules/b2b-server/b2b-server-routing.module';
import { ClusterRoutingModule } from './modules/cluster/cluster-routing.module';
import { CollaboratorRoutingModule } from './modules/collaborator/collaborator-routing.module';
import { CommercePosRoutingModule } from './modules/commerce-pos/commerce-pos-routing.module';
import { CommerceServiceByCycleRoutingModule } from './modules/commerce-service-by-cycle/commerce-service-by-cycle.routing';
import { ContractRoutingModule } from './modules/contract/contract-routing.module';
import { CoreConnectionRoutingModule } from './modules/core-connection/core-connection-routing.module';
import { CrawlRoutingModule } from './modules/crawl/crawl-routing.module';
import { DeploymentRoutingModule } from './modules/deployment/deployment-routing.module';
import { EmailMarketingRoutingModule } from './modules/email-marketing/email-marketing-routing.module';
import { HelpdeskRoutingModule } from './modules/helpdesk/helpdesk-routing.module';
import { HumanResouceRoutingModule } from './modules/human-resource/human-resource-routing.module';
import { IvoipRoutingModule } from './modules/ivoip/ivoip-routing.module';
import { MarketingRoutingModule } from './modules/marketing/marketing-routing.module';
import { MenuRoutingModule } from './modules/menu/menu-routing.module';
import { MiniErpRoutingModule } from './modules/minierp/minierp-routing.module';
import { MobileAppRoutingModule } from './modules/mobile-app/mobile-app-routing.module';
import { ModulesRoutingModule } from './modules/modules/modules-routing.module';
import { NetworkRoutingModule } from './modules/network/network-routing.module';
import { NotificationRoutingModule } from './modules/notification/notification-routing.module';
import { PromotionRoutingModule } from './modules/promotion/promotion-routing.module';
import { ShortLinkRoutingModule } from './modules/short-link/ads-routing.module';
import { SmartBotRoutingModule } from './modules/smart-bot/smart-bot-routing.module';
import { SmsRoutingModule } from './modules/sms/sms-routing.module';
import { SystemRoutingModule } from './modules/system/system-routing.module';
import { UsersRoutingModule } from './modules/users/users-routing.module';
import { WebHostingRoutingModule } from './modules/web-hosting/web-hosting-routing.module';
import { WordpressRoutingModule } from './modules/wordpress/wordpress-routing.module';
import { ZaloOaRoutingModule } from './modules/zalo-oa/zalo-ao-routing.module';
import { pageRoutes } from './modules/collaborator/page/page-routing.module';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { VirtualPhoneRoutingModule } from './modules/virtual-phone/virtual-phone-routing.module';
import { WarehouseRoutingModule } from './modules/warehouse/warehouse-routing.module';
import { PurchaseRoutingModule } from './modules/purchase/purchase-routing.module';
import { SalesRoutingModule } from './modules/sales/sales-routing.module';
import { DemoRoutingModule } from './modules/demo/demo-routing.module';
import { TaxRoutingModule } from './modules/tax/tax-routing.module';
import { CodeTemplateRoutingModule } from './modules/code-template/code-template-routing.module';
// import { contractRoutes } from './modules/contract/contract.routing';

@Injectable({
  providedIn: 'root',
})
export class RoutingResolve implements Resolve<any> {

  constructor(public translate: TranslateService, public cms: CommonService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const $this = this;
    return new Promise<any>(resolve => {
      (function checkLocalStorageOnline() {
        if (localStorage && $this.translate) {
          let locale = localStorage.getItem('configuration.locale');
          if (!locale) {
            const browserLangCode = $this.translate.getBrowserLang();
            locale = browserLangCode.match(/en|vi/) ? browserLangCode : 'en-US';
          }
          // $this.locale$.next({locale: locale, skipUpdate: true});
          $this.translate.use(locale).subscribe(res => {
            resolve(locale);
            if (!$this.cms.configReady$.value) {
              $this.cms.configReady$.next(true);
            }
          });

        } else {
          setTimeout(() => {
            checkLocalStorageOnline();
          }, 100);
        }
      })();
    });
  }
}

const routes: Routes = [
  { path: '', redirectTo: '/dashboard/e-commerce', pathMatch: 'full', resolve: { configuration: RoutingResolve } },
  { path: 'auth', loadChildren: () => AuthModule },
  { path: 'dashboard', loadChildren: () => DashboardRoutingModule },
  // Include module routes
  { path: 'admin-product', loadChildren: () => AdminProductRoutingModule },
  { path: 'contact', loadChildren: () => ContactRoutingModule },
  { path: 'file', loadChildren: () => FileRoutingModule },
  { path: 'acc-manager', loadChildren: () => AccManagerRoutingModule },
  { path: 'accounting', loadChildren: () => AccountingRoutingModule },
  { path: 'ads', loadChildren: () => AdsRoutingModule },
  { path: 'b2b-center', loadChildren: () => B2bCenterRoutingModule },
  { path: 'b2b-server', loadChildren: () => B2bServerRoutingModule },
  { path: 'cluster', loadChildren: () => ClusterRoutingModule },
  { path: 'collaborator', loadChildren: () => CollaboratorRoutingModule },
  { path: 'commerce-pos', loadChildren: () => CommercePosRoutingModule },
  { path: 'commerce-service-by-cycle', loadChildren: () => CommerceServiceByCycleRoutingModule },
  { path: 'contract', loadChildren: () => ContractRoutingModule },
  { path: 'core-connection', loadChildren: () => CoreConnectionRoutingModule, },
  { path: 'crawl', loadChildren: () => CrawlRoutingModule },
  { path: 'deployment', loadChildren: () => DeploymentRoutingModule },
  { path: 'email-marketing', loadChildren: () => EmailMarketingRoutingModule, },
  { path: 'helpdesk', loadChildren: () => HelpdeskRoutingModule },
  { path: 'human-resource', loadChildren: () => HumanResouceRoutingModule },
  { path: 'ivoip', loadChildren: () => IvoipRoutingModule },
  { path: 'marketing', loadChildren: () => MarketingRoutingModule },
  { path: 'menu', loadChildren: () => MenuRoutingModule },
  { path: 'minierp', loadChildren: () => MiniErpRoutingModule },
  { path: 'mobile-app', loadChildren: () => MobileAppRoutingModule },
  { path: 'modules', loadChildren: () => ModulesRoutingModule, },
  { path: 'network', loadChildren: () => NetworkRoutingModule },
  { path: 'notification', loadChildren: () => NotificationRoutingModule },
  { path: 'promotion', loadChildren: () => PromotionRoutingModule },
  { path: 'purchase', loadChildren: () => PurchaseRoutingModule },
  { path: 'sales', loadChildren: () => SalesRoutingModule },
  { path: 'smart-bot', loadChildren: () => ShortLinkRoutingModule },
  { path: 'short-link', loadChildren: () => SmartBotRoutingModule },
  { path: 'sms', loadChildren: () => SmsRoutingModule },
  { path: 'system', loadChildren: () => SystemRoutingModule },
  { path: 'users', loadChildren: () => UsersRoutingModule },
  { path: 'warehouse', loadChildren: () => WarehouseRoutingModule },
  { path: 'virtual-phone', loadChildren: () => VirtualPhoneRoutingModule },
  { path: 'web-hosting', loadChildren: () => WebHostingRoutingModule },
  { path: 'wordpress', loadChildren: () => WordpressRoutingModule },
  { path: 'zalo-oa', loadChildren: () => ZaloOaRoutingModule },
  { path: 'demo', loadChildren: () => DemoRoutingModule },
  { path: 'tax', loadChildren: () => TaxRoutingModule },
  { path: 'code-template', loadChildren: () => CodeTemplateRoutingModule },
  // ...adminProductRoutes,
  // ...contactRoutes,
  // ...fileRoutes,
  // ...accManagerRoutes,
  // ...accountingRoutes,
  // ...adsRoutes,
  // ...b2bCenterRoutes,
  // ...b2bServerRoutes,
  // ...clusterRoutes,
  // ...collaboratorRoutes,
  // ...commercePosRoutes,
  // ...commonServiceByCycleRoutes,
  // ...contractRoutes,
  // ...coreConnectionRoutes,
  // ...crawlRoutes,
  // ...deploymentRoutes,
  // ...emailMarketingRoutes,
  // ...helpdeskRoutes,
  // ...humanResourceRoutes,
  // ...ivoipRoutes,
  // ...marketingRoutes,
  // ...menuRoutes,
  // ...miniErpRoutes,
  // ...mobileAppRoutes,
  // ...modulesRoutes,
  // ...networkRoutes,
  // ...notificationRoutes,
  // ...promotionRoutes,
  // ...purchaseRoutes,
  // ...salesRoutes,
  // ...shortLinkRoutes,
  // ...smartBotRoutes,
  // ...smsRoutes,
  // ...systemRoutes,
  // ...userRoutes,
  // ...warehouseRoutes,
  // ...webHostingRoutes,
  // ...wordpressRoutes,
  // ...zaloOaRoutes,
  ...pageRoutes,

  // { path: '', redirectTo: 'mini-erp', pathMatch: 'full' },
  // { path: '**', redirectTo: 'probo' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
