<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('EmailMarketing.Advertisement.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật email quảng cáo
            <ngx-action-control-list style="float: right;" [list]="actionControlList" [option]="{formIndex: i, form: formItem}"></ngx-action-control-list>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Id">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Template'), 'warning')">
                  <label class="label"><span class="valid">Mẫu Email</span><span class="invalid">Mẫ Email là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="Template" (selectChange)="onTemplateChanged($event, formItem)" [select2Option]="select2TempateOption"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('GatewayGroup'), 'warning')">
                  <label class="label"><span class="valid">Nhóm gateway (*)</span><span class="invalid">Nhóm gateway là
                      bắt
                      buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="GatewayGroup" [select2Option]="select2GatewayGroupOption"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('AddressList'), 'warning')">
                  <label class="label"><span class="valid">Danh sách địa chỉ email (*)</span><span class="invalid">Danh sách địa chỉ email List là
                      bắt
                      buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="AddressList" [select2Option]="select2AddressListOption"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SentAlgorithm'), 'warning')">
                  <label class="label"><span class="valid">Thuật toán gửi mail (*)</span><span class="invalid">Thuật toán gửi mail là
                      bắt
                      buộc
                      (*)</span></label>
                  <nb-select size="medium" formControlName="SentAlgorithm" fullWidth>
                    <nb-option value="">Thuật toán gửi mail ...</nb-option>
                    <nb-option value=SMARTMARKETING>Gửi mail marketing</nb-option>
                    <nb-option value="SENTPROMTION">Gửi mail khuyến mãi</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var1'), 'warning')">
                  <label class="label"><span class="valid">Tham số 1: $tham_so_1</span><span class="invalid">Tham số 1 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var1" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var2'), 'warning')">
                  <label class="label"><span class="valid">Tham số 2: $tham_so_2</span><span class="invalid">Tham số 2 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var2" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var3'), 'warning')">
                  <label class="label"><span class="valid">Tham số 3: $tham_so_3</span><span class="invalid">Tham số 3 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var3" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Var4'), 'warning')">
                  <label class="label"><span class="valid">Tham số 4: $tham_so_4</span><span class="invalid">Tham số 4 là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Var4" placeholder="Tham số tuỳ biến">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Subject'), 'warning')">
                  <label class="label"><span class="valid">Tiêu đề</span><span class="invalid">Tiêu đề là
                      bắt
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Subject" placeholder="Tiêu đề">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SubjectPreview'), 'warning')">
                  <label class="label"><span class="valid">Xem trước tiêu đề</span><span class="invalid"></span></label>
                  <input type="text" nbInput fullWidth formControlName="SubjectPreview" placeholder="Xem trước tiêu đề">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Content'), 'warning')">
                  <label class="label"><span class="valid">Nội dung</span><span class="invalid">Nội dung mẫu
                      là bắt
                      buộc
                      (*)</span></label>
                  <!-- <ckeditor formControlName="Content" [config]="{ extraPlugins: 'divarea', height: '320' }"></ckeditor> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Preview'), 'warning')">
                  <label class="label"><span class="valid">Xem trước</span><span class="invalid">Xem trước
                      là bắt
                      buộc
                      (*)</span></label>
                  <!-- <ckeditor formControlName="Preview" [config]="{ extraPlugins: 'divarea', height: '320' }"></ckeditor> -->
                </div>
              </div>

            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="success" [disabled]="!form.valid" hero style="float: right;">Lưu</button>
            <button nbButton status="danger" [disabled]="!form.valid" (click)="onSaveAndSendClick()" hero>Bắt đầu gửi</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>