import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-b2b-center',
  templateUrl: './b2b-center.component.html',
  styleUrls: ['./b2b-center.component.scss'],
})
export class B2bCenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
