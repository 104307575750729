<nb-card class="small-header smart-table" size="large">

  <nb-card-header>
    <ngx-card-header [size]="'medium'" [icon]="{pack: 'eva', name: 'list'}" [title]="('Ivoip.Dashboard.title' | translate:{action: '', definition: cms.translate.instant('Common.list')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
  </nb-card-header>
  <div class="chart-container">
    <ngx-chart-panel-header [type]="period" (periodChange)="setPeriodAndGetChartData($event)">
    </ngx-chart-panel-header>
    <ngx-orders-chart #ordersChart [ordersChartData]="ordersChartData"></ngx-orders-chart>
  </div>
</nb-card>