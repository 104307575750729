<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Purchase.Voucher.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area" style="position: relative;">
                <div class="invoice-wrapper-content-vi">
                    <div class="header">
                        <div class="qr-code-symbol">
                            <div class="qr-code">
                                <ngx-qrcode [elementType]="'text'" [value]="data.SerialData.qrcode" cssClass="qr-code" errorCorrectionLevel="L" [margin]="0" style="margin-right: 0.5mm;"></ngx-qrcode>
                            </div>
                            <div class="symbol">
                                <div>Mẫu số: {{data.SerialData.khmshdon}}</div>
                                <div>Ký hiệu: {{data.SerialData.khhdon}}</div>
                                <div>Số: {{data.SerialData.shdon}}</div>
                            </div>
                        </div>
                        <div class="title">HÓA ĐƠN GIÁ TRỊ GIA TĂNG</div>
                        <div class="date">Ngày {{data.SerialData.tdlap | date:'d'}} tháng {{data.SerialData.tdlap | date:'M'}} năm {{data.SerialData.tdlap | date:'Y'}}</div>
                        <div class="invoice-id">MCCQT: {{data.SerialData.mhdon}}</div>
                    </div>
                    <div class="vip-divide"></div>
                    <div class="body">
                        <div class="sales-side">
                            <div>Tên người bán: {{data.SerialData.nbten}}</div>
                            <div>Mã số thuế: {{data.SerialData.nbmst}}</div>
                            <div>Địa chỉ: {{data.SerialData.nbdchi}}</div>
                            <div>Điện thoại: {{data.SerialData.nbsdthoai}}</div>
                            <div>Số tài khoản: {{data.SerialData.nbstkhoan}} {{data.SerialData.nbtnhang}}</div>
                        </div>
                        <div class="vip-divide"></div>
                        <div class="purchase-side">
                            <div>Tên người mua: {{data.SerialData.nmten}}</div>
                            <div>Họ tên người mua: {{data.SerialData.nmtgvchdden}}</div>
                            <div>Mã số thuế: {{data.SerialData.nmmst}}</div>
                            <div>Hình thức thanh toán: {{data.SerialData.xxx}}</div>
                            <div>Đơn vị tiền tệ: {{data.SerialData.xxx}}</div>
                            <div>Số tài khoản: {{data.SerialData.nmstkhoan}} {{data.SerialData.nmtnhang}}</div>
                        </div>
                        <div style="display: flex; justify-content: space-evenly;">
                            <div>Số bản kê: {{data.SerialData.xxx}}</div>
                            <div>Ngày bản kê: {{data.SerialData.xxx}}</div>
                        </div>
                        <div class="main-table">
                            <table style="width: 100%">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Tính chất</th>
                                        <th>Tên hàng hóa dịch vụ</th>
                                        <th>Đơn vị tính</th>
                                        <th class="text-align-right">Số lượng</th>
                                        <th class="text-align-right">Đơn giá</th>
                                        <th class="text-align-right">Chiết khấu</th>
                                        <th class="text-align-right">Thuế suất</th>
                                        <th class="text-align-right">Thành tiền chưa có thuế GTGT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.SerialData.hdhhdvu; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.tchat === 1 ? 'Hàng hóa, dịch vụ' : ''}}</td>
                                        <td>{{item.ten}}</td>
                                        <td>{{item.dvtinh}}</td>
                                        <td class="text-align-right">{{item.sluong | number:'1.0-2'}}</td>
                                        <td class="text-align-right">{{item.dgia | currency:'VND':'symbol':'1.0-2'}}</td>
                                        <td class="text-align-right">{{item.tlckhau | number:'1.0-2'}}</td>
                                        <td class="text-align-right">{{item.ltsuat}}</td>
                                        <td class="text-align-right">{{item.thtien | currency:'VND':'symbol':'1.0-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="display: flex;">
                            <div class="tax-table" style="margin-right: 10px;">
                                <table style="width: 100%">
                                    <thead>
                                        <tr>
                                            <th>Thuế suất</th>
                                            <th>Tổng tiền chưa thuế</th>
                                            <th>Tổng tiền thuế</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.SerialData.thttltsuat; let i = index">
                                            <td class="text-align-right">{{item.tsuat}}</td>
                                            <td class="text-align-right">{{item.thtien | currency:'VND':'symbol':'1.0-2'}}</td>
                                            <td class="text-align-right">{{item.tthue | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="summary-table">
                                <table style="width: 100%">
                                    <tbody>
                                        <tr>
                                            <td>Tổng tiền chưa thuế
                                                (Tổng cộng thành tiền chưa có thuế)</td>
                                            <td class="text-align-right">{{data.SerialData.tgtcthue | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng giảm trừ không chịu thuế</td>
                                            <td class="text-align-right">{{data.SerialData.tgtkcthue | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng tiền thuế (Tổng cộng tiền thuế)</td>
                                            <td class="text-align-right">{{data.SerialData.tgtthue | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng tiền phí</td>
                                            <td class="text-align-right">{{data.SerialData.tgtphi | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng tiền chiết khấu thương mại </td>
                                            <td class="text-align-right">{{data.SerialData.ttcktmai | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng giảm trừ khác </td>
                                            <td class="text-align-right">{{data.SerialData.tgtkhac | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng tiền thanh toán bằng số</td>
                                            <td class="text-align-right">{{data.SerialData.tgtttbso | currency:'VND':'symbol':'1.0-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tổng tiền thanh toán bằng chữ</td>
                                            <td class="text-align-right">{{data.SerialData.tgtttbchu}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="signature">
                            <div class="purchase-side-signature">
                                <div>NGƯỜI MUA HÀNG</div>
                                <div>(Chữ ký số (nếu có))</div>
                            </div>
                            <div class="sales-side-signature">
                                <div>NGƯỜI BÁN HÀNG</div>
                                <div>(Chữ ký điện tử, chữ ký số)</div>
                                <div class="PagePattern__SignBoxStyled-sc-j7yw1w-4 ftouUH">
                                    <div class="sign-box">
                                        <span>Signature Valid</span><br>
                                        <span>Ký bởi {{data.SerialData.nbcks.Subject}}</span><br>
                                        <span>Ký ngày: {{data.SerialData.nbcks.SigningTime | date:'short'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="digital-signature">(Cần kiểm tra, đối chiếu khi lập, nhận hóa đơn)</div>
                        <div class="note"></div>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
            <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
                <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
            </span>
        </nb-card-footer>
        <nb-card-footer>
            <div class="buttons-row">
                <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
                    <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
                </button>
                <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button>

                <!--State process buttons-->
                <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
                <!--End State process buttons-->

                <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>