import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NbToastrService, NbDialogService, NbDialogRef} from '@nebular/theme';
import {DataManagerFormComponent} from '../../../../lib/data-manager/data-manager-form.component';
import {Model} from '../../../../models/model';
import {ApiService} from '../../../../services/api.service';
import {CommonService} from '../../../../services/common.service';
import {RootServices} from '../../../../services/root.services';
import {AdminProductService} from '../../../admin-product/admin-product.service';

@Component({
  selector: 'ngx-input-invoice-forward-login',
  templateUrl: './input-invoice-forward-login.component.html',
  styleUrls: ['./input-invoice-forward-login.component.scss']
})
export class InputInvoiceForwardLoginComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'InputInvoiceForwardLoginComponent';
  idKey = 'Code';
  apiPath = '/tax/input-invoices/forward-login';

  // baseFormUrl = '/code-template/simple-data-manager/form';

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<InputInvoiceForwardLoginComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  getRequestId(callback: (id?: string[]) => void) {
    callback(this.inputId);
  }

  public captcha: string;
  private captchaKey: string;

  loadCapchar() {
    this.apiService.getPromise<{
      key: string,
      content: string
    }>('/tax/input-invoices/forward-login-captcha').then(resp => {
      this.captchaKey = resp.key;
      this.captcha = 'data:image/svg+xml;base64,' + btoa(resp.content);
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    return super.init().then(rs => {
      this.loadCapchar();
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['keyName'] = value;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }

      // return true;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group<any>({
      username: ['', Validators.required],
      password: ['', Validators.required],
      cvalue: ['', Validators.required],
    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  async save(): Promise<Model[]> {
    const loginInfo = this.array.controls[0].value;
    loginInfo.ckey = this.captchaKey;
    return this.apiService.postPromise<Model[]>('/tax/input-invoices/forward-login', {}, [loginInfo]).then(rs => {
      this.onDialogSave && this.onDialogSave(rs);
      return rs;
    });
  }

}
