import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeTemplateComponent } from './code-template.component';
import { AuthGuardService } from '../../services/auth-guard.service';
import { SimpleDataManagerListComponent } from './simple-data-manager/simple-data-manager-list/simple-data-manager-list.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: CodeTemplateComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'simple-data-manager/list',
    canActivate: [AuthGuardService],
    component: SimpleDataManagerListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CodeTemplateRoutingModule { }
