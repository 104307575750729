import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { WarehouseBookCommitComponent } from "./book/warehouse-book-commit/warehouse-book-commit.component";
import { WarehouseBookFormComponent } from "./book/warehouse-book-form/warehouse-book-form.component";
import { WarehouseBookHeadAmountComponent } from "./book/warehouse-book-head-amount/warehouse-book-head-amount.component";
import { WarehouseBookListComponent } from "./book/warehouse-book-list/warehouse-book-list.component";
import { WarehouseBookPrintComponent } from "./book/warehouse-book-print/warehouse-book-print.component";
import { WarehouseGoodsContainerFormComponent } from "./goods-container/warehouse-goods-container-form/warehouse-goods-container-form.component";
import { WarehouseGoodsContainerListComponent } from "./goods-container/warehouse-goods-container-list/warehouse-goods-container-list.component";
import { WarehouseGoodsContainerPrintComponent } from "./goods-container/warehouse-goods-container-print/warehouse-goods-container-print.component";
import { WarehouseGoodsDeliveryNoteFormComponent } from "./goods-delivery-note/warehouse-goods-delivery-note-form/warehouse-goods-delivery-note-form.component";
import { WarehouseGoodsDeliveryNoteListComponent } from "./goods-delivery-note/warehouse-goods-delivery-note-list/warehouse-goods-delivery-note-list.component";
import { WarehouseGoodsDeliveryNotePrintComponent } from "./goods-delivery-note/warehouse-goods-delivery-note-print/warehouse-goods-delivery-note-print.component";
import { WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent } from "./goods-receipt-note/warehouse-goods-access-number-print/warehouse-goods-access-number-print.component";
import { WarehouseGoodsReceiptNoteFormComponent } from "./goods-receipt-note/warehouse-goods-receipt-note-form/warehouse-goods-receipt-note-form.component";
import { WarehouseGoodsReceiptNoteListComponent } from "./goods-receipt-note/warehouse-goods-receipt-note-list/warehouse-goods-receipt-note-list.component";
import { WarehouseGoodsReceiptNotePrintComponent } from "./goods-receipt-note/warehouse-goods-receipt-note-print/warehouse-goods-receipt-note-print.component";
import { AssignContainerFormComponent } from "./goods/assign-containers-form/assign-containers-form.component";
import { AssignNewContainerFormComponent } from "./goods/assign-new-containers-form/assign-new-containers-form.component";
import { WarehouseGoodsFindOrderTempPrintComponent } from "./goods/warehouse-goods-find-order-temp-print/warehouse-goods-find-order-temp-print.component";
import { WarehouseGoodsFormComponent } from "./goods/warehouse-goods-form/warehouse-goods-form.component";
import { WarehouseGoodsListComponent } from "./goods/warehouse-goods-list/warehouse-goods-list.component";
import { WarehouseGoodsPrintComponent } from "./goods/warehouse-goods-print/warehouse-goods-print.component";
import { WarehouseGoodsQueueComponent } from "./goods/warehouse-goods-queue/warehouse-goods-queue.component";
import { WarehouseInventoryAdjustNoteFormComponent } from "./inventory-adjust-note/inventory-adjust-note-form/inventory-adjust-note-form.component";
import { WarehouseInventoryAdjustNoteListComponent } from "./inventory-adjust-note/inventory-adjust-note-list/inventory-adjust-note-list.component";
import { WarehouseInventoryAdjustNotePrintComponent } from "./inventory-adjust-note/inventory-adjust-note-print/inventory-adjust-note-print.component";
import { WarehouseFindOrderTempPrintComponent } from "./label/warehouse-find-order-temp-print/warehouse-find-order-temp-print.component";
import { ProductionOrderFormComponent } from "./production-order/production-order-form/production-order-form.component";
import { ProductionOrderListComponent } from "./production-order/production-order-list/production-order-list.component";
import { ProductionOrderPrintComponent } from "./production-order/production-order-print/production-order-print.component";
import { WarehouseDetailByObjectReportAgComponent } from "./reports/detail-by-object-report-ag/detail-by-object-report-ag.component";
import { WarehouseSummaryReportComponent } from "./reports/summary-report/warehouse-summary-report.component";
import { WarehouseDetailByGoodsReportComponent } from "./reports/warehouse-detail-by-goods-report/warehouse-detail-by-goods-report.component";
import { WarehouseReportComponent } from "./reports/warehouse-report.component";
import { WarehouseDashboardComponent } from "./warehouse-dashboard/warehouse-dashboard.component";
import { WarehouseRoutingModule } from "./warehouse-routing.module";
import { WarehouseFormComponent } from "./warehouse/warehouse-form/warehouse-form.component";
import { WarehouseListComponent } from "./warehouse/warehouse-list/warehouse-list.component";
import { WarehousePrintComponent } from "./warehouse/warehouse-print/warehouse-print.component";
import { WarehouseComponent } from "./warehouse.component";

export const warehouseComponents = [
    WarehouseComponent,
    WarehouseGoodsReceiptNoteListComponent,
    WarehouseGoodsReceiptNoteFormComponent,
    WarehouseGoodsReceiptNotePrintComponent,
    WarehouseGoodsReceiptNoteDetailAccessNumberPrintComponent,
    WarehouseGoodsDeliveryNoteListComponent,
    WarehouseGoodsDeliveryNoteFormComponent,
    WarehouseGoodsDeliveryNotePrintComponent,
    WarehouseGoodsContainerListComponent,
    WarehouseGoodsContainerFormComponent,
    WarehouseGoodsContainerPrintComponent,
    WarehouseInventoryAdjustNoteListComponent,
    WarehouseInventoryAdjustNoteFormComponent,
    WarehouseInventoryAdjustNotePrintComponent,
    WarehouseListComponent,
    WarehouseFormComponent,
    WarehousePrintComponent,
    WarehouseBookListComponent,
    WarehouseBookFormComponent,
    WarehouseBookPrintComponent,
    WarehouseGoodsListComponent,
    WarehouseGoodsFormComponent,
    WarehouseGoodsFindOrderTempPrintComponent,
    WarehouseGoodsPrintComponent,
    AssignContainerFormComponent,
    AssignNewContainerFormComponent,
    WarehouseBookCommitComponent,
    WarehouseFindOrderTempPrintComponent,
    WarehouseDashboardComponent,
    WarehouseReportComponent,
    WarehouseDetailByGoodsReportComponent,
    WarehouseSummaryReportComponent,
    WarehouseDetailByObjectReportAgComponent,
    ProductionOrderListComponent,
    ProductionOrderFormComponent,
    ProductionOrderPrintComponent,
    WarehouseBookHeadAmountComponent,
    WarehouseGoodsQueueComponent,
];

@NgModule({
    declarations: warehouseComponents,
    exports: warehouseComponents,
    imports: [
        ...commonModules,
        WarehouseRoutingModule,
    ],
    bootstrap: [WarehouseComponent],
    providers: [
        ...commonProviders,
    ],
})
export class WarehouseModule { }