<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sms.Template.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật liên hệ
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Parameter'), 'warning')">
                  <label class="label"><span class="valid">Tham số (*)</span><span class="invalid">Tham số là bắt buộc
                      (*)</span></label>
                      <ngx-select2 [select2Option]="select2ParamsOption" formControlName="Parameter"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Content'), 'warning')">
                  <label class="label"><span class="valid">Nội dung</span><span class="invalid">Nội dung là bắt buộc
                      (*)</span></label>
                  <textarea type="text" nbInput fullWidth formControlName="Content" placeholder="Nội dung" rows="5"></textarea>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
