import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-zalo-oa',
  templateUrl: './zalo-oa.component.html',
  styleUrls: ['./zalo-oa.component.scss'],
})
export class ZaloOaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
