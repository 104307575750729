import {NgModule} from "@angular/core";
import {commonModules, commonProviders} from "../../common-modules";
import {AuthorizedSaleVoucherCustomerPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-customer-print/authorized-sale-voucher-customer-print.component";
import {AuthorizedSaleVoucherDeliveryPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-delivery-print/authorized-sale-voucher-delivery-print.component";
import {AuthorizedSaleVoucherDetailsReportPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-details-report-print/authorized-sale-voucher-details-report-print.component";
import {AuthorizedSaleVoucherFormComponent} from "./authorized-sale-voucher/authorized-sale-voucher-form/authorized-sale-voucher-form.component";
import {AuthorizedSaleVoucherListComponent} from "./authorized-sale-voucher/authorized-sale-voucher-list/authorized-sale-voucher-list.component";
import {AuthorizedSaleVoucherPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-print/authorized-sale-voucher-print.component";
import {AuthorizedSaleVoucherReportPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-report-print/authorized-sale-voucher-report-print.component";
import {AuthorizedSaleVoucherSupplierPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-supplier-print/authorized-sale-voucher-supplier-print.component";
import {AuthorizedSaleVoucherTransportPrintComponent} from "./authorized-sale-voucher/authorized-sale-voucher-transport-print/authorized-sale-voucher-transport-print.component";
import {SalesB2bQuotationFormComponent} from "./b2b-quotation/b2b-quotation-form/sales-b2b-quotation-form.component";
import {SalesB2bQuotationListComponent} from "./b2b-quotation/b2b-quotation-list/sales-b2b-quotation-list.component";
import {SalesB2bQuotationPrintComponent} from "./b2b-quotation/b2b-quotation-print/sales-b2b-quotation-print.component";
import {DiscountTableUpdateNoteFormComponent} from "./discount-table-update-note/discount-table-update-note-form/discount-table-update-note-form.component";
import {DiscountTableUpdateNoteListComponent} from "./discount-table-update-note/discount-table-update-note-list/discount-table-update-note-list.component";
import {DiscountTableUpdateNotePrintComponent} from "./discount-table-update-note/discount-table-update-note-print/discount-table-update-note-print.component";
import {SalesDiscountTableComponent} from "./discount-table/discount-table/discount-table.component";
import {MasterPriceTableQueueComponent} from "./master-price-table-queue/master-price-table-queue.component";
import {MasterPriceTableUpdateNoteFormComponent} from "./master-price-table-update-note/master-price-table-update-note-form/master-price-table-update-note-form.component";
import {MasterPriceTableUpdateNoteListComponent} from "./master-price-table-update-note/master-price-table-update-note-list/master-price-table-update-note-list.component";
import {MasterPriceTableUpdateNotePrintComponent} from "./master-price-table-update-note/master-price-table-update-note-print/master-price-table-update-note-print.component";
import {MasterPriceTableFormComponent} from "./master-price-table/master-price-table-form/master-price-table-form.component";
import {MasterPriceTableListComponent} from "./master-price-table/master-price-table-list/master-price-table-list.component";
import {MasterPriceTablePrintComponent} from "./master-price-table/master-price-table-print/master-price-table-print.component";
import {SalesMasterPriceTableComponent} from "./master-price-table/master-price-table/master-price-table.component";
import {SalesPriceReportFormComponent} from "./price-report/sales-price-report-form/sales-price-report-form.component";
import {SalesPriceReportListComponent} from "./price-report/sales-price-report-list/sales-price-report-list.component";
import {SalesPriceReportPrintComponent} from "./price-report/sales-price-report-print/sales-price-report-print.component";
import {PriceTableFormComponent} from "./price-table/price-table-form/price-table-form.component";
import {PriceTableListComponent} from "./price-table/price-table-list/price-table-list.component";
import {PriceTablePrintAsListComponent} from "./price-table/price-table-print-as-list/price-table-print-as-list.component";
import {PriceTablePrintComponent} from "./price-table/price-table-print/price-table-print.component";
import {SalesProductDemoTemPrintComponent} from "./product/product-demo-tem-print/product-demo-tem-print.component";
import {SalesProductListComponent} from "./product/sales-product-list/sales-product-list.component";
import {SalesProductQrCodePrintComponent} from "./product/sales-product-qrcode-print/sales-product-qrcode-print.component";
import {SaleByCommissionVoucherDetailsReportPrintComponent} from "./sale-by-commission-voucher/sale-by-commission-voucher-details-report-print/sale-by-commission-voucher-details-report-print.component";
import {SaleByCommissionVoucherFormComponent} from "./sale-by-commission-voucher/sale-by-commission-voucher-form/sale-by-commission-voucher-form.component";
import {SaleByCommissionVoucherListComponent} from "./sale-by-commission-voucher/sale-by-commission-voucher-list/sale-by-commission-voucher-list.component";
import {SaleByCommissionVoucherPrintComponent} from "./sale-by-commission-voucher/sale-by-commission-voucher-print/sale-by-commission-voucher-print.component";
import {SaleByCommissionVoucherReportPrintComponent} from "./sale-by-commission-voucher/sale-by-commission-voucher-report-print/sale-by-commission-voucher-report-print.component";
import {SalesDashboardComponent} from "./sales-dashboard/sales-dashboard.component";
import {SaleProductFormComponent} from "./sales-product/sales-product-form/sales-product-form.component";
import {SaleProductListComponent} from "./sales-product/sales-product-list/sales-product-list.component";
import {SalesReturnsVoucherFormComponent} from "./sales-returns-voucher/sales-returns-voucher-form/sales-returns-voucher-form.component";
import {SalesReturnsVoucherListComponent} from "./sales-returns-voucher/sales-returns-voucher-list/sales-returns-voucher-list.component";
import {SalesReturnsVoucherPrintComponent} from "./sales-returns-voucher/sales-returns-voucher-print/sales-returns-voucher-print.component";
import {SalesRoutingModule} from "./sales-routing.module";
import {SalesVoucherFormComponent} from "./sales-voucher/sales-voucher-form/sales-voucher-form.component";
import {SalesVoucherListComponent} from "./sales-voucher/sales-voucher-list/sales-voucher-list.component";
import {SalesVoucherPrintComponent} from "./sales-voucher/sales-voucher-print/sales-voucher-print.component";
import {SimpleSalesVoucherFormComponent} from "./sales-voucher/simple-sales-voucher-form/simple-sales-voucher-form.component";
import {SalesComponent} from "./sales.component";
import {CurrencyPipe} from "@angular/common";
import {SalesPriceTableQuotationListComponent} from './price-table-quotation/sales-price-table-quotation-list/sales-price-table-quotation-list.component';
import { SalesPriceTableQuotationFormComponent } from './price-table-quotation/sales-price-table-quotation-form/sales-price-table-quotation-form.component';
import { SalesPriceTableQuotationPrintComponent } from './price-table-quotation/sales-price-table-quotation-print/sales-price-table-quotation-print.component';

@NgModule({
  declarations: [
    SalesComponent,
    SalesPriceReportListComponent,
    SalesPriceReportFormComponent,
    SalesPriceReportPrintComponent,
    SalesVoucherListComponent,
    SalesVoucherFormComponent,
    SalesVoucherPrintComponent,
    SalesReturnsVoucherListComponent,
    SalesReturnsVoucherFormComponent,
    SalesReturnsVoucherPrintComponent,
    SalesProductListComponent,
    SalesProductQrCodePrintComponent,
    PriceTableListComponent,
    PriceTableFormComponent,
    PriceTablePrintComponent,
    PriceTablePrintAsListComponent,
    SimpleSalesVoucherFormComponent,
    MasterPriceTableListComponent,
    MasterPriceTableFormComponent,
    MasterPriceTablePrintComponent,
    SalesProductDemoTemPrintComponent,
    SalesDashboardComponent,
    SaleProductListComponent,
    SaleProductFormComponent,
    MasterPriceTableUpdateNoteListComponent,
    MasterPriceTableUpdateNoteFormComponent,
    MasterPriceTableUpdateNotePrintComponent,
    MasterPriceTableQueueComponent,
    SalesMasterPriceTableComponent,
    SalesB2bQuotationListComponent,
    SalesB2bQuotationFormComponent,
    SalesB2bQuotationPrintComponent,
    SalesDiscountTableComponent,
    DiscountTableUpdateNoteListComponent,
    DiscountTableUpdateNoteFormComponent,
    DiscountTableUpdateNotePrintComponent,
    SaleByCommissionVoucherListComponent,
    SaleByCommissionVoucherFormComponent,
    SaleByCommissionVoucherPrintComponent,
    SaleByCommissionVoucherDetailsReportPrintComponent,
    SaleByCommissionVoucherReportPrintComponent,
    AuthorizedSaleVoucherListComponent,
    AuthorizedSaleVoucherFormComponent,
    AuthorizedSaleVoucherPrintComponent,
    AuthorizedSaleVoucherDetailsReportPrintComponent,
    AuthorizedSaleVoucherReportPrintComponent,
    AuthorizedSaleVoucherCustomerPrintComponent,
    AuthorizedSaleVoucherSupplierPrintComponent,
    AuthorizedSaleVoucherDeliveryPrintComponent,
    AuthorizedSaleVoucherTransportPrintComponent,
    SalesPriceTableQuotationListComponent,
    SalesPriceTableQuotationFormComponent,
    SalesPriceTableQuotationPrintComponent,
  ],
  exports: [
  ],
  imports: [
    ...commonModules,
    SalesRoutingModule,
  ],
  bootstrap: [SalesComponent],
  providers: [
    ...commonProviders,
  ],
})
export class SalesModule {
}
