import {APP_ID, Inject, Injectable} from "@angular/core";
import { AdminProductService } from "../modules/admin-product/admin-product.service";
import { HeaderService } from "../@theme/components/header/header.service";
import { AccountingService } from "../modules/accounting/accounting.service";

@Injectable({
  providedIn: 'root',
})
export class RootServices {


  constructor(
    public adminProductService: AdminProductService,
    public accountingService: AccountingService,
    public headerService: HeaderService,
    @Inject(APP_ID) public appId: string,
  ) {
  }
}
