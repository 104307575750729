import { CurrencyPipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbToastrService, NbDialogService, NbDialogRef, NbThemeService } from "@nebular/theme";
import { GridApi, ColumnApi, ColDef, IRowNode } from "@ag-grid-community/core";
import { AgCurrencyCellInput } from "../../../../../lib/custom-element/ag-list/cell/input/curency.component";
import { AgDateTimeCellInput } from "../../../../../lib/custom-element/ag-list/cell/input/datetime.component";
import { AgSelect2CellInput } from "../../../../../lib/custom-element/ag-list/cell/input/select2.component";
import { AgTextCellRenderer } from "../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeCommandColDef } from "../../../../../lib/custom-element/ag-list/column-define/command.define";
import { agMakeImageColDef } from "../../../../../lib/custom-element/ag-list/column-define/image.define";
import { agMakeSelectionColDef } from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { DataManagerFormComponent } from "../../../../../lib/data-manager/data-manager-form.component";
import { Model } from "../../../../../models/model";
import { ProductUnitModel, ProductModel } from "../../../../../models/product.model";
import { ApiService } from "../../../../../services/api.service";
import { CommonService } from "../../../../../services/common.service";
import { RootServices } from "../../../../../services/root.services";
import { AgDynamicListComponent } from "../../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component";
import { CollaboratorService } from "../../../collaborator.service";
import { CollaboratorProductListComponent } from "../../../product/collaborator-product-list/collaborator-product-list.component";
import { CollaboratorPublisherListComponent } from "../../../publisher/collaborator-publisher-list/collaborator-publisher-list.component";
import { CollaboratorBasicStrategyProductFormComponent } from "../../basic-strategy/product-form/collaborator-basic-strategy-product-form.component";


@Component({
  selector: 'ngx-collaborator-opportunity-cost-strategy-form',
  templateUrl: './opportunity-cost-strategy-form.component.html',
  styleUrls: ['./opportunity-cost-strategy-form.component.scss'],
  providers: [
    CurrencyPipe
  ]
})
export class CollaboratorOpportunityCostStrategyFormComponent extends DataManagerFormComponent<Model> implements OnInit {


  componentName: string = 'CollaboratorOpportunityCostStrategyFormComponent';
  idKey = ['Code'];
  apiPath = '/collaborator/opportunity-cost-strategies';
  baseFormUrl = '/collaborator/opportunity-cost-strategy/form';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref?: NbDialogRef<CollaboratorOpportunityCostStrategyFormComponent>,
    public collaboratorService?: CollaboratorService,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);


    const $this = this;
    $this.cms.waitForReady().then(async status => {
      const states = await this.apiService.getPromise<Model[]>('/collaborator/opportunities/states');
      /** AG-Grid */
      this.columnDefs = [
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'STT',
          field: 'Id',
          valueGetter: 'node.data.Publisher',
        },
        {
          ...agMakeImageColDef(this.cms),
          headerName: 'Avatar',
          field: 'Avatar',
          width: 100,
        },
        {
          headerName: 'Publisher',
          field: 'Publisher',
          width: 100,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'left',
        },
        {
          headerName: 'Tên CTV',
          field: 'PublisherName',
          width: 200,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'left',
        },
        {
          headerName: 'Bắt đầu',
          field: 'DateOfStart',
          width: 180,
          filter: 'agTextColumnFilter',
          cellRenderer: AgDateTimeCellInput,
          cellRendererParams: {
            takeUntilDelay: 0,
          },
          // pinned: 'right',
        },
        {
          headerName: 'Kết thúc ',
          field: 'DateOfEnd',
          width: 180,
          filter: 'agTextColumnFilter',
          cellRenderer: AgDateTimeCellInput,
          cellRendererParams: {
            takeUntilDelay: 0,
          },
          // pinned: 'right',
        },
        {
          headerName: 'Mẫu hợp đồng',
          field: 'ContractTemplate',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgSelect2CellInput,
          cellRendererParams: {
            select2Option: this.cms.makeSelect2AjaxOption('/contract/templates', { onlyIdText: true }),
          },
          // pinned: 'right',
        },
        {
          headerName: 'Trạng thái tính phí',
          field: 'StateOfCost',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgSelect2CellInput,
          cellRendererParams: {
            list: states.filter(f => ['FREEZE', 'WARM', 'HOT', 'CONVERTED'].indexOf(this.cms.getObjectId(f)) > -1),
          },
          // pinned: 'right',
        },
        {
          headerName: 'Chi phí Cơ bản',
          field: 'BasicCost',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgCurrencyCellInput,
          cellRendererParams: {
            takeUntilDelay: 0,
          },
          // pinned: 'right',
        },
        {
          headerName: 'Chi phí Nâng cao',
          field: 'AdvanceCost',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgCurrencyCellInput,
          cellRendererParams: {
            takeUntilDelay: 0,
          },
          // pinned: 'right',
        },
        {
          headerName: 'Chi phí Add-on',
          field: 'AddOnCost',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgCurrencyCellInput,
          cellRendererParams: {
            takeUntilDelay: 0,
          },
          // pinned: 'right',
        },
        {
          headerName: 'Sản phẩm',
          field: 'Products',
          width: 150,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'right',
        },
        {
          ...agMakeCommandColDef(null, this.cms, false, (params) => {
            this.gridApi.applyTransaction({ remove: [params] });
          }, false, [
          ]),
          // width: 123,
          headerName: 'Lệnh',
        },
      ];

      this.productsColumnDefs = [
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'STT',
          field: 'Id',
          valueGetter: 'node.data.Product',
        },
        {
          ...agMakeImageColDef(this.cms),
          headerName: 'Hình',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          headerName: 'Sku',
          field: 'Sku',
          width: 100,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'left',
        },
        {
          headerName: 'Product',
          field: 'Product',
          width: 100,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'left',
        },
        {
          headerName: 'Tên sản phẩm',
          field: 'ProductName',
          width: 250,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'left',
        },
        {
          headerName: 'ĐVT',
          field: 'Unit',
          width: 110,
          filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          // pinned: 'right',
        },
        {
          ...agMakeCommandColDef(null, this.cms, false, (params) => {
            $this.productGridApi.applyTransaction({ remove: [params] });
            $this.updateObjectProducts();
          }, false, [
          ]),
          // width: 123,
          headerName: 'Lệnh',
        },
      ];
      /** End AG-Grid */
    });
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public productGridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public productsColumnDefs: ColDef[];
  public gridParams;

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }
  onProductGridReady(params) {
    this.productGridApi = params.api;
    this.productGridApi.setRowData([]);
  }

  loadList(callback?: (list: Model[]) => void) {
    if (this.gridApi) {
      let publishers: Model[] = (this.array.controls[0].get('Publishers').value || []).map((publisher: Model) => {
        if (!publisher.Products) {
          publisher.Products = [];
        }
        publisher.Products.map(product => {
          product['id'] = this.cms.getObjectId(product.Product) + '-' + this.cms.getObjectId(product.Unit);
          product['text'] = product.ProductName + '/' + this.cms.getObjectText(product.Unit) + '/' + product.Level1ConvertedCost;
        });
        return publisher;
      });
      this.gridApi.setRowData(publishers);
    }
  }
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includePublishers'] = true;
    params['includeProducts'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group<any>({
      Code: { value: '', disabled: true },
      Title: ['', Validators.required],
      Page: [this.collaboratorService.currentpage$.value, Validators.required],
      DateRange: [[Date.today(), Date.today().next().month()], Validators.required],
      Publishers: [[]],
    });
    if (data) {
      data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Extract date range
      if (item.DateRange) {
        item.DateOfStart = item.DateRange[0];
        item.DateOfEnd = item.DateRange[1];
      }

      // Get details data from ag-grid
      item.Publishers = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
        }
        item.Publishers.push(rawDetail);
      });
    }
    return data;
  }

  async save(): Promise<ProductModel[]> {
    return super.save();
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở CTV',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm CTV',
      status: 'success',
      label: 'Thêm CTV',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.openDialog(CollaboratorPublisherListComponent, {
          context: {
            prepareApiParams: (params) => {
              // params['eq_Groups'] = '[PUBLISHERSUPPORTER]';
              // params['includeGroups'] = true;
              return params;
            },
            gridHeight: '90vh',
            onDialogChoose(chooseItems) {
              console.log(chooseItems);
              const newRowNodeTrans = $this.gridApi.applyTransaction({
                add: chooseItems.map(m => ({
                  id: m.Contact,
                  text: m.Name,
                  Publisher: m.Contact,
                  PublisherName: m.Name,
                  Avatar: m.Avatar,
                }))
              });
              console.log('New Row Node Trans: ', newRowNodeTrans);
            },
          }
        });

        return true;
      }
    });

    component.actionButtonList = actionButtonList;
  }

  onProductGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.publisher,
      click: (event) => {
        const selectedNodes: IRowNode[] = $this.productGridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm sản phẩm',
      status: 'success',
      label: 'Thêm sản phẩm',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.publisher,
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.openDialog(CollaboratorProductListComponent, {
          context: {
            // gridHeight: '90vh',
            prepareApiParams: (params) => {
              params['onlyBusinessProducts'] = true;
              return params;
            },
            onDialogChoose(chooseItems) {
              console.log(chooseItems);
              const newRowNodeTrans = $this.productGridApi.applyTransaction({
                add: chooseItems.map(m => ({
                  id: m.Code,
                  text: m.Name,
                  Product: m.Code,
                  ProductName: m.Name,
                  Sku: m.Sku,
                  Unit: m.Unit,
                  Pictures: m.Pictures,
                  FeaturePicture: m.FeaturePicture,
                }))
              });
              console.log('New Row Node Trans: ', newRowNodeTrans);
              $this.updateObjectProducts();
            },
          }
        });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'settings',
      title: 'Cấu hình',
      status: 'primary',
      label: 'Cài đặt',
      iconPack: 'eva',
      icon: 'settings-2-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.publisher,
      click: (event) => {
        const selectedNodes: IRowNode[] = $this.productGridApi.getSelectedNodes();

        // Setting for product
        if (selectedNodes && selectedNodes.length > 0) {
          this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
            context: {
              data: selectedNodes.map(m => m.data),
              onDialogSave(newData) {
                console.log(newData);
                for (const itemData of newData) {
                  let currentNode: IRowNode = $this.productGridApi.getRowNode($this.cms.getObjectId(itemData.Product) + '-' + $this.cms.getObjectId(itemData.Unit));
                  currentNode.setData({ ...currentNode.data, ...itemData });
                }

                $this.updateObjectProducts();
              },
            }
          });
        }

        return true;
      }
    });

    component.actionButtonList = actionButtonList;
  }

  productExtendData: any = {};
  selectedObjectNode: IRowNode<Model> = null;
  onPublishersSelected(nodes: IRowNode<Model>[]) {
    console.log('On Publishers selected: ', nodes);
    if (nodes.length == 1) {
      // Load relative products
      this.productGridApi.setRowData(nodes[0].data.Products);
      this.productExtendData.publisher = nodes[0].data;
      this.selectedObjectNode = nodes[0];
    } else {
      // Clear relative products
      this.productExtendData.publisher = null;
      this.selectedObjectNode = null;
      this.productGridApi.setRowData([]);
    }
  }

  updateObjectProducts() {
    if (this.selectedObjectNode) {
      const products = [];
      this.productGridApi.forEachNode(rowNode => products.push({ ...rowNode.data, id: this.cms.getObjectId(rowNode.data['Product']) + '-' + this.cms.getObjectId(rowNode.data['Unit']), text: rowNode.data['ProductName'] + '/' + this.cms.getObjectText(rowNode.data['Unit']) + '/' + rowNode.data['Level1ConvertedCost'] }));
      this.selectedObjectNode.setData({ ...this.selectedObjectNode.data, Products: products });
    }
  }
}
