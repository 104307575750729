import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CodeTemplateRoutingModule } from './code-template-routing.module';
import { commonModules } from '../../common-modules';
import { SimpleDataManagerListComponent } from './simple-data-manager/simple-data-manager-list/simple-data-manager-list.component';
import { SimpleDataManagerFormComponent } from './simple-data-manager/simple-data-manager-form/simple-data-manager-form.component';
import { SimpleDataManagerPrintComponent } from './simple-data-manager/simple-data-manager-print/simple-data-manager-print.component';


@NgModule({
  declarations: [
    SimpleDataManagerListComponent,
    SimpleDataManagerFormComponent,
    SimpleDataManagerPrintComponent
  ],
  imports: [
    CommonModule,
    CodeTemplateRoutingModule,
    ...commonModules,
  ]
})
export class CodeTemplateModule { }
