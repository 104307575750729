import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { DeploymentRoutingModule } from "./deployment-routing.module";
import { DeploymentVoucherFormComponent } from "./deployment-voucher/deployment-voucher-form/deployment-voucher-form.component";
import { DeploymentVoucherListComponent } from "./deployment-voucher/deployment-voucher-list/deployment-voucher-list.component";
import { DeploymentVoucherPrintComponent } from "./deployment-voucher/deployment-voucher-print/deployment-voucher-print.component";
import { DeploymentComponent } from "./deployment.component";

export const deploymentComponents = [
    DeploymentComponent,
    DeploymentVoucherListComponent,
    DeploymentVoucherFormComponent,
    DeploymentVoucherPrintComponent
];

@NgModule({
    declarations: deploymentComponents,
    exports: deploymentComponents,
    imports: [
        ...commonModules,
        DeploymentRoutingModule,
    ],
    bootstrap: [DeploymentComponent],
    providers: [
        ...commonProviders,
    ],
})
export class DeploymentModule { }