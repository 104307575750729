import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { CoreConnectionListComponent } from "./core-connection-list/core-connection-list.component";
import { NgModule } from "@angular/core";

export const coreConnectionRoutes: Routes = [
  {
    path: 'core-connection/list',
    canActivate: [AuthGuardService],
    component: CoreConnectionListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(coreConnectionRoutes)],
  exports: [RouterModule],
})
export class CoreConnectionRoutingModule {
}
