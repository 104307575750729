<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('WebHosting.DatabaseUser.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật tài khoản CSDL
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="database_user_id">
              <!-- <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('hosting'), 'warning')">
                  <label class="label"><span class="valid">Hosting (*)</span><span class="invalid">Hosting là bắt buộc (*)</span></label>
                  <ngx-select2 [data]="this.webHostingService.hostingList" formControlName="hosting"
                            [select2Option]="this.webHostingService.hostingListConfig"></ngx-select2>
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('database_user'), 'warning')">
                  <label class="label"><span class="valid">database_user (*)</span><span class="invalid">database_user Ip là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="database_user" placeholder="database_user">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('database_password'), 'warning')">
                  <label class="label"><span class="valid">database_password (*)</span><span class="invalid">database_password là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="database_password" placeholder="database_password">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
