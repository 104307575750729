<nb-card class="small-header smart-table">
  <nb-card-header>
    <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Dialplan.title' | translate:{action: '', definition: cms.translate.instant('Common.table')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    <!-- <nav>
      <nb-icon pack="eva" icon="list-outline"></nb-icon> Bảng định tuyến cuộc gọi
      <button nbButton status="success" hero size="tiny" (click)="loadList()" style="float: right;">
        <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
      </button>
      <button nbButton status="primary" hero size="tiny" (click)="router.navigate(['/ivoip/call-routes/list'])" style="float: right;">
        Định tuyến
      </button>
      <button nbButton status="primary" hero size="tiny" (click)="router.navigate(['/ivoip/time-conditions/list'])" style="float: right;">
        Giờ làm việc
      </button>
      <ngx-select2 [data]="domainList" [value]="ivoipService.activeDomainUuid" [select2Option]="select2OptionForDoaminList"
        (selectChange)="onChangeDomain($event)" style="float: right; min-width: 250px;">
      </ngx-select2>
    </nav> -->
  </nb-card-header>
<!-- </nb-card>
<nb-card class="small-header smart-table"> -->
  <!-- <nb-card-body> -->
    <nb-tabset>
      <nb-tab tabTitle="Inbound routes">
        <div class="ng2-smart-table-wrap">
          <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="inboundSource" (delete)="delete($event)" (edit)="onEditAction($event)"
            (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
          </ng2-smart-table>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Outbout rotues">
        <div class="ng2-smart-table-wrap">
          <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="outboundSource" (delete)="delete($event)" (edit)="onEditAction($event)"
            (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
          </ng2-smart-table>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Dialplans">
        <div class="ng2-smart-table-wrap">
          <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source" (delete)="delete($event)" (edit)="onEditAction($event)"
            (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
          </ng2-smart-table>
        </div>
      </nb-tab>
    </nb-tabset>
  <!-- </nb-card-body> -->
</nb-card>
