import { Component, OnInit } from '@angular/core';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { ProductCategoryModel } from '../../../../models/product.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CommonService } from '../../../../services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminProductService } from '../../admin-product.service';
import { RootServices } from '../../../../services/root.services';
import { FileModel } from '../../../../models/file.model';
import { ShowcaseDialogComponent } from '../../../../lib/component/dialog/showcase-dialog/showcase-dialog.component';
import { ImagesViewerComponent } from '../../../../lib/custom-element/my-components/images-viewer/images-viewer.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-product-category-form',
  templateUrl: './product-category-form.component.html',
  styleUrls: ['./product-category-form.component.scss'],
})
export class ProductCategoryFormComponent extends DataManagerFormComponent<ProductCategoryModel> implements OnInit {

  componentName: string = 'ProductCategoryFormComponent';
  idKey = 'Code';
  apiPath = '/admin-product/categories';
  baseFormUrl = '/admin-product/category/form';

  select2OptionForParent = {
    placeholder: 'Chọn danh mục...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'ProductCategory',
      text: 'ProductCategoryName',
    },
    multiple: false,
    ajax: {
      // url: params => {
      //   return this.apiService.buildApiUrl('/admin-product/categories', { 'filter_Name': params['term'], select: 'Parent=>Parent,ProductCategory=>Code,ProductCategoryName=>Name' });
      // },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        const params = settings.data;
        this.apiService.getPromise('/admin-product/categories', { 'filter_Name': params['term'], select: 'Parent=>Parent,ProductCategory=>Code,ProductCategoryName=>Name' }).then(rs => {
          success(rs);
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data.map(item => {
            item['id'] = item['ProductCategory'];
            item['text'] = item['ProductCategoryName'];
            return item;
          }),
        };
      },
    },
  };

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<ProductCategoryFormComponent>,
    public adminProductService: AdminProductService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);
  }

  getRequestId(callback: (id?: string[]) => void) {
    callback(this.inputId);
  }

  select2ParamsOption = {
    placeholder: 'Brandname...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: true,
    tags: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductCategoryModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeParent'] = true;
    super.executeGet(params, success, error);
  }

  makeNewFormGroup(data?: ProductCategoryModel): FormGroup {
    const newForm = this.formBuilder.group<any>({
      Code_old: [''],
      Code: [''],
      Parent: [''],
      Name: ['', Validators.required],
      Description: [],
      Sequence: [999],
      FeaturePicture: [],
      Pictures: [[]],
    });
    if (data) {
      data['Code_old'] = data['Code'];
      newForm.patchValue(data);

      const featurePictureFormControl = newForm.get('FeaturePicture');
      newForm.get('Pictures').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        if (!featurePictureFormControl.value && value && value.length > 0) {
          featurePictureFormControl.setValue(value[0]);
        }
      });
    }

    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductCategoryModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/category/list']);
    } else {
      this.ref.close();
      // this.dismiss();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  async save(): Promise<ProductCategoryModel[]> {
    return super.save().then(rs => {
      this.adminProductService.updateCategoryList();
      this.cms.numOfRequestRebuildProductIndex$.next(this.cms.numOfRequestRebuildProductIndex$.value + 1);
      return rs;
    });
  };

  onThumbnailPcitureClick(file: FileModel, form: FormGroup) {
    console.log(file);
    this.cms.openDialog(ShowcaseDialogComponent, {
      context: {
        title: this.cms.translateText('Common.action'),
        actions: [
          {
            label: this.cms.translateText('Common.close'),
            status: 'danger',
            action: () => {

            },
          },
          {
            label: this.cms.translateText('Common.preview'),
            status: 'success',
            action: () => {
              // window.open(file.OriginImage, '_blank');
              const pictures = form.get('Pictures').value;
              // console.log(pictures);
              if (pictures && pictures.length > 0) {
                this.cms.openDialog(ImagesViewerComponent, {
                  context: {
                    images: pictures.map(m => m.OriginImage),
                    imageIndex: pictures.findIndex(f => f.Id == file.Id)
                  }
                });
              }
            },
          },
          {
            label: this.cms.translateText('Common.setFeaturePicture'),
            status: 'primary',
            action: () => {
              form.get('FeaturePicture').setValue(file);
            },
          },
        ],
      }
    });
  }
}
