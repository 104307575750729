import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard.service';
import { DemoComponent } from './demo.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: DemoComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'workflow',
    canActivate: [AuthGuardService],
    component: DemoComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DemoRoutingModule { }
