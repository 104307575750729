<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('File.Store.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
                        [controls]="actionButtonList"></ngx-card-header>

                    <!-- <nav>
            Cập nhật file store
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <input type="hidden" formControlName="Code_old">
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Code'), 'warning')">
                                    <label class="label"><span class="valid">Mã</span><span class="invalid">Mã là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="Tạo thủ công">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Type" label="Common.type" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <nb-select size="medium" formControlName="Type" fullWidth placeholder="{{'Common.type' | translate | headtitlecase}}">
                                        <nb-option value="LOCAL">{{'Common.local' | translate | headtitlecase}}</nb-option>
                                        <nb-option value="REMOTE">{{'Common.remote' | translate | headtitlecase}}</nb-option>
                                    </nb-select>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                                    <label class="label"><span class="valid">Tên</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên danh mục">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Protocol'), 'warning')">
                                    <label class="label"><span class="valid">Giao thức</span><span class="invalid">Giao thức là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Protocol" placeholder="Giao thức">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Port'), 'warning')">
                                    <label class="label"><span class="valid">Cổng</span><span class="invalid">Cổng là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Port" placeholder="Cổng">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Host'), 'warning')">
                                    <label class="label"><span class="valid">Host</span><span class="invalid">Host là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Host" placeholder="Host">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Path'), 'warning')">
                                    <label class="label"><span class="valid">Path</span><span class="invalid">Path là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Path" placeholder="Path">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DirPath'), 'warning')">
                                    <label class="label"><span class="valid">DirPath</span><span class="invalid">DirPath là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="DirPath" placeholder="DirPath">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('RemoteToken'), 'warning')">
                                    <label class="label"><span class="valid">RemoteToken</span><span class="invalid">RemoteToken là bắt buộc
                      (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="RemoteToken" placeholder="RemoteToken">
                                </div>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
                        <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
                        <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
                    </div>
                </nb-card-footer>
            </nb-card>

        </div>
    </div>
</form>