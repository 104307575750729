import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { MobileAppRoutingModule } from "./mobile-app-routing.module";
import { MobileAppComponent } from "./mobile-app.component";

export const mobileAppComponents = [
    MobileAppComponent,
];

@NgModule({
    declarations: mobileAppComponents,
    exports: mobileAppComponents,
    imports: [
        ...commonModules,
        MobileAppRoutingModule,
    ],
    bootstrap: [MobileAppComponent],
    providers: [
        ...commonProviders,
    ],
})
export class MobileAppModule { }