import { NgModule } from "@angular/core";
import { MktMemberCardFormComponent } from "./member-card/member-card-form/member-card-form.component";
import { MktMemberCardListComponent } from "./member-card/member-card-list/member-card-list.component";
import { MktMemberCardPrintComponent } from "./member-card/member-card-print/member-card-print.component";
import { MktPromotionProgramFormComponent } from "./promotion-program/promotion-program-form/promotion-program-form.component";
import { MktPromotionProgramListComponent } from "./promotion-program/promotion-program-list/promotion-program-list.component";
import { MktPromotionProgramPrintComponent } from "./promotion-program/promotion-program-print/promotion-program-print.component";
import { commonModules, commonProviders } from "../../common-modules";
import { DeploymentRoutingModule } from "../deployment/deployment-routing.module";
import { MarketingComponent } from "./marketing.component";
import { MarketingRoutingModule } from "./marketing-routing.module";

export const marketingComponents = [
    MarketingComponent,
    MktMemberCardListComponent,
    MktMemberCardFormComponent,
    MktMemberCardPrintComponent,
    MktPromotionProgramListComponent,
    MktPromotionProgramFormComponent,
    MktPromotionProgramPrintComponent,
];

@NgModule({
    declarations: marketingComponents,
    exports: marketingComponents,
    imports: [
        ...commonModules,
        MarketingRoutingModule,
    ],
    bootstrap: [MarketingComponent],
    providers: [
        ...commonProviders,
    ],
})
export class MarketingModule { }