import { CollaboratorAwardDetailPrintComponent } from "./award/collaborator-award-detail-print/collaborator-award-detail-print.component";
import { CollaboartorAwardDetailComponent } from "./award/collaborator-award-form/collaboartor-award-detail/collaboartor-award-detail.component";
import { CollaboratorAwardFormComponent } from "./award/collaborator-award-form/collaborator-award-form.component";
import { CollaboratorAwardListComponent } from "./award/collaborator-award-list/collaborator-award-list.component";
import { CollaboratorAwardPrintComponent } from "./award/collaborator-award-print/collaborator-award-print.component";
import { CollaboratorRevenueStatisticsComponent } from "./collaborator-dashboard/collaborator-dashboard-statistics.component";
import { CollaboratorDashboardComponent } from "./collaborator-dashboard/collaborator-dashboard.component";
import { CollaboratorMostOfDebtComponent } from "./collaborator-dashboard/collaborator-most-of-debt/collaborator-most-of-debt.component";
import { CollaboratorPageComponent } from "./collaborator-page/collaborator-page.component";
import { CollaboratorSellerListComponent } from "./human-resource/seller/collaborator-seller-list/collaborator-seller-list.component";
import { CollaboratorCommissionIncurredFormComponent } from "./commission-incurred/commission-incurred-form/commission-incurred-form.component";
import { CollaboratorCommissionIncurredListComponent } from "./commission-incurred/commission-incurred-list/commission-incurred-list.component";
import { CollaboratorCommissionIncurredPrintComponent } from "./commission-incurred/commission-incurred-print/commission-incurred-print.component";
import { CollaboratorCommissionPaymentFormComponent } from "./commission-payment/collaborator-commission-payment-form/collaborator-commission-payment-form.component";
import { CollaboratorCommissionPaymentListComponent } from "./commission-payment/collaborator-commission-payment-list/collaborator-commission-payment-list.component";
import { CollaboratorCommissionPaymentPrintComponent } from "./commission-payment/collaborator-commission-payment-print/collaborator-commission-payment-print.component";
import { CollaboratorCommissionDetailPrintComponent } from "./commission/collaborator-commission-detail-print/collaborator-commission-detail-print.component";
import { CollaboartorCommissionDetailComponent } from "./commission/collaborator-commission-form/collaboartor-commission-detail/collaboartor-commission-detail.component";
import { CollaboratorCommissionFormComponent } from "./commission/collaborator-commission-form/collaborator-commission-form.component";
import { CollaboratorCommissionListComponent } from "./commission/collaborator-commission-list/collaborator-commission-list.component";
import { CollaboratorCommissionPrintComponent } from "./commission/collaborator-commission-print/collaborator-commission-print.component";
import { CollaboratorEducationArticleFormComponent } from "./education-article/education-article-form/collaborator-education-article-form.component";
import { CollaboratorEducationArticleListComponent } from "./education-article/education-article-list/collaborator-education-article-list.component";
import { CollaboratorEducationArticlePrintComponent } from "./education-article/education-article-print/collaborator-education-article-print.component";
import { CollaboratorEmployeeGroupFormComponent } from "./employee-group/employee-group-form/employee-group-form.component";
import { CollaboratorEmployeeGroupListComponent } from "./employee-group/employee-group-list/employee-group-list.component";
import { CollaboratorKpiAwardFormComponent } from "./kpi/publisher-care/kpi-award/kpi-award-form/kpi-award-form.component";
import { CollaboratorKpiAwardListComponent } from "./kpi/publisher-care/kpi-award/kpi-award-list/kpi-award-list.component";
import { CollaboratorKpiAwardInfoComponent } from "./kpi/publisher-care/kpi-award/kpi-award-print/info/kpi-award-info.component";
import { CollaboratorKpiAwardPrintComponent } from "./kpi/publisher-care/kpi-award/kpi-award-print/kpi-award-print.component";
import { CollaboratorKpiDashboardComponent } from "./kpi/publisher-care/kpi-dashboard/kpi-dashboard.component";
import { CollaboratorKpiDistributedContractFormComponent } from "./kpi/publisher-care/kpi-distributed-contract/kpi-distributed-contract-form/kpi-distributed-contract-form.component";
import { CollaboratorKpiDistributedContractListComponent } from "./kpi/publisher-care/kpi-distributed-contract/kpi-distributed-contract-list/kpi-distributed-contract-list.component";
import { CollaboratorKpiDistributedContractPrintComponent } from "./kpi/publisher-care/kpi-distributed-contract/kpi-distributed-contract-print/kpi-distributed-print.component";
import { CollaboratorKpiGroupFormComponent } from "./kpi/publisher-care/kpi-group/kpi-group-form/kpi-group-form.component";
import { CollaboratorKpiGroupListComponent } from "./kpi/publisher-care/kpi-group/kpi-group-list/kpi-group-list.component";
import { CollaboratorKpiIndicatorListComponent } from "./kpi/publisher-care/kpi-indicator/kpi-indicator-list/kpi-indicator-list.component";
import { CollaboratorKpiStrategyFormComponent } from "./kpi/publisher-care/kpi-strategy/kpi-strategy-form/kpi-strategy-form.component";
import { CollaboratorKpiStrategyListComponent } from "./kpi/publisher-care/kpi-strategy/kpi-strategy-list/kpi-strategy-list.component";
import { CollaboratorKpiStrategyInfoComponent } from "./kpi/publisher-care/kpi-strategy/kpi-strategy-print/info/kpi-strategy-info.component";
import { CollaboratorKpiStrategyPrintComponent } from "./kpi/publisher-care/kpi-strategy/kpi-strategy-print/kpi-strategy-print.component";
import { CollaboratorOrderFormComponent } from "./order/collaborator-order-form/collaborator-order-form.component";
import { CollaboratorOrderListComponent } from "./order/collaborator-order-list/collaborator-order-list.component";
import { CollaboratorPublisherFormComponent } from "./publisher/collaborator-publisher-form/collaborator-publisher-form.component";
import { CollaboratorRebuyStrategyProductFormComponent } from "./rebuy-strategy/product-form/collaborator-rebuy-strategy-product-form.component";
import { CollaboratorRebuyStrategyPublisherFormComponent } from "./rebuy-strategy/publisher-form/collaborator-rebuy-strategy-publisher-form.component";
import { CollaboratorRebuyStrategyFormComponent } from "./rebuy-strategy/rebuy-strategy-form/collaborator-rebuy-strategy-form.component";
import { CollaboratorRebuyStrategyListComponent } from "./rebuy-strategy/rebuy-strategy-list/collaborator-rebuy-strategy-list.component";
import { CollaboratorSalesLeaderListComponent } from "./human-resource/sales-leader/collaborator-sales-leader-list/collaborator-sales-leader-list.component";
import { CollaboratorPublisherSupporterListComponent } from "./publisher-supporter/collaborator-publisher-supporter-list/collaborator-publisher-supporter-list.component";
import { CollaboratorOrderCommissionPrintComponent } from "./order/collaborator-order-commission-print/collaborator-order-commission-print.component";
import { CollaboratorOpportunityListComponent } from "./opportunity/opportunity-list/opportunity-list.component";
import { CollaboratorOpportunityFormComponent } from "./opportunity/opportunity-form/opportunity-form.component";
import { CollaboratorOpportunityPrintComponent } from "./opportunity/opportunity-print/opportunity-print.component";
import { CommentComponent } from "./opportunity/comment/comment.component";
import { CollaboratorOpportunityQuotationPrintComponent } from "./opportunity/opportunity-quotation-print/opportunity-quotation-print.component";
import { CollaboratorSalarySlipListComponent } from "./salary-slip/salary-slip-list/salary-slip-list.component";
import { CollaboratorSalarySlipFormComponent } from "./salary-slip/salary-slip-form/salary-slip-form.component";
import { CollaboratorSalarySlipPrintComponent } from "./salary-slip/salary-slip-print/salary-slip-print.component";
import { CollaboratorReportSalaryFormComponent } from "./report/salary/report-salary-form/report-salary-form.component";
import { CollaboratorReportSalaryListComponent } from "./report/salary/report-salary-list/report-salary-list.component";
import { CollaboratorReportSalaryPrintComponent } from "./report/salary/report-salary-print/report-salary-print.component";
import { CollaboratorBasicPublisherCareCommissionConfigListComponent } from "./strategies/publisher-care-commission-config/basic-publisher-care-commission-config/basic-publisher-care-commission-config-list/basic-publisher-care-commission-config-list.component";
import { CollaboratorBasicPublisherCareCommissionConfigFormComponent } from "./strategies/publisher-care-commission-config/basic-publisher-care-commission-config/basic-publisher-care-commission-config-form/basic-publisher-care-commission-config-form.component";
import { CollaboratorAdvancedPublisherCareCommissionConfigListComponent } from "./strategies/publisher-care-commission-config/advanced-publisher-care-commission-config/advanced-publisher-care-commission-config-list/advanced-publisher-care-commission-config-list.component";
import { CollaboratorAdvancedPublisherCareCommissionConfigFormComponent } from "./strategies/publisher-care-commission-config/advanced-publisher-care-commission-config/advanced-publisher-care-commission-config-form/advanced-publisher-care-commission-config-form.component";
import { CollaboratorAddonPublisherCareCommissionConfigListComponent } from "./strategies/publisher-care-commission-config/addon-publisher-care-commission-config/addon-publisher-care-commission-config-list/addon-publisher-care-commission-config-list.component";
import { CollaboratorAddonPublisherCareCommissionConfigFormComponent } from "./strategies/publisher-care-commission-config/addon-publisher-care-commission-config/addon-publisher-care-commission-config-form/addon-publisher-care-commission-config-form.component";
import { CollaboratorCustomerListComponent } from "./customer/collaborator-customer-list/collaborator-customer-list.component";
import { CollaboratorCustomerFormComponent } from "./customer/collaborator-customer-form/collaborator-customer-form.component";
import { CollaboratorSellerMyReportListComponent } from "./report/seller-my-report/seller-my-report-list/seller-my-report-list.component";
import { CollaboratorSellerMyReportFormComponent } from "./report/seller-my-report/seller-my-report-form/seller-my-report-form.component";
import { CollaboratorSellerMyReportPrintComponent } from "./report/seller-my-report/seller-my-report-print/seller-my-report-print.component";
import { CollaboratorPublisherCommissionReportListComponent } from "./report/publisher/commission/commission-list/publisher-commission-report-list.component";
import { CollaboratorPublisherCommissionReportFormComponent } from "./report/publisher/commission/commission-form/publisher-commission-report-form.component";
import { CollaboratorPublisherCommissionReportPrintComponent } from "./report/publisher/commission/commission-print/publisher-commission-report-print.component";
import { CollaboratorPublisherListCommissionReportFormComponent } from "./report/publisher/list-commission-report/list-commission-report-form/list-commission-report-form.component";
import { CollaboratorPublisherListCommissionReportListComponent } from "./report/publisher/list-commission-report/list-commission-report-list/list-commission-report-list.component";
import { CollaboratorPublisherListCommissionReportPrintComponent } from "./report/publisher/list-commission-report/list-commission-report-print/list-commission-report-print.component";
import { CollaboratorBasicPublisherCareCommissionConfigPrintComponent } from "./strategies/publisher-care-commission-config/basic-publisher-care-commission-config/basic-publisher-care-commission-config-print/basic-publisher-care-commission-config-print.component";
import { CollaboratorAdvancedPublisherCareCommissionConfigPrintComponent } from "./strategies/publisher-care-commission-config/advanced-publisher-care-commission-config/advanced-publisher-care-commission-config-print/advanced-publisher-care-commission-config-print.component";
import { CollaboratorAddonPublisherCareCommissionConfigPrintComponent } from "./strategies/publisher-care-commission-config/addon-publisher-care-commission-config/addon-publisher-care-commission-config-print/addon-publisher-care-commission-config-print.component";
import { CollaboratorOpportunityCostStrategyFormComponent } from "./strategies/opportunity-cost-strategy/opportunity-cost-strategy-form/opportunity-cost-strategy-form.component";
import { CollaboratorOpportunityCostStrategyListComponent } from "./strategies/opportunity-cost-strategy/opportunity-cost-strategy-list/opportunity-cost-strategy-list.component";
import { CollaboratorAddonStrategyListComponent } from "./strategies/addon-strategy/addon-strategy-list/collaborator-addon-strategy-list.component";
import { CollaboratorAddonStrategyPrintComponent } from "./strategies/addon-strategy/addon-strategy-print/addon-strategy-print.component";
import { CollaboratorAddonStrategyPublisherFormComponent } from "./strategies/addon-strategy/publisher-form/collaborator-addon-strategy-publisher-form.component";
import { CollaboratorAdvanceStrategyFormComponent } from "./strategies/advance-strategy/advance-strategy-form/collaborator-advance-strategy-form.component";
import { CollaboratorAdvanceStrategyListComponent } from "./strategies/advance-strategy/advance-strategy-list/collaborator-advance-strategy-list.component";
import { CollaboratorAdvanceStrategyPrintComponent } from "./strategies/advance-strategy/advance-strategy-print/advance-strategy-print.component";
import { CollaboratorAdvanceStrategyPublisherFormComponent } from "./strategies/advance-strategy/publisher-form/collaborator-advance-strategy-publisher-form.component";
import { CollaboratorBasicStrategyFormComponent } from "./strategies/basic-strategy/basic-strategy-form/collaborator-basic-strategy-form.component";
import { CollaboratorBasicStrategyListComponent } from "./strategies/basic-strategy/basic-strategy-list/collaborator-basic-strategy-list.component";
import { CollaboratorBasicStrategyPrintComponent } from "./strategies/basic-strategy/basic-strategy-print/collaborator-basic-strategy-print.component";
import { CollaboratorBasicStrategyProductFormComponent } from "./strategies/basic-strategy/product-form/collaborator-basic-strategy-product-form.component";
import { CollaboratorAddonSaleCommissionConfigurationFormComponent } from "./strategies/sale-commission-configuration/addon-sale-commission-configuration/addon-sale-commission-configuration-form/collaborator-addon-sale-commission-configuration-form.component";
import { CollaboratorAddonSaleCommissionConfigurationListComponent } from "./strategies/sale-commission-configuration/addon-sale-commission-configuration/addon-sale-commission-configuration-list/collaborator-addon-sale-commission-configuration-list.component";
import { CollaboratorAddonSaleCommissionConfigurationPrintComponent } from "./strategies/sale-commission-configuration/addon-sale-commission-configuration/addon-sale-commission-configuration-print/addon-sale-commission-configuration-print.component";
import { CollaboratorAdvancedSaleCommissionConfigurationFormComponent } from "./strategies/sale-commission-configuration/advanced-sale-commission-configuration/advanced-sale-commission-configuration-form/collaborator-advanced-sale-commission-configuration-form.component";
import { CollaboratorAdvancedSaleCommissionConfigurationListComponent } from "./strategies/sale-commission-configuration/advanced-sale-commission-configuration/advanced-sale-commission-configuration-list/collaborator-advanced-sale-commission-configuration-list.component";
import { CollaboratorAdvancedSaleCommissionConfigurationPrintComponent } from "./strategies/sale-commission-configuration/advanced-sale-commission-configuration/advanced-sale-commission-configuration-print/advanced-sale-commission-configuration-print.component";
import { CollaboratorBasicSaleCommissionConfigurationFormComponent } from "./strategies/sale-commission-configuration/basic-sale-commission-configuration/basic-sale-commission-configuration-form/collaborator-basic-sale-commission-configuration-form.component";
import { CollaboratorBasicSaleCommissionConfigurationListComponent } from "./strategies/sale-commission-configuration/basic-sale-commission-configuration/basic-sale-commission-configuration-list/collaborator-basic-sale-commission-configuration-list.component";
import { CollaboratorBasicSaleCommissionConfigurationPrintComponent } from "./strategies/sale-commission-configuration/basic-sale-commission-configuration/basic-sale-commission-configuration-print/basic-sale-commission-configuration-print.component";
import { CollaboratorStrategyCompileRuleListComponent } from "./strategies/strategy-compile-rules/strategy-compile-rule-list/strategy-compile-rule-list.component";
import { CollaboratorAddonStrategyFormComponent } from "./strategies/addon-strategy/addon-strategy-form/collaborator-addon-strategy-form.component";
import { CollaboratorSalesKpiAwardListComponent } from "./kpi/sales/kpi-award/kpi-award-list/kpi-award-list.component";
import { CollaboratorSalesKpiAwardFormComponent } from "./kpi/sales/kpi-award/kpi-award-form/kpi-award-form.component";
import { CollaboratorSalesKpiAwardInfoComponent } from "./kpi/sales/kpi-award/kpi-award-print/info/kpi-award-info.component";
import { CollaboratorSalesKpiAwardPrintComponent } from "./kpi/sales/kpi-award/kpi-award-print/kpi-award-print.component";
import { CollaboratorSalesKpiDashboardComponent } from "./kpi/sales/kpi-dashboard/kpi-dashboard.component";
import { CollaboratorSalesKpiDistributedContractFormComponent } from "./kpi/sales/kpi-distributed-contract/kpi-distributed-contract-form/kpi-distributed-contract-form.component";
import { CollaboratorSalesKpiDistributedContractListComponent } from "./kpi/sales/kpi-distributed-contract/kpi-distributed-contract-list/kpi-distributed-contract-list.component";
import { CollaboratorSalesKpiDistributedContractPrintComponent } from "./kpi/sales/kpi-distributed-contract/kpi-distributed-contract-print/kpi-distributed-print.component";
import { CollaboratorSalesKpiGroupFormComponent } from "./kpi/sales/kpi-group/kpi-group-form/kpi-group-form.component";
import { CollaboratorSalesKpiGroupListComponent } from "./kpi/sales/kpi-group/kpi-group-list/kpi-group-list.component";
import { CollaboratorSalesKpiIndicatorListComponent } from "./kpi/sales/kpi-indicator/kpi-indicator-list/kpi-indicator-list.component";
import { CollaboratorSalesKpiStrategyFormComponent } from "./kpi/sales/kpi-strategy/kpi-strategy-form/kpi-strategy-form.component";
import { CollaboratorSalesKpiStrategyListComponent } from "./kpi/sales/kpi-strategy/kpi-strategy-list/kpi-strategy-list.component";
import { CollaboratorSalesKpiStrategyInfoComponent } from "./kpi/sales/kpi-strategy/kpi-strategy-print/info/kpi-strategy-info.component";
import { CollaboratorSalesKpiStrategyPrintComponent } from "./kpi/sales/kpi-strategy/kpi-strategy-print/kpi-strategy-print.component";
import { CollaboratorSalesDirectorListComponent } from "./human-resource/sales-director/collaborator-sales-director-list/collaborator-sales-director-list.component";
import { CollaboratorSubSalesDirectorListComponent } from "./human-resource/sub-sales-director/collaborator-sub-sales-director-list/collaborator-sub-sales-director-list.component";
import { CollaboratorProductPreviewListComponent } from "./product/collaborator-product-preview-list/collaborator-product-preview-list.component";
import { CollaboratorSubscriptionProductComponent } from "./product/collaborator-subscription-product/collaborator-subscription-product.component";
import { CollaboratorProductListComponent } from "./product/collaborator-product-list/collaborator-product-list.component";
import { CollaboratorProductFormComponent } from "./product/collaborator-product-form/collaborator-product-form.component";
import { CollaboratorPageListComponent } from "./page/collaborator-page-list/collaborator-page-list.component";
import { PublisherCommissionStatisticsComponent } from "./collaborator-publisher-dashboard/publisher-commission-statistics.component";
import { CollaboratorPublisherListComponent } from "./publisher/collaborator-publisher-list/collaborator-publisher-list.component";
import { CollaboratorPublisherDashboardComponent } from "./collaborator-publisher-dashboard/collaborator-publisher-dashboard.component";
import { MostActiveProductsComponent } from "./collaborator-publisher-dashboard/most-active-products/most-active-products.component";
import { CollaboratorPageReportComponent } from "./collaborator-page-report/collaborator-page-report.component";
import { MostActivePublishersComponent } from "./collaborator-page-dashboard/most-active-publishers/most-active-publishers.component";
import { ChartjsBarHorizontalComponent } from "./collaborator-page-dashboard/chartjs-bar-horizontal.component";
import { ChartjsBarComponent } from "./collaborator-page-dashboard/chartjs-bar.component";
import { ChartjsLineComponent } from "./collaborator-page-dashboard/chartjs-line.component";
import { ChartjsMultipleXaxisComponent } from "./collaborator-page-dashboard/chartjs-multiple-xaxis.component";
import { ChartjsPieComponent } from "./collaborator-page-dashboard/chartjs-pie.component";
import { ChartjsRadarComponent } from "./collaborator-page-dashboard/chartjs-radar.component";
import { CollaboratorPageDashboardComponent } from "./collaborator-page-dashboard/collaborator-page-dashboard.component";
import { CollaboratorOrderTeleCommitFormComponent } from "./order/collaborator-order-tele-commit/collaborator-order-tele-commit.component";
import { CollaboratorPublisherComponent } from "./collaborator-publisher/collaborator-publisher.component";
import { CollaboratorComponent } from "./collaborator.component";
import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { CollaboratorRoutingModule } from "./collaborator-routing.module";
import { pageComponents } from "./page/page.module";
import { CollaboratorPageFormComponent } from "./page/collaborator-page-form/collaborator-page-form.component";
import { CollaboratorSubscriptionPageListComponent } from "./page/collaborator-subscription-page-list/collaborator-subscription-page-list.component";
import { CollaboratorOrderPrintComponent } from "./order/collaborator-order-print/collaborator-order-print.component";

export const collaboratorComponents = [
    CollaboratorComponent,
    CollaboratorPageComponent,
    CollaboratorCommissionListComponent,
    CollaboratorCommissionFormComponent,
    CollaboratorCommissionPrintComponent,
    CollaboratorCommissionPaymentListComponent,
    CollaboratorCommissionPaymentFormComponent,
    CollaboratorCommissionPaymentPrintComponent,
    CollaboartorCommissionDetailComponent,
    CollaboratorPublisherFormComponent,
    CollaboratorCommissionDetailPrintComponent,
    CollaboratorAwardListComponent,
    CollaboratorAwardFormComponent,
    CollaboratorAwardPrintComponent,
    CollaboartorAwardDetailComponent,
    CollaboratorAwardDetailPrintComponent,
    CollaboratorEducationArticleListComponent,
    CollaboratorEducationArticleFormComponent,
    CollaboratorEducationArticlePrintComponent,

    CollaboratorBasicStrategyListComponent,
    CollaboratorBasicStrategyFormComponent,
    CollaboratorBasicStrategyPrintComponent,

    CollaboratorBasicStrategyProductFormComponent,

    CollaboratorAdvanceStrategyListComponent,
    CollaboratorAdvanceStrategyFormComponent,
    CollaboratorAdvanceStrategyPrintComponent,

    CollaboratorAdvanceStrategyPublisherFormComponent,

    CollaboratorAddonStrategyListComponent,
    CollaboratorAddonStrategyFormComponent,
    CollaboratorAddonStrategyPublisherFormComponent,
    CollaboratorAddonStrategyPrintComponent,

    CollaboratorRebuyStrategyListComponent,
    CollaboratorRebuyStrategyFormComponent,
    CollaboratorRebuyStrategyPublisherFormComponent,
    CollaboratorRebuyStrategyProductFormComponent,
    CollaboratorDashboardComponent,
    CollaboratorRevenueStatisticsComponent,
    CollaboratorMostOfDebtComponent,
    CollaboratorCommissionIncurredListComponent,
    CollaboratorCommissionIncurredFormComponent,
    CollaboratorCommissionIncurredPrintComponent,
    CollaboratorStrategyCompileRuleListComponent,
    CollaboratorEmployeeGroupListComponent,
    CollaboratorEmployeeGroupFormComponent,

    // Publisher care KPI
    CollaboratorKpiAwardListComponent,
    CollaboratorKpiAwardFormComponent,
    CollaboratorKpiAwardInfoComponent,
    CollaboratorKpiAwardPrintComponent,
    CollaboratorKpiDistributedContractListComponent,
    CollaboratorKpiDistributedContractFormComponent,
    CollaboratorKpiDistributedContractPrintComponent,
    CollaboratorKpiDashboardComponent,
    CollaboratorKpiIndicatorListComponent,
    CollaboratorKpiGroupListComponent,
    CollaboratorKpiGroupFormComponent,
    CollaboratorKpiStrategyListComponent,
    CollaboratorKpiStrategyFormComponent,
    CollaboratorKpiStrategyInfoComponent,
    CollaboratorKpiStrategyPrintComponent,
    CollaboratorKpiStrategyPrintComponent,

    // Sales KPI
    CollaboratorSalesKpiAwardListComponent,
    CollaboratorSalesKpiAwardFormComponent,
    CollaboratorSalesKpiAwardInfoComponent,
    CollaboratorSalesKpiAwardPrintComponent,
    CollaboratorSalesKpiDistributedContractListComponent,
    CollaboratorSalesKpiDistributedContractFormComponent,
    CollaboratorSalesKpiDistributedContractPrintComponent,
    CollaboratorSalesKpiDashboardComponent,
    CollaboratorSalesKpiIndicatorListComponent,
    CollaboratorSalesKpiGroupListComponent,
    CollaboratorSalesKpiGroupFormComponent,
    CollaboratorSalesKpiStrategyListComponent,
    CollaboratorSalesKpiStrategyFormComponent,
    CollaboratorSalesKpiStrategyInfoComponent,
    CollaboratorSalesKpiStrategyPrintComponent,
    CollaboratorSalesKpiStrategyPrintComponent,

    CollaboratorOrderFormComponent,
    CollaboratorOrderListComponent,
    CollaboratorOrderPrintComponent,
    //
    CollaboratorBasicSaleCommissionConfigurationListComponent,
    CollaboratorBasicSaleCommissionConfigurationFormComponent,
    CollaboratorBasicSaleCommissionConfigurationPrintComponent,

    CollaboratorAdvancedSaleCommissionConfigurationListComponent,
    CollaboratorAdvancedSaleCommissionConfigurationFormComponent,
    CollaboratorAdvancedSaleCommissionConfigurationPrintComponent,

    CollaboratorAddonSaleCommissionConfigurationListComponent,
    CollaboratorAddonSaleCommissionConfigurationFormComponent,
    CollaboratorAddonSaleCommissionConfigurationPrintComponent,
    //
    CollaboratorSellerListComponent,
    CollaboratorSalesLeaderListComponent,
    CollaboratorPublisherSupporterListComponent,
    CollaboratorOrderCommissionPrintComponent,

    CollaboratorOpportunityListComponent,
    CollaboratorOpportunityFormComponent,
    CollaboratorOpportunityPrintComponent,
    CommentComponent,
    CollaboratorOpportunityQuotationPrintComponent,

    CollaboratorSalarySlipListComponent,
    CollaboratorSalarySlipFormComponent,
    CollaboratorSalarySlipPrintComponent,

    CollaboratorReportSalaryListComponent,
    CollaboratorReportSalaryFormComponent,
    CollaboratorReportSalaryPrintComponent,

    CollaboratorOpportunityCostStrategyListComponent,
    CollaboratorOpportunityCostStrategyFormComponent,

    CollaboratorBasicPublisherCareCommissionConfigListComponent,
    CollaboratorBasicPublisherCareCommissionConfigFormComponent,
    CollaboratorBasicPublisherCareCommissionConfigPrintComponent,

    CollaboratorAdvancedPublisherCareCommissionConfigListComponent,
    CollaboratorAdvancedPublisherCareCommissionConfigFormComponent,
    CollaboratorAdvancedPublisherCareCommissionConfigPrintComponent,

    CollaboratorAddonPublisherCareCommissionConfigListComponent,
    CollaboratorAddonPublisherCareCommissionConfigFormComponent,
    CollaboratorAddonPublisherCareCommissionConfigPrintComponent,

    CollaboratorCustomerListComponent,
    CollaboratorCustomerFormComponent,

    CollaboratorSellerMyReportListComponent,
    CollaboratorSellerMyReportFormComponent,
    CollaboratorSellerMyReportPrintComponent,

    CollaboratorPublisherCommissionReportListComponent,
    CollaboratorPublisherCommissionReportFormComponent,
    CollaboratorPublisherCommissionReportPrintComponent,

    CollaboratorPublisherListCommissionReportListComponent,
    CollaboratorPublisherListCommissionReportFormComponent,
    CollaboratorPublisherListCommissionReportPrintComponent,

    // Human resources
    CollaboratorSalesDirectorListComponent,
    CollaboratorSubSalesDirectorListComponent,


    // Common
    CollaboratorProductPreviewListComponent,
    CollaboratorSubscriptionProductComponent,
    CollaboratorProductListComponent,
    CollaboratorProductFormComponent,
    CollaboratorPageListComponent,
    PublisherCommissionStatisticsComponent,
    CollaboratorPublisherListComponent,
    CollaboratorPublisherDashboardComponent,
    MostActiveProductsComponent,
    CollaboratorPageReportComponent,
    MostActivePublishersComponent,
    ChartjsBarHorizontalComponent,
    ChartjsBarComponent,
    ChartjsLineComponent,
    ChartjsMultipleXaxisComponent,
    ChartjsPieComponent,
    ChartjsRadarComponent,
    CollaboratorPageDashboardComponent,
    CollaboratorOrderTeleCommitFormComponent,
    CollaboratorPublisherComponent,

    CollaboratorSubscriptionPageListComponent,
    CollaboratorPageListComponent,
    CollaboratorPageFormComponent,
];

@NgModule({
    declarations: collaboratorComponents,
    exports: collaboratorComponents,
    imports: [
        ...commonModules,
        CollaboratorRoutingModule,
    ],
    bootstrap: [CollaboratorComponent],
    providers: [
        ...commonProviders,
    ],
})
export class CollaboratorModule { }