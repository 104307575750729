import { ColDef, ColumnApi, GridApi, IRowNode } from "@ag-grid-community/core";
import { CurrencyPipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbToastrService, NbDialogService, NbDialogRef, NbThemeService } from "@nebular/theme";
import { AgDateTimeCellInput } from "../../../../../lib/custom-element/ag-list/cell/input/datetime.component";
import { AgNumberCellInput } from "../../../../../lib/custom-element/ag-list/cell/input/number.component";
import { AgTextCellRenderer } from "../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeCommandColDef } from "../../../../../lib/custom-element/ag-list/column-define/command.define";
import { agMakeImageColDef } from "../../../../../lib/custom-element/ag-list/column-define/image.define";
import { agMakeSelectionColDef } from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { DataManagerFormComponent } from "../../../../../lib/data-manager/data-manager-form.component";
import { CollaboratorAdvanceStrategyModel, CollaboratorBasicStrategyProductModel, CollaboratorAdvanceStrategyPublisherModel } from "../../../../../models/collaborator.model";
import { ProductUnitModel, ProductModel } from "../../../../../models/product.model";
import { ApiService } from "../../../../../services/api.service";
import { CommonService } from "../../../../../services/common.service";
import { RootServices } from "../../../../../services/root.services";
import { AgDynamicListComponent } from "../../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component";
import { CollaboratorService } from "../../../collaborator.service";
import { CollaboratorProductListComponent } from "../../../product/collaborator-product-list/collaborator-product-list.component";
import { CollaboratorPublisherListComponent } from "../../../publisher/collaborator-publisher-list/collaborator-publisher-list.component";
import { CollaboratorBasicStrategyProductFormComponent } from "../../basic-strategy/product-form/collaborator-basic-strategy-product-form.component";
import { Model } from "../../../../../models/model";
import { AgDateCellInput } from "../../../../../lib/custom-element/ag-list/cell/input/date.component";
import { agMakeTextColDef } from "../../../../../lib/custom-element/ag-list/column-define/text.define";

@Component({
  selector: 'ngx-collaborator-advance-strategy-form',
  templateUrl: './collaborator-advance-strategy-form.component.html',
  styleUrls: ['./collaborator-advance-strategy-form.component.scss'],
  providers: [
    CurrencyPipe
  ]
})
export class CollaboratorAdvanceStrategyFormComponent extends DataManagerFormComponent<CollaboratorAdvanceStrategyModel> implements OnInit {


  componentName: string = 'CollaboratorAdvanceStrategyFormComponent';
  idKey = ['Code'];
  apiPath = '/collaborator/advance-strategies';
  baseFormUrl = '/collaborator/advance-strategy/form';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref?: NbDialogRef<CollaboratorAdvanceStrategyFormComponent>,
    public collaboratorService?: CollaboratorService,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);


    const $this = this;
    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Publisher',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Avatar',
        field: 'Avatar',
        width: 100,
      },
      {
        headerName: 'Tên CTV',
        field: 'PublisherName',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'Bắt đầu',
        field: 'DateOfStart',
        width: 250,
        filter: 'agTextColumnFilter',
        cellRenderer: AgDateCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        // pinned: 'right',
      },
      {
        headerName: 'Kết thúc ',
        field: 'DateOfEnd',
        width: 250,
        filter: 'agTextColumnFilter',
        cellRenderer: AgDateCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        // pinned: 'right',
      },
      {
        headerName: 'Sản phẩm',
        field: 'Products',
        width: 150,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'right',
      },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          this.gridApi.applyTransaction({ remove: [params] });
        }, false, [
          // {
          //   name: 'setting',
          //   title: 'Cài đặt',
          //   icon: 'settings-2-outline',
          //   status: 'primary',
          //   outline: false,
          //   action: async (params) => {
          //     this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
          //       context: {
          //         data: [
          //           params.node.data,
          //         ],
          //         onDialogSave(newData) {
          //           console.log(newData);
          //           let currentNode: IRowNode = $this.gridApi.getRowNode($this.cms.getObjectId(params.data.Product) + '-' + $this.cms.getObjectId(params.data.Unit));
          //           currentNode.setData(newData[0]);
          //         },
          //       }
          //     });
          //     return true;
          //   }
          // },
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];

    this.productsColumnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Product',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Hình',
        field: 'FeaturePicture',
        width: 100,
      },
      {
        headerName: 'Sku',
        field: 'Sku',
        width: 100,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        pinned: 'left',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Tên sản phẩm',
        field: 'ProductName',
        width: 250,
        // filter: 'agTextColumnFilter',
        // cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'ĐVT',
        field: 'Unit',
        width: 110,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'right',
      },
      {
        headerName: 'Chiết khấu',
        field: 'CommissionRatio',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: AgNumberCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
          // changed: (value, params) => {
          //   params.status = 'success';
          //   console.log(this.selectedPublisherNode.data);
          //   console.log(params.data);

          //   // this.updatePublisherProducts();
          // }
        },
        // pinned: 'right',
      },
      // {
      //   headerName: 'Thưởng tuần',
      //   field: 'Level1WeeklyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng tháng',
      //   field: 'Level1MonthlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng quý',
      //   field: 'Level1QuarterlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      // {
      //   headerName: 'Thưởng năm',
      //   field: 'Level1YearlyAwardRatio',
      //   width: 150,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   // pinned: 'right',
      // },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          $this.productGridApi.applyTransaction({ remove: [params] });
          $this.updatePublisherProducts();
        }, false, [
          // {
          //   name: 'setting',
          //   title: 'Cài đặt',
          //   icon: 'settings-2-outline',
          //   status: 'primary',
          //   outline: false,
          //   action: async (params) => {
          //     this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
          //       context: {
          //         data: [
          //           params.node.data,
          //         ],
          //         onDialogSave(newData) {
          //           console.log(newData);
          //           let currentNode: IRowNode = $this.productGridApi.getRowNode($this.cms.getObjectId(params.data.Product) + '-' + $this.cms.getObjectId(params.data.Unit));
          //           currentNode && currentNode.setData({ ...currentNode.data, ...newData[0] });
          //           $this.updatePublisherProducts();
          //         },
          //       }
          //     });
          //     return true;
          //   }
          // },
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];
    /** End AG-Grid */
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public productGridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public productsColumnDefs: ColDef[];
  public gridParams;

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }
  onProductGridReady(params) {
    this.productGridApi = params.api;
    this.productGridApi.setRowData([]);
  }

  loadList(callback?: (list: CollaboratorBasicStrategyProductModel[]) => void) {
    if (this.gridApi) {
      let publishers: CollaboratorAdvanceStrategyPublisherModel[] = (this.array.controls[0].get('Publishers').value || []).map((publisher: CollaboratorAdvanceStrategyPublisherModel) => {
        if (!publisher.Products) {
          publisher.Products = [];
        }
        publisher.Products.map(product => {
          product['id'] = this.cms.getObjectId(product.Product) + '-' + this.cms.getObjectId(product.Unit);
          product['text'] = product.ProductName + '/' + this.cms.getObjectText(product.Unit) + '/' + product.Level1CommissionRatio;
        });
        return publisher;
      });
      this.gridApi.setRowData(publishers);
    }
  }
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includePublishers'] = true;
    params['includeProducts'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group<any>({
      Code: { value: '', disabled: true },
      Title: ['', Validators.required],
      Page: [this.collaboratorService.currentpage$.value, Validators.required],
      // DateRange: [[Date.today(), Date.today().next().month()], Validators.required],
      DateOfStart: [],
      DateOfEnd: [],
      Publishers: [[]],
    });
    if (data) {
      // data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở CTV Bán Hàng',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm CTV Bán Hàng',
      status: 'success',
      label: 'Thêm CTV Bán Hàng',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.openDialog(CollaboratorPublisherListComponent, {
          context: {
            gridHeight: '90vh',
            onDialogChoose(chooseItems) {
              console.log(chooseItems);
              const newRowNodeTrans = $this.gridApi.applyTransaction({
                add: chooseItems.map(m => ({
                  id: m.Contact,
                  text: m.Name,
                  Publisher: m.Contact,
                  PublisherName: m.Name,
                  Avatar: m.Avatar,
                }))
              });
              console.log('New Row Node Trans: ', newRowNodeTrans);
            },
          }
        });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'copyto',
      title: 'Copy to',
      status: 'info',
      label: 'Copy',
      iconPack: 'eva',
      icon: 'copy-outline',
      size: 'medium',
      disabled: () => this.gridApi.getSelectedNodes().length != 1,
      click: (event) => {
        const selectedNode: IRowNode = this.gridApi.getSelectedNodes()[0];
        const nodes: IRowNode[] = [];
        this.gridApi.forEachNode(node => nodes.push(node));

        if (selectedNode) {
          const $this = this;
          this.cms.openDialog(AgDynamicListComponent, {
            context: {
              title: 'Copy danh sách sản phẩm sang các CTV khác',
              width: '905px',
              height: '400px',
              // apiPath: '/accounting/reports',
              hideChooseButton: true,
              rowModelType: 'clientSide',
              idKey: ['Id'],
              rowData: nodes.map(m => m.data).filter(f => this.cms.getObjectId(f.Publisher) != this.cms.getObjectId(selectedNode.data.Publisher)),
              extendActionButtons: [
                {
                  name: 'confirm',
                  label: 'Copy',
                  title: 'Copy',
                  size: 'medium',
                  icon: 'copy-outline',
                  status: 'danger',
                  click: (event, option, component: AgDynamicListComponent<Model>) => {
                    if (component.rowData && component.rowData.length > 0) {
                      // component.loading = true;
                      const targetNodes = component.gridApi.getSelectedNodes();
                      for (const i in targetNodes) {
                        const targetNodeData = targetNodes[i].data;
                        $this.gridApi.forEachNode((node: IRowNode) => {
                          if (this.cms.getObjectId(targetNodeData.Publisher) == this.cms.getObjectId(node.data.Publisher)) {
                            targetNodeData.Products = selectedNode.data.Products.map(m => ({ ...m, Id: null, SystemUuid: null, Publisher: targetNodeData.Publisher }));
                            node.setData(targetNodeData);
                          }
                        });
                      }

                      component.close();
                      this.cms.showToast('Đã copy cấu hình chiết khấu sang các CTV đã chọn', 'Đã copy cấu hình chiết khấu', { status: 'success' })

                      // this.apiService.putPromise<any[]>('/admin-product/products', { id: component.rowData.map(m => this.cms.getObjectId(m.Product)) }, component.rowData.map(m => ({
                      //   Code: m.Product,
                      //   Name: m.Name,
                      // }))).then(rs => {
                      //   component.loading = false;
                      //   component.close();
                      //   this.refresh();
                      // }).catch(err => {
                      //   component.loading = false;
                      // });
                    }
                    return true;
                  }
                }
              ],
              onDialogChoose: (chooseItems) => {

              },
              columnDefs: this.columnDefs,
              onInit: (component) => {
                component.actionButtonList = component.actionButtonList.filter(f => ['close', 'choose', 'preview', 'refresh', 'confirm'].indexOf(f.name) > -1);
              }
            },
            closeOnEsc: false,
          });
        }

        return true;
      }
    });

    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'settings',
    //   title: 'Cấu hình',
    //   status: 'primary',
    //   label: 'Cài đặt',
    //   iconPack: 'eva',
    //   icon: 'settings-2-outline',
    //   size: 'medium',
    //   click: (event) => {
    //     const selectedNodes: IRowNode[] = $this.gridApi.getSelectedNodes();

    //     // Setting for product
    //     if (selectedNodes && selectedNodes.length > 0) {
    //       this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
    //         context: {
    //           data: selectedNodes.map(m => m.data),
    //           onDialogSave(newData) {
    //             console.log(newData);
    //             for (const itemData of newData) {
    //               let currentNode: IRowNode = $this.gridApi.getRowNode($this.cms.getObjectId(itemData.Product) + '-' + $this.cms.getObjectId(itemData.Unit));
    //               currentNode.setData(itemData);
    //             }
    //           },
    //         }
    //       });
    //     }

    //     return true;
    //   }
    // });

    component.actionButtonList = actionButtonList;
  }

  onProductGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.publisher,
      click: (event) => {
        const selectedNodes: IRowNode[] = $this.productGridApi.getSelectedNodes();
        $this.productGridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });
        $this.updatePublisherProducts();
        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm sản phẩm',
      status: 'success',
      label: 'Thêm sản phẩm',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.publisher,
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.openDialog(CollaboratorProductListComponent, {
          context: {
            // gridHeight: '90vh',
            onDialogChoose(chooseItems) {
              console.log(chooseItems);
              const newRowNodeTrans = $this.productGridApi.applyTransaction({
                add: chooseItems.map(m => ({
                  id: m.Code,
                  text: m.Name,
                  Product: m.Code,
                  ProductName: m.Name,
                  Sku: m.Sku,
                  Unit: m.Unit,
                  Pictures: m.Pictures,
                  FeaturePicture: m.FeaturePicture,
                  CommissionRatio: 0,
                }))
              });
              console.log('New Row Node Trans: ', newRowNodeTrans);
              $this.updatePublisherProducts();
            },
          }
        });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'settings',
      title: 'Cấu hình',
      status: 'primary',
      label: 'Cài đặt',
      iconPack: 'eva',
      icon: 'settings-2-outline',
      size: 'medium',
      disabled: () => !$this.productExtendData?.publisher,
      click: (event) => {
        const selectedNodes: IRowNode[] = $this.productGridApi.getSelectedNodes();

        // Setting for product
        if (selectedNodes && selectedNodes.length > 0) {
          this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
            context: {
              data: selectedNodes.map(m => m.data),
              onDialogSave(newData) {
                console.log(newData);
                for (const itemData of newData) {
                  let currentNode: IRowNode = $this.productGridApi.getRowNode($this.cms.getObjectId(itemData.Product) + '-' + $this.cms.getObjectId(itemData.Unit));
                  currentNode.setData({ ...currentNode.data, ...itemData });
                }

                $this.updatePublisherProducts();
              },
            }
          });
        }

        return true;
      }
    });

    component.actionButtonList = actionButtonList;
  }

  productExtendData: any = {};
  selectedPublisherNode: IRowNode<CollaboratorAdvanceStrategyPublisherModel> = null;
  onPublishersSelected(nodes: IRowNode<CollaboratorAdvanceStrategyPublisherModel>[]) {
    console.log('On Publishers selected: ', nodes);
    if (nodes.length == 1) {
      // Load relative products
      const time = Date.now();
      nodes[0].data.Products['__timestamp'] = time;
      console.log(nodes[0].data.Products);
      this.productGridApi.setRowData(nodes[0].data.Products);
      this.productExtendData.publisher = nodes[0].data;
      this.selectedPublisherNode = nodes[0];
    } else {
      // Clear relative products
      this.productExtendData.publisher = null;
      this.selectedPublisherNode = null;
      this.productGridApi.setRowData([]);
    }
  }

  updatePublisherProducts() {
    if (this.selectedPublisherNode) {
      // const products = [];

      // Emplty products
      this.selectedPublisherNode.data.Products.splice(0, this.selectedPublisherNode.data.Products.length);

      // Add new producs
      this.productGridApi.forEachNode(rowNode => {
        rowNode.data.id = this.cms.getObjectId(rowNode.data['Product']) + '-' + this.cms.getObjectId(rowNode.data['Unit']);
        rowNode.data.text = rowNode.data['ProductName'] + '/' + this.cms.getObjectText(rowNode.data['Unit']) + '/' + rowNode.data['Level1CommissionRatio'];
        this.selectedPublisherNode.data.Products.push(rowNode.data);
        // this.selectedPublisherNode.data.Products.push({
        //   ...rowNode.data,
        //   id: this.cms.getObjectId(rowNode.data['Product']) + '-' + this.cms.getObjectId(rowNode.data['Unit']),
        //   text: rowNode.data['ProductName'] + '/' + this.cms.getObjectText(rowNode.data['Unit']) + '/' + rowNode.data['Level1CommissionRatio'],
        // });
      });
      // this.selectedPublisherNode.data.Products = products;
      // this.selectedPublisherNode.setData(this.selectedPublisherNode.data);
    }
  }

  /** Hight performance config */
  patchedDataAfterSave = false;
  cleanedDataBeforeSave = true;
  /**
   * Override: Clean data for detail form items
   */
  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Get details data from ag-grid
      item.Publishers = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
        }
        item.Publishers.push(rawDetail);
      });
    }
    return data;
  }
  /** Override: Auto update SystemUuid for detail form item */
  onItemAfterSaveSubmit(formItemData: CollaboratorAdvanceStrategyModel, index: number, method: string) {
    const result = super.onItemAfterSaveSubmit(formItemData, index, method);
    if (result && formItemData.Publishers) {
      for (const d in formItemData.Publishers) {
        const publisherNode = this.gridApi.getRowNode(this.cms.getObjectId(formItemData.Publishers[d].Publisher));
        publisherNode.setData(formItemData.Publishers[d]);
        if(this.selectedPublisherNode) {
          if(!formItemData.Publishers[d].Products) {
            formItemData.Publishers[d].Products = [];
          }
          for (const p in formItemData.Publishers[d].Products) {
            const product = formItemData.Publishers[d].Products[p];
            const productNode = this.productGridApi.getRowNode(this.cms.getObjectId(product.Product)+this.cms.getObjectId(product.Unit));
            if(productNode) {
              productNode.setData(product);
            }
          }
        }
      }
    }
    return result;
  }
  /** End Hight performance config */
}
