import { RouterModule, Routes } from '@angular/router';
import { MenuListComponent } from './manager-menu/menu-list/menu-list.component';
import { MenuFormComponent } from './manager-menu/menu-form/menu-form.component';
import { NgModule } from '@angular/core';

export const menuRoutes: Routes = [
  {
    path: 'manager/list',
    // canActivate: [AuthGuardService],
    component: MenuListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'manager/form',
    // canActivate: [AuthGuardService],
    component: MenuFormComponent,
  },
  {
    path: 'manager/form/:id',
    // canActivate: [AuthGuardService],
    component: MenuFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(menuRoutes)],
  exports: [RouterModule],
})
export class MenuRoutingModule {
}