import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { MenuFormComponent } from "./manager-menu/menu-form/menu-form.component";
import { MenuListComponent } from "./manager-menu/menu-list/menu-list.component";
import { MenuRoutingModule } from "./menu-routing.module";
import { MenuComponent } from "./menu.component";

export const menuCompoments = [
  MenuComponent,
  MenuListComponent,
  MenuFormComponent,
];

@NgModule({
  declarations: menuCompoments,
  // exports: moduleCompoments,
  imports: [
    ...commonModules,
    MenuRoutingModule,
  ],
  bootstrap: [MenuComponent],
  providers: [
    ...commonProviders,
  ],
})
export class MenuModule { }