import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { B2bServerPageListComponent } from "./page/b2b-server-page-list/b2b-server-page-list.component";
import { B2bProductListComponent } from "./product/product/product-list/product-list.component";
import { B2bProductCategoryListComponent } from "./product/category/product-category-list/product-category-list.component";
import { B2bProductGroupListComponent } from "./product/group/product-group-list/product-group-list.component";
import { B2bProductPropertyListComponent } from "./product/property/product-property-list/product-property-list.component";
import { B2bProductBrandListComponent } from "./product/brand/product-brand-list/product-brand-list.component";
import { B2bProductKeywordListComponent } from "./product/keyword/product-keyword-list/product-keyword-list.component";
import { B2bProductUnitListComponent } from "./product/unit/product-unit-list/product-unit-list.component";
import { B2bOrderListComponent } from "./order/b2b-order-list/b2b-order-list.component";
import { B2bBasicCommissionConfigListComponent } from "./commission/basic-config/list/basic-commission-config-list.component";
import { NgModule } from "@angular/core";

export const b2bServerRoutes: Routes = [
  {
    path: 'page/list',
    canActivate: [AuthGuardService],
    component: B2bServerPageListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/list',
    canActivate: [AuthGuardService],
    component: B2bProductListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/category/list',
    canActivate: [AuthGuardService],
    component: B2bProductCategoryListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/group/list',
    canActivate: [AuthGuardService],
    component: B2bProductGroupListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/property/list',
    canActivate: [AuthGuardService],
    component: B2bProductPropertyListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/brand/list',
    canActivate: [AuthGuardService],
    component: B2bProductBrandListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/keyword/list',
    canActivate: [AuthGuardService],
    component: B2bProductKeywordListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/unit/list',
    canActivate: [AuthGuardService],
    component: B2bProductUnitListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'order/list',
    canActivate: [AuthGuardService],
    component: B2bOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'commission/config/basic/list',
    canActivate: [AuthGuardService],
    component: B2bBasicCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(b2bServerRoutes)],
    exports: [RouterModule],
})
export class B2bServerRoutingModule {
}
