import { IvoipComponent } from './ivoip.component';
import { CdrComponent } from './cdr/cdr.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { DevicesComponent } from './devices/devices.component';
import { PstnNumbersComponent } from './pstn-numbers/pstn-numbers.component';
import { CallBlocksComponent } from './call-blocks/call-blocks.component';
import { PbxFormComponent } from './pbx/pbx-form/pbx-form.component';
import { PbxListComponent } from './pbx/pbx-list/pbx-list.component';
import { CallBlockListComponent } from './call-blocks/call-block-list/call-block-list.component';
import { CallBlockFormComponent } from './call-blocks/call-block-form/call-block-form.component';
import { ExtensionListComponent, ButtonViewComponent } from './extensions/extension-list/extension-list.component';
import { ExtensionFormComponent } from './extensions/extension-form/extension-form.component';
import { DeviceListComponent } from './devices/device-list/device-list.component';
import { DeviceFormComponent } from './devices/device-form/device-form.component';
import { DomainListComponent } from './domains/domain-list/domain-list.component';
import { DomainFormComponent } from './domains/domain-form/domain-form.component';
import { CdrListComponent } from './cdrs/cdr-list/cdr-list.component';
import { PstnNumberListComponent } from './pstn-numbers/pstn-number-list/pstn-number-list.component';
import { PstnNumberFormComponent } from './pstn-numbers/pstn-number-form/pstn-number-form.component';
import { GatewayListComponent } from './gateways/gateway-list/gateway-list.component';
import { GatewayFormComponent } from './gateways/gateway-form/gateway-form.component';
import { DialplanListComponent } from './dialplans/dialplan-list/dialplan-list.component';
import { DialplanFormComponent } from './dialplans/dialplan-form/dialplan-form.component';
import { IvrMenuListComponent } from './ivr-menus/ivr-menu-list/ivr-menu-list.component';
import { IvrMenuFormComponent } from './ivr-menus/ivr-menu-form/ivr-menu-form.component';
import { TimeConditionListComponent } from './time-conditions/time-condition-list/time-condition-list.component';
import { TimeConditionFormComponent } from './time-conditions/time-condition-form/time-condition-form.component';
import { CallRouteListComponent } from './call-routes/call-route-list/call-route-list.component';
import { CallRouteFormComponent } from './call-routes/call-route-form/call-route-form.component';
import { CallCenterFormComponent } from './call-centers/call-center-form/call-center-form.component';
import { CallCenterListComponent } from './call-centers/call-center-list/call-center-list.component';
import { CallCenterAgentListComponent } from './call-centers/agents/call-center-agent-list/call-center-agent-list.component';
import { CallCenterAgentFormComponent } from './call-centers/agents/call-center-agent-form/call-center-agent-form.component';
import { RecordingListComponent } from './recordings/recording-list/recording-list.component';
import { RecordingFormComponent } from './recordings/recording-form/recording-form.component';
import { CustomerListComponent } from './customers/customer-list/customer-list.component';
import { CustomerFormComponent } from './customers/customer-form/customer-form.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserFormComponent } from './users/user-form/user-form.component';
import { IvoipDashboardComponent } from './dashboard/ivoip-dashboard.component';
import { IvoipOrdersChartComponent } from './dashboard/charts-panel/charts/orders-chart.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { IvoipRoutingModule } from './ivoip-routing.module';
import { IvoipCdrStatisticsChartsPanelComponent } from './dashboard/charts-panel/cdr-statistics-charts-panel.component';
import { ChartPanelHeaderComponent } from './dashboard/charts-panel/chart-panel-header/chart-panel-header.component';
import { ProfitChartComponent } from './dashboard/charts-panel/charts/profit-chart.component';
import { ECommerceLegendChartComponent } from './dashboard/legend-chart/legend-chart.component';

export const ivoipComponents = [
  IvoipComponent, CdrComponent,
  ExtensionsComponent, DevicesComponent,
  PstnNumbersComponent, CallBlocksComponent,
  PbxListComponent, PbxFormComponent, CallBlockListComponent, CallBlockFormComponent,
  ExtensionListComponent, ExtensionFormComponent,
  ButtonViewComponent,
  DeviceListComponent,
  DeviceFormComponent,
  DomainListComponent,
  DomainFormComponent,
  CdrListComponent,
  PstnNumberListComponent,
  PstnNumberFormComponent,
  GatewayListComponent,
  GatewayFormComponent,
  DialplanListComponent,
  DialplanFormComponent,
  IvrMenuListComponent,
  IvrMenuFormComponent,
  TimeConditionListComponent,
  TimeConditionFormComponent,
  CallRouteListComponent,
  CallRouteFormComponent,
  CallCenterListComponent,
  CallCenterFormComponent,
  CallCenterAgentListComponent,
  CallCenterAgentFormComponent,
  RecordingListComponent,
  RecordingFormComponent,
  CustomerListComponent,
  CustomerFormComponent,
  UserListComponent,
  UserFormComponent,

  IvoipCdrStatisticsChartsPanelComponent,

  IvoipDashboardComponent,
  // StatsCardFrontComponent,
  // StatsAreaChartComponent,
  // StatsBarAnimationChartComponent,
  // ProfitCardComponent,
  // CdrStatisticsChartsPanelComponent,
  ChartPanelHeaderComponent,
  // ChartPanelSummaryComponent,
  // HelpdeskOrdersChartComponent,
  ProfitChartComponent,
  // StatsCardBackComponent,
  // TrafficRevealCardComponent,
  // TrafficBarChartComponent,
  // TrafficFrontCardComponent,
  // TrafficBackCardComponent,
  // TrafficBarComponent,
  // TrafficCardsHeaderComponent,
  // CountryOrdersComponent,
  // CountryOrdersMapComponent,
  // CountryOrdersChartComponent,
  // ECommerceVisitorsAnalyticsComponent,
  // ECommerceVisitorsAnalyticsChartComponent,
  // ECommerceVisitorsStatisticsComponent,
  ECommerceLegendChartComponent,
  // ECommerceUserActivityComponent,
  // ECommerceProgressSectionComponent,
  // SlideOutComponent,
  // EarningCardComponent,
  // EarningCardFrontComponent,
  // EarningCardBackComponent,
  // EarningPieChartComponent,
  // EarningLiveUpdateChartComponent,
  IvoipOrdersChartComponent,
];

@NgModule({
  declarations: ivoipComponents,
  exports: ivoipComponents,
  imports: [
    ...commonModules,
    IvoipRoutingModule,
  ],
  bootstrap: [IvoipComponent],
  providers: [
    ...commonProviders,
  ],
})
export class IvoipModule { }