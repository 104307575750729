import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard.service';
import { PromotionComponent } from './promotion.component';
import { PromotionListComponent } from './promotion/promotion-list/promotion-list.component';
import { PromotionFormComponent } from './promotion/promotion-form/promotion-form.component';

export const promotionRoutes: Routes = [
  {
    path: 'promotion/list',
    canActivate: [AuthGuardService],
    component: PromotionListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'promotion/form',
    canActivate: [AuthGuardService],
    component: PromotionFormComponent,
  },
  {
    path: 'promotion/form/:id',
    canActivate: [AuthGuardService],
    component: PromotionFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(promotionRoutes)],
  exports: [RouterModule],
})
export class PromotionRoutingModule {
}
