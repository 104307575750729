import {Component, OnInit} from '@angular/core';
import {AgGridDataManagerListComponent} from "../../../../lib/data-manager/ag-grid-data-manger-list.component";
import {B2bBasicSalesCommissionConfigModel} from "../../../b2b-center/b2b-center.model";
import {RootServices} from "../../../../services/root.services";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {CommonService} from "../../../../services/common.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {DatePipe} from "@angular/common";
import {AppModule} from "../../../../app.module";
import {agMakeSelectionColDef} from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeTextColDef} from "../../../../lib/custom-element/ag-list/column-define/text.define";
import {AgTextCellRenderer} from "../../../../lib/custom-element/ag-list/cell/text.component";
import {AgDateCellRenderer} from "../../../../lib/custom-element/ag-list/cell/date.component";
import {agMakeStateColDef} from "../../../../lib/custom-element/ag-list/column-define/state.define";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef, IGetRowsParams} from "@ag-grid-community/core";
import {SalesPriceTableQuotationFormComponent} from '../sales-price-table-quotation-form/sales-price-table-quotation-form.component';
import {SalesPriceTableQuotationPrintComponent} from '../sales-price-table-quotation-print/sales-price-table-quotation-print.component';
import {SalesStateMap} from "../../sales.state.map";
import {agMakeDatetimeColDef} from "../../../../lib/custom-element/ag-list/column-define/datetime.define";

@Component({
  selector: 'ngx-sales-price-table-quotation-list',
  templateUrl: './sales-price-table-quotation-list.component.html',
  styleUrls: ['./sales-price-table-quotation-list.component.scss']
})
export class SalesPriceTableQuotationListComponent extends AgGridDataManagerListComponent<B2bBasicSalesCommissionConfigModel, SalesPriceTableQuotationFormComponent> implements OnInit {

  componentName: string = 'PriceTableQuotationListComponent';
  // formPath = '/collaborator/product/form';
  apiPath = '/sales/price-table-quotations';
  idKey: string[] = ['Code'];
  formDialog = SalesPriceTableQuotationFormComponent;
  printDialog = SalesPriceTableQuotationPrintComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;
  // @Input() gridHeight = 'calc(100vh - 230px)';

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<SalesPriceTableQuotationListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    };

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  runningState = {
    ...AppModule.approvedState,
    nextState: 'RUNNING',
    outlilne: true,
    status: 'danger',
    label: 'Đang chạy',
    nextStates: [
      {...AppModule.notJustApprodedState, status: 'warning'},
    ],
  };

  async init() {
    return super.init().then(async state => {

      await this.cms.waitForLanguageLoaded();

      // const processingMap = CollaboratorStateMap.strategyStateMap;

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tiêu đề',
          field: 'Title',
          // pinned: 'left',
          width: 900,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày áp dụng',
          field: 'DateOfApplied',
          width: 150,
          filter: 'agTextColumnFilter',
          pinned: 'right',
          cellRendererParams: {
            format: 'shortDate',
          },
        },
        {
          ...agMakeStateColDef(this.cms, SalesStateMap.priceTableQuotationStateMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, (data) => {
            this.deleteConfirm([data.Code]);
          }, false, []),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    return params;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

}
