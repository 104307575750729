<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.CallRoute.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          <!-- <nav>
            Cập nhật định tuyến cuộc gọi
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá" [disabled]="isProcessing">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại" [disabled]="isProcessing">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()"
              [disabled]="!canUndo || isProcessing" style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về"
              [disabled]="isProcessing">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="extension_uuid">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('extension'), 'warning')">
                  <label class="label"><span class="valid">Số nội bộ</span><span class="invalid">Số nội bộ là bắt
                      buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="extension" placeholder="Số nội bộ (Ext)">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Nhóm</span>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="call_group" placeholder="Nhóm">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"><span class="valid">Mô tả</span>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="description" placeholder="Mô tả">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_all_enabled">
                    <label class="label"><span class="valid">Chuyển tất cả cuộc gọi</span></label>
                  </nb-checkbox>
                  <input type="text" nbInput fullWidth formControlName="forward_all_destination"
                    placeholder="Số chuyển tới">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_busy_enabled">
                    <label class="label"><span class="valid">Chuyển cuộc gọi khi bận</span></label>
                  </nb-checkbox>
                  <input type="text" nbInput fullWidth formControlName="forward_busy_destination"
                    placeholder="Số chuyển tới">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_no_answer_enabled">
                    <label class="label"><span class="valid">Khi không bắt máy</span></label>
                  </nb-checkbox>
                  <input type="text" [tabindex]="i*10 + 8" nbInput fullWidth
                    formControlName="forward_no_answer_destination" placeholder="Số chuyển tới">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <nb-checkbox formControlName="forward_user_not_registered_enabled">
                    <label class="label"><span class="valid">Khi ngoại tuyến</span></label>
                  </nb-checkbox>
                  <input type="text" [tabindex]="i*10 + 8" nbInput fullWidth
                    formControlName="forward_user_not_registered_destination" placeholder="Số chuyển tới">
                </div>
              </div>
            </div>

            <nb-checkbox formControlName="follow_me_enabled">
              <label class="label"><span class="valid">Tính năng Follow me</span></label>
            </nb-checkbox>
            <div class="row">
              <div class="col-sm-1 label">STT</div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-5 label">Số nhận cuộc gọi</div>
                  <div class="col-sm-3 label">Thời gian chờ</div>
                  <div class="col-sm-3 label">Thời gian đỗ chuông</div>
                  <div class="col-sm-1 label">Prompt Confirm</div>
                </div>
              </div>
              <div class="col-sm-1 label" style="text-align: center;">Lệnh</div>
            </div>

            <hr>
            <div formArrayName="follow_me_destinations" class="form-details">
              <div class="form-detail-item" *ngFor="let followMe of getFollowMes(i).controls; let il=index"
                [formGroup]="followMe">
                <input type="hidden" formControlName="follow_me_destination_uuid">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled value="{{ il + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label class="label">Số nhận cuộc gọi (*)</label>
                          <input type="text" nbInput fullWidth formControlName="follow_me_destination"
                            placeholder="Số nhận cuộc gọi: 101/102/09091234456">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Thờ gian chờ (s)</label>
                          <input type="text" nbInput fullWidth formControlName="follow_me_delay" placeholder="Thờ gian chờ ">
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Thời gian đỗ chuông (s)</label>
                          <input type="text" nbInput fullWidth formControlName="follow_me_timeout"
                            placeholder="Thời gian đỗ chuông">
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <label class="label">Kích hoạt</label>
                          <nb-checkbox formControlName="follow_me_prompt"></nb-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">CM</label> -->
                      <button nbButton status="danger" hero size="small" (click)="removeFollowMeGroup(i, il)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-11">
                  <i>Tính năng giúp người dùng có thể sử dụng nhiều số phụ để tăng khả năng trực tuyến, người dùng có
                    thể sử dụng điện thoại bàn cố định tại văn phòng hoặc số nội bộ cài trong mobile app hoặc số di động
                    cá nhân để nhận cuộc gọi.</i>
                </div>
                <div class="col-sm-1">
                  <button nbButton status="success" (click)="addFollowMeFormGroup(i)" size="small"
                    style="width: 100%; margin-bottom: 3px;" hero>
                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero
              [disabled]="isProcessing">Thêm</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" hero>Lưu</button>
            <button nbButton status="danger" [disabled]="!form.valid || this.id.length > 0 || isProcessing"
              (click)="onAutoGenrateExtensionClick(i)" hero>Tạo tự động</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
