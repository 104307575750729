import { agMakeNumberColDef } from '../../../../lib/custom-element/ag-list/column-define/number.define';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { ProductModel, ProductTaxRateTableModel } from '../../../../models/product.model';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { WpSiteModel } from '../../../../models/wordpress.model';
import { AgDynamicListComponent } from '../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { RootServices } from '../../../../services/root.services';
import { TaxRateUpdateNoteFormComponent } from '../../tax-rate-table-update-note/tax-rate-table-update-note-form/tax-rate-table-update-note-form.component';
import { BehaviorSubject } from 'rxjs';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeDatetimeColDef } from '../../../../lib/custom-element/ag-list/column-define/datetime.define';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';

@Component({
  selector: 'ngx-tax-rate-table',
  templateUrl: './tax-rate-table.component.html',
  styleUrls: ['./tax-rate-table.component.scss']
})
export class ProductTaxRateTableComponent extends AgGridDataManagerListComponent<ProductTaxRateTableModel, TaxRateUpdateNoteFormComponent> implements OnInit {

  componentName: string = 'ProductTaxRateTableComponent';
  formPath = '';
  apiPath = '/admin-product/tax-rate/table/details';
  idKey = ['Code', 'Unit'];
  // formDialog = WordpressProductFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';
  refCategoryList = [];
  siteList: WpSiteModel[];
  refCategoriesLoading = false;

  // taxRateTable: ProductTaxRateTableModel;
  taxRateTable$ = new BehaviorSubject<ProductTaxRateTableModel>(null);
  taxRateTableList: ProductTaxRateTableModel[] = [];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<ProductTaxRateTableComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {

      // const processingMap = AppModule.processMaps['purchaseOrder'];
      this.taxRateTableList = await this.apiService.getPromise<ProductTaxRateTableModel[]>('/admin-product/tax-rate/tables', { includeIdText: true });
      if (this.taxRateTableList.length > 0) {
        this.taxRateTable$.next(this.cms.getObjectId(this.taxRateTableList[0]));
      }
      await this.cms.waitForLanguageLoaded();

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          headerName: 'ID',
          field: 'Code',
          pinned: 'left',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Sku',
          field: 'Sku',
          pinned: 'left',
          width: 120,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Sản phẩm',
          field: 'Name',
          width: 400,
          // pinned: 'left',
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'ĐVT',
          field: 'Unit',
          width: 130,
          // filter: 'agTextColumnFilter',
          cellRenderer: AgTextCellRenderer,
          autoHeight: true,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/units', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn ĐVT...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Danh mục',
          field: 'Categories',
          // pinned: 'left',
          width: 350,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/categories', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 250,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/admin-product/groups', { includeIdText: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Cập nhật cuối',
          field: 'LastUpdate',
          width: 180,
          filter: 'agDateColumnFilter',
          pinned: 'right',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Thuế suất',
          field: 'TaxRate',
          pinned: 'right',
          // valueGetter: params => params?.node?.data?.TaxRate || 0,
          cellRendererParams: {
            symbol: '%',
            format: '1.0-2',
          },
          width: 120,
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'historyUpdate',
              // label: 'Lịch sử cập nhật',
              status: 'info',
              outline: false,
              icon: 'external-link-outline',
              appendTo: 'tail',
              action: async (params) => {
                this.cms.openDialog(AgDynamicListComponent, {
                  context: {
                    stateIndex: 'tax-rate-update-history-dialog',
                    title: 'Lịch sử cập nhật thuế suất',
                    width: '90%',
                    height: '95vh',
                    apiPath: '/admin-product/tax-rate/table/entries',
                    idKey: ['Id'],
                    // getRowNodeId: (item) => {
                    //   return item.Product + '-' + item.Unit;
                    // },
                    // rowMultiSelectWithClick: true,
                    // suppressRowClickSelection: false,
                    prepareApiParams: (exParams, getRowParams) => {
                      exParams['taxRateTable'] = this.cms.getObjectId(this.taxRateTable$.value);
                      exParams['eq_Product'] = params.node.data.Code;
                      exParams['eq_Unit'] = this.cms.getObjectId(params.node.data.Unit);
                      // exParams['eq_CustomerGroup'] = this.cms.getObjectId(params.node.data.CustomerGroup);
                      exParams['includeUnit'] = true;
                      exParams['includeProduct'] = true;
                      // exParams['includeRequestBy'] = true;
                      // exParams['includeApprovedBy'] = true;
                      return exParams;
                    },
                    onDialogChoose: (chooseItems) => {

                    },
                    columnDefs: [
                      {
                        ...agMakeSelectionColDef(this.cms),
                        headerName: 'STT',
                        // width: 52,
                        field: 'Id',
                        valueGetter: 'node.data.Id',
                      },
                      {
                        ...agMakeDatetimeColDef(this.cms),
                        headerName: 'Ngày cập nhật',
                        field: 'LastUpdate',
                        pinned: 'left',
                        width: 180,
                        initialSort: 'asc'
                      },
                      {
                        ...agMakeTextColDef(this.cms),
                        headerName: 'Sản phẩm',
                        field: 'Product',
                        // pinned: 'left',
                        width: 400,
                        filter: 'agTextColumnFilter',
                      },
                      {
                        ...agMakeTextColDef(this.cms),
                        headerName: 'ĐVT',
                        field: 'Unit',
                        width: 200,
                      },
                      {
                        ...agMakeTagsColDef(this.cms, (tag) => {
                          this.cms.previewVoucher(tag.type, tag.id);
                        }),
                        headerName: 'Phiếu cập thuế suất',
                        field: 'Note',
                        width: 330,
                        valueGetter: params => params && params.data && params.data.Note ? [{ id: params.data.Note, text: params.data.Note, type: 'TAXRATEUPDATENOTE' }] : [],
                      },
                      {
                        ...agMakeNumberColDef(this.cms),
                        headerName: 'Thuế suất',
                        field: 'TaxRate',
                        pinned: 'right',
                        // valueGetter: params => params?.node?.data?.TaxRate || 0,
                        cellRendererParams: {
                          symbol: '%',
                          format: '1.0-2',
                        },
                        width: 120,
                      },
                      {
                        ...agMakeCommandColDef(this, this.cms, false, false, false, [
                          {
                            name: 'extend',
                            // label: 'In',
                            status: 'danger',
                            outline: false,
                            icon: 'external-link-outline',
                            action: async (params: any) => {
                              this.cms.previewVoucher(null, params.node.data.Note);
                              return true;
                            }
                          }])
                      }
                    ],
                    onInit: (component) => {
                      component.actionButtonList = component.actionButtonList.filter(f => ['close', 'choose', 'preview', 'refresh', 'reset'].indexOf(f.name) > -1);
                    }
                  }
                });
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      this.actionButtonList.unshift({
        type: 'button',
        name: 'createTaxRateUpdateNote',
        status: 'danger',
        label: 'Tạo phiếu cập nhật',
        title: 'Tạo phiếu cập nhật thuế suất',
        size: 'medium',
        icon: 'file-text-outline',
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          this.cms.openDialog(TaxRateUpdateNoteFormComponent, {
            context: {
              data: [
                {
                  Title: 'Cập nhật thuế suất ' + new Date().toUTCString(),
                  Details: this.selectedItems.map(m => ({
                    Images: m.Pictures,
                    Product: { id: m.Code, text: m.Name },
                    Unit: m.Unit as any,
                    Description: m.Name,
                    OldTaxRate: m.TaxRate,
                    TaxRate: m.TaxRate,
                  })) as any
                }
              ],
              onDialogSave: (newData) => {
                if (newData[0].State === 'APPROVED') {
                  this.apiService.putPromise<any>('/admin-product/tax-rate/tables/' + this.cms.getObjectId(this.taxRateTable$.value), { rebuildCache: true }, [{ Code: this.cms.getObjectId(this.taxRateTable$.value) }]).then(rs => {
                    this.cms.showToast('Đã cập nhật cache bảng thuế suất !', 'Cập nhật cache bảng thuế suất thành công', { status: 'success' });
                    this.refresh();
                  });
                }
              },
            }
          });
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'rebuildCache',
        status: 'primary',
        label: 'Cập nhật cache',
        title: 'Bảng giá sử dụng bộ nhớ cache để tăng tốc độ truy vấn, nếu có sai lệch về giá bạn hãy cập nhật lại cache !',
        size: 'medium',
        icon: 'sync-outline',
        disabled: () => !this.taxRateTable$.value,
        click: () => {
          this.cms.showDialog('Cập nhật cache bảng giá', 'Bạn có muốn cập nhật cache cho bảng giá ' + this.cms.getObjectText(this.taxRateTable$.value) + '?\n Bảng giá sử dụng bộ nhớ cache để tăng tốc độ truy vấn, nếu có sai lệch về giá bạn hãy cập nhật lại cache !', [
            {
              label: 'Trở về',
              status: 'basic',
              action: () => {
                return true;
              }
            },
            {
              label: 'Cập nhật',
              status: 'primary',
              action: () => {
                this.apiService.putPromise<any>('/admin-product/tax-rate/tables/' + this.cms.getObjectId(this.taxRateTable$.value), { rebuildCache: true }, [{ Code: this.cms.getObjectId(this.taxRateTable$.value) }]).then(rs => {
                  this.cms.showToast('Đã cập nhật cache bảng giá !', 'Cập nhật cache bảng giá thành công', { status: 'success' });
                  this.refresh();
                });
              }
            },
          ]);
        }
      });

      this.actionButtonList.unshift({
        type: 'select2',
        name: 'masterpriceTable',
        status: 'success',
        label: 'Select page',
        icon: 'plus',
        title: 'Site',
        size: 'medium',
        select2: {
          option: {
            placeholder: 'Chọn bảng thuế suất...',
            allowClear: false,
            width: '100%',
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            keyMap: {
              id: 'id',
              text: 'text',
            },
            data: this.taxRateTableList,
          }
        },
        asyncValue: this.taxRateTable$,
        // value: this.taxRateTable,
        change: async (value: any, option: any) => {
          this.refresh();

        },
        disabled: () => {
          return this.loading;
        },
        click: () => {
          // this.gotoForm();
          return false;
        },
      });

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['taxRateTable'] = this.taxRateTable$.value ? this.cms.getObjectId(this.taxRateTable$.value) : '-1';
    params['includeCategories'] = true;
    params['includeGroups'] = true;
    params['includeUnit'] = true;
    params['includeFeaturePicture'] = true;
    params['sort_Id'] = 'desc';
    // params['group_Unit'] = true;
    params['includeContainers'] = true;
    params['includeNumberOfProducts'] = true;
    // params['sort_Id'] = 'desc';
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: ProductModel[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(MasterPriceTableFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: ProductModel[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }
}
