import { ZaloOaComponent } from './zalo-oa.component';
// import { DialogModule } from '../dialog/dialog.module';
// import { CKEditorModule } from 'ng2-ckeditor';
import { ZaloOaConversationComponent } from './conversation/zalo-oa-conversation/zalo-oa-conversation.component';
import { ZaloOaFollowerListComponent } from './follower/zalo-oa-follower-list/zalo-oa-follower-list.component';
import { ZaloOaFollowerFormComponent } from './follower/zalo-oa-follower-form/zalo-oa-follower-form.component';
import { ZaloOfficialAccountListComponent } from './official-account/zalo-official-account-list/zalo-official-account-list.component';
import { ZaloOfficialAccountFormComponent } from './official-account/zalo-official-account-form/zalo-official-account-form.component';
import { ZaloOaTemplateListComponent } from './template/zalo-oa-template-list/zalo-oa-template-list.component';
import { ZaloOaTemplateFormComponent } from './template/zalo-oa-template-form/zalo-oa-template-form.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { ZaloOaRoutingModule } from './zalo-ao-routing.module';

export const zaloOaComponents = [
  ZaloOaComponent,
  ZaloOaConversationComponent,
  ZaloOaFollowerListComponent,
  ZaloOaFollowerFormComponent,
  ZaloOfficialAccountListComponent,
  ZaloOfficialAccountFormComponent,
  ZaloOaTemplateListComponent,
  ZaloOaTemplateFormComponent,
];

@NgModule({
  declarations: zaloOaComponents,
  // exports: zaloOaComponents,
  imports: [
    ...commonModules,
    ZaloOaRoutingModule,
  ],
  bootstrap: [ZaloOaComponent],
  providers: [
    ...commonProviders,
  ],
})
export class ZaloOaModule { }