<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('ZaloOa.OficialAccount.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="AppId" label="ZaloOa.appId" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="AppId" placeholder="{{'ZaloOa.appId' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Common.code' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="CallbackUrl" label="ZaloOa.callbackUrl" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="CallbackUrl" placeholder="{{'ZaloOa.callbackUrl' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="OaId" label="ZaloOa.oaid" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="OaId" placeholder="{{'ZaloOa.oaid' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ApiUrl" label="ZaloOa.apiUrl" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="{{'ZaloOa.apiUrl' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Forward" label="Common.forward" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Forward" placeholder="{{'Common.forward' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="ForwardUserToken" label="Common.forwardUserToken" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="ForwardUserToken" placeholder="{{'Common.forwardUserToken' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="AppSecret" label="App Secret" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="AppSecret" placeholder="{{'App Secret' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <!-- <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="AppSecret" label="ZaloOa.appSecret" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="AppSecret" placeholder="{{'ZaloOa.appSecret' | translate | headtitlecase}}">
                </ngx-form-group>
              </div> -->
              <div class="col-sm-3">
                <div class="row">
                  <div class="col-sm-6">
                    <ngx-form-group [formGroup]="formItem" name="IsDefault" label="Common.default" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                      <nb-checkbox formControlName="IsDefault" title="{{'Common.default' | translate | headtitlecase}}">{{'Common.default' | translate | headtitlecase}}</nb-checkbox>
                    </ngx-form-group>
                  </div>
                  <div class="col-sm-6">
                    <ngx-form-group [formGroup]="formItem" name="IsEnabled" label="Common.enable" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                      <nb-checkbox formControlName="IsEnabled" title="{{'Common.enable' | translate | headtitlecase}}">{{'Common.enable' | translate | headtitlecase}}</nb-checkbox>
                    </ngx-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>