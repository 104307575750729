<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sms.PhoneNumber.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Danh sách gửi số điện thoại (kéo thả file csv để import)
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton size="tiny" status="danger" (click)="resetSentCount()" style="float: right;">
              Đặt lại trạng thái
            </button>
            <input #uploadButton style="display: none;" type="file" ngFileSelect [options]="options"
              (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code_old">
              <input type="hidden" formControlName="ImportFile">
              <input type="hidden" nbInput fullWidth formControlName="ImportPhoneNumberIndex" placeholder="STT cột số điện thoại : 1->n">
                  <input type="hidden" nbInput fullWidth formControlName="ImportNameIndex" placeholder="STT cột tên : 1->n">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Code'), 'warning')">
                  <label class="label"><span class="valid">Mã</span><span class="invalid">Mã là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="Có thể tạo thủ công">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên danh sách">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">Mô tả</span><span class="invalid">Mô tả là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-12">
                <nb-progress-bar style="margin: 1rem;" *ngFor="let file of files"
                  [value]="file.progress.status == 1 ? file.progress.data.percentage*90/100 : file.progress.data.percentage"
                  [status]="file.progress.status == 1 ? 'danger' : 'success'" size="medium">
                  {{file.progress.data.percentage > 40 ? file.name : ''}} upload
                  {{file.progress.data.percentage < 100 ? (file.progress.data.percentage + '%') : 'complete'}}
                </nb-progress-bar>
              </div>
              <div class="col-sm-12">
                <label class="label"><span class="valid">Danh sách địa chỉ email</span></label>
                <div class="ag-grid-wrap"
                  style="height: 250px; border: #ccc 1px solid; border-radius: 3px; overflow: hidden;">
                  <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham"
                     [gridOptions]="gridOptions" [modules]="modules" [columnDefs]="columnDefs" [floatingFilter]="false" [debug]="true"
                    [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowDeselection]="true"
                    [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize"
                    [cacheOverflowSize]="cacheOverflowSize"
                    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
                    [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache"
                    [cacheBlockSize]="cacheBlockSize" [getRowNodeId]="getRowNodeId" [components]="components"
                    [rowData]="rowData" (gridReady)="onGridReady($event)" [multiSortKey]="multiSortKey"
                    [rowDragManaged]="rowDragManaged" [getRowHeight]="getRowHeight" [rowHeight]="rowHeight"
                    (columnResized)="onColumnResized()" (rowSelected)="onRowSelected()" [pagination]="pagination">
                  </ag-grid-angular>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">Thêm cái
              nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero
              (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
