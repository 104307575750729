import { Component } from '@angular/core';

@Component({
  selector: 'ngx-code-template',
  templateUrl: './code-template.component.html',
  styleUrls: ['./code-template.component.scss']
})
export class CodeTemplateComponent {

}
