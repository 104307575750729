import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TaxComponent} from './tax.component';
import {AuthGuardService} from '../../services/auth-guard.service';
import {InputInvoiceListComponent} from './input-invoice/input-invoice-list/input-invoice-list.component';
import {OutputInvoiceListComponent} from "./output-invoice/output-invoice-list/output-invoice-list.component";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: TaxComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'input-invoice/list',
    canActivate: [AuthGuardService],
    component: InputInvoiceListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'output-invoice/list',
    canActivate: [AuthGuardService],
    component: OutputInvoiceListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaxRoutingModule {
}
