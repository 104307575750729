<!-- <ngx-profit-card style="height: 15%;"></ngx-profit-card> -->


<ngx-quick-ticket-form *ngFor="let quickForm of quickTicketFormList; let i = index;" (onInit)="onQuickFormInit($event)" [phoneNumber]="quickForm.phoneNumber" [index]="quickForm.index" [ticketCode]="quickForm.ticketCode" [inputMode]="'inline'" (onClose)="onQuickFormClose($event)"></ngx-quick-ticket-form>
<nb-card id="helpdeskDashboard" class="small-header helpdesk-dashoard">
    <nb-card-header id="helpdeskHeader">
        <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Helpdesk.Ticket.title' | translate:{action: '', definition: cms.translate.instant('Common.list')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body>
        <div class="m-list" nbInfiniteList listenWindowScroll [threshold]="500" (bottomThreshold)="loadNext(infiniteLoadModel)">
            <div class="row m-list-item state-{{item?.State?.id}}" [class.active]="item.selected" *ngFor="let item of infiniteLoadModel.tickets" [style.color]="item.TicketStateColor" (click)="selectOne($event, item)">
                <div class="col-md-3 m-list-item-property">
                    <div class="caption">Nội dung yêu cầu</div>
                    <div class="value nowrap">
                        <nb-icon icon="ios-paper" pack="ion"></nb-icon> <span class="text">Định danh:
                            {{ item.Code | objectstext }}</span>
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Request_Source">
                        <nb-icon icon="android-exit" pack="ion"></nb-icon> <span class="text">Nguồn thông tin:
                            {{ item.Infos.Request_Source | objectstext }}</span>
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Customer_Groups_Matched">
                        <nb-icon icon="ios-people" pack="ion"></nb-icon> <span class="text">Nhóm khách hàng:
                            {{ item.Infos.Customer_Groups_Matched | objectstext }}</span>
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Zalo_User_Tags">
                        <nb-icon icon="pricetags" pack="ion"></nb-icon> <span class="text">Nhãn zalo:
                            {{ item.Infos.Zalo_User_Tags | objectstext }}</span>
                    </div>
                    <!-- <div class="value" *ngIf="item.Infos?.Description">
            <nb-icon icon="document-text" pack="ion"></nb-icon> Nội dung yêu cầu: {{ item.Infos.Description }}
          </div> -->
                    <div class="value" *ngIf="item?.Description">
                        <nb-icon icon="document-text" pack="ion"></nb-icon> <span class="text">Mô tả: {{ item.Description }}</span>
                    </div>
                    <div class="value" *ngIf="item?.LastMessage">
                        <nb-icon icon="chatbox-working" pack="ion"></nb-icon> <span class="text">Tin nhắn: {{ item.LastMessage }}</span>
                    </div>
                    <div class="caption">Kênh tương tác</div>
                    <div class="value nowrap" *ngIf="item.ZaloOaName">
                        <nb-icon icon="chevron-right" pack="ion"></nb-icon>Zalo OA:&nbsp;<a href="#" (click)="openChatRoom(item.ChatRoom)" class="text {{item.ZaloConnectStatus ? 'connected' : ''}}">{{ item.ZaloOaName }}</a>
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Pbx_Request_Caller_Destination">
                        <nb-icon icon="chevron-right" pack="ion"></nb-icon>Voice:&nbsp;<span href="#" class="text connected">{{ item?.Infos?.Pbx_Request_Caller_Destination }}</span>
                    </div>
                </div>
                <div class="col-md-2 m-list-item-property">
                    <div class="caption">Người yêu cầu</div>
                    <div class="value nowrap" *ngIf="!!item.ObjectName">
                        <nb-icon icon="person" pack="ion"></nb-icon> {{ item.ObjectName }}
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="ios-telephone" pack="ion"></nb-icon>
                        <span *ngIf="!!item.ObjectPhone">
                            {{ item.ObjectPhone }} (<a style="cursor: pointer;" href="#" (click)="phoneCall(item, item.ObjectPhone, item.ObjectName)">call</a>)
                        </span>
                        <span *ngIf="!item.ObjectPhone">Chưa có số điện thoại</span>
                    </div>
                    <div class="value nowrap" *ngIf="!!item.ObjectEmail">
                        <nb-icon icon="android-mail" pack="ion"></nb-icon> {{ item.ObjectEmail }}
                    </div>
                </div>
                <div class="col-md-2 m-list-item-property">
                    <div class="caption">Nhân viên/Nhóm tương tác</div>
                    <div class="value nowrap" *ngIf="item.Infos?.User_Group">
                        <nb-icon icon="android-contacts" pack="ion"></nb-icon> <span class="text">Nhóm: {{ item.Infos?.User_Group | objecttext }}</span>
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Accepted_User">
                        <nb-icon icon="android-contact" pack="ion"></nb-icon> Tiếp nhận bởi: {{ item.Infos?.Accepted_User | objecttext }}
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Completed_User">
                        <nb-icon icon="checkmark-circled" pack="ion"></nb-icon> Hoàn tất bởi: {{ item.Infos?.Completed_User | objecttext }}
                    </div>
                    <div class="value nowrap" *ngIf="item.Infos?.Cancelled_User">
                        <nb-icon icon="close-circled" pack="ion"></nb-icon> Hủy bởi: {{ item.Infos?.Cancelled_User | objecttext }}
                    </div>
                </div>
                <div class="col-md-3 m-list-item-property">
                    <div class="caption">Qui trình</div>
                    <div class="value" *ngIf="item.Procedure">
                        <span>{{item.ProcedureName}}</span>
                        <!-- <nb-icon icon="document-text" pack="ion"></nb-icon> {{ item.Infos?.Helpdesk_Procedure | objecttext }} -->
                        <!-- <ul style="list-style: decimal; padding-left: 1rem;" *ngIf="item.Procedure">
                            <li *ngFor="let step of item.Infos?.Helpdesk_Procedure?.Steps">{{step.Description}}</li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-md-2 m-list-item-property">
                    <div class="caption">Trạng thái</div>
                    <div class="value nowrap state" *ngIf="!!item?.State['Code']">
                        <nb-icon [icon]="item.State['Icon'].split('.')[1]" [pack]="item.State['Icon'].split('.')[0]"></nb-icon>
                        {{ item.State['Name'] }} (<a href="#" (click)="previewState(item)">{{ (item.State['id'] === 'OPEN' ? 'Helpdesk.accept' : 'Common.change') | translate | headtitlecase}}</a>)
                    </div>
                    <div class="value" *ngIf="!!item.DateOfCreate">
                        <nb-icon icon="ios-time" pack="ion"></nb-icon> {{ item.DateOfCreate }}
                    </div>
                    <div class="value state">
                        <nb-icon icon="chatbubbles" pack="ion"></nb-icon> <a href="#" (click)="openChatRoom(item.ChatRoom)">Phòng
                            chat</a>
                    </div>
                    <div class="value state">
                        <nb-icon icon="android-create" pack="ion"></nb-icon> <a href="#" (click)="editItem(item.Code)">Chỉnh sửa</a>
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="share" pack="ion"></nb-icon>
                        <a href="#" (click)="openShareDialog(item)">{{ 'Common.share' | translate | headtitlecase }}</a>
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="ios-telephone" pack="ion"></nb-icon>
                        <a href="#" (click)="phoneCall(item)">{{ 'Gọi lại' }}</a>
                    </div>
                </div>
            </div>

            <div class="row m-list-item ghost" *ngFor="let _ of infiniteLoadModel.placeholders">
                <div class="col-md-3 m-list-item-property">
                    <div class="caption">Nội dung yêu cầu</div>
                    <div class="value">
                        <nb-icon icon="document-text" pack="ion"></nb-icon> Mô tả...
                    </div>
                    <div class="value">
                        <nb-icon icon="chatbox-working" pack="ion"></nb-icon> Tin nhắn...
                    </div>
                </div>
                <div class="col-md-2 m-list-item-property">
                    <div class="caption">Người yêu cầu</div>
                    <div class="value nowrap">
                        <nb-icon icon="person" pack="ion"></nb-icon> Tên...
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="ios-telephone" pack="ion"></nb-icon> Số điện thoại...
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="email" pack="ion"></nb-icon> Email...
                    </div>
                </div>
                <div class="col-md-2 m-list-item-property">
                    <div class="caption">Nhân viên/Nhóm tương tác</div>
                    <div class="value nowrap">
                        <nb-icon icon="text" pack="ion"></nb-icon> ...
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="text" pack="ion"></nb-icon> ...
                    </div>
                    <div class="value nowrap">
                        <nb-icon icon="text" pack="ion"></nb-icon> ...
                    </div>
                </div>
                <div class="col-md-3 m-list-item-property">
                    <div class="caption">Quy trình tiếp nhận</div>
                    <ul style="list-style: decimal; padding-left: 1rem;">
                        <li>Bước 1...</li>
                        <li>Bước 2...</li>
                        <li>Bước 3...</li>
                    </ul>
                </div>
                <div class="col-md-2 m-list-item-property">
                    <div class="caption">Trạng thái</div>
                    <div class="value nowrap">
                        <nb-icon icon="radio-waves" pack="ion"></nb-icon> Trạng thái...
                    </div>
                    <div class="value">
                        <nb-icon icon="chatbubbles" pack="ion"></nb-icon> Phòng chat...
                    </div>
                    <div class="value">
                        <nb-icon icon="android-create" pack="ion"></nb-icon> Chỉnh sửa...
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>