<nb-card class="small-header ag-grid-card" style="height: 100%;">
  <nb-card-header>
    <nav>
      <nb-icon pack="eva" icon="list-outline"></nb-icon> Danh sách ticket
      <div class="action-list">
        <button *ngFor="let actionBtn of actionButtonList" nbButton [status]="actionBtn.status" hero
          [size]="actionBtn.size" [disabled]="actionBtn.disabled()" [title]="actionBtn.title"
          (click)="actionBtn.click()">
          <nb-icon pack="eva" [icon]="actionBtn.icon"></nb-icon><span
            *ngIf="!!actionBtn.label">{{actionBtn.label}}</span>
        </button>
      </div>
    </nav>
  </nb-card-header>

  <nb-card-body>
    <div class="ag-grid-wrap">
      <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham"
         [gridOptions]="gridOptions" [modules]="modules" [columnDefs]="columnDefs" [debug]="true" [enableCellTextSelection]="enableCellTextSelection"
        [defaultColDef]="defaultColDef" [rowSelection]="rowSelection"
        [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize"
        [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
        [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache" [cacheBlockSize]="cacheBlockSize"
        [getRowNodeId]="getRowNodeId" [components]="components" [rowData]="rowData" (gridReady)="onGridReady($event)"
        [multiSortKey]="multiSortKey" [rowDragManaged]="rowDragManaged" [getRowHeight]="getRowHeight"
        [rowHeight]="rowHeight" (columnResized)="onColumnResized()" (rowSelected)="onRowSelected()" [pagination]="pagination">
      </ag-grid-angular>
    </div>
  </nb-card-body>
</nb-card>
