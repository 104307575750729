import { ShortLinkComponent } from './short-link.component';
import { ShortLinkListComponent } from './short-link/short-link-list/short-link-list.component';
import { ShortLinkFormComponent } from './short-link/short-link-form/short-link-form.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { ShortLinkRoutingModule } from './ads-routing.module';

export const shortLinkComponents = [
  ShortLinkComponent,
  ShortLinkListComponent,
  ShortLinkFormComponent,
];

@NgModule({
  declarations: shortLinkComponents,
  // exports: shortLinkComponents,
  imports: [
    ...commonModules,
    ShortLinkRoutingModule,
  ],
  bootstrap: [ShortLinkComponent],
  providers: [
    ...commonProviders,
  ],
})
export class ShortLinkModule { }