import { Model } from "./model";

export class WhWebsiteModel extends Model {

  domain_id?: string;
  hosting?: string;
  sys_userid?: string;
  sys_groupid?: string;
  sys_perm_user?: string;
  sys_perm_group?: string;
  sys_perm_other?: string;
  server_id?: string;
  ip_address?: string;
  ipv6_address?: string;
  domain?: string;
  type?: string;
  parent_domain_id?: string;
  vhost_type?: string;
  document_root?: string;
  web_folder?: string;
  system_user?: string;
  system_group?: string;
  hd_quota?: string;
  traffic_quota?: string;
  cgi?: string;
  ssi?: string;
  suexec?: string;
  errordocs?: string;
  is_subdomainwww?: string;
  subdomain?: string;
  php?: string;
  ruby?: string;
  python?: string;
  perl?: string;
  redirect_type?: string;
  redirect_path?: string;
  seo_redirect?: string;
  rewrite_to_https?: string;
  ssl?: string;
  ssl_letsencrypt?: string;
  ssl_letsencrypt_exclude?: string;
  ssl_state?: string;
  ssl_locality?: string;
  ssl_organisation?: string;
  ssl_organisation_unit?: string;
  ssl_country?: string;
  ssl_domain?: string;
  ssl_request?: string;
  ssl_cert?: string;
  ssl_bundle?: string;
  ssl_key?: string;
  ssl_action?: string;
  stats_password?: string;
  stats_type?: string;
  allow_override?: string;
  apache_directives?: string;
  nginx_directives?: string;
  php_fpm_use_socket?: string;
  php_fpm_chroot?: string;
  pm?: string;
  pm_max_children?: string;
  pm_start_servers?: string;
  pm_min_spare_servers?: string;
  pm_max_spare_servers?: string;
  pm_process_idle_timeout?: string;
  pm_max_requests?: string;
  php_open_basedir?: string;
  custom_php_ini?: string;
  backup_interval?: string;
  backup_copies?: string;
  backup_excludes?: string;
  active?: string;
  traffic_quota_lock?: string;
  fastcgi_php_version?: string;
  proxy_directives?: string;
  enable_spdy?: string;
  last_quota_notification?: string;
  rewrite_rules?: string;
  added_date?: string;
  added_by?: string;
  directive_snippets_id?: string;
  enable_pagespeed?: boolean;
  http_port?: string;
  https_port?: string;
  folder_directive_snippets?: string;
  log_retention?: string;

}
