import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { CommercePosDashboardComponent } from "./commerce-pos-dashboard/commerce-pos-dashboard.component";
import { CommercePosOrderFormComponent } from "./commerce-pos-order/commerce-pos-order-form/commerce-pos-order-form.component";
import { CommercePosOrderListComponent } from "./commerce-pos-order/commerce-pos-order-list/commerce-pos-order-list.component";
import { CommercePosOrderPrintComponent } from "./commerce-pos-order/commerce-pos-order-print/commerce-pos-order-print.component";
import { CommercePosReturnFormComponent } from "./commerce-pos-return/commerce-pos-return-form/commerce-pos-return-form.component";
import { CommercePosReturnListComponent } from "./commerce-pos-return/commerce-pos-return-list/commerce-pos-return-list.component";
import { CommercePosReturnPrintComponent } from "./commerce-pos-return/commerce-pos-return-print/commerce-pos-return-print.component";
import { CommercePosRoutingModule } from "./commerce-pos-routing.module";
import { CommercePosComponent } from "./commerce-pos.component";
import { CommercePosDeploymentVoucherPrintComponent } from "./gui/commerce-pos-deployment-voucher-print/commerce-pos-deployment-voucher-print.component";
import { CommercePosChooseProductComponent } from "./gui/commerce-pos-gui/choose-product/commerce-pos-choose-product.component";
import { CommercePosGuiComponent } from "./gui/commerce-pos-gui/commerce-pos-gui.component";
import { CommercePosBillPrintComponent } from "./gui/commerce-pos-order-print/commerce-pos-bill-print.component";
import { CommercePosPaymnentPrintComponent } from "./gui/commerce-pos-payment-print/commerce-pos-payment-print.component";
import { CommercePosReturnsPrintComponent } from "./gui/commerce-pos-returns-print/commerce-pos-returns-print.component";


export const commercePosComponents = [
    CommercePosComponent,
    CommercePosGuiComponent,
    CommercePosBillPrintComponent,
    CommercePosReturnsPrintComponent,
    CommercePosPaymnentPrintComponent,
    CommercePosOrderFormComponent,
    CommercePosOrderListComponent,
    CommercePosOrderPrintComponent,
    CommercePosReturnFormComponent,
    CommercePosReturnListComponent,
    CommercePosReturnPrintComponent,
    CommercePosDashboardComponent,
    CommercePosDeploymentVoucherPrintComponent,
    CommercePosChooseProductComponent,
];

@NgModule({
    declarations: commercePosComponents,
    exports: commercePosComponents,
    imports: [
        ...commonModules,
        CommercePosRoutingModule,
    ],
    bootstrap: [CommercePosComponent],
    providers: [
        ...commonProviders,
    ],
})
export class CommercePosModule { }