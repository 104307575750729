import { NetworkComponent } from './network.component';
import { ProxyListComponent } from './proxy/proxy-list/proxy-list.component';
import { ProxyFormComponent } from './proxy/proxy-form/proxy-form.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { NetworkRoutingModule } from './network-routing.module';

export const networkComponents = [
  NetworkComponent,
  ProxyListComponent,
  ProxyFormComponent
];

@NgModule({
  declarations: networkComponents,
  // exports: networkComponents,
  imports: [
    ...commonModules,
    NetworkRoutingModule,
  ],
  bootstrap: [NetworkComponent],
  providers: [
    ...commonProviders,
  ],
})
export class NetworkModule { }