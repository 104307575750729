import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from '../../../../services/common.service';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, AbstractControl, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { BaseComponent } from '../../../base-component';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Model } from '../../../../models/model';

/**
this.cms.openDialog(DialogSortingFormComponent, {
  context: {
    title: 'Cập nhật phiên bản phát hành',
    controls: [
      {
        name: 'Version',
        label: 'Phiên bản sẽ được cập nhật',
        initValue: this.moduleSettings['MINIERP_RELEASE_VERSION'],
        placeholder: 'Phiên bản sẽ được cập nhật tự động',
        type: 'text',
      },
    ],
    actions: [
      {
        label: 'Trở về',
        icon: 'back',
        status: 'info',
        action: () => { },
      },
      {
        label: 'Cập nhật',
        icon: 'generate',
        status: 'success',
        action: (form: FormGroup) => {
          this.apiService.putPromise(this.apiPath + '/settings', {}, [{
            Name: 'MINIERP_RELEASE_VERSION',
            Value: form.value['Version'],
          }]).then(rs => {
            this.refresh();
          });

        },
      },
    ],
  },
});
 */

export interface DialogFormControl {
  name: string,
  type?: string,
  dataList?: { id: string, text: string }[],
  label: string,
  placeholder: string,
  initValue?: any;
  disabled?: boolean,
  focus?: boolean,
  option?: any,
  value?: any,
  class?: string,
  validators?: any[];
}
export interface DialogFormAction {
  label: string,
  icon?: string,
  status?: string,
  keyShortcut?: string,
  disabled?: (actionParamrs: DialogFormAction, form: FormGroup, dialog?: DialogSortingFormComponent) => boolean,
  action?: (form: FormGroup, dialog?: DialogSortingFormComponent) => Promise<boolean>
}
@Component({
  selector: 'ngx-sorting-form',
  templateUrl: './sorting-form.component.html',
  styleUrls: ['./sorting-form.component.scss'],
})
export class DialogSortingFormComponent extends BaseComponent implements OnInit, AfterViewInit {

  componentName: string = 'DialogFormComponent';

  @Input() title: string;
  @Input() cardStyle?: any;
  @Input() width?: string;

  @Input() controls: DialogFormControl[];
  @Input() actions: DialogFormAction[];
  @Input() onInit: (form: FormGroup, dialog: DialogSortingFormComponent) => Promise<boolean>;
  @Input() onClose: (form: FormGroup, dialog: DialogSortingFormComponent) => Promise<boolean>;
  @ViewChild('formEle', { static: true }) formEle: ElementRef;
  // @Input() onKeyboardEvent?: (event: KeyboardEvent, component: DialogFormComponent) => void;

  public destroy$: Subject<void> = new Subject<void>();
  curencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0, allowNegative: true };
  numberFormat = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });
  formGroup: FormGroup;
  processing = false;
  processingProcess = null;

  inited = new BehaviorSubject<boolean>(false);

  errorMap: { [key: string]: string } = {
    required: 'bắt buộc',
  };

  @Input() data: Model[] = [];

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public formBuilder: FormBuilder,
    public ref: NbDialogRef<DialogSortingFormComponent>,
  ) {
    super(rsv, cms, router, apiService, ref);
    this.formGroup = this.formBuilder.group<any>({
      array: this.formBuilder.array([
        // this.makeNewFormGroup(),
      ]),
    });

    if (this.actions) {
      this.actions.forEach(element => {
        if (!element.action) {
          element.action = () => {
            return Promise.resolve(true);
          };
        }
        if (!element.status) {
          element.status = 'info';
        }
      });
    }
  }

  startProcessing(timeout?: number) {
    this.processing = true;
    if (this.processingProcess) {
      clearTimeout(this.processingProcess);
    }
    this.processingProcess = setTimeout(() => {
      this.processing = false;
    }, timeout || 10000);
  }

  stopProcessing() {
    if (this.processingProcess) {
      clearTimeout(this.processingProcess);
    }
    this.processing = false;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.inited.pipe(filter(f => f), take(1)).toPromise().then(rs => {
      // this.controls.forEach(control => {
      //   if (control?.focus) {
      //     const formcontrol = $(this.formEle.nativeElement).find(`[name=${control.name}]`)
      //     formcontrol[0].focus();
      //     formcontrol.select();
      //   }
      // });
      console.log(this.data);

      for (const i in this.data) {
        const newFormGroup = this.makeNewFormGroup({ ...this.data[i], no: parseInt(i) + 1 });
        this.array.push(newFormGroup);
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    // const fcs: { [key: string]: AbstractControl } = {};
    // this.controls.forEach(control => {
    //   fcs[control.name] = new FormControl(control.initValue, control.validators);
    //   if (control.disabled) {
    //     fcs[control.name].disable();
    //   }
    // });
    // this.formGroup = new FormGroup(fcs);
    if (this.onInit) {
      this.processing = true;
      this.onInit(this.formGroup, this).then(rs => {
        this.processing = false;
        this.inited.next(true);
      });
    } else {
      this.inited.next(true);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onKeyboardEvent(event: KeyboardEvent, component?: BaseComponent) {
    if (this.cms.dialogStack[this.cms.dialogStack.length - 1] === this.ref) {
      if (!this.processing) {
        const action = this.actions.find(f => f.keyShortcut == event.key);
        if (action) {
          if (action.action(this.formGroup, this)) {
            this.dismiss();
          }
          return false;
        }
      }
    }
    return super.onKeyboardEvent(event, component);
  }

  onAction(item: DialogFormAction, form: FormGroup) {
    return item?.action(form, this);
  }

  checkButtonDisabled(actionParams: DialogFormAction, form: FormGroup) {
    if (actionParams?.disabled) {
      return actionParams?.disabled(actionParams, form, this);
    }
    return false;
  }

  dismiss() {
    this.ref.close();
    return false;
  }

  get array() {
    return this.formGroup.get('array') as FormArray;
  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group<any>({
      no: [null],
      id: [null],
      text: [null],
    });
    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }
}
