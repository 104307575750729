import { Model } from "./model";

export class EmployeeModel extends Model {
  Code: string;
  Name: string;
  Phone: string;
  Email: string;
  Address: string;

}
