import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { AccManagerRoutingModule } from "./acc-manager-routing.module";
import { AccManagerComponent } from "./acc-manager.component";
import { AccManagerAccountFormComponent } from "./account/acc-manager-account-form/acc-manager-account-form.component";
import { AccManagerAccountListComponent } from "./account/acc-manager-account-list/acc-manager-account-list.component";
import { AccManagerAccountPrintComponent } from "./account/acc-manager-account-print/acc-manager-account-print.component";
import { AccManagerImportFbAccountFormComponent } from "./account/acc-manager-import-fb-account-form/acc-manager-import-fb-account-form.component";

export const accManagerComponents = [
    AccManagerComponent,
    AccManagerAccountListComponent,
    AccManagerAccountFormComponent,
    AccManagerAccountPrintComponent,
    AccManagerImportFbAccountFormComponent,
];

@NgModule({
    declarations: accManagerComponents,
    // exports: accManagerComponents,
    imports: [
        ...commonModules,
        AccManagerRoutingModule,
    ],
    bootstrap: [AccManagerComponent],
    providers: [
        ...commonProviders,
    ],
})
export class AccMangerModule { }
