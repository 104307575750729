import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { CoreConnectionFormComponent } from "./core-connection-form/core-connection-form.component";
import { CoreConnectionListComponent } from "./core-connection-list/core-connection-list.component";
import { CoreConnectionRoutingModule } from "./core-connection-routing.module";
import { CoreConnectionComponent } from "./core-connection.component";

export const coreConnectionComponents = [
    CoreConnectionComponent,
    CoreConnectionListComponent,
    CoreConnectionFormComponent,
];

@NgModule({
    declarations: coreConnectionComponents,
    exports: coreConnectionComponents,
    imports: [
        ...commonModules,
        CoreConnectionRoutingModule,
    ],
    bootstrap: [CoreConnectionComponent],
    providers: [
        ...commonProviders,
    ],
})
export class CoreConnectionModule { }