import { Component } from '@angular/core';

@Component({
  selector: 'ngx-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent {

}
