import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-email-marketing-dashboard',
  templateUrl: './email-marketing-dashboard.component.html',
  styleUrls: ['./email-marketing-dashboard.component.scss'],
})
export class EmailMarketingDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
