import { NgModule } from '@angular/core';
import { NotificationComponent } from './notification.component';
import { commonModules, commonProviders } from '../../common-modules';
import { DeploymentComponent } from '../deployment/deployment.component';
import { NotificationRoutingModule } from './notification-routing.module';

export const notificationComponents = [
  NotificationComponent
];

@NgModule({
  declarations: notificationComponents,
  // exports: notificationComponents,
  imports: [
    ...commonModules,
    NotificationRoutingModule,
  ],
  bootstrap: [DeploymentComponent],
  providers: [
    ...commonProviders,
  ],
})
export class NotificationModule { }