<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Device.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật thông tin thết bị voip
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="device_uuid">
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('device_mac_address'), 'warning')">
                  <label class="label"><span class="valid">Mã mac (*)</span><span class="invalid">Mã mac là bắt
                      buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="device_mac_address"
                    placeholder="Mã mac">
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label class="label"><span class="valid">Mô tả</span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'device_description')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <input type="text" nbInput fullWidth formControlName="device_description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="label"><span class="valid">Extension</span></label>
                  <ngx-select2 [data]="extensionList" formControlName="extension"
                    [select2Option]="extensionListConfig" (selectChange)="onExtensionChange($event, i)">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Nhãn</span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'device_label')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <input type="text" nbInput fullWidth formControlName="device_label" placeholder="Nhãn">
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('domain_uuid'), 'warning')">
                  <label class="label"><span class="valid">Domain</span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'domain_uuid')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <ngx-select2 [data]="privateDmainList" formControlName="domain_uuid"
                    [select2Option]="privateDmainListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('device_template'), 'warning')">
                  <label class="label"><span class="valid">Template</span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'device_template')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <ngx-select2 [data]="templateList" formControlName="device_template"
                    [select2Option]="templateListConfig" (selectChange)="onVendorTemplateChange($event, i)">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Kiểu</span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'device_model')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <input type="text" nbInput fullWidth formControlName="device_model"
                    placeholder="Kiểu">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Nhà sản xuất</span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'device_vendor')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <input type="text" nbInput fullWidth formControlName="device_vendor"
                    placeholder="Nhà sản xuất">
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('device_enabled'), 'warning')">
                  <label class="label"><span class="valid">Kích hoạt (*)</span><span class="invalid"></span> <nb-icon (click)="copyFormControlValueToOthers(array, i, 'device_enabled')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <nb-checkbox formControlName="device_enabled">Kích hoạt</nb-checkbox>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <button nbButton status="success" [disabled]="!form.valid || submiting" hero>Lưu</button>
            <!-- <button nbButton status="danger" [disabled]="!form.valid || this.id.length > 0 || submiting" (click)="onAutoGenrateExtensionClick(i)" hero>Tạo tự động</button> -->
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
