import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { AppModule } from '../../../../app.module';
import { CollaboratorEducationArticleFormComponent } from '../education-article-form/collaborator-education-article-form.component';
import { CollaboratorEducationArticlePrintComponent } from '../education-article-print/collaborator-education-article-print.component';
import { CollaboratorEducationArticleModel } from '../../../../models/collaborator.model';
import { CollaboratorService } from '../../collaborator.service';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeDatetimeColDef } from '../../../../lib/custom-element/ag-list/column-define/datetime.define';
import { agMakeCheckboxColDef } from '../../../../lib/custom-element/ag-list/column-define/checkbox.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { PageModel } from '../../../../models/page.model';

@Component({
  selector: 'ngx-collaborator-education-article-list',
  templateUrl: './collaborator-education-article-list.component.html',
  styleUrls: ['./collaborator-education-article-list.component.scss'],
})
export class CollaboratorEducationArticleListComponent extends AgGridDataManagerListComponent<CollaboratorEducationArticleModel, CollaboratorEducationArticleFormComponent> implements OnInit {

  componentName: string = 'CollaboratorEducationArticleListComponent';
  formPath = '/collaborator/education-article/form';
  apiPath = '/collaborator/education-articles';
  idKey = ['Code', 'Page'];

  formDialog = CollaboratorEducationArticleFormComponent;
  printDialog = CollaboratorEducationArticlePrintComponent;
  reuseDialog = true;
  static _dialog: NbDialogRef<CollaboratorEducationArticleListComponent>;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public collaboratorService: CollaboratorService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorEducationArticleListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init() {
    return super.init().then(async status => {
      // Add page choosed
      this.collaboratorService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'pbxdomain',
          status: 'success',
          label: 'Select page',
          icon: 'plus',
          title: this.cms.textTransform(this.cms.translate.instant('Collaborator.Page.title', { action: this.cms.translateText('Common.choose'), definition: '' }), 'head-title'),
          size: 'medium',
          select2: {
            data: pageList,
            option: {
              placeholder: 'Chọn trang...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.collaboratorService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            // this.gotoForm();
            return false;
          },
        });
      });

      const processingMap = AppModule.processMaps.collaboratorEdutcationArticle;
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mã',
          field: 'Code',
          width: 150,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 300,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên sản phẩm',
          field: 'ProductName',
          width: 300,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Link',
          field: 'Link',
          width: 200,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Người tạo',
          field: 'Creator',
          width: 300,
        },
        {
          ...agMakeDatetimeColDef(this.cms),
          headerName: 'Ngày tạo',
          field: 'DateOfCreated',
          width: 300,
        },
        {
          ...agMakeCheckboxColDef(this, this.cms, (params, checked) => {
            this.apiService.putPromise<CollaboratorEducationArticleModel[]>(`${this.apiPath}/${params.data.Code}`, {}, [{ Code: params.data.Code, IsSync: checked }]).then(rs => {
              console.info(rs);
            });
          }),
          headerName: 'Đồng bộ/Thu hồi',
          field: 'IsSync',
          pinned: 'right',
          width: 300,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            // this.preview([data]);
            // if (this.cms.getObjectId(data.State) == 'PROCESSING') {
            //   this.openForm([data.Code]);
            // } else {
            this.preview([data]);
            // }
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            {
              name: 'copy',
              label: 'Đầu kỳ',
              status: 'primary',
              icon: 'lock-outline',
              outline: false,
              action: async (params, btnConfig) => {
                this.cms.openDialog(CollaboratorEducationArticleFormComponent, {
                  context: {
                    inputMode: 'dialog',
                    inputId: [params?.data?.Code],
                    isDuplicate: true,
                    onDialogSave: (newData: CollaboratorEducationArticleModel[]) => {
                    },
                    onDialogClose: () => {
                      this.refresh();
                    },
                  },
                });
                return true;
              }
            },
          ]),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return status;
    });
  }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: CollaboratorEducationArticleModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: CollaboratorEducationArticleModel[] | HttpErrorResponse) => void) {
  //   params['useBaseTimezone'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  // editing = {};
  // rows = [];

  // loadListSetting(): any {
  //   return this.configSetting({
  //     mode: 'external',
  //     selectMode: 'multi',
  //     actions: this.isChoosedMode ? false : {
  //       position: 'right',
  //     },
  //     add: this.configAddButton(),
  //     edit: this.configEditButton(),
  //     delete: this.configDeleteButton(),
  //     pager: this.configPaging(),
  //     columns: {
  //       No: {
  //         title: 'No.',
  //         type: 'string',
  //         width: '1%',
  //         filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Code: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.code'), 'head-title'),
  //         type: 'string',
  //         width: '5%',
  //       },
  //       Title: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.title'), 'head-title'),
  //         type: 'string',
  //         width: '50%',
  //       },
  //       ProductName: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Sản phẩm liên quan'), 'head-title'),
  //         type: 'string',
  //         width: '50%',
  //       },
  //       Creator: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.creator'), 'head-title'),
  //         type: 'string',
  //         width: '10%',
  //         valuePrepareFunction: (cell: string, row?: any) => {
  //           return this.cms.getObjectText(cell);
  //         },
  //       },
  //       DateOfCreated: {
  //         title: this.cms.textTransform(this.cms.translate.instant('Common.dateOfCreated'), 'head-title'),
  //         type: 'custom',
  //         width: '15%',
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableDateRangeFilterComponent,
  //         },
  //         renderComponent: SmartTableDateTimeComponent,
  //         onComponentInitFunction: (instance: SmartTableDateTimeComponent) => {
  //           // instance.format$.next('medium');
  //         },
  //       },
  //       // IsNewFeed: {
  //       //   title: 'Lên trang chủ',
  //       //   type: 'boolean',
  //       //   editable: true,
  //       //   width: '5%',
  //       //   onChange: (value, rowData: CollaboratorEducationArticleModel) => {
  //       //     // rowData.AutoUpdate = value;
  //       //     this.apiService.putPromise<CollaboratorEducationArticleModel[]>(`${this.apiPath}/${rowData.Code}`, {}, [{ Code: rowData.Code, IsNewFeed: value }]).then(rs => {
  //       //       console.info(rs);
  //       //     });
  //       //   },
  //       // },
  //       IsSync: {
  //         title: 'Đồng bộ/thu hồi',
  //         type: 'boolean',
  //         editable: true,
  //         width: '5%',
  //         onChange: (value, rowData: CollaboratorEducationArticleModel) => {
  //           // rowData.AutoUpdate = value;
  //           this.apiService.putPromise<CollaboratorEducationArticleModel[]>(`${this.apiPath}/${rowData.Code}`, {}, [{ Code: rowData.Code, IsSync: value }]).then(rs => {
  //             console.info(rs);
  //           });
  //         },
  //       },
  //       // Link: {
  //       //   title: 'Link',
  //       //   type: 'custom',
  //       //   width: '5%',
  //       //   exclude: this.isChoosedMode,
  //       //   renderComponent: SmartTableButtonComponent,
  //       //   onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //       //     instance.iconPack = 'eva';
  //       //     instance.icon = 'link-2-outline';
  //       //     // instance.label = this.cms.translateText('Common.copy');
  //       //     instance.display = true;
  //       //     instance.status = 'primary';
  //       //     instance.init.pipe(takeUntil(this.destroy$)).subscribe((row: CollaboratorEducationArticleModel) => {
  //       //       const link = `https://probox.vn/${(row.Page && row.Page.id || row.Page).toLowerCase()}/ctvbanhang/edu/${row?.Code?.toLowerCase()}`;
  //       //       instance.title = link;
  //       //     });
  //       //     instance.click.pipe(takeUntil(this.destroy$)).subscribe(async (row: CollaboratorEducationArticleModel) => {
  //       //       const link = `https://probox.vn/${(row.Page && row.Page.id || row.Page).toLowerCase()}/ctvbanhang/edu/${row?.Code?.toLowerCase()}`;
  //       //       this.cms.copyTextToClipboard(link);
  //       //     });
  //       //   },
  //       // },
  //       Copy: {
  //         title: 'Copy',
  //         type: 'custom',
  //         width: '5%',
  //         exclude: this.isChoosedMode,
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'copy';
  //           // instance.label = this.cms.translateText('Common.copy');
  //           instance.display = true;
  //           instance.status = 'warning';
  //           instance.valueChange.subscribe(value => {
  //             // if (value) {
  //             //   instance.disabled = false;
  //             // } else {
  //             //   instance.disabled = true;
  //             // }
  //           });
  //           instance.click.subscribe(async (row: CollaboratorEducationArticleModel) => {

  //             this.cms.openDialog(CollaboratorEducationArticleFormComponent, {
  //               context: {
  //                 inputMode: 'dialog',
  //                 inputId: [row.Code],
  //                 isDuplicate: true,
  //                 onDialogSave: (newData: CollaboratorEducationArticleModel[]) => {
  //                   // if (onDialogSave) onDialogSave(row);
  //                 },
  //                 onDialogClose: () => {
  //                   // if (onDialogClose) onDialogClose();
  //                   this.refresh();
  //                 },
  //               },
  //             });

  //           });
  //         },
  //       },
  //       State: {
  //         title: this.cms.translateText('Common.state'),
  //         type: 'custom',
  //         width: '5%',
  //         // class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'checkmark-circle';
  //           instance.display = true;
  //           instance.status = 'success';
  //           instance.disabled = this.isChoosedMode;
  //           instance.title = this.cms.translateText('Common.approved');
  //           instance.label = this.cms.translateText('Common.approved');
  //           instance.valueChange.subscribe(value => {
  //             const processMap = AppModule.processMaps.collaboratorEdutcationArticle[value || ''];
  //             instance.label = this.cms.translateText(processMap?.label);
  //             instance.status = processMap?.status;
  //             instance.outline = processMap?.outline;
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: CollaboratorEducationArticleModel) => {
  //             // this.apiService.getPromise<CollaboratorEducationArticleModel[]>(this.apiPath, { id: [rowData.Code], includeContact: true, includeDetails: true, useBaseTimezone: true }).then(rs => {
  //             this.preview([rowData]);
  //             // });
  //           });
  //         },
  //       },
  //       Permission: {
  //         title: this.cms.translateText('Common.permission'),
  //         type: 'custom',
  //         width: '5%',
  //         class: 'align-right',
  //         exclude: this.isChoosedMode,
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'shield';
  //           instance.display = true;
  //           instance.status = 'danger';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.valueChange.subscribe(value => {
  //             // instance.icon = value ? 'unlock' : 'lock';
  //             // instance.status = value === 'REQUEST' ? 'warning' : 'success';
  //             // instance.disabled = value !== 'REQUEST';
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: CollaboratorEducationArticleModel) => {

  //             this.cms.openDialog(ResourcePermissionEditComponent, {
  //               context: {
  //                 inputMode: 'dialog',
  //                 inputId: [rowData.Code],
  //                 note: 'Click vào nút + để thêm 1 phân quyền, mỗi phân quyền bao gồm người được phân quyền và các quyền mà người đó được thao tác',
  //                 resourceName: this.cms.translateText('Purchase.PurchaseVoucher  .title', { action: '', definition: '' }) + ` ${rowData.Title || ''}`,
  //                 // resrouce: rowData,
  //                 apiPath: this.apiPath,
  //               }
  //             });

  //             // this.getFormData([rowData.Code]).then(rs => {
  //             //   this.preview(rs);
  //             // });
  //           });
  //         },
  //       },
  //       Preview: {
  //         title: this.cms.translateText('Common.show'),
  //         type: 'custom',
  //         width: '5%',
  //         class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'external-link-outline';
  //           instance.display = true;
  //           instance.status = 'primary';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.valueChange.subscribe(value => {
  //             // instance.icon = value ? 'unlock' : 'lock';
  //             // instance.status = value === 'REQUEST' ? 'warning' : 'success';
  //             // instance.disabled = value !== 'REQUEST';
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: CollaboratorEducationArticleModel) => {
  //             // this.getFormData([rowData.Code]).then(rs => {
  //             this.preview([rowData]);
  //             // });
  //           });
  //         },
  //       }
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // getList(callback: (list: CollaboratorEducationArticleModel[]) => void) {
  //   super.getList((rs) => {
  //     if (callback) callback(rs);
  //   });
  // }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['includeObject'] = true;
  //     params['includeContact'] = true;
  //     params['includeCreator'] = true;
  //     params['includeRelativeVouchers'] = true;
  //     params['sort_Id'] = 'desc';
  //     // params['eq_Type'] = 'PAYMENT';
  //     return params;
  //   };

  //   return source;
  // }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<CollaboratorEducationArticleModel, CollaboratorEducationArticleFormComponent>) {
    params['includeObject'] = true;
    params['includeContact'] = true;
    params['includeCreator'] = true;
    params['includeRelativeVouchers'] = true;
    return params;
  }

  // async getFormData(ids: string[]) {
  //   return this.apiService.getPromise<CollaboratorEducationArticleModel[]>(this.apiPath, { id: ids, includeContact: true, includeDetails: true });
  // }

  // preview(data: CollaboratorEducationArticleModel[], source?: string) {
  //   this.cms.openDialog(PurchaseVoucherPrintComponent, {
  //     context: {
  //       showLoading: true,
  //       title: 'Xem trước',
  //       data: data,
  //       idKey: ['Code'],
  //       id: data.map(m => m[this.idKey]),
  //       sourceOfDialog: 'list',
  //       mode: 'print',
  //       // approvedConfirm: true,
  //       onChange: (data: CollaboratorEducationArticleModel) => {
  //         this.refresh();
  //       },
  //       onSaveAndClose: () => {
  //         this.refresh();
  //       },
  //     },
  //   });
  //   return false;
  // }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

}
