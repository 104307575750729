import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard.service';
import { NgModule } from '@angular/core';
import { WordpressOrderFormComponent } from './order/order-form/order-form.component';
import { WordpressOrderListComponent } from './order/order-list/order-list.component';
import { WordpressProductListComponent } from './product/product-list/product-list.component';
import { WordpressSyncProfileListComponent } from './sync-profile/sync-profile-list/sync-profile-list.component';

export const wordpressRoutes: Routes = [
  {
    path: 'sync-profile/list',
    canActivate: [AuthGuardService],
    component: WordpressSyncProfileListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'product/list',
    canActivate: [AuthGuardService],
    component: WordpressProductListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'order/list',
    canActivate: [AuthGuardService],
    component: WordpressOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'order/form',
    canActivate: [AuthGuardService],
    component: WordpressOrderFormComponent,
  },
  {
    path: 'order/form/:id',
    canActivate: [AuthGuardService],
    component: WordpressOrderFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(wordpressRoutes)],
  exports: [RouterModule],
})
export class WordpressRoutingModule {
}