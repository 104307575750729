import { EmailMarketingComponent } from './email-marketing.component';
import { EmailMarketingDashboardComponent } from './dashboard/email-marketing-dashboard/email-marketing-dashboard.component';
import { EmailSentListComponent } from './email-sent/email-sent-list/email-sent-list.component';
import { EmailSentFormComponent } from './email-sent/email-sent-form/email-sent-form.component';
import { EmailTemplateListComponent } from './email-template/email-template-list/email-template-list.component';
import { EmailTemplateFormComponent } from './email-template/email-template-form/email-template-form.component';
import { EmailAdvertisementListComponent } from './email-advertisement/email-advertisement-list/email-advertisement-list.component';
import { EmailAdvertisementFormComponent } from './email-advertisement/email-advertisement-form/email-advertisement-form.component';
import { EmailGatewayListComponent } from './email-gateway/email-gateway-list/email-gateway-list.component';
import { EmailGatewayFormComponent } from './email-gateway/email-gateway-form/email-gateway-form.component';
import { EmailAddressListComponent } from './address/email-address-list/email-address-list.component';
import { EmailAddressFormComponent } from './address/email-address-form/email-address-form.component';
import { EmailSentStatsListComponent } from './email-sent-stats-list/email-sent-stats-list.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { EmailMarketingRoutingModule } from './email-marketing-routing.module';

export const emailMarketingComponents = [EmailMarketingComponent,
  EmailMarketingComponent,
  EmailMarketingDashboardComponent,
  EmailSentListComponent,
  EmailSentFormComponent,
  EmailTemplateListComponent,
  EmailTemplateFormComponent,
  EmailAdvertisementListComponent,
  EmailAdvertisementFormComponent,
  EmailGatewayListComponent,
  EmailGatewayFormComponent,
  EmailAddressListComponent,
  EmailAddressFormComponent,
  EmailSentStatsListComponent,
];

@NgModule({
  declarations: emailMarketingComponents,
  // exports: emailMarketingComponents,
  imports: [
    ...commonModules,
    EmailMarketingRoutingModule,
  ],
  bootstrap: [EmailMarketingComponent],
  providers: [
    ...commonProviders,
  ],
})
export class EmailMarketingModule { }