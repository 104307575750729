import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { DeploymentComponent } from "../deployment/deployment.component";
import { MinierpDashboardComponent } from "./minierp-dashboard/minierp-dashboard.component";
import { MiniErpRoutingModule } from "./minierp-routing.module";
import { MinierpComponent } from "./minierp.component";
import { MinierpFormComponent } from "./minierps/minierp-form/minierp-form.component";
import { MinierpListComponent } from "./minierps/minierp-list/minierp-list.component";

export const miniErpComponents = [
  MinierpComponent,
  MinierpDashboardComponent,
  MinierpListComponent,
  MinierpFormComponent,
];

@NgModule({
  declarations: miniErpComponents,
  exports: miniErpComponents,
  imports: [
    ...commonModules,
    MiniErpRoutingModule,
  ],
  bootstrap: [MinierpComponent],
  providers: [
    ...commonProviders,
  ],
})
export class MiniErpModule { }