<div class="dialog-wrap" #dialogWrap>
    <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Deployment.Voucher.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="print-title">PHIẾU TRIỂN KHAI<br>KIÊM PHIẾU GIAO HÀNG</div>
                        </div>
                        <div *ngIf="data.BarCode" style="flex: 0.5; text-align: center; display: flex; align-items: center; flex-direction: column; padding-top: 0.3rem;">
                            <img [src]="data.BarCode">
                            <div class="under-title" style="padding: 0.3rem;">{{ data.Code }} - {{ data.DeploymentDate | date:'short' }}</div>
                        </div>
                    </div>
                </div>
                <div class="font-large">
                    <div class="under-line">{{'Tiêu đề' | translate | headtitlecase}} :
                        {{renderValue(data.Title)}}
                    </div>
                    <div class="under-line">{{'Voucher.dearCustomer' | translate | headtitlecase}} :
                        {{renderValue(data.ObjectName)}}
                    </div>
                    <div class="under-line">{{'Common.address' | translate | headtitlecase}} :
                        {{renderValue(data.ObjectAddress)}}
                    </div>
                </div>
                <div class="font-large">
                    <div>
                        <table style="width: 100%;" class="print-voucher-detail-table">
                            <thead class="print-voucher-detail-header">
                                <td>#</td>
                                <td>{{'Sales.technicalDescription' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{'Voucher.shortQuantity' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{'Voucher.shortUnit' | translate | headtitlecase}}</td>
                            </thead>
                            <ng-container *ngFor="let detail of data.Details; let i = index;">
                                <tr class="print-voucher-detail-line" *ngIf="detail.Type === 'CATEGORY'">
                                    <td colspan="7" [innerHTML]="detail.Description"></td>
                                </tr>
                                <tr class="print-voucher-detail-line" *ngIf="detail.Type !== 'CATEGORY'">
                                    <td style="padding-top: 0.5rem; padding-bottom: 0.5rem; font-size: 1.5rem; line-height: 2rem;">{{detail.No}}</td>
                                    <td style="padding-top: 0.5rem; padding-bottom: 0.5rem;"><span style="font-size: 1.5rem !important; font-weight: bold;" [innerHTML]="detail.Description"></span><br>
                                        <span style="font-size: 1rem; font-style: italic;">
                                            <!-- {{'Common.note' | translate}} : {{detail.Product?.Code}}, -->
                                            <span style="font-size: 1.5rem !important; font-weight: bold;">SKU: {{detail.Sku}}</span>
                                        </span>
                                    </td>
                                    <td class="text-align-right" style="font-size: 2.8rem !important; font-weight: bold; line-height: 3.6rem !important; padding-top: 0.5rem; padding-bottom: 0.5rem;">{{detail.Quantity | number}}</td>
                                    <td class="text-align-right" style="font-size: 1.5rem !important; font-weight: bold; padding-top: 0.5rem; padding-bottom: 0.5rem;">{{detail.Unit | objecttext}}</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="4">{{'Common.note' | translate | headtitlecase}}: <span [innerHTML]="renderValue(data.Note)"></span></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- <div class="under-line" *ngIf="data.Note"><span [innerHTML]="renderValue(data.Note)"></span></div> -->

                <hr>
                <div *ngFor="let relativeVoucher of data.RelativeVouchers">
                    <label style="font-size: 16px; font-weight: bold;">Hành trình vận chuyển trên chứng từ liên quan {{relativeVoucher | objectid}} - {{relativeVoucher|objecttext}}</label>
                    <table style="width: 100%;" class="print-voucher-detail-table">
                        <thead class="print-voucher-detail-header">
                            <td>#</td>
                            <td>{{'Đơn vị vận chuyển' | translate | headtitlecase}}</td>
                            <td>{{'SĐT Đơn vị vận chuyển' | translate | headtitlecase}}</td>
                            <td>{{'Địa chỉ giao hàng' | translate | headtitlecase}}</td>
                            <td>{{'Bên trả phí' | translate | headtitlecase}}</td>
                            <td class="text-align-right">{{'Phí' | translate | headtitlecase}}</td>
                        </thead>
                        <ng-container *ngFor="let transportPoint of relativeVoucher.TransportPoints; let t = index">
                            <tr class="print-voucher-detail-line">
                                <td>{{transportPoint.No}}</td>
                                <td><span [innerHTML]="transportPoint.ShippingUnitName"></span></td>
                                <td><span [innerHTML]="transportPoint.ShippingUnitPhone"></span></td>
                                <td><span [innerHTML]="transportPoint.DestinationFullAddress"></span></td>
                                <td><span [innerHTML]="transportPoint.SideOfPayment | objecttext"></span></td>
                                <td class="text-align-right"><span [innerHTML]="transportPoint.TransportCost | currency:'VND'"></span></td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="4"></td>
                            <td class="text-align-right"><span style="font-weight: bold">Tổng: </span></td>
                            <td class="text-align-right"><span style="font-weight: bold" [innerHTML]="sumOfColumn(relativeVoucher.TransportPoints, 'TransportCost') | currency:'VND'"></span></td>
                        </tr>
                    </table>
                    <hr>
                </div>
                <div class="font-large" style="display: flex;">
                    <div *ngIf="data.MapUrlQrCode">
                        <img style="margin-right: 0.5rem;" [src]="data.MapUrlQrCode">
                        <div style="line-height: 1rem !important; padding-bottom: 0.8rem;">Tọa độ<br>google maps</div>
                    </div>
                    <div style="flex: 1;">
                        <div>
                            <div class="under-line" style="font-size: 1.5rem !important;
                            font-weight: bold;
                            line-height: 2rem !important;">{{'Sales.deliveryAddress' | translate | headtitlecase}}:
                                {{renderValue(data.FullDeliveryAddress)}}
                            </div>
                            <div>
                                <div style="font-size: 1.5rem !important; font-weight: bold; line-height: 2.5rem !important;" class="under-line">{{'Người nhận hàng' | translate | headtitlecase}}:
                                    {{renderValue(data.DirectReceiverName)}}
                                </div>
                                <div class="under-line" style="font-size: 1.5rem !important; font-weight: bold; line-height: 2.5rem !important;">{{'SĐT Người nhận hàng' | translate | headtitlecase}}:
                                    {{renderValue(data.DirectReceiverPhone)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="font-large">
                    <div style="display:flex">
                        <div style="flex: 1" class="under-line">{{'Tài xế' | translate | headtitlecase}} :
                            {{renderValue(data.ImplementerName)}} ({{renderValue(data.Implementer | objecttext)}})
                        </div>
                        <div class="under-line">{{'SĐT tài xế' | translate | headtitlecase}} :
                            {{renderValue(data.ImplementerPhone)}}
                        </div>
                    </div>
                    <div style="display:flex">
                        <div style="flex: 1" class="under-line">{{'Bên trả phí vận chuyển' | translate | headtitlecase}} :
                            {{renderValue(data.ShippingCostPaymentBy | objecttext)}}
                        </div>
                    </div>
                </div> -->
                <div class="font-large" style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Người triển khai/Giao Hàng' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Người nghiệm thu/Nhận Hàng' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                </div>
                <div class="default-print-footer" [innerHTML]="cms.systemConfigs$.value.PARAMETERS.DEFAULT_DEPLOYMENTVOUCHER_PRINT_FOOTER"></div>
                <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
            </div>
        </nb-card-body>
        <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
            <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
                <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
            </span>
        </nb-card-footer>
        <nb-card-footer>
            <div class="buttons-row">
                <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
                    <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
                </button>
                <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="prepareCopy(data);" style="float: left">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button>

                <!--State process buttons-->
                <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward-outline'}}"></nb-icon></button>
                <!--End State process buttons-->

                <button *ngIf="mode === 'preview'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>