import { take, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../../services/api.service';
import { RootServices } from '../../../../../services/root.services';
import { CommonService } from '../../../../../services/common.service';
import { PageModel } from '../../../../../models/page.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AgGridDataManagerListComponent } from '../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import { ContactModel } from '../../../../../models/contact.model';
import { agMakeImageColDef } from '../../../../../lib/custom-element/ag-list/column-define/image.define';
import { HttpErrorResponse } from '@angular/common/http';
import { DataManagerFormComponent } from '../../../../../lib/data-manager/data-manager-form.component';
import { B2bCenterService } from '../../../b2b-center.service';
import { agMakeTextColDef } from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeIdColDef } from '../../../../../lib/custom-element/ag-list/column-define/id.define';
import { B2bPartnerModel } from '../../../b2b-center.model';
import { ContactAllListComponent } from '../../../../contact/contact-all-list/contact-all-list.component';
import { DialogFormComponent } from '../../../../../lib/component/dialog/dialog-form/dialog-form.component';

@Component({
  selector: 'ngx-b2b-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss'],
  providers: [CurrencyPipe],
})
export class B2bSupplierListComponent extends AgGridDataManagerListComponent<B2bPartnerModel, DataManagerFormComponent<B2bPartnerModel>> implements OnInit {

  componentName: string = 'B2bSupplierListComponent';
  // formPath = '/b2b-center/product/map/form';
  apiPath = '/b2b-center/purchase/suppliers';
  // idKey: string[] = ['Product', 'Unit', 'RefProduct', 'RefUnit'];
  idKey: string[] = ['Id'];
  // formDialog = B2bSupplierFopComponent;
  currentPage: PageModel;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  // @Input() gridHeight = 'calc(100vh - 230px)';


  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<B2bSupplierListComponent>,
    public datePipe: DatePipe,
    public b2bCenterService: B2bCenterService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {

      // Remove buttons
      this.actionButtonList = this.actionButtonList.filter(f => ['add'].indexOf(f.name) < 0);

      // this.actionButtonList.unshift({
      //   type: 'button',
      //   name: 'addProduct',
      //   label: this.cms.translateText('Collaborator.Product.add'),
      //   icon: 'cube-outline',
      //   status: 'primary',
      //   size: 'medium',
      //   disabled: () => !this.b2bCenterService.currentpage$.value,
      //   title: this.cms.translateText('Common.subscribe'),
      //   click: () => {
      //     this.cms.openDialog(ProductListComponent, {
      //       context: {
      //         inputMode: 'dialog',
      //         gridHeight: '95vh',
      //         onDialogChoose: async (chooseItems: B2bPartnerModel[]) => {
      //           console.log(chooseItems);
      //           const page = this.b2bCenterService.currentpage$?.value;

      //           const map = [];
      //           for (const i in chooseItems) {
      //             const product = chooseItems[i];
      //             for (const u in product.UnitConversions) {
      //               const unit = product.UnitConversions[u];
      //               map.push({
      //                 Page: this.cms.getObjectId(page),
      //                 PageName: this.cms.getObjectText(page),
      //                 Product: product.Code,
      //                 ProductName: product.Name,
      //                 Unit: this.cms.getObjectId(unit),
      //                 UnitLabel: this.cms.getObjectText(unit),
      //               });
      //             }
      //           }

      //           this.apiService.putPromise<B2bPartnerModel[]>('/b2b-center/sales/partners', {}, map).then(rs => {
      //             this.cms.toastService.show('Đã thêm sản phẩm', 'Sản phẩm đã được thêm vào danh sách đồng bộ', {
      //               status: 'success',
      //             })
      //             this.refresh();
      //           });
      //         },
      //         onDialogClose: () => {
      //         },
      //       },
      //     })
      //   },
      // });

      // Add page choosed
      this.b2bCenterService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'pbxdomain',
          status: 'success',
          label: 'Select page',
          icon: 'plus',
          title: this.cms.textTransform(this.cms.translate.instant('Common.createNew'), 'head-title'),
          size: 'medium',
          select2: {
            data: pageList, option: {
              placeholder: 'Chọn shop...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.b2bCenterService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            // this.gotoForm();
            return false;
          },
        });
      });

      // this.actionButtonList.unshift({
      //   type: 'button',
      //   name: 'exportPdf',
      //   status: 'primary',
      //   label: 'Báo chiết khấu (PDF)',
      //   title: 'Xuất báo giá chiết khấu cho CTV ra PDF',
      //   size: 'medium',
      //   icon: 'download-outline',
      //   disabled: () => {
      //     return this.selectedIds.length == 0;
      //   },
      //   click: () => {
      //     const product = this.selectedItems.map(m => this.cms.getObjectId(m.Code));
      //     let params = {
      //       type: 'pdf',
      //       report: 'ExportProductCommission',
      //       eq_Code: '[' + product.join(',') + ']'
      //     };
      //     params['includeCategories'] = true;
      //     params['includeGroups'] = true;
      //     params['includeProduct'] = true;
      //     params['includeUnit'] = true;
      //     params['includeUnitPrices'] = true;
      //     params['includeCommissionRatio'] = true;
      //     params['productOfPage'] = true;
      //     params['includePrice'] = true;
      //     params['sort_Id'] = 'desc';
      //     if (this.b2bCenterService.currentpage$.value) {
      //       params['page'] = this.b2bCenterService.currentpage$.value;
      //     }
      //     window.open(this.apiService.buildApiUrl(this.apiPath, params), '__blank');
      //   }
      // });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'addContact',
        status: 'primary',
        label: 'Thêm liên hệ',
        title: 'Thêm nhà cung cấp từ liên hệ',
        size: 'medium',
        icon: 'cloud-download-outline',
        // disabled: () => {
        //   return this.selectedIds.length == 0;
        // },
        hidden: () => this.isChoosedMode,
        click: (event, option, context, controlEle) => {


          this.cms.openDialog(DialogFormComponent, {
            context: {
              width: '512px',
              controls: [
                {
                  name: 'RefPage',
                  label: 'Shop liên kết',
                  type: 'select2',
                  option: {
                    ...this.cms.makeSelect2AjaxOption('/b2b-center/server/pages', { page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value) }, {
                      prepareReaultItem: (item) => {
                        item.label = item.id + ' - ' + item.text;
                        return item;
                      }
                    }),
                    placeholder: 'Chọn shop liên kết...',
                    allowClear: false,
                    width: '100%',
                    dropdownAutoWidth: true,
                    minimumInputLength: 1,
                    keyMap: {
                      id: 'id',
                      text: 'text',
                    },
                  },
                }
              ],
              actions: [
                {
                  label: 'Back',
                  status: 'basic',
                  outline: true,
                  action: async () => {
                    return true;
                  }
                },
                {
                  label: 'Tiếp theo',
                  status: 'primary',
                  action: async (form, dialog) => {
                    const refPage = form.get('RefPage').value;
                    console.log(refPage);
                    if (!this.cms.getObjectId(refPage)) {
                      this.cms.showError('Bạn chưa chọn shop của nhà cung cấp');
                      return false;
                    }
                    this.cms.openDialog(ContactAllListComponent, {
                      context: {
                        onDialogChoose: chooseItems => {
                          console.log(refPage);
                          console.log(chooseItems);
                          this.apiService.putPromise<B2bPartnerModel[]>('/b2b-center/purchase/suppliers', {}, chooseItems.map(contact => ({
                            Page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value),
                            PageName: this.cms.getObjectText(this.b2bCenterService.currentpage$.value),
                            RefPage: this.cms.getObjectId(refPage),
                            RefPageName: this.cms.getObjectText(refPage),
                            Contact: contact.Code,
                            Name: contact.Name,
                          }))).then(rs => {
                            this.cms.showToast('Đã thêm và liên kết nhà cung cấp với shop của họ', 'Thêm thông tin nhà cung cấp thành công', { status: 'success' });
                            this.refresh();
                          }).catch(err => this.cms.showError(err));
                        },
                      },
                      closeOnEsc: false,
                    });
                    return true;
                  }
                },
              ],
            }
          });
        }
      });

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return [rowData.Avatar?.LargeImage];
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'Avatar',
          width: 100,
        },
        {
          ...agMakeIdColDef(this.cms),
          headerName: 'ID Liên hệ',
          field: 'Contact',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
          // valueGetter: params => this.cms.getObjectId(params?.data?.Product),
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên nhà cung cấp',
          field: 'Name',
          // pinned: 'left',
          width: 400,
          filter: 'agTextColumnFilter',
          // cellRenderer: AgTextCellRenderer,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Số điện thoại',
          field: 'Phone',
          width: 200,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Email',
          field: 'Email',
          width: 200,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Địa chỉ',
          field: 'Address',
          width: 200,
        },
        {
          ...agMakeIdColDef(this.cms),
          headerName: 'Shop ID',
          field: 'RefPage',
          width: 180,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên Shop',
          field: 'RefPageName',
          width: 250,
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, (data) => {
            this.deleteConfirm([this.makeId(data)]);
          }, false, [
          ]),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  // @Input() prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: B2bSupplierListComponent) {
  //   params['includeCategories'] = true;
  //   params['includeGroups'] = true;
  //   params['includeProduct'] = true;
  //   params['includeUnit'] = true;
  //   params['includeUnitPrices'] = true;
  //   params['includeCommissionRatio'] = true;
  //   params['productOfPage'] = true;
  //   params['includePrice'] = true;
  //   params['onlyBusinessProducts'] = true;

  //   params['page'] = this.collaboratorService?.currentpage$?.value;
  //   return params;
  // }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<B2bPartnerModel, DataManagerFormComponent<B2bPartnerModel>>) {
    params['page'] = this.cms.getObjectId(this.b2bCenterService?.currentpage$?.value);
    return super.prepareApiParams(params, getRowParams, component);
  }

  executeGet(params: any, success: (resources: B2bPartnerModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: B2bPartnerModel[] | HttpErrorResponse) => void): void {

    params['page'] = this.b2bCenterService?.currentpage$?.value;
    return super.executeGet(params, success, error, complete);
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(ContactFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: ContactModel[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.b2bCenterService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  /** Api delete funciton */
  async executeDelete(ids: any, success: (resp: any) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: any | HttpErrorResponse) => void) {
    if (this.rowModelType === 'infinite') {
      // await super.executeDelete(ids, success, error, complete);
      const params = {
        id: ids,
        page: this.b2bCenterService?.currentpage$?.value,
      };
      return this.apiService.deletePromise(this.apiPath, params).then(resp => {
        // this.removeGridItems(deletedItems);
        this.refresh();
        if (success) success(resp);
        if (complete) complete(resp);
      }).catch(err => {
        if (error) error(err);
        return Promise.reject(err);
      });
    } else if (this.rowModelType === 'clientSide') {
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });
    }
  }

  // async pushProductsPlatform(): Promise<any> {
  //   const toast = this.cms.showToast('Đang đẩy thông tin sản phẩm lên sàn B2B Center', 'Đang đẩy thông tin sản phẩm', { status: 'danger', duration: 0 });
  //   return this.b2bCenterService.pushProductToPlatform().then(rs => {
  //     toast.close();
  //     this.cms.showToast('Đã đẩy thông tin sản phẩm lên sàn B2B Center', 'Đẩy thông tin sản phẩm thành công', { status: 'success' });
  //     return rs;
  //   }).catch(err => {
  //     toast.close();

  //     return Promise.reject(err);
  //   });
  // }
}
