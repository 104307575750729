import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
})
export class WarehouseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
