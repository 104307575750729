<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Pbx.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật thông kết nối
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;" title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;" title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo" style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <!-- <input type="hidden" formControlName="Owner_old"> -->
              <!-- <input type="hidden" readonly nbInput fullWidth formControlName="Code"> -->
              <input type="hidden" readonly nbInput fullWidth formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label">Tên</label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label">Diễn giải</label>
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="Diễn giải">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiUrl'), 'warning')">
                  <label class="label"><span class="valid">Api Url (*)</span><span class="invalid">Api Url: bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="Api Url">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"><span class="valid">Domain gốc</span></label>
                  <input type="text" nbInput fullWidth formControlName="BaseDomainName" placeholder="Domain gốc">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label">Api key</label>
                  <input type="password" nbInput fullWidth formControlName="ApiKey" placeholder="Api key">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiVersion'), 'warning')">
                  <label class="label"><span class="valid">Api Version</span></label>
                  <input type="text" nbInput fullWidth formControlName="ApiVersion" placeholder="Api Version">
                </div>
              </div>
            </div>

            <hr>
            <label class="label">Danh sách domains</label>
            <div class="row">
              <div class="col-sm-1">STT</div>
              <div class="col-sm-11">
                <div class="row">
                  <div class="col-sm-5">Domain</div>
                  <!-- <div class="col-sm-4">Api key</div> -->
                  <div class="col-sm-7">Diễn giải</div>
                </div>
              </div>
              <!-- <div class="col-sm-1" style="text-align: center;"> -->
                <!-- <button nbButton status="success" (click)="addDomainFormGroup(i)" size="tiny" style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button> -->
              <!-- </div> -->
            </div>

            <hr>
            <div formArrayName="Domains" class="form-details">
              <div class="form-detail-item" *ngFor="let domain of getDomains(i).controls; let ic=index"
                [formGroup]="domain">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-11">
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label class="label">Domain (*)</label>
                          <input type="text" disabled nbInput fullWidth formControlName="DomainName" placeholder="Domain">
                        </div>
                      </div>
                      <!-- <div class="col-sm-4">
                        <div class="form-group">
                          <label class="label">Api Key (*)</label>
                          <input type="text" nbInput fullWidth formControlName="AdminKey" placeholder="Api Key">
                        </div>
                      </div> -->
                      <div class="col-sm-7">
                        <div class="form-group">
                          <label class="label">Diễn giải</label>
                          <input type="text" nbInput disabled fullWidth formControlName="Description" placeholder="Diễn giải">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-1">
                    <div class="form-group">
                      <label class="label">CM</label>
                      <button nbButton status="danger" hero size="tiny" (click)="removeDomainGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div> -->
                </div>

              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm PBX</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Cập nhật</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
