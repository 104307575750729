<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon"
            [title]="title ? title : ('Helpdesk.Procedure.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
            [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="State" label="Common.state" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="true">
                  <nb-select size="medium" formControlName="State" fullWidth
                    placeholder="{{'Common.state' | translate | headtitlecase}}">
                    <nb-option value="ACTIVE">{{'Common.active' | translate | headtitlecase}}</nb-option>
                    <nb-option value="INACTIVE">{{'Common.inactive' | translate | headtitlecase}}</nb-option>
                  </nb-select>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array"
                  [index]="i" [allowCopy]="false" [required]="false">
                  <!-- <textarea nbInput fullWidth formControlName="Description"
                    placeholder="{{'Common.description' | translate | headtitlecase}}" rows="3"></textarea> -->
                    <!-- <ckeditor formControlName="Description" [config]="{ extraPlugins: 'divarea', height: '200' }"></ckeditor> -->
                </ngx-form-group>
              </div>
            </div>

            <!-- Step form -->
            <hr>
            <label class="label">{{'Helpdesk.Procedure.steps' | translate | headtitlecase}}</label>
            <div class="row">
              <div class="col-sm-1 label">
                <button nbButton status="success" (click)="addStepFormGroup(i)" size="tiny"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  {{'Common.add' | translate | headtitlecase}}
                </button>
              </div>
              <div class="col-sm-11">
                <div class="row">
                  <div class="col-sm-12 label">{{'Common.description' | translate | headtitlecase}}</div>
                </div>
              </div>
              <!-- <div class="col-sm-1" style="text-align: center;">
                <button nbButton status="success" (click)="addStepFormGroup(i)" size="medium"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div> -->
            </div>

            <div formArrayName="Steps" class="form-details" [sortablejs]="getSteps(i)"
              [sortablejsOptions]="{ handle: '.sorting-handle' }">
              <div class="form-detail-item" *ngFor="let step of getSteps(i).controls; let ic=index" [formGroup]="step">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <label class="label">Stt</label>
                      <input nbInput fullWidth class="sorting-handle" disabled="true" value="{{ ic + 1 }}"
                        style="text-align: center; cursor: grab;">
                      <input type="hidden" formControlName="Id">
                    </div>
                    <div class="form-group">
                      <label class="label">CM</label>
                      <button nbButton status="danger" hero size="medium" (click)="removeStepGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-11">
                    <div class="row">
                      <div class="col-sm-12">
                        <ngx-form-group [formGroup]="step" name="Description" label="Common.description" [array]="array"
                          [index]="i" [allowCopy]="false" [required]="true">
                          <textarea nbInput fullWidth formControlName="Description"
                            placeholder="{{'Common.description' | translate | headtitlecase}}" rows="3"></textarea>
                        </ngx-form-group>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-1">
                    <div class="form-group">
                      <label class="label">CM</label>
                      <button nbButton status="danger" hero size="medium" (click)="removeStepGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div> -->
                </div>

              </div>
            </div>
            <!-- End Confition form -->
          </div>

        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero
              (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
