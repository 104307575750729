import { UsersComponent } from './users.component';
import { UserGroupListComponent } from './user-group/user-group-list/user-group-list.component';
import { UserGroupFormComponent } from './user-group/user-group-form/user-group-form.component';
import { UserGroupViewComponent } from './user-group/user-group-view/user-group-view.component';
import { UserGroupReportComponent } from './user-group/user-group-report/user-group-report.component';
import { UserListComponent } from './user-manager/user-list/user-list.component';
import { UserFormComponent } from './user-manager/user-form/user-form.component';
import { UserViewComponent } from './user-manager/user-view/user-view.component';
import { UserReportComponent } from './user-manager/user-report/user-report.component';
import { PermissionGrantComponent } from './permission-grant/permission-grant.component';
import { UserChangePasswordFormComponent } from './profile/user-change-password-form/user-change-password-form.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { UsersRoutingModule } from './users-routing.module';

export const userComponents = [
  UsersComponent,
  UserListComponent,
  UserFormComponent,
  UserViewComponent,
  UserReportComponent,
  UserGroupListComponent,
  UserGroupFormComponent,
  UserGroupViewComponent,
  UserGroupReportComponent,
  UserFormComponent,
  UserViewComponent,
  UserReportComponent,
  PermissionGrantComponent,
  UserChangePasswordFormComponent
];

@NgModule({
  declarations: userComponents,
  exports: userComponents,
  imports: [
    ...commonModules,
    UsersRoutingModule,
  ],
  bootstrap: [UsersComponent],
  providers: [
    ...commonProviders,
  ],
})
export class UsersModule { }