<nb-card class="small-header smart-table">
  <nb-card-header>
    <ngx-card-header [size]="size" [icon]="favicon" [title]="(title ? title : ('Ivoip.Cdrs.title' | translate:{action: '', definition: cms.translate.instant('Common.list')} | headtitlecase)) + ' ('+(source.count() | number:'1.0-0')+')'" [controls]="actionButtonList"></ngx-card-header>
    <!-- <nav>
      <nb-icon pack="eva" icon="list-outline"></nb-icon> {{'Ivoip.List.title' | translate | headtitlecase}}
      <button nbButton status="success" hero size="tiny" (click)="onReloadBtnClick()" style="float: right;">
        <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
      </button>
      <button nbButton status="danger" hero size="tiny" (click)="reset()" title="{{'Common.reset' | translate | headtitlecase}}" style="float: right;">
        <nb-icon pack="eva" icon="slash"></nb-icon>
      </button>
      <button nbButton status="primary" hero size="tiny" (click)="exportCdrs()" style="float: right;">
        <nb-icon pack="eva" icon="external-link-outline"></nb-icon> Xuất
      </button>
      <ngx-select2 [data]="domainList" [value]="ivoipService.activeDomainUuid" [select2Option]="select2OptionForDoaminList"
        (selectChange)="onChangeDomain($event)" style="float: right; min-width: 250px;">
      </ngx-select2>
    </nav> -->
  </nb-card-header>

  <nb-card-body>
    <div class="ng2-smart-table-wrap">
      <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source"
      (delete)="onDeleteConfirm($event)" (edit)="onEditAction($event)" (create)="onSerialAction($event)"
        (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
      </ng2-smart-table>
    </div>
  </nb-card-body>
</nb-card>
