import {Component} from '@angular/core';

@Component({
  selector: 'ngx-output-invoice-list',
  templateUrl: './output-invoice-list.component.html',
  styleUrls: ['./output-invoice-list.component.scss']
})
export class OutputInvoiceListComponent {

}
