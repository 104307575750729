<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)"
      (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [ngStyle]="{width: width, height: height}">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon"
                           [title]="title ? title : ('Đăng nhập thuế' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
                           [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">

              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="username" label="ID" [array]="array" [index]="i"
                                [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="username" placeholder="ID">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="password"
                                label="{{'Common.password' | translate | headtitlecase}}" [array]="array" [index]="i"
                                [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="password" nbInput fullWidth formControlName="password"
                         placeholder="{{'Common.password' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <nb-card style="background-color: white;" [nbSpinner]="!captcha">
                  <nb-card-header style="color: #000">Mã xác thực</nb-card-header>
                  <img [src]="captcha">
                </nb-card>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="cvalue"
                                label="{{'Mã xác thực' | translate | headtitlecase}}" [array]="array" [index]="i"
                                [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="cvalue"
                         placeholder="{{'Mã xác thực' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero
                    (click)="saveAndClose()">{{ 'Đăng nhập' | translate | headtitlecase }}
            </button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
