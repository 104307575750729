import { SystemComponent } from './system.component';
import { SystemParameterListComponent } from './parameter/system-parameter-list/system-parameter-list.component';
import { SystemParameterFormComponent } from './parameter/system-parameter-form/system-parameter-form.component';
import { SystemLocaleConfigComponent } from '../../lib/component/configuration/system-configuration-board/system-locale-config/system-locale-config.component';
import { SystemActionListComponent } from './action/system-action-list/system-action-list.component';
import { SystemActionFormComponent } from './action/system-action-form/system-action-form.component';
import { SystemParamListComponent } from './param/system-param-list/system-param-list.component';
import { SystemParamFormComponent } from './param/system-param-form/system-param-form.component';
import { SystemRouteListComponent } from './route/system-route-list/system-route-list.component';
import { SystemRouteFormComponent } from './route/system-route-form/system-route-form.component';
import { SystemLibraryIconComponent } from './library/icon/system-library-icon/system-library-icon.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { SystemRoutingModule } from './system-routing.module';

export const systemComponents = [
  SystemComponent,
  SystemParameterListComponent,
  SystemParameterFormComponent,
  // SystemConfigurationBoardComponent,
  // SystemLocaleConfigComponent,
  // UserConfigBoardComponent,
  // UserLocaleConfigComponent,
  SystemActionListComponent,
  SystemActionFormComponent,
  SystemParamListComponent,
  SystemParamFormComponent,
  SystemRouteListComponent,
  SystemRouteFormComponent,
  SystemLibraryIconComponent,
  // ThemeConfigBoardComponent,
  // ThemeConfigComponent,
  // SysParameterListComponent,
];

@NgModule({
  declarations: systemComponents,
  // exports: systemComponents,
  imports: [
    ...commonModules,
    SystemRoutingModule,
  ],
  bootstrap: [SystemComponent],
  providers: [
    ...commonProviders,
  ],
})
export class SystemModule { }