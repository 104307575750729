import { RouterModule, Routes } from '@angular/router';
import { ModuleFormComponent } from './module-manager/module-form/module-form.component';
import { ResourceFormComponent } from './resources/resource-form/resource-form.component';
import { ResourceListComponent } from './resources/resource-list/resource-list.component';
import { ModuleListComponent } from './module-manager/module-list/module-list.component';
import { NgModule } from '@angular/core';


export const modulesRoutes: Routes = [
  {
    path: 'manager',
    redirectTo: 'modules/manager/list',
    pathMatch: 'full',
  },
  {
    path: 'manager/list',
    // canActivate: [AuthGuardService],
    component: ModuleListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'manager/form',
    // canActivate: [AuthGuardService],
    component: ModuleFormComponent,
  },
  {
    path: 'manager/form/:id',
    // canActivate: [AuthGuardService],
    component: ModuleFormComponent,
  },
  // Resources
  {
    path: 'resources/list',
    // canActivate: [AuthGuardService],
    component: ResourceListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'resources/form',
    // canActivate: [AuthGuardService],
    component: ResourceFormComponent,
  },
  {
    path: 'resources/form/:id',
    // canActivate: [AuthGuardService],
    component: ResourceFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(modulesRoutes)],
  exports: [RouterModule],
})
export class ModulesRoutingModule {
}
