<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ads.Code.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật định danh quảng cáo
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Type'), 'warning')">
                  <label class="label"><span class="valid">Loại (*)</span><span class="invalid">Loại là bắt buộc
                      (*)</span></label>
                      <nb-select size="medium" formControlName="Type" fullWidth selected="WEBSITE">
                        <nb-option value="WEBSITE">Website</nb-option>
                      </nb-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Site'), 'warning')">
                  <label class="label"><span class="valid">Nội dung (*)</span><span class="invalid">Site là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Site" placeholder="Site quảng cáo">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Area'), 'warning')">
                  <label class="label"><span class="valid">Vị trí</span><span class="invalid">Vi trí là bắt buộc
                      (*)</span></label>
                      <nb-select size="medium" formControlName="Area" fullWidth selected="TOPCONTENT">
                        <nb-option value="TOP">Đầu trang</nb-option>
                        <nb-option value="TOPCONTENT">Đầu nội dung</nb-option>
                        <nb-option value="MIDCONTENT">Giữ nội dung</nb-option>
                        <nb-option value="BOTTOMCONTENT">Cuối nội dung</nb-option>
                        <nb-option value="SIDEBAR">Trong sidebar</nb-option>
                        <nb-option value="BOTTOM">Cuối trang</nb-option>
                      </nb-select>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Embed'), 'warning')">
                  <label class="label"><span class="valid">Embed (*)</span><span class="invalid">Embed là bắt buộc
                      (*)</span></label>
                      <textarea nbInput fullWidth formControlName="Embed" placeholder="Embed" readonly rows="16"></textarea>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm cái nữa</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
