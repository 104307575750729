<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.CallCenter.Agent.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật vị trí trực máy
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="call_center_agent_uuid">
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('agent_name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="agent_name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('domain_uuid'), 'warning')">
                  <label class="label"><span class="valid">Domain</span>
                    <nb-icon (click)="copyFormControlValueToOthers(array, i, 'domain_uuid')" pack="eva"
                      icon="copy-outline"></nb-icon>
                  </label>
                  <ngx-select2 [data]="privateDmainList" formControlName="domain_uuid"
                    [select2Option]="privateDmainListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('agent_call_timeout'), 'warning')">
                  <label class="label"><span class="valid">Thời gian ngắt (*)</span><span class="invalid">Thời gian ngắt (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="agent_call_timeout" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('agent_contact'), 'warning')">
                  <label class="label"><span class="valid">Liên hệ (*)</span></label>
                  <ngx-select2 [data]="actionList" formControlName="agent_contact"
                    [select2Option]="actionListConfig" status="primary">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('agent_status'), 'warning')">
                  <label class="label"><span class="valid">Trạng thái (*)</span></label>
                  <nb-select size="medium" formControlName="agent_status" fullWidth>
                    <nb-option value="">Chọn trạng thái...</nb-option>
                    <nb-option *ngFor="let status of statusList" [value]="status.id">{{status.text}}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('agent_no_answer_delay_time'), 'warning')">
                  <label class="label"><span class="valid">Thời gian đỗ chuông (*)</span><span class="invalid">Thời gian đỗ chuông (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="agent_no_answer_delay_time" placeholder="Tên">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
