<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Dialplan.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật định tuyến cuộc gọi
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="dialplan_uuid">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_type'), 'warning')">
                  <label class="label"><span class="valid">Loại (*)</span><span class="invalid">Loại là bắt buộc
                      (*)</span></label>
                  <nb-select size="medium" formControlName="dialplan_type" fullWidth>
                    <nb-option value="">Loại</nb-option>
                    <nb-option value="inbound">Inbound</nb-option>
                    <nb-option value="outbound">Outbound</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_gateway'), 'warning')">
                  <label class="label"><span class="valid">Gateway</span><span class="invalid">Gateway là bắt buộc
                      (*)</span></label>
                  <nb-select size="medium" formControlName="dialplan_gateway" fullWidth>
                    <nb-option value="">Gateway</nb-option>
                    <nb-option *ngFor="let gateway of gatewaylist" [value]="gateway.id">{{gateway.text}}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="dialplan_name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_number'), 'warning')">
                  <label class="label"><span class="valid">Số đấu nối (*)</span><span class="invalid">Số đấu nối là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="dialplan_number" placeholder="Số điện thoại">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_regex'), 'warning')">
                  <label class="label"><span class="valid">Mẫu so khớp</span><span class="invalid">Mẫu so khớp</span></label>
                  <input type="text" nbInput fullWidth formControlName="dialplan_regex" placeholder="VD: \d{7,12} ~ dãy số 7-12 chữ số; \d{1,3}281234567 ~ 0281234567|84281234567">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('domain_uuid'), 'warning')">
                  <label class="label"><span class="valid">Domain (*)</span><span class="invalid">Domain là bắt buộc
                      (*)</span></label>
                  <ngx-select2 [data]="privateDmainList" formControlName="domain_uuid"
                    [select2Option]="privateDmainListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"><span class="valid">Mô tả</span></label>
                  <input type="text" nbInput fullWidth formControlName="dialplan_description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_context'), 'warning')">
                  <label class="label"><span class="valid">Context</span><span class="invalid">Context là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="dialplan_context" placeholder="Context">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"><span class="valid">Thứ tự</span></label>
                  <input type="text" nbInput fullWidth formControlName="dialplan_order" placeholder="Thứ tự">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('dialplan_enabled'), 'warning')">
                  <label class="label"><span class="valid">Kích hoạt (*)</span><span class="invalid"></span></label>
                  <nb-checkbox formControlName="dialplan_enabled">Kích hoạt</nb-checkbox>
                </div>
              </div>
            </div>
            <hr>
            <label class="label">Điều hướng</label>
            <div class="row">
              <div class="col-sm-1">STT</div>
              <div class="col-sm-11">
                <div class="row">
                  <div class="col-sm-3" seleted>Tag</div>
                  <div class="col-sm-5">Type</div>
                  <div class="col-sm-2">Break</div>
                  <div class="col-sm-1">Inline</div>
                  <div class="col-sm-1" style="text-align: center;">
                    <button nbButton status="success" (click)="addDialplanDetailFormGroup(i)" size="medium"
                      style="width: 100%; margin-bottom: 3px;" hero>
                      <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                    </button>
                  </div>
                </div>
                </div>
              </div>

            <hr>
            <div formArrayName="dialplan_details" class="form-details" [sortablejs]="getDialplanDetails(i)"
              [sortablejsOptions]="{ handle: '.sorting-handle' }">
              <div class="form-detail-item" *ngFor="let dialplanDetail of getDialplanDetails(i).controls; let ic=index"
                [formGroup]="dialplanDetail">
                <input type="hidden" formGroupName="dialplan_detail_uuid">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                    <div class="form-group">
                      <button nbButton status="success" hero size="medium" class="sorting-handle" style="width: 100%;">
                        <nb-icon pack="eva" icon="move-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-11">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="form-group"
                          [attr.state]="formControlValidate(dialplanDetail.get('dialplan_detail_tag'), 'warning')">
                          <label class="label"><span class="valid">Tag (*)</span><span class="invalid">Tag
                              (*)</span></label>
                          <nb-select size="medium" formControlName="dialplan_detail_tag" fullWidth>
                            <nb-option value="">Tag</nb-option>
                            <nb-option value="condition">Condition</nb-option>
                            <nb-option value="regex">Regular Expression</nb-option>
                            <nb-option value="action">Action</nb-option>
                            <nb-option value="anti-action">Anti-Action</nb-option>
                          </nb-select>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group"
                          [attr.state]="formControlValidate(dialplanDetail.get('dialplan_detail_type'), 'warning')">
                          <label class="label"><span class="valid">Type (*)</span><span class="invalid">Type
                              (*)</span></label>
                          <input type="text" nbInput fullWidth formControlName="dialplan_detail_type"
                            placeholder="Type">
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group"
                          [attr.state]="formControlValidate(dialplanDetail.get('dialplan_detail_break'), 'warning')">
                          <label class="label"><span class="valid">Break (*)</span><span class="invalid">Break
                              (*)</span></label>
                          <nb-select size="medium" formControlName="dialplan_detail_break" fullWidth>
                            <nb-option value="">Break</nb-option>
                            <nb-option value="on-true">On True</nb-option>
                            <nb-option value="on-false">On False</nb-option>
                            <nb-option value="always">Always</nb-option>
                            <nb-option value="never">Never</nb-option>
                          </nb-select>
                        </div>
                      </div>
                      <div class="col-sm-1  ">
                        <div class="form-group"
                          [attr.state]="formControlValidate(dialplanDetail.get('dialplan_detail_inline'), 'warning')">
                          <label class="label"><span class="valid">Inline</span><span class="invalid">Inline
                              (*)</span></label>
                          <nb-checkbox formControlName="dialplan_detail_inline"></nb-checkbox>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <!-- <label class="label">CM</label> -->
                          <button nbButton status="danger" hero size="medium" (click)="removeDialplanDetailGroup(i, ic)"
                            style="width: 100%; margin-bottom: 3px;">
                            <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group"
                          [attr.state]="formControlValidate(dialplanDetail.get('dialplan_detail_data'), 'warning')">
                          <label class="label"><span class="valid">Data (*)</span><span class="invalid">Data
                              (*)</span></label>
                          <input type="text" nbInput fullWidth formControlName="dialplan_detail_data"
                            placeholder="Data">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>

              </div>

              <div class="row">
                <div class="col-sm-12">
                  <i>Chi tiết cài đặt điều hướng sẽ được tạo tự động sau khi lưu.</i>
                </div>
              </div>

            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
