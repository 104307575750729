import { AdminProductService } from '../../../admin-product/admin-product.service';
import { DynamicListDialogComponent } from '../../../../lib/component/dialog/dynamic-list-dialog/dynamic-list-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { environment } from '../../../../../environments/environment';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { CustomIcon, FormGroupComponent } from '../../../../lib/custom-element/form/form-group/form-group.component';
import { DataManagerFormComponent, MyUploadAdapter } from '../../../../lib/data-manager/data-manager-form.component';
import { ContactModel } from '../../../../models/contact.model';
import { ProductModel } from '../../../../models/product.model';
import { MultifunctionalPurchaseTransportPointModel, PurchaseProductModel } from '../../../../models/purchase.model';
import { TaxModel } from '../../../../models/tax.model';
import { UnitModel } from '../../../../models/unit.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { ProductFormComponent } from '../../../admin-product/product/product-form/product-form.component';
import { ContactFormComponent } from '../../../contact/contact/contact-form/contact-form.component';
import { CollaboratorOpportunityPrintComponent } from '../opportunity-print/opportunity-print.component';
import { SmartTableButtonComponent, SmartTableCurrencyComponent, SmartTableTagsComponent } from '../../../../lib/custom-element/smart-table/smart-table.component';
import { filter, takeUntil } from 'rxjs/operators';
import { ReferenceChoosingDialogComponent } from '../../../../components/reference-choosing-dialog/reference-choosing-dialog.component';
import * as XLSX from 'xlsx';
import { DialogFormComponent } from '../../../../lib/component/dialog/dialog-form/dialog-form.component';
import { FileModel } from '../../../../models/file.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { RootServices } from '../../../../services/root.services';
import { CollaboratorOpportunityDetailModel, CollaboratorOpportunityModel } from '../../../../models/collaborator.model';
import { Select2Option } from '../../../../lib/custom-element/select2/select2.component';
import { SalesMasterPriceTableDetailModel } from '../../../../models/sales.model';
import { ChatRoomModel, ChatRoomMemberModel } from '../../../../models/chat-room.model';
import { MobileAppService } from '../../../mobile-app/mobile-app.service';
import { CollaboratorService } from '../../collaborator.service';
import { AccountingService } from '../../../accounting/accounting.service';
import * as ClassicEditorBuild from '../../../../../vendor/ckeditor/ckeditor5-custom-build/build/ckeditor.js';
var CryptoJS = require("crypto-js");


function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    const options = editor.config.get('simpleUpload');
    return new MyUploadAdapter(loader, options);
  };
}
@Component({
  selector: 'ngx-opportunity-form',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './opportunity-form.component.html',
  styleUrls: ['./opportunity-form.component.scss']
})
export class CollaboratorOpportunityFormComponent extends DataManagerFormComponent<CollaboratorOpportunityModel> implements OnInit {

  componentName: string = 'CollaboratorOpportunityFormComponent';
  idKey = 'Code';
  apiPath = '/collaborator/opportunities';
  baseFormUrl = '/collaborator/opportunity/form';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });

  /** Tax list */
  static _taxList: (TaxModel & { id?: string, text?: string })[];
  taxList: (TaxModel & { id?: string, text?: string })[];

  /** Unit list */
  static _unitList: (UnitModel & { id?: string, text?: string })[];
  unitList: (UnitModel & { id?: string, text?: string })[];
  @ViewChild('detailsViewport', { static: false }) detailsViewport: CdkVirtualScrollViewport;

  // select2ContactOption = {
  //   placeholder: 'Chọn liên hệ...',
  //   allowClear: true,
  //   width: '100%',
  //   dropdownAutoWidth: true,
  //   minimumInputLength: 0,
  //   // multiple: true,
  //   // tags: true,
  //   keyMap: {
  //     id: 'id',
  //     text: 'text',
  //   },
  //   ajax: {
  //     transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
  //       console.log(settings);
  //       const params = settings.data;
  //       this.apiService.getPromise('/contact/contacts', { includeIdText: true, includeGroups: true, filter_Name: params['term'] }).then(rs => {
  //         success(rs);
  //       }).catch(err => {
  //         console.error(err);
  //         failure();
  //       });
  //     },
  //     delay: 300,
  //     processResults: (data: any, params: any) => {
  //       console.info(data, params);
  //       return {
  //         results: data.map(item => {
  //           item['id'] = item['Code'];
  //           item['text'] = item['Code'] + ' - ' + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
  //           return item;
  //         }),
  //       };
  //     },
  //   },
  // };

  uploadConfig = {

  };

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<CollaboratorOpportunityFormComponent>,
    public mobileAppService: MobileAppService,
    public collaboratorService: CollaboratorService,
    public accountingService: AccountingService,
    // public changeDirectorRef: ChangeDetectorRef,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });
  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }


  public Editor = ClassicEditorBuild;
  public ckEditorConfig = {
    height: '200px',
    // plugins: [ImageResize],
    extraPlugins: [MyCustomUploadAdapterPlugin],
    simpleUpload: {
      uploadUrl: () => {
        // return this.apiService.getPromise<FileStoreModel[]>('/file/file-stores', { filter_Type: 'REMOTE', sort_Weight: 'asc', requestUploadToken: true, weight: 4194304, limit: 1 }).then(fileStores => {
        return this.cms.getAvailableFileStores().then(fileStores => fileStores[0]).then(fileStore => {
          return this.apiService.buildApiUrl(fileStore.Path + '/v1/file/files', { token: fileStore['UploadToken'] });
        });
      },
    },
  };

  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  select2OptionForPublisher = {
    ...this.cms.makeSelect2AjaxOption('/collaborator/publishers', {}, {
      placeholder: 'Chọn CTV Bán Hàng...', limit: 10, prepareReaultItem: (item) => {
        item['id'] = item.Contact;
        item['text'] = item.Name;
        return item;
      }
    }),
    // minimumInputLength: 1,
  };

  select2OptionForTarget: Select2Option = {
    placeholder: 'Chọn mục tiêu...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  select2OptionForProvince = {
    placeholder: 'Chọn tỉnh/TP...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      // url: (params, options: any) => {
      //   return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[PROVINCE,CITY]' });
      // },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        const params = settings.data;
        this.apiService.getPromise('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[PROVINCE,CITY]' }).then(rs => {
          success(rs);
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data
        };
      },
    },
  };
  select2OptionForDistrict = {
    placeholder: 'Chọn quận/huyện...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      url: (params, options: any) => {
        const formGroup = options?.formGroup;
        const provice = formGroup && this.cms.getObjectId(formGroup.get('DeliveryProvince').value);
        return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: provice });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        console.info(data, params);
        return {
          results: data
        };
      },
    },
  };

  select2OptionForWard = {
    placeholder: 'Chọn phường/xã/thị trấn...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      url: (params: any, options: any) => {
        const formGroup = options?.formGroup;
        const district = formGroup && this.cms.getObjectId(formGroup.get('DeliveryDistrict').value);
        return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: district });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data
        };
      },
    },
  };

  select2OptionForDeploymentProvince = {
    placeholder: 'Chọn tỉnh/TP...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      // url: (params, options: any) => {
      //   return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[PROVINCE,CITY]' });
      // },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        const params = settings.data;
        this.apiService.getPromise('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[PROVINCE,CITY]' }).then(rs => {
          success(rs);
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data
        };
      },
    },
  };
  select2OptionForDeploymentDistrict = {
    placeholder: 'Chọn quận/huyện...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      url: (params, options: any) => {
        const formGroup = options?.formGroup;
        const provice = formGroup && this.cms.getObjectId(formGroup.get('DeploymentProvince').value);
        return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: provice });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        console.info(data, params);
        return {
          results: data
        };
      },
    },
  };

  select2OptionForDeploymentWard = {
    placeholder: 'Chọn phường/xã/thị trấn...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      url: (params: any, options: any) => {
        const formGroup = options?.formGroup;
        const district = formGroup && this.cms.getObjectId(formGroup.get('DeploymentDistrict').value);
        return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: district });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data
        };
      },
    },
  };

  makeSelect2Option(select2Options: any, formGroup: FormGroup) {
    return {
      ...select2Options,
      formGroup
    }
  }

  select2OptionForProduct: Select2Option = {
    placeholder: 'Chọn...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    withThumbnail: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      data: function (params) {
        return {
          ...params,
          offset: params['offset'] || 0,
          limit: params['limit'] || 10
        };
      },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        const params = settings.data;
        const offset = settings.data['offset'];
        const limit = settings.data['limit'];
        const results = !params['term'] ? this.adminProductService.productSearchIndexsGroupById : this.adminProductService.productSearchIndexsGroupById.filter(f => this.cms.smartFilter(f.SearchText, params['term']));
        success({ data: results.slice(offset, offset + limit), total: results.length });
        return null;
      },
      delay: 300,
      processResults: (rs: { data: any[], total: number }, params: any) => {
        const data = rs.data;
        const total = rs.total;
        params.limit = params.limit || 10;
        params.offset = params.offset || 0;
        params.offset = params.offset += params.limit;
        return {
          results: data.map(item => {
            item.id = item.Code;
            item.text = `${item.Sku} - ${item.Name} (${item.Code})`;
            item.thumbnail = item?.FeaturePicture?.Thumbnail;
            return item;
          }),
          pagination: {
            more: params.offset < total
          }
        };
      },
    },
  };

  select2OptionForUnit = {
    placeholder: 'Chọn ĐVT...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  select2OptionForTax = {
    placeholder: 'Chọn thuế...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  // Type field option
  select2OptionForType = {
    placeholder: 'Chọn loại...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2DataForType = [
    { id: 'PRODUCT', text: 'Sản phẩm' },
    { id: 'CATEGORY', text: 'Danh mục' },
  ];

  // Costs field option
  select2OptionForCosts = {
    placeholder: 'Phí cơ hội...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: true,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2OptionForSideOfPayment = {
    placeholder: 'Bên trả phí...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: false,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: [
      { id: 'BUYER', text: 'Bên mua' },
      { id: 'SELLER', text: 'Bên bán' },
    ],
  };
  select2DataForCosts = [
    // { id: 'BASIC', text: 'Cơ bản' },
    // { id: 'ADVANCE', text: 'Nâng cao' },
    // { id: 'ADDON', text: 'Add-on' },
  ];

  availableShippingUnitList: ContactModel[] = [];
  select2OptionForShippingUnit = {
    ...this.cms.select2OptionForTemplate,
    placeholder: 'Nhân viên/ĐV Vận chuyển...',
  };

  objectControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Customer').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoading: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name };
            formGroup.get('Customer').patchValue(newContact);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  contactControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Publisher').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoading: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name };
            formGroup.get('Publisher').patchValue(newContact);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  // accountingBusinessList: BusinessModel[] = [];
  // select2OptionForAccountingBusiness = {
  //   placeholder: 'Nghiệp vụ kế toán...',
  //   allowClear: true,
  //   width: '100%',
  //   dropdownAutoWidth: true,
  //   minimumInputLength: 0,
  //   // dropdownCssClass: 'is_tags',
  //   maximumSelectionLength: 1,
  //   multiple: true,
  //   // tags: true,
  //   keyMap: {
  //     id: 'Code',
  //     text: 'Name',
  //   },
  // };

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    /** Load and cache tax list */
    this.taxList = (await this.apiService.getPromise<TaxModel[]>('/accounting/taxes')).map(tax => {
      tax['id'] = tax.Code;
      tax['text'] = tax.Name;
      return tax;
    });

    /** Load and cache unit list */
    this.unitList = (await this.apiService.getPromise<UnitModel[]>('/admin-product/units', { limit: 'nolimit' })).map(tax => {
      tax['id'] = tax.Code;
      tax['text'] = tax.Name;
      return tax;
    });

    this.accountingService.accountingBusinessList$.pipe(takeUntil(this.destroy$), filter(f => !!f)).subscribe(accountingBusinessList => {
      this.select2DataForCosts = accountingBusinessList.filter(f => f.Type == 'CLBRTOPPORTUNITYCOST');
    });

    this.select2OptionForTarget.data = await this.apiService.getPromise<any[]>(this.apiPath + '/targets');

    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);
          this.getDetails(formItem as FormGroup).controls.forEach(conditonFormGroup => {
            // Clear id
            // conditonFormGroup.get('Id').setValue('');
          });
        });
      }

      this.actionButtonList.unshift({
        type: 'button',
        name: 'opentask',
        status: 'primary',
        label: 'Chat với CTV Bán hàng',
        icon: 'message-circle',
        title: 'Chat với CTV Bán hàng',
        size: 'medium',
        click: () => {
          this.cms.showDialog('Chat với CTV Bán hàng', 'Bạn có muốn chát với CTV Bán hàng không ? hệ thống sẽ tạo task và add CTV Bán hàng liên quan vào !', [
            {
              status: 'basic',
              label: 'Trở về',
            },
            {
              status: 'primary',
              icon: 'message-circle',
              label: 'Chat',
              action: async () => {
                const voucher = this.array.controls[0].value;
                let task = voucher.RelativeVouchers?.find(f => f.type == 'CHATROOM');
                if (task) {
                  this.cms.openMobileSidebar();
                  this.mobileAppService.openChatRoom({ ChatRoom: task.id });
                } else {
                  // Assign resource to chat room
                  task = await this.apiService.putPromise<ChatRoomModel[]>('/chat/rooms', { assignResource: true }, [{
                    Code: null,
                    Resources: [
                      {
                        ResourceType: 'CLBRTOPPORTUNITY',
                        Resource: voucher.Code,
                        Title: voucher.Title,
                        Date: voucher.DateOfOpportunity,
                      }
                    ]
                  }]).then(rs => {
                    if (rs && rs.length > 0) {
                      // const link = rs[0].Resources[0];
                      // if (link && link.ChatRoom) {

                      // Add publisher to chat room
                      if (this.cms.getObjectId(voucher.Publisher)) {
                        this.apiService.putPromise<ChatRoomMemberModel[]>('/chat/room-members', { chatRoom: rs[0].Code }, [{
                          ChatRoom: rs[0].Code as any,
                          Type: 'CONTACT',
                          RefUserUuid: voucher.PublisherRefId,
                          Name: this.cms.getObjectText(voucher.Publisher) || voucher.PublisherName,
                          Page: voucher.Page,
                          RefPlatform: 'PROBOXONE',
                          RefType: 'PUBLISHER',
                          id: this.cms.getObjectId(voucher.Publisher),
                          text: this.cms.getObjectText(voucher.Publisher) || voucher.PublisherName,
                        }]).then(rs2 => {

                          // Connect publisher
                          this.apiService.putPromise<ChatRoomMemberModel[]>('/chat/room-members', { chatRoom: rs[0].Code, connectRefContactMember: true }, [{
                            Type: 'CONTACT',
                            Contact: rs2[0].Contact,
                          }]).then(rs3 => {
                            this.cms.openMobileSidebar();
                            this.mobileAppService.openChatRoom({ ChatRoom: rs[0].Code });
                          });

                        });
                      } else {
                        this.cms.openMobileSidebar();
                        this.mobileAppService.openChatRoom({ ChatRoom: rs[0].Code });
                      }

                      // }
                      return { id: rs[0].Code, text: voucher.Title, type: 'TASK' };
                    }
                  });
                }
              },
            }
          ]);
          return false;
        },
      });

      // this.changeDirectorRef.detectChanges();//https://viblo.asia/p/tim-hieu-ve-change-detection-trong-angular-djeZ18EjKWz
      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: CollaboratorOpportunityModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includePublisher'] = true;
    params['includeCustomer'] = true;
    params['includeDetails'] = true;
    params['includeComments'] = true;
    params['includeRelativeVouchers'] = true;
    params['useBaseTimezone'] = true;
    params['includeTransportPoints'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: CollaboratorOpportunityModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: CollaboratorOpportunityModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Details form load
      if (itemFormData.Details) {
        const details = this.getDetails(newForm);
        itemFormData.Details.forEach(detail => {
          const newDetailFormGroup = this.makeNewDetailFormGroup(newForm, detail);
          details.push(newDetailFormGroup);
          // const comIndex = details.length - 1;
          this.onAddDetailFormGroup(newForm, newDetailFormGroup, details.length - 1);
        });
        this.setNoForArray(details.controls as FormGroup[], (detail: FormGroup) => detail.get('Type').value === 'PRODUCT');
      }

      const contactIds = [
        ...new Set([
          ...itemFormData.TransportPoints.map(m => this.cms.getObjectId(m.Supplier)).filter(f => !!f),
          ...itemFormData.TransportPoints.map(m => this.cms.getObjectId(m.ShippingUnit)).filter(f => !!f),
        ])];

      const cotnacts = (contactIds.length > 0 ? (await this.apiService.getPromise<ContactModel[]>('/contact/contacts', {
        eq_Code: `[${contactIds.join(',')}]`,
        includeIdText: true,
        includeGroups: true,
      })) : []).reduce((result, curr, index) => {
        result[curr.id] = curr;
        curr['text'] = (curr['Title'] ? (curr['Title'] + '. ') : '') + (curr['ShortName'] ? (curr['ShortName'] + '/') : '') + curr['Name'] + ' - ' + curr['Code'] + (curr['Groups'] ? (' (' + curr['Groups'].map(g => g.text).join(', ') + ')') : '');
        return result;
      }, {} as { [key: string]: ContactModel });


      if (itemFormData.TransportPoints) {
        const transportPointArray = this.getTransportPoints(newForm);
        for (const transportPoint of itemFormData.TransportPoints) {
          if (transportPoint.ShippingUnit) {
            transportPoint.ShippingUnit = cotnacts[this.cms.getObjectId(transportPoint.ShippingUnit)];
          }
          const newTransportPointFormGroup = this.makeNewTransportPointFormGroup(newForm, transportPoint);
          transportPointArray.push(newTransportPointFormGroup);
          const comIndex = transportPointArray.length - 1;
          this.onAddTransportPointFormGroup(newForm, newTransportPointFormGroup, comIndex);
        }
        this.setNoForArray(transportPointArray.controls as FormGroup[], (detail: FormGroup) => true);
      }


      // setTimeout(() => {
      //   this.detailsViewport.checkViewportSize();
      // }, 1500);
      this.cms.waitFor(150, 20, async () => !!this.detailsViewport).then(() => this.detailsViewport.checkViewportSize());

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  makeNewFormGroup(data?: CollaboratorOpportunityModel): FormGroup {
    if (data?.DateOfOpportunity) {
      data.DateOfOpportunity = this.cms.datePipe.transform(data.DateOfOpportunity, 'shortDate');
    }
    const newForm = this.formBuilder.group<any>({
      Code: { value: null, disabled: true },
      Target: [null, Validators.required],
      Page: { disabled: true, value: this.collaboratorService.currentpage$?.value || null },
      Customer: [null, Validators.required],
      CustomerName: { disabled: true, value: null },
      CustomerEmail: { disabled: true, value: null },
      CustomerPhone: { disabled: true, value: null },
      CustomerCallcenterNumber: { disabled: true, value: null },
      CustomerAddress: { disabled: true, value: null },
      CustomerIdentifiedNumber: { disabled: true, value: null },
      Recipient: [],
      CustomerTaxCode: [],
      // DirectReceiverName: [''],
      CustomerBankName: [],
      CustomerBankCode: [],

      Publisher: [],
      PublisherRefId: [],
      PublisherName: { disabled: true, value: null },
      PublisherPhone: { disabled: true, value: null },
      PublisherEmail: { disabled: true, value: null },
      PublisherAddress: { disabled: true, value: null },
      PublisherIdentifiedNumber: { disabled: true, value: null },

      DirectReceiver: [],
      DirectReceiverName: [],
      DirectReceiverPhone: [],
      DirectReceiverEmail: [],

      DeliveryProvince: [],
      DeliveryDistrict: [],
      DeliveryWard: [],
      DeliveryAddress: [],
      DeliveryMapLink: [],

      // DeploymentProvince: [],
      // DeploymentDistrict: [],
      // DeploymentWard: [],
      DeploymentAddress: [],
      DeploymentMapLink: [],
      // DeliveryCost: [],

      DateOfReceived: [''],
      Title: [null, Validators.required],
      Note: [],
      SubNote: [],
      Costs: [[]],
      // DateOfOpportunity: {value: null, disabled: true},
      // RelativeVouchers: [],
      _total: [0],
      RelativeVouchers: [],
      TransportPoints: this.formBuilder.array([]),
      // RequireInvoice: [false],
      Details: this.formBuilder.array([]),
    });
    if (data) {
      // data['Code_old'] = data['Code'];
      newForm.patchValue(data);
    } else {
      this.addDetailFormGroup(newForm);
    }
    newForm['_details'] = this.getDetails(newForm);
    newForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      console.log('Form value change: ', value);
    });
    if (data) {
      newForm['Comments'] = (data.Comments || []).map(m => {
        return {
          text: m.Content,
          date: new Date(m.DateOfPost),
          user: {
            id: m.Sender,
            name: m.SenderName,
            avatar: m.SenderAvatar?.Thumbnail,
          },
          files: (m.Attachments || []).map(a => ({
            url: a.LargeImage,
            type: a.Type,
            icon: 'nb-compose',
          }))
        };
      });
    }
    this.disabledControls.push(newForm.get('DateOfOpportunity'));

    const transportPointArray = newForm['TransportPoints'] = newForm.get('TransportPoints') as FormArray;
    // Auto sum transport cost
    transportPointArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((transportPoints: MultifunctionalPurchaseTransportPointModel[]) => {
      this.cms.takeUntil('987346_transport_points_change', 300, () => {
        console.log('987346_transport_points_change: ', transportPoints);
        newForm['_totalTransportCost'] = 0;
        for (const transportPoint of transportPoints) {
          newForm['_totalTransportCost'] += parseFloat(transportPoint.TransportCost as any);
        }
      });
    });
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: CollaboratorOpportunityModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/opportunity/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Detail Form */
  makeNewDetailFormGroup(parentFormGroup: FormGroup, data?: CollaboratorOpportunityDetailModel): FormGroup {
    let newForm: FormGroup;
    newForm = this.formBuilder.group<any>({
      // Id: [''],
      SystemUuid: [''],
      No: [''],
      Type: ['PRODUCT'],
      Product: ['', (control: FormControl) => {
        if (newForm && newForm.get('Type').value === 'PRODUCT' && !this.cms.getObjectId(control.value)) {
          return { invalidName: true, required: true, text: 'trường bắt buộc' };
        }
        return null;
      }],
      Description: ['', Validators.required],
      Quantity: [1],
      Price: [],
      Unit: ['', (control: FormControl) => {
        if (newForm && this.cms.getObjectId(newForm.get('Type').value) === 'PRODUCT' && !this.cms.getObjectId(control.value)) {
          return { invalidName: true, required: true, text: 'trường bắt buộc' };
        }
        return null;
      }],
      // Tax: ['VAT10'],
      ToMoney: [0],
      Image: [[]],
      Reason: [''],
      SupplierSku: [''],
      SupplierProductName: [''],
      ProductTaxName: [''],
      Tax: [''],
      // Business: [null, (control: FormControl) => {
      //   if (newForm && this.cms.getObjectId(newForm.get('Type').value) === 'PRODUCT' && !this.cms.getObjectId(control.value)) {
      //     return { invalidName: true, required: true, text: 'trường bắt buộc' };
      //   }
      //   return null;
      // }]
      IsRelativeToPublisher: [false],
    });

    newForm['__type'] = 'PRODUCT';
    if (data) {
      if (data?.Product && Array.isArray(data.Product['Units'])) {
        const unitControl = newForm.get('Unit');
        newForm['UnitList'] = data?.Product['Units'];
      }
      // (async () => {
      newForm.patchValue(data);
      if (!data['Type']) {
        data["Type"] = 'PRODUCT';
      }
      // await new Promise(resolve => setTimeout(() => resolve(true), 300));
      // this.toMoney(parentFormGroup, newForm, null, );
      // })()
      newForm['__type'] = data["Type"];
    }
    newForm.get('Type').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      newForm['__type'] = this.cms.getObjectId(value);
      // if (newForm['__type'] == 'CATEGORY') {
      newForm.get('Image').setValue([]);
      newForm.get('Product').setValue(null);
      newForm.get('Unit').setValue(null);
      newForm.get('Quantity').setValue(null);
      newForm.get('Price').setValue(null);
      newForm.get('ToMoney').setValue(null);
      newForm.get('Description').setValue(null);
      newForm.get('SupplierSku').setValue(null);
      newForm.get('SupplierProductName').setValue(null);
      newForm.get('ProductTaxName').setValue(null);
      newForm.get('Tax').setValue(null);
      // }
    });
    return newForm;
  }
  getDetails(parentFormGroup: FormGroup) {
    return parentFormGroup.get('Details') as FormArray;
  }
  addDetailFormGroup(parentFormGroup: FormGroup) {
    const newChildFormGroup = this.makeNewDetailFormGroup(parentFormGroup);
    const detailsFormArray = this.getDetails(parentFormGroup);
    detailsFormArray.push(newChildFormGroup);
    const noFormControl = newChildFormGroup.get('No');
    if (!noFormControl.value) {
      noFormControl.setValue(detailsFormArray.length);
    }
    detailsFormArray.controls = [...detailsFormArray.controls];
    this.onAddDetailFormGroup(parentFormGroup, newChildFormGroup, detailsFormArray.length - 1);
    return false;
  }
  removeDetailGroup(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    const details = this.getDetails(parentFormGroup);
    details.removeAt(index);
    details.controls = [...details.controls];
    this.onRemoveDetailFormGroup(parentFormGroup, detail);
    return false;
  }

  purchaseProductMap: { [key: string]: PurchaseProductModel } = {};
  onAddDetailFormGroup(parentFormGroup: FormGroup, newChildFormGroup: FormGroup, index: number) {
    this.toMoney(parentFormGroup, newChildFormGroup, 'Quantity', index, { force: true });
    // newChildFormGroup.get('Quantity').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => this.toMoney(parentFormGroup, newChildFormGroup, 'Quantity', index));
    // newChildFormGroup.get('Price').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => this.toMoney(parentFormGroup, newChildFormGroup, 'Price', index));
    // newChildFormGroup.get('ToMoney').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => this.toMoney(parentFormGroup, newChildFormGroup, 'ToMoney', index));
    // newChildFormGroup.get('Type').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => this.toMoney(parentFormGroup, newChildFormGroup, 'Type', index));
    // Load product name
    const productControl = newChildFormGroup.get('Product');
    productControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {

      const productId = this.cms.getObjectId(value);
      const supplierId = this.cms.getObjectId(parentFormGroup.get('Customer').value);
      let purchaseProduct = this.purchaseProductMap[productId + '-' + supplierId];
      if (typeof purchaseProduct == 'undefined') {
        purchaseProduct = await this.apiService.getPromise<PurchaseProductModel[]>('/purchase/products/', { eq_Product: productId, eq_Supplier: supplierId, sort_LastUpdate: 'desc' }).then(rs => rs[0]);
        this.purchaseProductMap[productId + '-' + supplierId] = purchaseProduct || null;
      }

      if (purchaseProduct) {
        if (!newChildFormGroup['IsImport'] || !newChildFormGroup.get('SupplierProductName').value) {
          newChildFormGroup.get('SupplierProductName').setValue(purchaseProduct.Name);
        }
        if (!newChildFormGroup['IsImport'] || !newChildFormGroup.get('ProductTaxName').value) {
          newChildFormGroup.get('ProductTaxName').setValue(purchaseProduct.TaxName);
        }
        if (!newChildFormGroup['IsImport'] || !newChildFormGroup.get('SupplierSku').value) {
          newChildFormGroup.get('SupplierSku').setValue(purchaseProduct.Sku);
        }
        if (!newChildFormGroup['IsImport'] || !newChildFormGroup.get('Tax').value) {
          newChildFormGroup.get('Tax').setValue(purchaseProduct.TaxValue);
        }
      }
    });

    // const businessControl = newChildFormGroup.get('AccBusiness');
    // newChildFormGroup.get('Unit').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async untiConversion => {

    //   if (productControl.value?.Type == 'PRODUCT') {
    //     if (untiConversion?.IsAutoAdjustInventory === false) {
    //       // if(!businessControl.value || businessControl.value.length == 0){
    //       businessControl.setValue([this.accountingBusinessList.find(f => this.cms.getObjectId(f) == 'PURCHASESKIPWAREHOUSE')]);
    //       // }
    //     }
    //     if (untiConversion?.IsAutoAdjustInventory === true) {
    //       // if(!businessControl.value || businessControl.value.length == 0){
    //       businessControl.setValue([this.accountingBusinessList.find(f => this.cms.getObjectId(f) == 'PURCHASEWAREHOUSE')]);
    //       // }
    //     }
    //   }
    //   if (productControl.value?.Type == 'SERVICE') {
    //     businessControl.setValue([this.accountingBusinessList.find(f => this.cms.getObjectId(f) == 'PURCHASECOST')]);
    //   }
    // });
  }
  onRemoveDetailFormGroup(parentFormGroup: FormGroup, detailFormGroup: FormGroup) {
  }

  /**
   * Choose product form recent purchase order and add to details
   * @param parentFormGroup
   * @returns
   */
  addMultiProducts(parentFormGroup: FormGroup) {

    const filter = { group_Customer: true, group_Product: true, includeUnit: true };
    const objectId = this.cms.getObjectId(parentFormGroup.get('Customer').value);
    if (objectId) {
      filter['eq_Customer'] = objectId;
      filter['sort_DateOfOpportunity'] = 'desc';
    }

    this.cms.openDialog(DynamicListDialogComponent, {
      context: {
        inputMode: 'dialog',
        choosedMode: true,
        onDialogChoose: async (choosedItems: CollaboratorOpportunityDetailModel[]) => {
          console.log(choosedItems);
          const productIds = choosedItems.map(m => m.Product);
          const productList = await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { eq_Code: '[' + productIds.join(',') + ']', select: "id=>Code,text=>Name,Code=>Code,Name,OriginName=>Name,Sku,FeaturePicture,Pictures", includeSearchResultLabel: true, includeUnits: true });
          const details = this.getDetails(parentFormGroup);
          for (const product of productList) {
            const chooseItem = choosedItems.find(f => f.Product as any == product.Code);
            const newDetailFormGroup = this.makeNewDetailFormGroup(parentFormGroup, {
              Product: product as any,
              Price: chooseItem?.Price,
              Quantity: chooseItem?.Quantity,
              Image: chooseItem?.Image
            });
            newDetailFormGroup['UnitList'] = product.Units;
            details.push(newDetailFormGroup);
            newDetailFormGroup.get('Unit').setValue(product.Units.find(f => f['DefaultImport']));
            this.onAddDetailFormGroup(parentFormGroup, newDetailFormGroup, details.length - 1);
          }
          this.setNoForArray(details.controls as FormGroup[], (detail: FormGroup) => detail.get('Type').value === 'PRODUCT');
          // this.changeDirectorRef.detectChanges();
        },
        title: 'Danh sách hàng hóa đã đặt hàng nhà cung cấp ' + parentFormGroup.get('CustomerName').value,
        apiPath: '/purchase/order-voucher-details',
        idKey: ['Product'],
        params: filter,
        // actionButtonList: [],
        listSettings: {
          // pager: {
          //   display: true,
          //   perPage: 10,
          // },
          actions: false,
          columns: {
            // No: {
            //   title: 'No.',
            //   type: 'string',
            //   width: '5%',
            //   filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
            // },
            Order: {
              title: this.cms.textTransform(this.cms.translate.instant('Common.voucher'), 'head-title'),
              type: 'text',
              renderComponent: SmartTableTagsComponent,
              // onComponentInitFunction: (instance: SmartTableTagsComponent) => {
              //   instance.click.subscribe((voucher: string) => this.cms.previewVoucher('CLBRTORDER', voucher));
              // },
              width: '10%',
              // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
              // valuePrepareFunction: (cell: string, row: any) => {
              //   return [{ id: cell, text: cell }] as any;
              // },
            },
            Product: {
              title: this.cms.textTransform(this.cms.translate.instant('Common.product'), 'head-title'),
              type: 'string',
              width: '10%',
              filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
            },
            Description: {
              title: this.cms.textTransform(this.cms.translate.instant('Common.description'), 'head-title'),
              type: 'string',
              width: '40%',
              filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
            },
            Unit: {
              title: this.cms.textTransform(this.cms.translate.instant('Product.unit'), 'head-title'),
              type: 'string',
              width: '10%',
              filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
              valuePrepareFunction: (cell, row) => {
                return this.cms.getObjectText(cell);
              }
            },
            Quantity: {
              title: this.cms.textTransform(this.cms.translate.instant('Common.quantity'), 'head-title'),
              type: 'string',
              width: '10%',
              filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
            },
            Price: {
              title: this.cms.textTransform(this.cms.translate.instant('Common.price'), 'head-title'),
              type: 'custom',
              class: 'align-right',
              width: '10%',
              position: 'right',
              renderComponent: SmartTableCurrencyComponent,
              onComponentInitFunction: (instance: SmartTableCurrencyComponent) => {
                // instance.format$.next('medium');
                instance.style = 'text-align: right';
              },
            },
            ToMoney: {
              title: this.cms.textTransform(this.cms.translate.instant('Common.numOfMoney'), 'head-title'),
              type: 'custom',
              class: 'align-right',
              width: '10%',
              position: 'right',
              renderComponent: SmartTableCurrencyComponent,
              onComponentInitFunction: (instance: SmartTableCurrencyComponent) => {
                // instance.format$.next('medium');
                instance.style = 'text-align: right';
              },
              valuePrepareFunction: (cell: string, row: CollaboratorOpportunityDetailModel) => {
                return `${row.Quantity * row.Price}`;
              },
            },
            Preview: {
              title: this.cms.translateText('Common.show'),
              type: 'custom',
              width: '5%',
              class: 'align-right',
              renderComponent: SmartTableButtonComponent,
              onComponentInitFunction: (instance: SmartTableButtonComponent) => {
                instance.iconPack = 'eva';
                instance.icon = 'external-link-outline';
                instance.display = true;
                instance.status = 'primary';
                instance.style = 'text-align: right';
                instance.class = 'align-right';
                instance.title = this.cms.translateText('Common.preview');
                instance.valueChange.subscribe(value => {
                  // instance.icon = value ? 'unlock' : 'lock';
                  // instance.status = value === 'REQUEST' ? 'warning' : 'success';
                  // instance.disabled = value !== 'REQUEST';
                });
                instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: CollaboratorOpportunityDetailModel) => {
                  this.cms.previewVoucher('PURCHASEORDER', rowData.Order);
                });
              },
            }
          }
        }
      },
    });

    return false;
  }
  /** End Detail Form */

  /** Transport Trip Form */
  makeNewTransportPointFormGroup(parentFormGroup: FormGroup, data?: MultifunctionalPurchaseTransportPointModel): FormGroup {
    let newForm: FormGroup = null;
    newForm = this.formBuilder.group<any>({
      // Id: [''],
      SystemUuid: [''],
      No: [''],
      ShippingUnit: [],
      ShippingUnitPhone: [],
      DestinationFullAddress: [],
      ShippingUnitMapLink: [],
      // ShippingUnitName: [],
      // TransportFrom: [],
      // TransportTo: [],
      Note: [],
      SideOfPayment: [],
      TransportCost: [],
      // Business: [],
    });

    if (data) {
      newForm.patchValue(data);
    }

    const shipingUnit = newForm.get('ShippingUnit');
    const shipingUnitPhone = newForm.get('ShippingUnitPhone');
    const shipingUnitMapLink = newForm.get('ShippingUnitMapLink');
    // const shipingUnitAddress = newForm.get('DestinationFullAddress');
    shipingUnit.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((contact: ContactModel) => {
      if (!this.isProcessing) {
        console.log('Shipping unit change: ', contact);
        shipingUnitPhone.setValue(contact?.Phone);
        // shipingUnitAddress.setValue(contact?.FullAddress);
        shipingUnitMapLink.setValue(contact?.MapLink);
      }
    });
    return newForm;
  }

  onTransportPointSupplierChange(detail: FormGroup, supplier: ContactModel, formItem: FormGroup) {
    console.log(supplier);
  }
  onShippingUnitChange(transportPoint: FormGroup, shippingUnit: ContactModel, formItem: FormGroup) {
    console.log(shippingUnit);
    if (shippingUnit) {
      transportPoint.get('ShippingUnitPhone').setValue(shippingUnit.Phone);
      // transportPoint.get('ShippingUnitEmail').setValue(shippingUnit.Email);
    }
  }

  getTransportPoints(parentFormGroup: FormGroup) {
    return parentFormGroup.get('TransportPoints') as FormArray;
  }
  addTransportPointFormGroup(parentFormGroup: FormGroup) {
    const newChildFormGroup = this.makeNewTransportPointFormGroup(parentFormGroup);
    const detailsFormArray = this.getTransportPoints(parentFormGroup);
    detailsFormArray.push(newChildFormGroup);
    const noFormControl = newChildFormGroup.get('No');
    if (!noFormControl.value) {
      noFormControl.setValue(detailsFormArray.length);
    }
    this.onAddTransportPointFormGroup(parentFormGroup, newChildFormGroup, detailsFormArray.length - 1);
    return false;
  }
  removeTransportPointGroup(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    this.getTransportPoints(parentFormGroup).removeAt(index);
    this.onRemoveTransportPointFormGroup(parentFormGroup, detail);
    return false;
  }
  onAddTransportPointFormGroup(parentFormGroup: FormGroup, newChildFormGroup: FormGroup, index: number) {
  }
  onRemoveTransportPointFormGroup(parentFormGroup: FormGroup, detailFormGroup: FormGroup) {
  }
  /** End TransportPoint Form */

  onCustomerChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('CustomerName').setValue(selectedData.Name);
          formGroup.get('CustomerPhone').setValue(selectedData.Phone);
          formGroup.get('CustomerCallcenterNumber').setValue(selectedData.CallcenterNumber);
          formGroup.get('CustomerEmail').setValue(selectedData.Email);
          formGroup.get('CustomerAddress').setValue(selectedData.FullAddress);
          // formGroup.get('CustomerTaxCode').setValue(selectedData.TaxCode);
          // formGroup.get('CustomerBankName').setValue(selectedData.BankName);
          // formGroup.get('CustomerBankCode').setValue(selectedData.BankAcc);
        }
      }
    }
  }

  onDirectReceiverChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {
        if (selectedData.Code) {
          formGroup.get('DirectReceiverName').setValue(selectedData.Name);
          if (selectedData['Phone'] && selectedData['Phone']['restricted']) formGroup.get('DirectReceiverPhone')['placeholder'] = selectedData['Phone']['placeholder']; else formGroup.get('DirectReceiverPhone').setValue(selectedData['Phone']);
          if (selectedData['Email'] && selectedData['Email']['restricted']) formGroup.get('DirectReceiverEmail')['placeholder'] = selectedData['Email']['placeholder']; else formGroup.get('DirectReceiverEmail').setValue(selectedData['Email']);
        }
      }
    }
  }

  onPublisherChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('PublisherName').setValue(this.cms.getObjectText(selectedData));
          formGroup.get('PublisherPhone').setValue(selectedData.Phone);
          formGroup.get('PublisherEmail').setValue(selectedData.Email);
          formGroup.get('PublisherAddress').setValue(selectedData.Address);
        }
      }
    }
  }

  /** Choose product event */
  async onSelectProduct(detail: FormGroup, selectedData: ProductModel, parentForm: FormGroup) {
    console.log(selectedData);
    const productId = this.cms.getObjectId(selectedData);
    detail.get('Image').setValue(selectedData.Pictures || (selectedData.FeaturePicture ? [selectedData.FeaturePicture] : []));
    const descriptionControl = detail.get('Description');
    descriptionControl.setValue(selectedData['Name']);
    const unitPriceMap = await this.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-tables/getProductPriceByUnits', {
      priceTable: 'default',
      product: this.cms.getObjectId(detail.get('Product').value),
      includeUnit: true,
    }).then(rs => rs.reduce((result, current, index) => { result[current.Product + '-' + current.Unit] = current.Price; return result; }, {}));
    if (productId) {
      if (selectedData.Units && selectedData?.Units.length > 0) {
        selectedData.Units.map(m => { m.Price = unitPriceMap[productId + '-' + this.cms.getObjectId(m)]; return m; })
        const defaultUnit = selectedData.Units.find(f => f['IsDefaultSales'] === true) || selectedData.Units[0];
        detail['UnitList'] = selectedData.Units;
        detail.get('Unit').setValue(defaultUnit);
      }
    }
  }

  calculatToMoney(detail: FormGroup, source?: string) {
    if (source === 'ToMoney') {
      const price = detail.get('ToMoney').value / detail.get('Quantity').value;
      return price;
    } else {
      const toMoney = detail.get('Quantity').value * detail.get('Price').value;
      return toMoney;
    }
  }

  toMoney(formItem: FormGroup, detail: FormGroup, source?: string, index?: number, option?: { force: boolean }) {
    console.log('toMoney for ' + source);
    if (option?.force || !this.isProcessing) {
      // this.cms.takeUntil(this.componentName + '_ToMoney_ ' + index, 300).then(() => {
      let quantity = detail.get('Quantity').value || 0;
      let toMoney = detail.get('ToMoney').value || 0;
      let price = detail.get('Price').value;
      switch (source) {
        case 'Price':
          toMoney = quantity * price;
          (detail.get('ToMoney') as FormControl).setValue(toMoney, { onlySelf: true, emitEvent: false });
          break;
        case 'Quantity':
          toMoney = quantity * price;
          detail.get('ToMoney').setValue(toMoney, { onlySelf: true, emitEvent: false });
          break;
        case 'ToMoney':
          price = toMoney / quantity;
          detail.get('Price').setValue(price, { onlySelf: true, emitEvent: false });
          break;
      }

      // Call culate total
      const details = this.getDetails(formItem);
      let total = 0;
      for (let i = 0; i < details.controls.length; i++) {
        let price = details.controls[i].get('Price').value;
        let quantity = details.controls[i].get('Quantity').value || 0;
        total += price * quantity;
      }
      formItem.get('_total').setValue(total);
      // });
    }
    return false;
  }

  /** Choose unit event */
  onSelectUnit(detail: FormGroup, selectedData: UnitModel, formItem: FormGroup) {
    if (selectedData && selectedData.Price !== null) {
      detail.get('Price').setValue(selectedData.Price);
      this.toMoney(formItem, detail);
    }
    return false;
  }


  async preview(formItem: FormGroup) {
    const data: CollaboratorOpportunityModel = formItem.value;
    this.cms.openDialog(CollaboratorOpportunityPrintComponent, {
      context: {
        showLoading: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: CollaboratorOpportunityModel) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: CollaboratorOpportunityModel) => {
          this.save();
        },
      },
    });
    return false;
  }

  getRawFormData() {
    return super.getRawFormData();
  }

  openRelativeVoucher(relativeVocher: any) {
    if (relativeVocher) this.cms.previewVoucher(this.cms.getObjectId(relativeVocher.type), relativeVocher);
    return false;
  }

  customIcons: { [key: string]: CustomIcon[] } = {};
  getCustomIcons(name: string): CustomIcon[] {
    if (this.customIcons[name]) return this.customIcons[name];
    return this.customIcons[name] = [{
      icon: 'plus-square-outline',
      title: this.cms.translateText('Common.addNewProduct'),
      status: 'success',
      states: {
        '<>': {
          icon: 'edit-outline',
          status: 'primary',
          title: this.cms.translateText('Common.editProduct'),
        },
        '': {
          icon: 'plus-square-outline',
          status: 'success',
          title: this.cms.translateText('Common.addNewProduct'),
        },
      },
      action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
        const currentProduct = this.cms.getObjectId(formGroup.get('Product').value);
        this.cms.openDialog(ProductFormComponent, {
          context: {
            inputMode: 'dialog',
            inputId: currentProduct ? [currentProduct] : null,
            showLoading: true,
            onDialogSave: (newData: ProductModel[]) => {
              console.log(newData);
              // const formItem = formGroupComponent.formGroup;
              const newProduct: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name, Units: newData[0].UnitConversions?.map(unit => ({ ...unit, id: this.cms.getObjectId(unit?.Unit), text: this.cms.getObjectText(unit?.Unit) })) };
              formGroup.get('Product').patchValue(newProduct);
            },
            onDialogClose: () => {

            },
          },
          closeOnEsc: false,
          closeOnBackdropClick: false,
        });
      }
    }];
  }

  openRelativeVoucherChoosedDialog(formGroup: FormGroup) {
    this.cms.openDialog(ReferenceChoosingDialogComponent, {
      context: {
        components: {
          'COMMERCEPOSORDER': { title: 'Đơn hàng POS' },
          'SALES': { title: 'Phiếu bán hàng' },
          'PRICEREPORT': { title: 'Phiếu báo giá' },
        },
        // inputMode: 'dialog',
        onDialogChoose: async (chooseItems: any[], type?: string) => {
          console.log(chooseItems);
          const relationVoucher = formGroup.get('RelativeVouchers');
          const relationVoucherValue: any[] = (relationVoucher.value || []);
          const insertList = [];
          this.onProcessing();
          // if (type === 'COMMERCEPOSORDER') {
          //   const details = this.getDetails(formGroup);
          //   for (let i = 0; i < chooseItems.length; i++) {
          //     const index = relationVoucherValue.findIndex(f => f?.id === chooseItems[i]?.Code);
          //     if (index < 0) {
          //       // get purchase order
          //       const voucher = await this.apiService.getPromise<CommercePosOrderModel[]>('/commerce-pos/orders/' + chooseItems[i].Code, { includeContact: true, includeObject: true, includeDetails: true, includeRelativeVouchers: true, includeUnit: true }).then(rs => rs[0]);

          //       if (['PRICEREPORT'].indexOf(this.cms.getObjectId(voucher.State)) < 0) {
          //         this.cms.toastService.show(this.cms.translateText('Đơn đặt POS chưa được báo giá'), this.cms.translateText('Common.warning'), { status: 'warning' });
          //         continue;
          //       }
          //       delete voucher.Id;
          //       formGroup.patchValue({ ...voucher, Code: null, Id: null, Object: null, ObjectName: null, ObjectPhone: null, PbjectAddress: null, ObjectIdentifiedNumber: null, Details: [] });
          //       details.clear();
          //       insertList.push(chooseItems[i]);

          //       // Insert order details into voucher details
          //       if (voucher?.Details) {
          //         details.push(this.makeNewDetailFormGroup(formGroup, { Type: 'CATEGORY', Description: 'Đơn đặt hàng POS: ' + voucher.Code + ' - ' + voucher.Title }));
          //         for (const voucherDetail of voucher.Details) {
          //           if (voucherDetail.Type === 'PRODUCT') {
          //             const newDetailFormGroup = this.makeNewDetailFormGroup(formGroup, { ...voucherDetail, Id: null, No: null, Voucher: null, Price: null, RelateDetail: `COMMERCEPOSORDER/${voucher.Code}/${voucherDetail.SystemUuid}` });
          //             details.push(newDetailFormGroup);
          //           }
          //         }
          //       }

          //     }
          //   }
          //   relationVoucher.setValue([...relationVoucherValue, ...insertList.map(m => ({ id: m?.Code, text: m.Title, type: 'COMMERCEPOSORDER', typeMap: this.cms.voucherTypeMap['COMMERCEPOSORDER'] }))]);
          //   this.setNoForArray(details.controls as FormGroup[], (detail: FormGroup) => detail.get('Type').value === 'PRODUCT');
          // }

          setTimeout(() => {
            this.onProcessed();
          }, 1000);
        },
      }
    })
    return false;
  }

  exportDetails(formItem: FormGroup) {
    const data = this.getRawFormData();
    const details = [];
    let no = 0;
    for (const detail of data.array[0].Details) {
      no++;
      details.push({
        STT: no,
        Sku: detail['Product']['Sku'],
        Product: this.cms.getObjectId(detail['Product']),
        ProductName: detail['Description'],
        ProductTaxName: detail['ProductTaxName'],
        Tax: detail['Tax'],
        Unit: this.cms.getObjectId(detail['Unit']),
        UnitName: this.cms.getObjectText(detail['Unit']),
        Price: detail['Price'],
        Quantity: detail['Quantity'],
        ToMoney: detail['ToMoney'],
      });
    }
    const sheet = XLSX.utils.json_to_sheet(details);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Chi tiết đơn đặt mua hàng');
    XLSX.writeFile(workbook, 'DDMH-' + data.array[0].Code + ' - ' + data.array[0].Title + ' - NCC: ' + this.cms.getObjectId(data.array[0].Customer) + ' - ' + data.array[0].CustomerName + '.xlsx');

  }

  fileName: string;
  importDetails(formItem: FormGroup, ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    this.fileName = file.name;
    reader.onload = async (event) => {
      try {
        this.isProcessing = true;
        let chooseSheet = null;
        const data = reader.result;
        const workBook = XLSX.read(data, { type: 'binary' });
        let sheet = null;
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.onProcessing();

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  // ev.target.
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        // Confirm mapping
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string) => {
          const id = m.split('/')[0];
          const text = m;
          return { id, text };
        });

        // Auto mapping
        const details = this.getDetails(formItem);
        if (details.controls.length != sheet.length) {
          this.cms.showToast('Số dòng trên file excel không khớp với số dòng trên đơn đặt hàng!', 'Không khớp số dòng!', { duration: 60000, status: 'warning' });
        }

        for (const row of sheet) {
          for (const colName in row) {
            const logicColName = colName.split('/')[0];
            row[logicColName] = row[colName];
          }
          let detailForm: FormGroup = null;
          if (row['Sku']) {
            detailForm = details.controls.find(f => f.get('Product')?.value?.Sku == row['Sku']) as FormGroup;
            // if (detailForm) {
            //   if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            //   if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            //   if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          } else if (row['SupplierSku']) {
            detailForm = details.controls.find(f => f.get('SupplierSku')?.value == row['SupplierSku']) as FormGroup;
            // if (detailForm) {
            //   if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            //   if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          } else if (row['SupplierProductName']) {// Load product by product name map by supplier
            detailForm = details.controls.find(f => f.get('SupplierProductName')?.value == row['SupplierProductName']) as FormGroup;
            // if (detailForm) {
            //   if (row['ProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            //   if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          } else if (row['SupplierProductTaxName']) {// Load product by product name map by supplier
            detailForm = details.controls.find(f => f.get('ProductTaxName')?.value == row['SupplierProductTaxName']) as FormGroup;
            // if (detailForm) {
            //   if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            //   if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          }
          if (detailForm) {
            if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // this.toMoney(formItem, detailForm);
          }

          // let unit = null;
          // if (row['Unit']) {
          //   unit = this.adminProductService.unitMap$?.value[row['Unit']?.trim()];
          // }
          // if (!unit && product) {
          //   unit = product.UnitConversions?.find(f => f.Name == row['UnitName']?.trim());
          // }

          if (!detailForm) {
            this.cms.showToast(row['ProductName'] + ' Không có trên đơn đặt hàng', 'Sản phẩm không có trên đơn đặt hàng !', { duration: 15000, status: 'warning', duplicatesBehaviour: 'previous', limit: 1 });
          } else {
            detailForm['IsImport'] = true;
          }
        }

        this.onProcessed();
        this.cms.showToast('Nhập chi tiết từ thành công', 'Hệ thống đã nhập các thông tin chi tiết trên file excel vào chi tiết tương ứng trên phiếu !', { duration: 15000, status: 'success' });
        return true;
      } catch (err) {
        console.error(err);
        this.onProcessed();
        this.cms.showToast(err, 'Có lỗi xảy ra trong quá trình nhập chi tiết!', { duration: 15000, status: 'danger', duplicatesBehaviour: 'previous', limit: 1 });
      }
    };
    reader.readAsBinaryString(file);
  }

  chooseFileAndFillDetails(formItem: FormGroup, ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    this.fileName = file.name;
    reader.onload = async (event) => {
      try {
        this.isProcessing = true;
        let chooseSheet = null;
        const data = reader.result;
        const workBook = XLSX.read(data, { type: 'binary' });
        let sheet = null;
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.onProcessing();

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  // ev.target.
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        // Confirm mapping
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string) => {
          const id = m.split('/')[0];
          const text = m;
          return { id, text };
        });

        // Auto mapping
        const details = this.getDetails(formItem);
        if (details.controls.length != sheet.length) {
          this.cms.showToast('Số dòng trên file excel không khớp với số dòng trên đơn đặt hàng!', 'Không khớp số dòng!', { duration: 60000, status: 'warning' });
        }

        for (const row of sheet) {
          for (const colName in row) {
            const logicColName = colName.split('/')[0];
            row[logicColName] = row[colName];
          }
          let detailForm: FormGroup = null;
          if (row['Sku']) {
            detailForm = details.controls.find(f => f.get('Product')?.value?.Sku == row['Sku']) as FormGroup;
            // if (detailForm) {
            //   if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            //   if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            //   if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          } else if (row['SupplierSku']) {
            detailForm = details.controls.find(f => f.get('SupplierSku')?.value == row['SupplierSku']) as FormGroup;
            // if (detailForm) {
            //   if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            //   if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          } else if (row['SupplierProductName']) {// Load product by product name map by supplier
            detailForm = details.controls.find(f => f.get('SupplierProductName')?.value == row['SupplierProductName']) as FormGroup;
            // if (detailForm) {
            //   if (row['ProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            //   if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          } else if (row['SupplierProductTaxName']) {// Load product by product name map by supplier
            detailForm = details.controls.find(f => f.get('ProductTaxName')?.value == row['SupplierProductTaxName']) as FormGroup;
            // if (detailForm) {
            //   if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            //   if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            //   if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            //   if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // }
          }
          if (detailForm) {
            if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            if (row['Price']) detailForm.get('Price').setValue(row['Price']);
            // this.toMoney(formItem, detailForm);
          }

          // let unit = null;
          // if (row['Unit']) {
          //   unit = this.adminProductService.unitMap$?.value[row['Unit']?.trim()];
          // }
          // if (!unit && product) {
          //   unit = product.UnitConversions?.find(f => f.Name == row['UnitName']?.trim());
          // }

          if (!detailForm) {
            this.cms.showToast(row['ProductName'] + ' Không có trên đơn đặt hàng', 'Sản phẩm không có trên đơn đặt hàng !', { duration: 15000, status: 'warning', duplicatesBehaviour: 'previous', limit: 1 });
          } else {
            detailForm['IsImport'] = true;
          }
        }

        this.onProcessed();
        this.cms.showToast('Nhập chi tiết từ thành công', 'Hệ thống đã nhập các thông tin chi tiết trên file excel vào chi tiết tương ứng trên phiếu !', { duration: 15000, status: 'success' });
        return true;
      } catch (err) {
        console.error(err);
        this.onProcessed();
        this.cms.showToast(err, 'Có lỗi xảy ra trong quá trình nhập chi tiết!', { duration: 15000, status: 'danger', duplicatesBehaviour: 'previous', limit: 1 });
      }
    };
    reader.readAsBinaryString(file);
  }

  chooseFileAndImportDetails(formItem: FormGroup, ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    this.fileName = file.name;
    reader.onload = async (event) => {
      try {
        // this.isProcessing = true;
        let chooseSheet = null;
        const data = reader.result;
        const workBook = XLSX.read(data, { type: 'binary' });
        let sheet = null;
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.onProcessing();

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  // ev.target.
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        // Confirm mapping
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string) => {
          const id = m.split('/')[0];
          const text = m;
          return { id, text };
        });

        // Auto mapping
        const details = this.getDetails(formItem);
        // if (details.controls.length != sheet.length) {
        //   this.cms.showToast('Số dòng trên file excel không khớp với số dòng trên đơn đặt hàng!', 'Không khớp số dòng!', { duration: 60000, status: 'warning' });
        // }


        details.clear();
        const skus = [];
        for (const row of sheet) {
          for (const colName in row) {
            const logicColName = colName.split('/')[0];
            row[logicColName] = row[colName];
          }

          row.SupplierSku = row.Sku;
          row.SupplierProductName = row.ProductName;

          if (row.CustomerSku) {
            skus.push(row.CustomerSku);
          }
          if (row.Sku) {
            skus.push(row.Sku);
          }
        }

        // Load products info by sku
        const products = await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { eq_Sku: '[' + skus.join(',') + ']', includeIdText: true, limit: 'nolimit' });
        const productMap: { [key: string]: ProductModel } = {};
        const productPictureMap: { [key: string]: FileModel } = {};
        for (const product of products) {
          productMap[product.Sku] = product;
        }

        for (const row of sheet) {
          if (!row.ProductName) {
            continue;
          }
          let localProduct = row.CustomerSku && productMap[row.CustomerSku] || null;
          if (!localProduct) {
            const purchaseProduct = await this.apiService.getPromise<PurchaseProductModel[]>('/purchase/products', { eq_Sku: row.Sku, eq_Supplier: this.cms.getObjectId(formItem.get('Customer').value), sort_LastUpdate: 'desc' }).then(rs => rs[0]);
            if (purchaseProduct) {
              localProduct = await this.apiService.getPromise<ProductModel[]>('/admin-product/products/' + purchaseProduct.Product, { includeIdText: true }).then(rs => rs[0]);
            }
          }
          if (!localProduct) {
            // Confirm create new product
            try {
              localProduct = await new Promise((resolve, reject) => {
                this.cms.showDialog('Chưa có thông tin sản phẩm', `Bạn có muốn tạo mới sản phẩm ${row.ProductName}, sản phẩm mới sau khi tạo sẽ tự động thêm vào chi tiết phiếu đặt mua hàng.`, [
                  {
                    label: 'Không',
                    status: 'basic',
                    action: () => {
                      resolve(null);
                    },
                  },
                  {
                    label: 'Tạo mới',
                    status: 'primary',
                    action: async (button, dialog) => {
                      dialog.loading = true;
                      // load exists product
                      const requestSku = row.CustomerSku || row.Sku;
                      let existsProduct: ProductModel = null;
                      // let existsPicturesMap: { [key: string]: FileModel } = {};
                      // let newPictures: FileModel[] = [];
                      if (requestSku) {
                        existsProduct = await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { eq_Sku: requestSku, includeIdText: true, includePictures: true, includeCategories: true, includeGroups: true, includeUnitConversions: true, includeProperties: true, includeWarehouseUnit: true }).then(rs => rs[0]);
                        // existsPictures = existsProduct.Pictures || [];
                        // for(const existsPicture of existsPictures) {
                        //   if(imageResources)
                        // }
                        if (existsProduct?.Pictures) {
                          for (const existsPicture of existsProduct.Pictures) {
                            if (existsPicture.Tag) {
                              // existsPicturesMap[existsPicture.Tag] = existsPicture;
                              productPictureMap[existsPicture.Tag] = existsPicture;
                            }
                          }
                        }
                      }

                      // create images
                      const imageResources: FileModel[] = [];
                      if (row.Image) {
                        const imageLinks = row.Image.split('\n');
                        for (const imageLink of imageLinks) {
                          const tag = CryptoJS.MD5(imageLink).toString();
                          if (productPictureMap[tag]) {
                            imageResources.push(productPictureMap[tag]);
                          } else {
                            const image = await this.apiService.uploadFileByLink(imageLink);
                            if (image) {
                              image.Tag = tag;
                              imageResources.push(image);
                              productPictureMap[tag] = image;
                            }
                          }
                        }
                      }

                      this.cms.openDialog(ProductFormComponent, {
                        context: {
                          inputId: existsProduct?.Code ? [existsProduct?.Code] : null,
                          data: [
                            {
                              Code: existsProduct?.Code || null,
                              Name: row.ProductName,
                              Sku: row.CustomerSku || row.Sku,
                              WarehouseUnit: { id: row.Unit, text: row.UnitName } as any,
                              UnitConversions: [
                                {
                                  Unit: { id: row.Unit, text: row.UnitName } as any,
                                  ConversionRatio: 1,
                                  IsDefaultSales: true,
                                  IsDefaultPurchase: true,
                                  Name: row.UnitName
                                }
                              ],
                              FeaturePicture: imageResources[0] || null,
                              Pictures: imageResources,
                            }
                          ],
                          onDialogSave(newData) {
                            this.cms.showToast('Đã tạo sản phẩm mới và thêm vào chi tiết đơn đặt mua hàng', 'Đã tạo sản phẩm mới', { status: 'info' });
                            resolve({ id: newData[0].Code, text: newData[0].Name, ...newData[0] });
                          },
                          onDialogClose: () => {
                            resolve(null);
                          },
                          onDialogError: async (component, err) => {
                            console.log(err);
                            if (err.error?.errorCode == 1062) {
                              const sku = component.getRawFormData().array[0].Sku;
                              if (sku) {
                                component.close();
                                this.cms.showToast('Sku đã tồn tại, tự động lấy thông tin sản phẩm theo Sku', 'Sku đã tồn tại', { status: 'info' });
                                localProduct = await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { eq_Sku: sku, includeIdText: true, includePictures: true, includeCategories: true, includeGroups: true, includeUnitConversions: true, includeProperties: true, includeWarehouseUnit: true }).then(rs => rs[0]);
                                resolve(localProduct);
                                // return Promise.resolve(null);
                              }
                            }
                          }
                        }
                      });

                      dialog.loading = false;
                    },
                  },
                  {
                    label: 'Dừng',
                    status: 'danger',
                    action: () => {
                      reject('STOP')
                    },
                  },
                ],
                  (asCase) => {
                    // Close by ESC
                    if (asCase == 'default') {
                      resolve(null);
                    }
                    if (asCase == 'close') {
                      resolve(null);
                    }
                  });
              });
            } catch (err) {
              console.error(err);
              if (err == 'STOP') {
                break;
              }
            }
          }
          if (localProduct) {
            row.Product = localProduct;
            row.Unit = { id: row.Unit, text: row.UnitName };
            row.Description = localProduct.Name;
            row.Image = localProduct.Pictures;
            let detailForm: FormGroup = this.makeNewDetailFormGroup(formItem, row);
            details.push(detailForm);
            this.onAddDetailFormGroup(formItem, detailForm, details.length - 1);
            this.setNoForArray(details.controls as FormGroup[], (detail: FormGroup) => detail.get('Type').value === 'PRODUCT');
          }
        }

        this.onProcessed();
        this.cms.showToast('Nhập chi tiết từ thành công', 'Hệ thống đã nhập các thông tin chi tiết trên file excel vào chi tiết tương ứng trên phiếu !', { duration: 15000, status: 'success' });
        return true;
      } catch (err) {
        console.error(err);
        this.onProcessed();
        this.cms.showToast(err, 'Có lỗi xảy ra trong quá trình nhập chi tiết!', { duration: 15000, status: 'danger', duplicatesBehaviour: 'previous', limit: 1 });
      }
    };
    reader.readAsBinaryString(file);
  }

  addDetailAfter(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    const formDetails = this.getDetails(parentFormGroup);
    const newDetailFormGroup = this.makeNewDetailFormGroup(parentFormGroup, { Type: 'CATEGORY' });
    formDetails.controls.splice(index + 1, 0, newDetailFormGroup);
  }

  onMoreBtnMessageClick(msg: any) {
    console.log(msg);
    this.cms.showDialog('Bình luận', msg.text, []);
  }

}
