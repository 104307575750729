import { B2bProductMapListComponent } from "./product/product-map/product-map-list/product-map-list.component";
import { B2bPublicProductListComponent } from "./product/public/public-product-list/public-product-list.component";
import { B2bImportProductFromPlatform } from "./product/product-map/import-b2b-product/import-b2b-product.component";
import { B2bCenterPageListComponent } from "./page/b2b-center-page-list/b2b-center-page-list.component";
import { B2bCenterPageFormComponent } from "./page/b2b-center-page-form/b2b-center-page-form.component";
import { B2bRefServerProductListComponent } from "./product/product-map/b2b-server-ref-product-list/b2b-server-ref-product-list.component";
import { B2bOrderFormComponent } from "./order/b2b-order-form/b2b-order-form.component";
import { B2bOrderListComponent } from "./order/b2b-order-list/b2b-order-list.component";
import { B2bOrderPrintComponent } from "./order/b2b-order-print/b2b-order-print.component";
import { B2bPurchaseOrderFormComponent } from "./purchse/order/b2b-purchase-order-form/b2b-purchase-order-form.component";
import { B2bPurchaseOrderListComponent } from "./purchse/order/b2b-purchase-order-list/b2b-purchase-order-list.component";
import { B2bPurchaseOrderPrintComponent } from "./purchse/order/b2b-purchase-order-print/b2b-purchase-order-print.component";
import { B2bSalesOrderFormComponent } from "./sales/order/b2b-sales-order-form/b2b-sales-order-form.component";
import { B2bSalesOrderListComponent } from "./sales/order/b2b-sales-order-list/b2b-sales-order-list.component";
import { B2bSalesOrderPrintComponent } from "./sales/order/b2b-sales-order-print/b2b-sales-order-print.component";
import { B2bBasicSalesCommissionConfigListComponent } from "./sales/commission/basic-config/list/basic-commission-config-list.component";
import { B2bBasicSalesCommissionConfigPrintComponent } from "./sales/commission/basic-config/print/basic-commission-config-print.component";
import { B2bBasicSalesCommissionConfigProductFormComponent } from "./sales/commission/basic-config/product-form/basic-commission-product-form.component";
import { B2bAdvanceSalesCommissionConfigFormComponent } from "./sales/commission/advance-config/form/advance-commission-config-form.component";
import { B2bAdvanceSalesCommissionConfigListComponent } from "./sales/commission/advance-config/list/advance-commission-config-list.component";
import { B2bAdvanceSalesCommissionConfigPrintComponent } from "./sales/commission/advance-config/print/advance-commission-config-print.component";
import { B2bAdvanceSalesCommissionConfigParterFormComponent } from "./sales/commission/advance-config/partner-form/advance-commission-config-partner-form.component";
import { B2bBasicSalesCommissionConfigFormComponent } from "./sales/commission/basic-config/form/basic-commission-config-form.component";
import { B2bSalesCustomerListComponent } from "./sales/partner/list/sales-partner-list.component";
import { B2bAddonSalesCommissionConfigFormComponent } from "./sales/commission/addon-config/form/addon-commission-config-form.component";
import { B2bAddonSalesCommissionConfigListComponent } from "./sales/commission/addon-config/list/addon-commission-config-list.component";
import { B2bAddonSalesCommissionConfigPrintComponent } from "./sales/commission/addon-config/print/addon-commission-config-print.component";
import { B2bSupplierListComponent } from "./purchse/supplier/list/supplier-list.component";
import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { B2bCenterRoutingModule } from "./b2b-center-routing.module";
import { B2bCenterComponent } from "./b2b-center.component";

export const b2bCenterComponents = [
    B2bCenterComponent,

    B2bCenterPageListComponent,
    B2bCenterPageFormComponent,

    B2bProductMapListComponent,
    B2bImportProductFromPlatform,
    B2bPublicProductListComponent,

    B2bRefServerProductListComponent,

    B2bOrderListComponent,
    B2bOrderFormComponent,
    B2bOrderPrintComponent,

    B2bPurchaseOrderListComponent,
    B2bPurchaseOrderFormComponent,
    B2bPurchaseOrderPrintComponent,

    B2bSalesOrderListComponent,
    B2bSalesOrderFormComponent,
    B2bSalesOrderPrintComponent,

    B2bBasicSalesCommissionConfigListComponent,
    B2bBasicSalesCommissionConfigFormComponent,
    B2bBasicSalesCommissionConfigPrintComponent,
    B2bBasicSalesCommissionConfigProductFormComponent,

    B2bAdvanceSalesCommissionConfigListComponent,
    B2bAdvanceSalesCommissionConfigFormComponent,
    B2bAdvanceSalesCommissionConfigPrintComponent,
    B2bAdvanceSalesCommissionConfigParterFormComponent,

    B2bSalesCustomerListComponent,

    B2bAddonSalesCommissionConfigListComponent,
    B2bAddonSalesCommissionConfigFormComponent,
    B2bAddonSalesCommissionConfigPrintComponent,

    B2bSupplierListComponent,

];

@NgModule({
    declarations: b2bCenterComponents,
    exports: b2bCenterComponents,
    imports: [
        ...commonModules,
        B2bCenterRoutingModule,
    ],
    bootstrap: [B2bCenterComponent],
    providers: [
        ...commonProviders,
    ],
})
export class B2bCenterModule { }