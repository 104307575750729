import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataManagerFormComponent, MyUploadAdapter} from "../../../../lib/data-manager/data-manager-form.component";
import {ProductModel, ProductUnitModel} from "../../../../models/product.model";
import {RootServices} from "../../../../services/root.services";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {NbDialogRef, NbDialogService, NbThemeService, NbToastrService} from "@nebular/theme";
import {CommonService} from "../../../../services/common.service";
import {AdminProductService} from "../../../admin-product/admin-product.service";
import {agMakeSelectionColDef} from "../../../../lib/custom-element/ag-list/column-define/selection.define";
import {agMakeImageColDef} from "../../../../lib/custom-element/ag-list/column-define/image.define";
import {AgTextCellRenderer} from "../../../../lib/custom-element/ag-list/cell/text.component";
import {AgSelect2Filter} from "../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import {agMakeCurrencyColDef} from "../../../../lib/custom-element/ag-list/column-define/currency.define";
import {agMakeCommandColDef} from "../../../../lib/custom-element/ag-list/column-define/command.define";
import {ColDef, ColumnApi, GridApi, IRowNode} from "@ag-grid-community/core";
import {HttpErrorResponse} from "@angular/common/http";
import {AgDynamicListComponent} from "../../../../lib/component/ag-dymanic-list/ag-dymanic-list.component";
import {DialogFormComponent} from "../../../../lib/component/dialog/dialog-form/dialog-form.component";
import {Model} from "../../../../models/model";
import * as XLSX from "xlsx";
import {UnitModel} from "../../../../models/unit.model";
import {ProductListComponent} from "../../../admin-product/product/product-list/product-list.component";
import {ProductUnitListComponent} from "../../../admin-product/unit/product-unit-list/product-unit-list.component";
import {Select2Option} from "../../../../lib/custom-element/select2/select2.component";
import {SalesMasterPriceTableComponent} from "../../master-price-table/master-price-table/master-price-table.component";
import {SalesPriceTableQuotationDetailModel, SalesPriceTableQuotationModel} from "../../sales.model";
import {agMakeIdColDef} from "../../../../lib/custom-element/ag-list/column-define/id.define";
import {agMakeTextColDef} from "../../../../lib/custom-element/ag-list/column-define/text.define";
import * as ClassicEditorBuild from '../../../../../vendor/ckeditor/ckeditor5-custom-build/build/ckeditor.js';

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    const options = editor.config.get('simpleUpload');
    return new MyUploadAdapter(loader, options);
  };
}

@Component({
  selector: 'ngx-sales-price-table-quotation-form',
  templateUrl: './sales-price-table-quotation-form.component.html',
  styleUrls: ['./sales-price-table-quotation-form.component.scss']
})
export class SalesPriceTableQuotationFormComponent extends DataManagerFormComponent<SalesPriceTableQuotationModel> implements OnInit {


  componentName: string = 'PriceTableQuotationFormComponent';
  idKey = ['Code'];
  // baseFormUrl = '/collaborator/basic-strategy/form';
  apiPath = '/sales/price-table-quotations';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];
  chooseProductDialog: NbDialogRef<SalesMasterPriceTableComponent>;

  public Editor = ClassicEditorBuild;
  public ckEditorConfig = {
    height: '200px',
    // plugins: [ImageResize],
    extraPlugins: [MyCustomUploadAdapterPlugin],
    simpleUpload: {
      uploadUrl: () => {
        // return this.apiService.getPromise<FileStoreModel[]>('/file/file-stores', { filter_Type: 'REMOTE', sort_Weight: 'asc', requestUploadToken: true, weight: 4194304, limit: 1 }).then(fileStores => {
        return this.cms.getAvailableFileStores().then(fileStores => fileStores[0]).then(fileStore => {
          return this.apiService.buildApiUrl(fileStore.Path + '/v1/file/files', {token: fileStore['UploadToken']});
        });
      },
    },
  };

  @ViewChild('chooseProductPriceFile', {static: true}) chooseProductPriceFile: ElementRef;

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref?: NbDialogRef<SalesPriceTableQuotationFormComponent>,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastService, dialogService, cms);


    const $this = this;
    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Product',
        rowDrag: true,
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Hình',
        field: 'FeaturePicture',
        width: 100,
        pinned: 'left',
      },
      {
        ...agMakeIdColDef(this.cms),
        headerName: 'ID',
        field: 'Product',
        width: 160,
        // pinned: 'left',
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Sku',
        field: 'Sku',
        width: 100,
        // pinned: 'left',
      },
      {
        headerName: 'Tên sản phẩm',
        field: 'ProductName',
        width: 800,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'Danh mục',
        field: 'Categories',
        // pinned: 'left',
        width: 400,
        cellRenderer: AgTextCellRenderer,
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            logic: 'OR',
            allowClear: true,
            ajax: {
              delay: 300,
              data: function (params) {
                return {
                  ...params,
                  offset: params['offset'] || 0,
                  limit: params['limit'] || 10
                };
              },
              transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                console.log(settings);
                const params = settings.data;
                const rs = this.categoryFilterData.filter(f => this.cms.smartFilter(f.text, params['term']));
                success({data: rs, total: rs.length});
                return null;
              },
              processResults: (rs: { data: any[], total: number }, params: any) => {
                const data = rs.data;
                const total = rs.total;
                params.limit = params.limit || 10;
                params.offset = params.offset || 0;
                params.offset = params.offset += params.limit;
                return {
                  results: data.map(item => {
                    item.thumbnail = item?.FeaturePicture?.Thumbnail;
                    return item;
                  }),
                  pagination: {
                    more: params.offset < total
                  }
                };
              },
            }
          }
        },
      },
      {
        headerName: 'ĐVT',
        field: 'Unit',
        width: 110,
        cellRenderer: AgTextCellRenderer,
        pinned: 'right',
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            ...this.cms.select2OptionForTemplate,
            multiple: true,
            logic: 'OR',
            allowClear: true,
            ajax: {
              delay: 300,
              data: function (params) {
                return {
                  ...params,
                  offset: params['offset'] || 0,
                  limit: params['limit'] || 10
                };
              },
              transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                console.log(settings);
                const params = settings.data;
                const rs = this.unitFilterData.filter(f => this.cms.smartFilter(f.text, params['term']));
                success({data: rs, total: rs.length});
                return null;
              },
              processResults: (rs: { data: any[], total: number }, params: any) => {
                const data = rs.data;
                const total = rs.total;
                params.limit = params.limit || 10;
                params.offset = params.offset || 0;
                params.offset = params.offset += params.limit;
                return {
                  results: data.map(item => {
                    // item.thumbnail = item?.FeaturePicture?.Thumbnail;
                    return item;
                  }),
                  pagination: {
                    more: params.offset < total
                  }
                };
              },
            }
          }
        },
      },
      {
        ...agMakeCurrencyColDef(this.cms),
        headerName: 'Giá',
        field: 'Price',
        width: 150,
        filter: 'agTextColumnFilter',
        pinned: 'right',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'DS Hình',
        field: 'Pictures',
        width: 150,
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Uuid',
        field: 'SystemUuid',
        width: 100,
      },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          this.gridApi.applyTransaction({remove: [params]});
          this.updateFilterData();
        }, false, []),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];
    /** End AG-Grid */
  }

  calculateDiscountPrice(data: SalesPriceTableQuotationDetailModel): number {
    return (data.ListedPrice || 0) - (data.ListedPrice || 0) * (data.CommissionRatio || 0) / 100;
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public gridParams;

  public categoryFilterData = [];
  public unitFilterData = [];

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  loadList(callback?: (list: SalesPriceTableQuotationDetailModel[]) => void) {
    if (this.gridApi) {
      let products: SalesPriceTableQuotationDetailModel[] = (this.array.controls[0].get('Details').value || []).map((detail: Model) => {
        return detail;
      });
      this.gridApi.setRowData(products);
      this.updateFilterData();
    }
  }

  select2OptionForPage: Select2Option = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeDetails'] = true;
    params['includeCategories'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group<any>({
      Code: {value: null, disabled: true},
      Title: [null, Validators.required],
      DateOfApplied: [null, Validators.required],
      Note: [],
      Details: [[]],
    });
    if (data) {
      // data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }

  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void {
  }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Extract date range
      // if (item.DateRange) {
      //   item.DateOfStart = item.DateRange[0];
      //   item.DateOfEnd = item.DateRange[1];
      // }

      // Get details data from ag-grid
      item.Details = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
        }
        item.Details.push(rawDetail);
      });
    }
    return data;
  }

  async save(): Promise<ProductModel[]> {
    return super.save();
  }

  updateFilterData() {
    const categoryFilterDataMap = {};
    const unitFilterDataMap = {};
    this.gridApi.forEachNode((rowNode, imdex) => {
      if (Array.isArray(rowNode.data?.Categories)) {
        for (const i in rowNode.data.Categories) {
          categoryFilterDataMap[this.cms.getObjectId(rowNode.data.Categories[i])] = rowNode.data.Categories[i];
        }
      }
      unitFilterDataMap[this.cms.getObjectId(rowNode.data.Unit)] = rowNode.data.Unit;
    });
    this.categoryFilterData = Object.keys(categoryFilterDataMap).map(k => categoryFilterDataMap[k]);
    this.unitFilterData = Object.keys(unitFilterDataMap).map(k => unitFilterDataMap[k]);
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    // const $this = this;
    let actionButtonList = component.actionButtonList.filter(f => ['reset'].indexOf(f.name) > -1);
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'reset',
      title: 'Reset',
      status: 'danger',
      // label: 'Reset',
      outline: true,
      iconPack: 'eva',
      icon: 'sync-outline',
      size: 'medium',
      click: (event) => {
        component.reset();
        return true;
      }
    });

    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở sản phẩm',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        this.gridApi.applyTransaction({remove: selectedNodes.map(m => m.data)});
        this.updateFilterData();
        return true;
      }
    });

    actionButtonList.unshift({
      type: 'button',
      name: 'addProduct',
      title: 'Thêm sản phẩm',
      status: 'success',
      label: 'Thêm sản phẩm',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.showDialog('Thêm sản phẩm vào bảng báo giá', 'Bạn muốn thêm tất cả sản phẩm, thêm theo danh muc hay thêm từng sản phẩm ?', [
          {
            label: 'Theo danh mục',
            status: 'primary',
            action: () => {
              this.cms.openDialog(DialogFormComponent, {
                context: {
                  width: '512px',
                  controls: [
                    {
                      name: 'Categories',
                      label: 'Danh mục',
                      type: 'select2',
                      option: {
                        ...this.cms.makeSelect2AjaxOption('/admin-product/categories', {onlyIdText: true}, {
                          prepareReaultItem: (item) => {
                            // item.label = item.id + ' - ' + item.text;
                            return item;
                          }
                        }),
                        placeholder: 'Chọn danh mục...',
                        allowClear: false,
                        width: '100%',
                        dropdownAutoWidth: true,
                        minimumInputLength: 0,
                        multiple: true,
                        keyMap: {
                          id: 'id',
                          text: 'text',
                        },
                      },
                    }
                  ],
                  actions: [
                    {
                      label: 'Back',
                      status: 'basic',
                      outline: true,
                      action: async () => {
                        return true;
                      }
                    },
                    {
                      label: 'Thêm',
                      status: 'primary',
                      action: async (form, dialog) => {
                        const categories = form.get('Categories').value as Model[];
                        console.log(categories);

                        if (!categories || categories.length == 0) {
                          this.cms.showToast('Bạn chưa chọn danh mục', 'Chưa chọn danh mục', {status: 'warning'});
                          return false;
                        }

                        let productList = [];
                        const limit = 100;
                        let offset = 0;
                        do {
                          productList = await this.apiService.getPromise<ProductModel[]>('/b2b-center/product/publics', {
                            includeChildrenCategories: true,
                            includeListedPrice: true,
                            eq_Categories: '[' + categories.map(m => this.cms.getObjectId(m)).join(',') + ']',
                            limit: limit,
                            offset: offset
                          });

                          const data = [];
                          for (const i in productList) {
                            const chooseItem = productList[i];
                            for (const u in chooseItem.UnitConversions) {
                              const unitConversion = chooseItem.UnitConversions[u];
                              const idKey = this.cms.getObjectId(chooseItem.Product) + '-' + this.cms.getObjectId(unitConversion.Unit);
                              if (!(this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                                data.push({
                                  id: this.cms.getObjectId(chooseItem.Product),
                                  text: chooseItem.ProductName,
                                  Product: chooseItem.Product,
                                  ProductName: chooseItem.ProductName,
                                  Sku: chooseItem.Sku,
                                  Unit: typeof unitConversion.Unit == 'string' ? {id: unitConversion.Unit, text: unitConversion.UnitLabel} : unitConversion.Unit,
                                  UnitLabel: unitConversion.UnitLabel,
                                  Pictures: chooseItem.Pictures,
                                  FeaturePicture: chooseItem.FeaturePicture,
                                  Categories: chooseItem.Categories,
                                  Groups: chooseItem.Groups,
                                  ListedPrice: unitConversion.ListedPrice,
                                });
                              } else {
                                console.warn('Duplicate: ' + idKey);
                              }
                            }
                          }
                          const newRowNodeTrans = this.gridApi.applyTransaction({
                            add: data
                          });
                          offset += limit;
                        } while (productList.length >= limit);
                        this.updateFilterData();
                        return true;
                      }
                    },
                  ],
                }
              });
            },
          },
          {
            label: 'Chọn sản phẩm',
            status: 'info',
            action: () => {
              if (this.chooseProductDialog) {
                this.chooseProductDialog.componentRef.instance.show();
              } else {
                this.chooseProductDialog = this.cms.openDialog(SalesMasterPriceTableComponent, {
                  context: {
                    height: 'calc(100vh - 1rem)',
                    width: 'calc(100vw - 2rem)',
                    pagination: true,
                    reuseDialog: true,
                    extendsParams: {
                      includeListedPrice: true,
                    },
                    onDialogChoose: (chooseItems) => {
                      console.log(chooseItems);
                      const data = [];
                      for (const i in chooseItems) {
                        const chooseItem = chooseItems[i];
                        // for (const u in chooseItem.UnitConversions) {
                        // const unitConversion = chooseItem.UnitConversions[u];
                        const idKey = chooseItem.Code + '-' + this.cms.getObjectId(chooseItem.Unit);
                        if (!(this.gridApi as any).rowModel?.nodeManager?.allNodesMap[idKey]) {
                          data.push({
                            id: this.cms.getObjectId(chooseItem.Product),
                            text: chooseItem.ProductName,
                            Product: chooseItem.Code,
                            ProductName: chooseItem.Name,
                            Sku: chooseItem.Sku,
                            Unit: chooseItem.Unit,
                            UnitLabel: chooseItem.UnitLabel,
                            Pictures: chooseItem.Pictures,
                            FeaturePicture: chooseItem.FeaturePicture,
                            Categories: chooseItem.Categories,
                            Groups: chooseItem.Groups,
                            ListedPrice: chooseItem.Price,
                            Price: chooseItem.Price,
                          });
                        } else {
                          console.warn('Duplicate: ' + idKey);
                        }
                        // }
                      }
                      const newRowNodeTrans = this.gridApi.applyTransaction({
                        add: data
                      });
                      this.updateFilterData();
                      console.log('New Row Node Trans: ', newRowNodeTrans);
                    },
                  },
                  closeOnEsc: false,
                  closeOnBackdropClick: false,
                });
              }
            },
          },
        ]);


        return true;
      }
    });

    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'settings',
    //   title: 'Cài chiết khấu cho các dòng đã lọc',
    //   status: 'primary',
    //   label: 'Cài chiết khấu cho các dòng đã lọc',
    //   iconPack: 'eva',
    //   icon: 'settings-2-outline',
    //   size: 'medium',
    //   click: (event) => {
    //
    //     this.cms.openDialog(DialogFormComponent, {
    //       context: {
    //         width: '512px',
    //         controls: [
    //           {
    //             name: 'CommissionRatio',
    //             label: '% Chiết khấu',
    //             placeholder: 'Điền % chiết khấu cho các dong đang lọc',
    //             type: 'number',
    //           }
    //         ],
    //         actions: [
    //           {
    //             label: 'Trở về',
    //             status: 'basic',
    //             outline: true,
    //             action: async (form, dialog) => {
    //               return true;
    //             }
    //           },
    //           {
    //             label: 'OK',
    //             status: 'success',
    //             action: async (form, dialog) => {
    //               const commissionRatio = form.get('CommissionRatio').value;
    //               this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
    //                 rowNode.setDataValue('CommissionRatio', commissionRatio);
    //               });
    //               return true;
    //             }
    //           }
    //         ],
    //       }
    //     });
    //
    //     return true;
    //   }
    // });
    //
    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'importSupplierPrice',
    //   title: 'Import giá bán của NCC',
    //   status: 'danger',
    //   label: 'Import giá bán NCC',
    //   iconPack: 'eva',
    //   icon: 'link-2-outline',
    //   size: 'medium',
    //   click: (event) => {
    //     this.chooseProductPriceFile.nativeElement.click();
    //     return true;
    //   }
    // });

    component.actionButtonList = actionButtonList;
  }

  onProductPriceFileChange(ev) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    // file.name;
    reader.onload = async (event) => {
      try {
        // this.processing = true;
        const data = reader.result;
        const workBook = XLSX.read(data, {type: 'binary'});
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, {header: 1});
          return initial;
        }, {});

        let sheet = null;
        let chooseSheet = null;

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: {width: '500px'},
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({id: m, text: m})),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => true,
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      // formDialogConpoent.dismiss();

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        console.log(sheet);
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], {header: 1});
        const columnList = tmpSheet[0].map((m: string, index) => {
          const id = m.split('/')[0];
          const colindex = index;
          const text = m;
          return {id, text, colindex};
        });

        this.cms.openDialog(DialogFormComponent, {
          context: {
            width: '512px',
            controls: [
              {
                label: 'Cột chứa Sku',
                name: 'Sku',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  placeholder: 'Chọn Sku',
                  data: columnList,
                },
                initValue: columnList.find(f => f.id == 'Sku'),
              },
              {
                label: 'Cột chứa ĐVT',
                name: 'Unit',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  placeholder: 'Chọn ĐVT',
                  data: columnList,
                },
                initValue: columnList.find(f => f.id == 'Unit'),
              },
              {
                label: 'Cột chứa Giá',
                name: 'Price',
                type: 'select2',
                option: {
                  ...this.cms.select2OptionForTemplate,
                  placeholder: 'Chọn Giá',
                  data: columnList,
                },
                initValue: columnList.find(f => f.id == 'Price'),
              },
            ],
            actions: [
              {
                label: 'Trở về',
                status: 'basic',
                outline: true,
                action: async () => true,
              },
              {
                label: 'Nhập',
                status: 'danger',
                outline: true,
                action: async (form, dialog) => {

                  const unitMap = await this.apiService.getPromise<UnitModel[]>('/admin-product/units', {onlyIdtext: true, limit: 'nolimit'}).then(rs => {
                    return rs.reduce((result, curr, index) => {
                      result[curr.Code] = curr;
                      return result;
                    }, {});
                  });

                  const map = {
                    // Code: form.value.Code.colindex,
                    Sku: form.value.Sku.colindex,
                    Unit: form.value.Unit.colindex,
                    Price: form.value.Price.colindex,
                  };

                  console.log(form.value);
                  const mapCheckExists = {};
                  const updateNote = {
                    Title: 'Import giá niêm yết ' + new Date().toLocaleDateString(),
                    Details: [],
                  };

                  // Check map product and unit
                  for (const r in sheet) {
                    if (r == '0') continue;
                    const row = sheet[r];
                    let existsProduct = this.adminProductService.skuMap[row[map.Sku]];
                    if (!existsProduct) {
                      console.warn('Sku không tồn tại');
                      existsProduct = await new Promise<ProductModel>((resolve, reject) => {
                        this.cms.openDialog(ProductListComponent, {
                          context: {
                            title: 'Chọn sản phẩm tương ứng với Sku ' + row[map.Sku],
                            width: '90vw',
                            height: '90vh',
                            onDialogChoose: (chooseItems) => {
                              if (chooseItems && chooseItems[0]) {
                                resolve(chooseItems[0]);
                              }
                            },
                            onDialogClose: () => {
                              resolve(null);
                            },
                          }
                        });
                      });
                      if (!existsProduct) {
                        continue;
                      }
                    }
                    if (!unitMap[row[map.Unit]]) {
                      console.warn('ĐVT không tồn tại');
                      const existsUnit = await new Promise<ProductModel>((resolve, reject) => {
                        this.cms.openDialog(ProductUnitListComponent, {
                          context: {
                            title: 'Chọn ĐVT tương ứng với ' + row[map.Unit],
                            width: '90vw',
                            height: '90vh',
                            onDialogChoose: (chooseItems) => {
                              resolve(chooseItems[0]);
                            },
                            onDialogClose: () => {
                              resolve(null);
                            },
                          }
                        });
                      });
                      if (!unitMap) {
                        continue;
                      }
                      unitMap[row[map.Unit]] = existsUnit;
                    }

                    if (existsProduct) {
                      // if (!mapCheckExists[existsProduct.Code + '-' + unitMap[row[map.Unit]].Code]) {
                      // updateNote.Details.push({
                      //   PriceType: 'REGULAR',
                      //   Product: existsProduct.Code,
                      //   Sku: existsProduct.Sku,
                      //   Unit: unitMap[row[map.Unit]].Code,
                      //   Price: row[map.Price],
                      // });
                      // mapCheckExists[existsProduct.Code + '-' + unitMap[row[map.Unit]].Code] = true;

                      // Find and fill price
                      // }
                      const rowNode = this.gridApi.getRowNode(existsProduct.Code + '-' + unitMap[row[map.Unit]].Code);
                      if (rowNode) {
                        const price = row[map.Price];
                        const listedPrice = rowNode.data.ListedPrice;
                        rowNode.setDataValue('Price', price);
                        rowNode.setDataValue('CommissionRatio', (listedPrice - price) / listedPrice * 100);
                      } else {
                        console.warn('Không tìm thấy sản phẩm trong danh sách');
                      }
                    }

                  }

                  // console.log(updateNote);
                  // const toast = this.cms.showToast('Đang tạo phiếu cập nhật giá, vui lòng chờ trong giây lát', 'Đang tạo phiếu cập nhật giá', { duration: 0, status: 'wwarning' });
                  // this.apiService.postPromise<MasterPriceTableUpdateNoteModel[]>('/sales/master-price-table-notes', {}, [updateNote]).then(rs => {
                  //   console.log(rs);
                  //   toast.close();
                  //   this.cms.openDialog(MasterPriceTableUpdateNotePrintComponent, {
                  //     context: {
                  //       id: [rs[0].Code],
                  //     }
                  //   });
                  // });
                  return true;
                },
              },
            ]
          }
        });

        // this.processing = false;
      } catch (err) {

      }
    };
    reader.readAsBinaryString(file);
  }
}
