import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { RootServices } from '../../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../../services/common.service';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { ProductListComponent } from '../../../../admin-product/product/product-list/product-list.component';
import { agMakeTextColDef } from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import { AppModule } from '../../../../../app.module';
import { AgTextCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeImageColDef } from '../../../../../lib/custom-element/ag-list/column-define/image.define';
import { agMakeSelectionColDef } from '../../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTagsColDef } from '../../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { IdTextModel } from '../../../../../models/common.model';
import { ProductCategoryModel, ProductGroupModel, ProductUnitModel, ProductModel } from '../../../../../models/product.model';
import { WarehouseGoodsContainerModel } from '../../../../../models/warehouse.model';
import { AssignCategoriesFormComponent } from '../../../../admin-product/product/assign-categories-form/assign-categories-form.component';
import { AgGridDataManagerListComponent } from '../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DataManagerFormComponent } from '../../../../../lib/data-manager/data-manager-form.component';
import { B2bProductService } from '../../../b2b-product.service';
import { B2bCenterService } from '../../../b2b-center.service';

@Component({
  selector: 'ngx-b2b-server-ref-product-list',
  templateUrl: './b2b-server-ref-product-list.component.html',
  styleUrls: ['./b2b-server-ref-product-list.component.scss'],
})
export class B2bRefServerProductListComponent extends AgGridDataManagerListComponent<ProductModel, DataManagerFormComponent<ProductModel>> implements OnInit {

  componentName: string = 'B2bRefServerProductListComponent';
  // formPath = '/b2b-server/product/product/form';
  apiPath = '/b2b-center/server/products';
  idKey: string[] = ['Code'];
  // formDialog = B2bProductFormComponent;

  @Input() reuseDialog = true;
  static _dialog: NbDialogRef<ProductListComponent>;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  // Category list for filter
  categoryList: ProductCategoryModel[] = [];
  groupList: ProductGroupModel[] = [];
  unitList: ProductUnitModel[] = [];
  containerList: WarehouseGoodsContainerModel[] = [];

  @Input() width = '100%';
  @Input() height = '100%';

  shelfList: IdTextModel[];
  @Input() pagingConfig: { display: boolean, perPage: number }
  @Input() pagination: boolean = true;
  @Input() paginationPageSize = 100;
  @Input() cacheBlockSize = this.paginationPageSize;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<B2bRefServerProductListComponent>,
    public b2bProdServ: B2bProductService,
    public b2bCenterService: B2bCenterService,
    public themeService: NbThemeService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    // this.defaultColDef = {
    //   ...this.defaultColDef,
    //   cellClass: 'ag-cell-items-center',
    // }
  }

  // protected configSetting(settings: any): ColDef[] {
  //   const superSettings = super.configSetting(settings);
  //   superSettings.push({
  //     ...agMakeTextColDef(this.cms),
  //     headerName: 'Ref Code',
  //     field: 'RefCode',
  //     width: 180,
  //   });
  //   superSettings.push({
  //     ...agMakeTextColDef(this.cms),
  //     headerName: 'Page',
  //     field: 'Page',
  //     width: 180,
  //   });
  //   return superSettings;
  // }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** Config for paging */
  protected configPaging() {
    if (this.pagingConfig) {
      return {
        ...super.configPaging(),
        ...this.pagingConfig,
      };
    }
    return super.configPaging();
  }

  async loadCache() {
    // this.categoryList = await this.b2bProdServ.categoryList$.pipe(take(1)).toPromise();
    // this.groupList = await this.b2bProdServ.groupList$.pipe(take(1)).toPromise();
    // this.containerList = await this.b2bProdServ.containerList$.pipe(take(1)).toPromise();
    // this.shelfList = this.containerList.filter(f => this.cms.getObjectId(f.Type) == 'SHELF');
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    console.log(this.smartTable);
  }

  async init() {
    await this.loadCache();
    return super.init().then(async rs => {

      // Load unit list
      // this.b2bProdServ.unitList$.pipe(takeUntil(this.destroy$)).subscribe(unitList => {
      //   this.unitList = unitList;
      // });


      this.actionButtonList.unshift({
        name: 'filterRefForSelf',
        status: 'danger',
        label: 'Lọc sản phẩm đã map với tôi',
        icon: 'funnel-outline',
        title: 'Lọc ra các sản phẩm mà đối tác đã chia sẻ bảng map với tôi',
        size: 'medium',
        // disabled: () => this.selectedIds.length === 0,
        // hidden: () => false,
        outline: true,
        click: (event, option, context, button) => {
          if (button.outline) {
            this.extendsParams['includeMapForPage'] = this.cms.getObjectId(this.b2bCenterService.currentpage$.value);
            this.extendsParams['eq_RefMapPage'] = this.cms.getObjectId(this.b2bCenterService.currentpage$.value);
            // button.outline = !button.outline;
            button.outline = false;
            button.appearance = 'filled';
          } else {
            delete (this.extendsParams['includeMapForPage']);
            delete (this.extendsParams['eq_RefMapPage']);
            button.outline = true;
            button.appearance = 'outline';
          }
          this.refresh();
          return false;
        },
      });
      // this.actionButtonList.unshift({
      //   name: 'copyProducts',
      //   status: 'danger',
      //   label: this.cms.textTransform(this.cms.translate.instant('Copy'), 'head-title'),
      //   icon: 'copy-outline',
      //   title: this.cms.textTransform(this.cms.translate.instant('Copy'), 'head-title'),
      //   size: 'medium',
      //   disabled: () => this.selectedIds.length === 0,
      //   hidden: () => false,
      //   click: () => {
      //     this.cms.openDialog(B2bProductFormComponent, {
      //       context: {
      //         showLoading: true,
      //         inputMode: 'dialog',
      //         inputId: this.selectedItems.map(item => this.makeId(item)),
      //         isDuplicate: true,
      //         onDialogSave: (newData: ProductModel[]) => {
      //         },
      //         onDialogClose: () => {
      //           this.refresh();
      //         },
      //       },
      //     });
      //     return false;
      //   },
      // });

      // this.actionButtonList.unshift({
      //   name: 'importProducts',
      //   status: 'primary',
      //   label: this.cms.textTransform(this.cms.translate.instant('Import'), 'head-title'),
      //   icon: 'download-outline',
      //   title: this.cms.textTransform(this.cms.translate.instant('Import'), 'head-title'),
      //   size: 'medium',
      //   disabled: () => false,
      //   hidden: () => false,
      //   click: () => {
      //     this.cms.openDialog(ImportProductDialogComponent, {
      //       context: {
      //         // showLoading: true,
      //         inputMode: 'dialog',
      //         onDialogSave: (newData: ProductModel[]) => {
      //         },
      //         onDialogClose: () => {
      //           this.refresh();
      //         },
      //       },
      //       closeOnEsc: false,
      //       closeOnBackdropClick: false,
      //     });
      //     return false;
      //   },
      // });

      // this.actionButtonList.unshift({
      //   name: 'rebuildCache',
      //   status: 'info',
      //   label: this.cms.textTransform(this.cms.translate.instant('Lập chỉ mục'), 'head-title'),
      //   icon: 'layers-outline',
      //   title: this.cms.textTransform(this.cms.translate.instant('Gửi yêu cầu lập chỉ mục tìm kiếm sản phẩm'), 'head-title'),
      //   size: 'medium',
      //   disabled: () => false,
      //   hidden: () => false,
      //   click: () => {
      //     this.cms.showDialog('Cập nhật chỉ mục tìm kiếm sản phẩm', 'Gửi yêu cầu cập nhật chỉ mục tìm kiếm sản phẩm, hệ thống sẽ tự động lập chỉ mục vào thông báo thay đổi đến các máy bán hàng POS và app Smart-BOT', [
      //       {
      //         label: 'Đóng',
      //         status: 'basic',
      //         outline: true,
      //         action: () => {
      //           return true;
      //         },
      //       },
      //       {
      //         label: 'Gửi yêu cầu',
      //         status: 'primary',
      //         action: () => {
      //           this.apiService.postPromise('/admin-product/products', { forceRebuildSearchIndex: true }, []).then(rs => {
      //             this.cms.showToast('Đã gửi yêu cầu lập chỉ mục tìm kiếm sản phẩm, hệ thống sẽ tự động lập chỉ mục vào thông báo thay đổi đến các máy bán hàng POS và app Smart-BOT', 'Đã gửi yêu cầu', { status: 'success' });
      //           });
      //         },
      //       },
      //     ])
      //     return false;
      //   },
      // });

      const processingMap = AppModule.processMaps['commercePos'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Hình',
          pinned: 'left',
          field: 'FeaturePicture',
          width: 100,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID SP Đối tác',
          field: 'RefCode',
          width: 180,
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Sku đối tác',
          field: 'RefSku',
          width: 180,
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên SP đối tác',
          field: 'RefName',
          // pinned: 'left',
          width: 300,
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
          }),
          headerName: 'ĐVT',
          field: 'UnitConversions',
          width: 200,
          valueGetter: (params: { data: ProductModel }) => {
            const baseUnitId = this.cms.getObjectId(params.data?.WarehouseUnit);
            const baseUnitText = this.cms.getObjectText(params.data?.WarehouseUnit);
            return params?.data?.UnitConversions?.map(unit => {
              let text = '';
              if (baseUnitId == unit?.id) {
                text = unit.text;
                unit.icon = 'checkmark-square-outline';
                // unit.status = 'primary';
              } else {
                text = `${unit.text} = ${unit.ConversionRatio} ${baseUnitText}`;
              }
              unit.toolTip = `${text} (${unit.IsAutoAdjustInventory ? 'Trừ kho tự động' : 'Không tự động trừ kho'}, ${unit.IsManageByAccessNumber ? 'Quản lý theo số truy xuất' : 'Không quản lý theo số truy xuất'})`;
              if (unit.IsManageByAccessNumber) {
                unit.status = 'danger';
              }
              if (!unit.IsAutoAdjustInventory) {
                unit.status = 'warning';
              }
              unit.label = (baseUnitId != unit?.id && `${unit.text} (${baseUnitId == unit?.id ? '*' : unit.ConversionRatio})` || unit.text) + ': ' + this.cms.currencyPipe.transform(unit.Price, 'VND');
              return unit;
            });
          },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/b2b-center/server/product/units', { page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value), refpage: this.extendsParams['refpage'], includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Danh mục',
          field: 'Categories',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/b2b-center/server/product/categories', { page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value), refpage: this.extendsParams['refpage'], includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn danh mục...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/b2b-center/server/product/groups', { page: this.cms.getObjectId(this.b2bCenterService.currentpage$.value), refpage: this.extendsParams['refpage'], includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Barcode',
          field: 'Barcode',
          // pinned: 'left',
          width: 180,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Sku Sàn',
          field: 'Sku',
          // pinned: 'left',
          width: 120,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên SP Sàn',
          field: 'Name',
          // pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID Page đối tác',
          field: 'Page',
          width: 180,
        },
        {
          headerName: 'ID SP Sàn',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        // {
        //   headerName: 'Đã map với shop ID',
        //   field: 'RefMapPage',
        //   width: 180,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'right',
        // },
        {
          ...agMakeCommandColDef(this, this.cms, (data) => {
            this.openForm([data.Code]);
          }, (data) => {
            this.deleteConfirm([data.Code]);
          }, false, [
          ]),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      if (this.onInit) {
        this.onInit(this);
      }
      return rs;
    });
  }

  editing = {};
  rows = [];

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeCategories'] = true;
    params['includeGroups'] = true;
    params['includeWarehouseUnit'] = true;
    // params['includeUnits'] = true;
    params['embedUnitConversions'] = true;
    params['includeCreator'] = true;
    params['includeLastUpdateBy'] = true;
    // params['sort_Id'] = 'desc';
    if (this.inputQuery) {
      for (const key in this.inputQuery) {
        params[key] = this.inputQuery[key];
      }
    }
    return super.prepareApiParams(params, getRowParams);
  }

  /** Api get funciton */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ProductModel[] | HttpErrorResponse) => void) {
    params['includeCategories'] = true;
    params['includeGroups'] = true;
    params['page'] = this.cms.getObjectId(this.b2bCenterService.currentpage$.value);
    params['refpage'] = this.extendsParams?.refpage;
    super.executeGet(params, success, error, complete);
  }

  getList(callback: (list: ProductModel[]) => void) {
    super.getList((rs) => {
      if (callback) callback(rs);
    });
  }

  /** Implement required */
  async openAssignCategoiesDialplog() {
    if (this.selectedIds.length > 0) {
      const editedItems = await this.convertIdsToItems(this.selectedIds);
      this.cms.openDialog(AssignCategoriesFormComponent, {
        context: {
          inputMode: 'dialog',
          inputProducts: this.selectedItems,
          onDialogSave: (newData: ProductModel[]) => {
            // this.refresh();
            this.updateGridItems(editedItems, newData);
          },
          onDialogClose: () => {
          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    }
  }

  openFormDialplog(ids?: string[], onDialogSave?: (newData: ProductModel[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }
}
