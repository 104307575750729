import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const smartBotRoutes: Routes = [

];

@NgModule({
    imports: [RouterModule.forChild(smartBotRoutes)],
    exports: [RouterModule],
})
export class SmartBotRoutingModule {
}