<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sms.Gateway.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật SMS gateway
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Type'), 'warning')">
                  <label class="label"><span class="valid">Loại (*)</span><span class="invalid">Loại là bắt buộc
                      (*)</span></label>
                      <nb-select size="medium" formControlName="Type" fullWidth>
                        <nb-option value="">Chọn loại...</nb-option>
                        <nb-option value=ITEL>iTEL</nb-option>
                        <nb-option value="DINSTAR">Dinstar</nb-option>
                        <nb-option value="PHONEAPP">Phone app</nb-option>
                      </nb-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Brandnames'), 'warning')">
                  <label class="label"><span class="valid">Brandnames</span><span class="invalid">Brandnames bắt buộc
                      (*)</span></label>
                      <ngx-select2 [select2Option]="select2ParamsOption" formControlName="Brandnames"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Groups'), 'warning')">
                  <label class="label"><span class="valid">Nhóm</span><span class="invalid">Nhóm là bắt buộc
                      (*)</span></label>
                      <ngx-select2 [select2Option]="select2GroupsOption" formControlName="Groups"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiUrl'), 'warning')">
                  <label class="label"><span class="valid">Api (*)</span><span class="invalid">Api là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Username'), 'warning')">
                  <label class="label"><span class="valid">Username (*)</span><span class="invalid">Username là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Username" placeholder="Username">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Password'), 'warning')">
                  <label class="label"><span class="valid">Password (*)</span><span class="invalid">Password là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Password" placeholder="Password">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero
              (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
