import { Component } from '@angular/core';

@Component({
  selector: 'ngx-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent {

}
