<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Crawl.Server.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          <!-- <nav>
            Cập nhật site wordpress
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">Mô tả</span><span class="invalid">Mô tả là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiToken'), 'warning')">
                  <label class="label"><span class="valid">Api Token</span><span class="invalid">Api Token là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="ApiToken" placeholder="Api Token">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiVersion'), 'warning')">
                  <label class="label"><span class="valid">Api Version</span><span class="invalid">Api Version là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="ApiVersion" placeholder="Api Version">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiUrl'), 'warning')">
                  <label class="label"><span class="valid">Api (*)</span><span class="invalid">Api là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="Api Url">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiUsername'), 'warning')">
                  <label class="label"><span class="valid">Api Username</span><span class="invalid">Api Username là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="ApiUsername" placeholder="Api Username">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiPassword'), 'warning')">
                  <label class="label"><span class="valid">Api Password</span><span class="invalid">Api Password là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="ApiPassword" placeholder="Api Password">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
