import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { ContractListComponent } from "./contract/contract-list/contract-list.component";
import { ContractTemplateListComponent } from "./template/contract-template-list/contract-template-list.component";
import { ContractBusinessInfoListComponent } from "./business-info/business-info-list/business-info-list.component";
import { NgModule } from "@angular/core";

export const contractRoutes: Routes = [
    //Contract
  {
    path: 'contract/list',
    canActivate: [AuthGuardService],
    component: ContractListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'template/list',
    canActivate: [AuthGuardService],
    component: ContractTemplateListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'business-info/list',
    canActivate: [AuthGuardService],
    component: ContractBusinessInfoListComponent,
    data: {
      reuse: true,
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(contractRoutes)],
    exports: [RouterModule],
})
export class ContractRoutingModule {
}
