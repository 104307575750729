import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { ContactAllListComponent } from "./contact-all-list/contact-all-list.component";
import { ContactCustomerListComponent } from "./contact-customer-list/contact-customer-list.component";
import { ContactEmployeeListComponent } from "./contact-employee-list/contact-employee-list.component";
import { ContactGroupFormComponent } from "./contact-group/contact-group-form/contact-group-form.component";
import { ContactGroupListComponent } from "./contact-group/contact-group-list/contact-group-list.component";
import { ContactRemovedListComponent } from "./contact-removed-list/contact-removed-list.component";
import { ContactRoutingModule } from "./contact-routing.module";
import { ContactSupplierListComponent } from "./contact-supplier-list/contact-supplier-list.component";
import { ContactFormComponent } from "./contact/contact-form/contact-form.component";
import { ContactListComponent } from "./contact/contact-list/contact-list.component";
import { IdentityCardVerificationListComponent } from "./identity-card-verification/identity-card-verification-list/identity-card-verification-list.component";
import { IdentityCardVerificationPrintComponent } from "./identity-card-verification/identity-card-verification-print/identity-card-verification-print.component";
import { ImportContactsDialogComponent } from "./import-contacts-dialog/import-contacts-dialog.component";
import { ContactComponent } from "./contact.component";

export const contactComponents = [
    ContactComponent,

    ContactFormComponent,
    ContactListComponent,
    ContactSupplierListComponent,
    ContactCustomerListComponent,
    ContactEmployeeListComponent,
    ContactRemovedListComponent,
    ContactAllListComponent,
    ImportContactsDialogComponent,
    ContactGroupListComponent,
    ContactGroupFormComponent,

    IdentityCardVerificationListComponent,
    IdentityCardVerificationPrintComponent
];

@NgModule({
    declarations: contactComponents,
    exports: contactComponents,
    imports: [
        ...commonModules,
        ContactRoutingModule,
    ],
    bootstrap: [ContactComponent],
    providers: [
        ...commonProviders,
    ],
})
export class ContactModule { }