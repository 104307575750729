<nb-card class="small-header smart-table">
  <nb-card-header>
    <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.CallCenter.title' | translate:{action: '', definition: cms.translate.instant('Common.list')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    <!-- <ngx-card-header [custom]="false" [size]="size">
      <div class="title item">
        <nb-icon *ngIf="favicon" [pack]="favicon.pack" [icon]="favicon.name"></nb-icon>
        {{title ? title : ('Ivoip.CallCenter.title' | translate:{action: '', definition: cms.translate.instant('Common.manage')} | headtitlecase)}}
      </div>
      <div class="controls item">
        <ngx-action-control-list [list]="actionButtonList">
          <ngx-action-control-end>
            <ngx-select2 [data]="domainList" [value]="ivoipService.activeDomainUuid"
              [select2Option]="select2OptionForDoaminList" (selectChange)="onChangeDomain($event)"
              style="min-width: 250px; margin-left: 0.5rem; margin-right: 0.5rem; float: right;">
            </ngx-select2>
          </ngx-action-control-end>
        </ngx-action-control-list>
      </div>
    </ngx-card-header> -->

    <!-- <nav>
      <nb-icon pack="eva" icon="list-outline"></nb-icon> Quản lý trung tâm cuộc gọi
      <button nbButton status="success" hero size="tiny" (click)="onReloadBtnClick()" style="float: right;">
        <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
      </button>
      <button nbButton status="primary" hero size="tiny" (click)="router.navigate(['/ivoip/call-centers/agents/list'])" style="float: right;">
        <nb-icon pack="eva" icon="cube-outline"></nb-icon>Vị trí trực máy
      </button>
      <ngx-select2 [data]="domainList" [value]="ivoipService.activeDomainUuid" [select2Option]="select2OptionForDoaminList"
        (selectChange)="onChangeDomain($event)" style="float: right; min-width: 250px;">
      </ngx-select2>
    </nav> -->
  </nb-card-header>

  <nb-card-body>
    <div class="ng2-smart-table-wrap">
      <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source"
        (delete)="delete($event)" (edit)="onEditAction($event)" (create)="onSerialAction($event)"
        (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
      </ng2-smart-table>
    </div>
  </nb-card-body>
</nb-card>
