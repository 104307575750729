import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { ModuleFormComponent } from "./module-manager/module-form/module-form.component";
import { ModuleListComponent } from "./module-manager/module-list/module-list.component";
import { ModulesRoutingModule } from "./modules-routing.module";
import { ModulesComponent } from "./modules.component";
import { ResourceFormComponent } from "./resources/resource-form/resource-form.component";
import { ResourceListComponent } from "./resources/resource-list/resource-list.component";

export const modulesCompoents = [
  ModulesComponent,
  ModuleFormComponent,
  ModuleListComponent,
  ResourceListComponent,
  ResourceFormComponent,
];

@NgModule({
  declarations: modulesCompoents,
  // exports: modulesCompoents,
  imports: [
    ...commonModules,
    ModulesRoutingModule,
  ],
  bootstrap: [ModulesComponent],
  providers: [
    ...commonProviders,
  ],
})
export class ModulesModule { }