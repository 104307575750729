import { FileComponent } from './file.component';
import { FileListComponent } from './file/file-list/file-list.component';
import { FileFormComponent } from './file/file-form/file-form.component';
// import { CurrencyMaskModule } from 'ng2-currency-mask';
// import { DialogModule } from '../dialog/dialog.module';
// import { CKEditorModule } from 'ng2-ckeditor';
import { FileStoreListComponent } from './store/file-store-list/file-store-list.component';
import { FileStoreFormComponent } from './store/file-store-form/file-store-form.component';
import { FileUploadComponent } from './file/file-upload/file-upload.component';
import { FileRoutingModule } from './file-routing.module';
import { commonModules, commonProviders } from '../../common-modules';
import { NgModule } from '@angular/core';

// @NgModule({
//   declarations: [FileComponent, FileListComponent, FileFormComponent, FileStoreListComponent, FileStoreFormComponent, FileUploadComponent],
//   imports: [
//     CommonModule,
//     NbTabsetModule,
//     FileRoutingModule,
//     NbCardModule,
//     Ng2SmartTableModule,
//     CustomElementModule,
//     NbIconModule,
//     NbInputModule,
//     NbCheckboxModule,
//     NbRouteTabsetModule,
//     NbStepperModule,
//     NbButtonModule,
//     NbListModule,
//     NbAccordionModule,
//     NbUserModule,
//     NbSelectModule,
//     NbActionsModule,
//     NbRadioModule,
//     NbDatepickerModule,
//     FormsModule,
//     ReactiveFormsModule,
//     NbProgressBarModule,
//     AgGridModule,
//     OwlDateTimeModule,
//     OwlNativeDateTimeModule,
//     NgxUploaderModule,
//     TranslateModule,
//     NbDialogModule.forChild(),
//     SortablejsModule.forRoot({
//       animation: 200,
//     }),
//   ],
//   entryComponents: [
//     FileStoreFormComponent,
//     FileFormComponent,
//     FileUploadComponent,
//     SmartTableThumbnailComponent,
//     SmartTableFilterComponent,
//   ],
//   providers: [
//     // use french locale
//     { provide: OWL_DATE_TIME_LOCALE, useValue: 'vi' },
//   ],
// })
// export class FileModule { }


export const fileCompoments = [
  FileComponent,
  FileListComponent,
  FileFormComponent,
  FileStoreListComponent,
  FileStoreFormComponent,
  FileUploadComponent,
];

@NgModule({
  declarations: fileCompoments,
  exports: fileCompoments,
  imports: [
    ...commonModules,
    FileRoutingModule,
  ],
  bootstrap: [FileComponent],
  providers: [
    ...commonProviders,
  ],
})
export class FileModule { }