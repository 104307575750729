import { NgModule } from "@angular/core";
import { commonModules, commonProviders } from "../../common-modules";
import { ClusterAuthorizedKeyFormComponent } from "./authorized-key/cluster-authorized-key-form/cluster-authorized-key-form.component";
import { ClusterAuthorizedKeyListComponent } from "./authorized-key/cluster-authorized-key-list/cluster-authorized-key-list.component";
import { ClusterRoutingModule } from "./cluster-routing.module";
import { ClusterComponent } from "./cluster.component";

export const clusterComponents = [
    ClusterComponent,
    ClusterAuthorizedKeyListComponent,
    ClusterAuthorizedKeyFormComponent,
];

@NgModule({
    declarations: clusterComponents,
    exports: clusterComponents,
    imports: [
        ...commonModules,
        ClusterRoutingModule,
    ],
    bootstrap: [ClusterComponent],
    providers: [
        ...commonProviders,
    ],
})
export class ClusterModule { }