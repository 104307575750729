<nb-flip-card [showToggleButton]="false" [flipped]="flipped">
  <nb-card-front>
    <nb-card size="tiny">
      <ngx-earning-card-front></ngx-earning-card-front>
      <nb-icon icon="chevron-right-outline" pack="eva" class="flip-icon" (click)="toggleView()"></nb-icon>
    </nb-card>
  </nb-card-front>
  <nb-card-back>
    <nb-card size="tiny">
      <ngx-earning-card-back></ngx-earning-card-back>
      <nb-icon icon="chevron-right-outline" pack="eva" class="flip-icon" (click)="toggleView()"></nb-icon>
    </nb-card>
  </nb-card-back>
</nb-flip-card>
