import { ActionControl } from './../../../../lib/custom-element/action-control-list/action-control.interface';
import { WarehouseGoodsContainerPrintComponent } from './../warehouse-goods-container-print/warehouse-goods-container-print.component';
import { Component, Input, OnInit } from '@angular/core';
import { WarehouseGoodsContainerModel } from '../../../../models/warehouse.model';
import { WarehouseGoodsContainerFormComponent } from '../warehouse-goods-container-form/warehouse-goods-container-form.component';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';

@Component({
  selector: 'ngx-warehouse-goods-container-list',
  templateUrl: './warehouse-goods-container-list.component.html',
  styleUrls: ['./warehouse-goods-container-list.component.scss'],
})
export class WarehouseGoodsContainerListComponent extends AgGridDataManagerListComponent<WarehouseGoodsContainerModel, WarehouseGoodsContainerFormComponent> implements OnInit {

  componentName: string = 'WarehouseGoodsContainerListComponent';
  formPath = '/warehouse/goods-container/form';
  apiPath = '/warehouse/goods-containers';
  idKey = ['Code'];
  formDialog = WarehouseGoodsContainerFormComponent;

  @Input() inputFilter: any;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public themeService: NbThemeService,
    public ref: NbDialogRef<WarehouseGoodsContainerListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  containerTypes = {
    'AREA': 'Khu',
    'SHELF': 'Kệ',
    'CUPBOARD': 'Tủ',
    'FLOOR': 'Tầng',
    'DRAWERS': 'Ngăn',
    'BASKET': 'Rổ',
    'UNKNOW': 'Chưa biết',
  };
  containerTypeList = [
    { id: 'AREA', text: 'Khu' },
    { id: 'SHELF', text: 'Kệ' },
    { id: 'CUPBOARD', text: 'Tủ' },
    { id: 'FLOOR', text: 'Tầng' },
    { id: 'DRAWERS', text: 'Ngăn' },
    { id: 'BASKET', text: 'Rổ' },
    { id: 'UNKNOW', text: 'Chưa biết' },
  ];


  async init() {
    return super.init().then(async rs => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Goods',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Số nhận thức',
          field: 'FindOrder',
          width: 150,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Kệ',
          field: 'Shelf',
          width: 250,
          valueGetter: params => params?.data?.Shelf ? {
            id: params.data.Shelf,
            text: params.data.ShelfName,
          } : null,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/warehouse/goods-containers', { includeIdText: true, eq_Type: 'SHELF', sort_Name: 'asc' }, {
                placeholder: 'Chọn kệ...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  item['text'] = item['FindOrder'] + ' - ' + item['text'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Đường dẫn',
          field: 'Path',
          width: 1000,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          width: 250,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Kho',
          field: 'Warehouse',
          width: 250,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Hàng hóa',
          field: 'Goods',
          width: 300,
          valueGetter: params => (params?.data?.Goods || []).map(m => ({ id: m.Code, text: m.Name })),
          // valueFormatter: params => params && params.data ? (params.data['Goods'] && params.data['Goods'].map(goods => this.cms.getObjectText(goods) + ' (' + goods.Unit + ')').join('<br>') || '') : null,
          // filter: AgSelect2Filter,
          // filterParams: {
          //   select2Option: {
          //     ...this.cms.makeSelect2AjaxOption('/admin-product/products', { includeIdText: true, sort_Name: 'asc' }, {
          //       placeholder: 'Chọn hàng hóa...', limit: 10, prepareReaultItem: (item) => {
          //         return item;
          //       }
          //     }),
          //     multiple: true,
          //     logic: 'OR',
          //     allowClear: true,
          //   }
          // },
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);


      const previewBtn = this.actionButtonList.find(f => f.name == 'preview');
      previewBtn.label = 'Print QR Code';
      previewBtn.disabled = () => false;
      previewBtn.icon = 'grid-outline';
      previewBtn.click = () => {
        this.cms.openDialog(WarehouseGoodsContainerPrintComponent, {
          context: {
            id: this.selectedItems.map(item => this.makeId(item)),
            printForType: 'DRAWERS',
          }
        });
      };
      const copyBtn: ActionControl = {
        ...previewBtn,
        label: 'Copy',
        title: 'Copy',
        status: 'danger',
        icon: 'copy-outline',
        click: () => {
          this.cms.openDialog(WarehouseGoodsContainerFormComponent, {
            context: {
              showLoading: true,
              inputMode: 'dialog',
              inputId: this.selectedItems.map(item => this.makeId(item)),
              isDuplicate: true,
              onDialogSave: (newData: WarehouseGoodsContainerModel[]) => {
                // if (onDialogSave) onDialogSave(row);
                // this.onClose && this.onClose(newData[0]);
                // this.onSaveAndClose && this.onSaveAndClose(newData[0]);
              },
              onDialogClose: () => {
                // if (onDialogClose) onDialogClose();
                this.refresh();
              },
            },
          });
        }
      };

      this.actionButtonList.unshift(copyBtn);
      return rs;
    });
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<WarehouseGoodsContainerModel, WarehouseGoodsContainerFormComponent>) {
    params['includeParent'] = true;
    params['includePath'] = true;
    params['includeWarehouse'] = true;
    params['includeWarehouse'] = true;
    params['includeGoods'] = true;
    params['includeIdText'] = true;
    return params;
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

}

