import { NgModule } from "@angular/core";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { NbCardModule, NbUserModule, NbButtonModule, NbIconModule, NbTabsetModule, NbSelectModule, NbListModule, NbProgressBarModule } from "@nebular/theme";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ChartModule } from "angular2-chartjs";
import { NgxEchartsModule } from "ngx-echarts";
import { ThemeModule } from "../../@theme/theme.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { ChartPanelHeaderComponent } from "./e-commerce/charts-panel/chart-panel-header/chart-panel-header.component";
import { ChartPanelSummaryComponent } from "./e-commerce/charts-panel/chart-panel-summary/chart-panel-summary.component";
import { ECommerceChartsPanelComponent } from "./e-commerce/charts-panel/charts-panel.component";
import { OrdersChartComponent } from "./e-commerce/charts-panel/charts/orders-chart.component";
import { ProfitChartComponent } from "./e-commerce/charts-panel/charts/profit-chart.component";
import { CountryOrdersChartComponent } from "./e-commerce/country-orders/chart/country-orders-chart.component";
import { CountryOrdersComponent } from "./e-commerce/country-orders/country-orders.component";
import { CountryOrdersMapComponent } from "./e-commerce/country-orders/map/country-orders-map.component";
import { CountryOrdersMapService } from "./e-commerce/country-orders/map/country-orders-map.service";
import { ECommerceDashboardComponent } from "./e-commerce/e-commerce-dashboard.component";
import { EarningCardBackComponent } from "./e-commerce/earning-card/back-side/earning-card-back.component";
import { EarningPieChartComponent } from "./e-commerce/earning-card/back-side/earning-pie-chart.component";
import { EarningCardComponent } from "./e-commerce/earning-card/earning-card.component";
import { EarningCardFrontComponent } from "./e-commerce/earning-card/front-side/earning-card-front.component";
import { EarningLiveUpdateChartComponent } from "./e-commerce/earning-card/front-side/earning-live-update-chart.component";
import { ECommerceLegendChartComponent } from "./e-commerce/legend-chart/legend-chart.component";
import { ECommerceProgressSectionComponent } from "./e-commerce/progress-section/progress-section.component";
import { SlideOutComponent } from "./e-commerce/slide-out/slide-out.component";
import { TrafficBackCardComponent } from "./e-commerce/traffic-reveal-card/back-side/traffic-back-card.component";
import { TrafficBarChartComponent } from "./e-commerce/traffic-reveal-card/back-side/traffic-bar-chart.component";
import { TrafficBarComponent } from "./e-commerce/traffic-reveal-card/front-side/traffic-bar/traffic-bar.component";
import { TrafficFrontCardComponent } from "./e-commerce/traffic-reveal-card/front-side/traffic-front-card.component";
import { TrafficCardsHeaderComponent } from "./e-commerce/traffic-reveal-card/traffic-cards-header/traffic-cards-header.component";
import { TrafficRevealCardComponent } from "./e-commerce/traffic-reveal-card/traffic-reveal-card.component";
import { ECommerceUserActivityComponent } from "./e-commerce/user-activity/user-activity.component";
import { ECommerceVisitorsAnalyticsChartComponent } from "./e-commerce/visitors-analytics/visitors-analytics-chart/visitors-analytics-chart.component";
import { ECommerceVisitorsAnalyticsComponent } from "./e-commerce/visitors-analytics/visitors-analytics.component";
import { ECommerceVisitorsStatisticsComponent } from "./e-commerce/visitors-analytics/visitors-statistics/visitors-statistics.component";
import { DashboardComponent } from "./dashboard.component";
import { StatsAreaChartComponent } from "./e-commerce/profit-card/back-side/stats-area-chart.component";
import { StatsCardBackComponent } from "./e-commerce/profit-card/back-side/stats-card-back.component";
import { StatsBarAnimationChartComponent } from "./e-commerce/profit-card/front-side/stats-bar-animation-chart.component";
import { StatsCardFrontComponent } from "./e-commerce/profit-card/front-side/stats-card-front.component";
import { ProfitCardComponent } from "./e-commerce/profit-card/profit-card.component";

export const dashboardComponents = [
  DashboardComponent,
    ECommerceDashboardComponent,
    StatsCardFrontComponent,
    StatsAreaChartComponent,
    StatsBarAnimationChartComponent,
    ProfitCardComponent,
    ECommerceChartsPanelComponent,
    ChartPanelHeaderComponent,
    ChartPanelSummaryComponent,
    OrdersChartComponent,
    ProfitChartComponent,
    StatsCardBackComponent,
    TrafficRevealCardComponent,
    TrafficBarChartComponent,
    TrafficFrontCardComponent,
    TrafficBackCardComponent,
    TrafficBarComponent,
    TrafficCardsHeaderComponent,
    CountryOrdersComponent,
    CountryOrdersMapComponent,
    CountryOrdersChartComponent,
    ECommerceVisitorsAnalyticsComponent,
    ECommerceVisitorsAnalyticsChartComponent,
    ECommerceVisitorsStatisticsComponent,
    ECommerceLegendChartComponent,
    ECommerceUserActivityComponent,
    ECommerceProgressSectionComponent,
    SlideOutComponent,
    EarningCardComponent,
    EarningCardFrontComponent,
    EarningCardBackComponent,
    EarningPieChartComponent,
    EarningLiveUpdateChartComponent,
];

@NgModule({
  imports: [
    DashboardRoutingModule,
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbTabsetModule,
    NbSelectModule,
    NbListModule,
    ChartModule,
    NbProgressBarModule,
    NgxEchartsModule,
    NgxChartsModule,
    LeafletModule,
  ],
  declarations: dashboardComponents,
  exports: dashboardComponents,
  bootstrap: [DashboardComponent],
  providers: [
    CountryOrdersMapService,
  ],
})
export class DashboardModule { }
