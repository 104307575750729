import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { ProductTaxRateTableComponent } from "./tax-rate-table/tax-rate-table/tax-rate-table.component";
import { TaxRateUpdateNoteListComponent } from "./tax-rate-table-update-note/tax-rate-table-update-note-list/tax-rate-table-update-note-list.component";
import { ProductBrandListComponent } from "./brand/product-brand-list/product-brand-list.component";
import { ProductCategoryFormComponent } from "./category/product-category-form/product-category-form.component";
import { ProductCategoryListComponent } from "./category/product-category-list/product-category-list.component";
import { ProductKeywordListComponent } from "./keyword/product-keyword-list/product-keyword-list.component";
import { ProductGroupFormComponent } from "./product-group/product-group-form/product-group-form.component";
import { ProductGroupListComponent } from "./product-group/product-group-list/product-group-list.component";
import { ProductObjectReferenceListComponent } from "./product-object-reference/product-object-reference-list/product-object-reference-list.component";
import { ProductFormComponent } from "./product/product-form/product-form.component";
import { ProductListComponent } from "./product/product-list/product-list.component";
import { ProductPropertyListComponent } from "./property/product-property-list/product-property-list.component";
import { ProductUnitFormComponent } from "./unit/product-unit-form/product-unit-form.component";
import { ProductUnitListComponent } from "./unit/product-unit-list/product-unit-list.component";
import { NgModule } from "@angular/core";

export const adminProductRoutes: Routes = [
    {
        path: 'product/list',
        canActivate: [AuthGuardService],
        component: ProductListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'product/form',
        canActivate: [AuthGuardService],
        component: ProductFormComponent,
    },
    {
        path: 'product/form/:id',
        canActivate: [AuthGuardService],
        component: ProductFormComponent,
    },
    {
        path: 'category/list',
        canActivate: [AuthGuardService],
        component: ProductCategoryListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'brand/list',
        canActivate: [AuthGuardService],
        component: ProductBrandListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'property/list',
        canActivate: [AuthGuardService],
        component: ProductPropertyListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'product-object-reference/list',
        canActivate: [AuthGuardService],
        component: ProductObjectReferenceListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'product-keywords/list',
        canActivate: [AuthGuardService],
        component: ProductKeywordListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'category/form',
        canActivate: [AuthGuardService],
        component: ProductCategoryFormComponent,
    },
    {
        path: 'category/form/:id',
        canActivate: [AuthGuardService],
        component: ProductCategoryFormComponent,
    },
    {
        path: 'unit/list',
        canActivate: [AuthGuardService],
        component: ProductUnitListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'unit/form',
        canActivate: [AuthGuardService],
        component: ProductUnitFormComponent,
    },
    {
        path: 'unit/form/:id',
        canActivate: [AuthGuardService],
        component: ProductUnitFormComponent,
    },
    // Product group

    {
        path: 'group/list',
        canActivate: [AuthGuardService],
        component: ProductGroupListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'group/form',
        canActivate: [AuthGuardService],
        component: ProductGroupFormComponent,
    },
    {
        path: 'group/form/:id',
        canActivate: [AuthGuardService],
        component: ProductGroupFormComponent,
    },
    {
        path: 'tax-rate/table',
        canActivate: [AuthGuardService],
        component: ProductTaxRateTableComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'tax-rate/update-note/list',
        canActivate: [AuthGuardService],
        component: TaxRateUpdateNoteListComponent,
        data: {
            reuse: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(adminProductRoutes)],
    exports: [RouterModule],
})
export class AdminProductRoutingModule {
}
