<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Recording.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật tệp âm thanh
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="recording_uuid">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('recording_name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tênlà bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="recording_name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('recording_filename'), 'warning')">
                  <label class="label"><span class="valid">Tên tệp (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="recording_filename" placeholder="Tên tệp">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('recording_description'), 'warning')">
                  <label class="label"><span class="valid">Mô tả (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="recording_description" placeholder="Mô tả">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
