<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Ivoip.Gateway.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật gateway
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <!-- <input type="hidden" formControlName="call_block_uuid_old"> -->
              <input type="hidden" formControlName="gateway_uuid">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('gateway'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="gateway" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Proxy</span></label>
                  <input type="text" nbInput fullWidth formControlName="proxy" placeholder="Proxy">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Username</span></label>
                  <input type="text" nbInput fullWidth formControlName="username" placeholder="Username">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Password</span></label>
                  <input type="text" nbInput fullWidth formControlName="password" placeholder="Password">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Realm</span></label>
                  <input type="text" nbInput fullWidth formControlName="realm" placeholder="Realm">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">From Domain</span></label>
                  <input type="text" nbInput fullWidth formControlName="from_domain" placeholder="From Domain">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">From user</span></label>
                  <input type="text" nbInput fullWidth formControlName="from_user" placeholder="From user">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Expire seconds</span></label>
                  <input type="text" nbInput fullWidth formControlName="expire_seconds" placeholder="Expire seconds">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Retry seconds</span></label>
                  <input type="text" nbInput fullWidth formControlName="retry_seconds" placeholder="Retry seconds">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Domain</span></label>
                  <ngx-select2 [data]="privateDmainList" formControlName="domain_uuid"
                    [select2Option]="privateDmainListConfig">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('profile'), 'warning')">
                  <label class="label"><span class="valid">Profile (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="profile" placeholder="Profile">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('register_transport'), 'warning')">
                  <label class="label"><span class="valid">Register transport</span><span class="invalid">Register transport (*)</span></label>
                  <nb-select size="medium" formControlName="register_transport" fullWidth>
                    <nb-option value="">Register transport</nb-option>
                    <nb-option value="udp">udp</nb-option>
                    <nb-option value="tcp">tcp</nb-option>
                    <nb-option value="tls">tls</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('caller_id_in_from'), 'warning')">
                  <label class="label"><span class="valid">Caller id in from (*)</span></label>
                  <nb-select size="medium" formControlName="caller_id_in_from" fullWidth>
                    <nb-option value="">Caller id in from</nb-option>
                    <nb-option value="true">True</nb-option>
                    <nb-option value="false">False</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('context'), 'warning')">
                  <label class="label"><span class="valid">Context (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="context" placeholder="Context">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"><span class="valid">Mô tả</span></label>
                  <input type="text" nbInput fullWidth formControlName="description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Kích hoạt</span></label>
                  <nb-checkbox formControlName="enabled">Kích hoạt</nb-checkbox>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label"><span class="valid">Đăng ký</span></label>
                  <nb-select size="medium" formControlName="register" fullWidth>
                    <nb-option value="">Register</nb-option>
                    <nb-option value="true">True</nb-option>
                    <nb-option value="false">False</nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
