
// @NgModule({
//   declarations: [CrawlComponent, CrawlDashboardComponent, CrawlServerListComponent, CrawlServerFormComponent, CrawlPlanListComponent, CrawlPlanFormComponent],
//   imports: [
//     CommonModule,
//     NbTabsetModule,
//     CrawlRoutingModule,
//     NbCardModule,
//     Ng2SmartTableModule,
//     CustomElementModule,
//     NbIconModule,
//     NbInputModule,
//     NbCheckboxModule,
//     // IvoipDashboardModule,
//     NbRouteTabsetModule,
//     NbStepperModule,
//     NbButtonModule,
//     NbListModule,
//     NbAccordionModule,
//     NbUserModule,
//     NbSelectModule,
//     NbActionsModule,
//     NbRadioModule,
//     NbDatepickerModule,
//     // CurrencyMaskModule,
//     FormsModule,
//     ReactiveFormsModule,
//     // DialogModule,
//     NbProgressBarModule,
//     AgGridModule,
//     TranslateModule,
//     NbDialogModule.forChild(),
//     SortablejsModule.forRoot({
//       animation: 200,
//     }),
//   ],
//   entryComponents: [
//     CrawlServerFormComponent,
//     CrawlPlanFormComponent,
//     SmartTableFilterComponent,
//   ],
// })
// export class CrawlModule { }

import { NgModule } from "@angular/core";
import { CrawlComponent } from "./crawl.component";
import { CrawlDashboardComponent } from "./dashboard/crawl-dashboard/crawl-dashboard.component";
import { CrawlPlanFormComponent } from "./plan/crawl-plan-form/crawl-plan-form.component";
import { CrawlPlanListComponent } from "./plan/crawl-plan-list/crawl-plan-list.component";
import { CrawlServerFormComponent } from "./server/crawl-server-form/crawl-server-form.component";
import { CrawlServerListComponent } from "./server/crawl-server-list/crawl-server-list.component";
import { commonModules, commonProviders } from "../../common-modules";
import { CrawlRoutingModule } from "./crawl-routing.module";

export const crawlComponents = [
    CrawlComponent,
    CrawlDashboardComponent,
    CrawlServerListComponent,
    CrawlServerFormComponent,
    CrawlPlanListComponent,
    CrawlPlanFormComponent,
];

@NgModule({
    declarations: crawlComponents,
    // exports: crawlComponents,
    imports: [
        ...commonModules,
        CrawlRoutingModule,
    ],
    bootstrap: [CrawlComponent],
    providers: [
        ...commonProviders,
    ],
})
export class CrawlModule { }