import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-ivoip',
  templateUrl: './ivoip.component.html',
  styleUrls: ['./ivoip.component.scss'],
})
export class IvoipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
