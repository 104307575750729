<div class="under-construction">
  <div class="under-construction-text"><span>DASHBOARD</span><br>UNDER CONSTRUCTION</div>
</div>
<div class="e-commerce-dashboard">
  <div class="row">
    <div class="col-xxl-5">
      <div class="row">
        <div class="col-md-6">
          <ngx-profit-card></ngx-profit-card>
        </div>
        <div class="col-md-6">
          <ngx-earning-card></ngx-earning-card>
        </div>
      </div>

      <ngx-traffic-reveal-card></ngx-traffic-reveal-card>
    </div>

    <div class="col-xxl-7">
      <ngx-ecommerce-charts></ngx-ecommerce-charts>
    </div>
  </div>

  <div class="row">
    <div class="col-xxl-9">
      <ngx-country-orders></ngx-country-orders>
    </div>

    <div class="col-xxl-3">
      <ngx-progress-section></ngx-progress-section>
    </div>
  </div>

  <div class="row">
    <div class="col-xxl-9">
      <ngx-ecommerce-visitors-analytics></ngx-ecommerce-visitors-analytics>
    </div>

    <div class="col-xxl-3">
      <ngx-user-activity></ngx-user-activity>
    </div>
  </div>
</div>