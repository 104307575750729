import { Model } from "./model";

export class WhFtpModel extends Model {

  ftp_user_id?: string;
  sys_userid?: string;
  sys_groupid?: string;
  sys_perm_user?: string;
  sys_perm_group?: string;
  sys_perm_other?: string;
  server_id?: string;
  parent_domain_id?: string;
  username?: string;
  username_prefix?: string;
  password?: string;
  quota_size?: string;
  active?: string;
  uid?: string;
  gid?: string;
  dir?: string;
  quota_files?: string;
  ul_ratio?: string;
  dl_ratio?: string;
  ul_bandwidth?: string;
  dl_bandwidth?: string;
  expires?: string;
  user_type?: string;
  user_config?: string;

}
