<nb-card class="small-header ag-grid-card" style="height: 100%;" [nbSpinner]="loading" [ngStyle]="{width: width, height: height, margin: isEmbed && '0 !important' || null}">
  <nb-card-header>
    <ngx-card-header [titleNowrap]="titleNowrap" [size]="size" [icon]="favicon" [title]="(title ? title : 'Danh sách') +  (selectedIds.length ?  (' - ' +'Đã chọn ' + selectedIds.length) : '') + (infiniteInitialRowCount && ('/' + infiniteInitialRowCount) || '')" [controls]="actionButtonList"></ngx-card-header>
  </nb-card-header>

  <nb-card-body>
    <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%;"
    id="myGrid"
    class="{{themeName}}"
     [gridOptions]="gridOptions" [modules]="modules"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [debug]="true" [enableCellTextSelection]="enableCellTextSelection"
    [defaultColDef]="defaultColDef"
    [rowSelection]="rowSelection"
    [rowModelType]="rowModelType"
    [paginationPageSize]="paginationPageSize"
    [cacheOverflowSize]="cacheOverflowSize"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [maxBlocksInCache]="maxBlocksInCache"
    [cacheBlockSize]="cacheBlockSize"
    [getRowNodeId]="getRowNodeId"
    [components]="components"
    [rowData]="rowData"
    [multiSortKey]="multiSortKey"
    [rowDragManaged]="rowDragManaged"
    [suppressMoveWhenRowDragging]="suppressMoveWhenRowDragging"
    [getRowHeight]="getRowHeight"
    [rowHeight]="rowHeight"
    [pagination]="pagination"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick"
    [suppressRowClickSelection]="suppressRowClickSelection"
    (columnResized)="onColumnResized()"
    (rowSelected)="onRowSelected()"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (onRowDataUpdated)="_onRowDataUpdated($event)"
    >
    </ag-grid-angular>
  </nb-card-body>
</nb-card>
