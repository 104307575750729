<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Crawl.Plan.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          <!-- <nav>
            Cập nhật kế hoạch thu thập dữ liệu
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">Mô tả (*)</span><span class="invalid">Mô tả là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="Mô tả">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('CrawlAlgorithm'), 'warning')">
                  <label class="label"><span class="valid">CrawlAlgorithm</span><span class="invalid">CrawlAlgorithm
                      là bắt buộc
                      (*)</span></label>
                  <nb-select size="medium" formControlName="CrawlAlgorithm" fullWidth selected="CrawlBot">
                    <nb-option *ngFor="let algorithm of crawlAlgorithmList" [value]="algorithm.id">{{algorithm.text}}</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('TargetContentPath'), 'warning')">
                  <label class="label"><span class="valid">Target Content Selector (*)</span><span
                      class="invalid">Target Content Selector
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetContentPath"
                    placeholder="Target Content Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('TargetUrl'), 'warning')">
                  <label class="label"><span class="valid">Target Url (*)</span><span class="invalid">Target Url
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetUrl" placeholder="Target Url">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('TargetTitlePath'), 'warning')">
                  <label class="label"><span class="valid">Target Title Selector (*)</span><span class="invalid">Target
                      Title Selector
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetTitlePath"
                    placeholder="Target Title Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('TargetDescriptionPath'), 'warning')">
                  <label class="label"><span class="valid">Target Description Selector (*)</span><span
                      class="invalid">Target Description Selector
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetDescriptionPath"
                    placeholder="Target Description Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('TargetCreatedPath'), 'warning')">
                  <label class="label"><span class="valid">Target Created Selector</span><span class="invalid">Target
                      Created Path
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetCreatedPath"
                    placeholder="Target Created Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('TargetCategoriesPath'), 'warning')">
                  <label class="label"><span class="valid">Target Categories Selector</span><span class="invalid">Target
                      Categories Selector
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetCategoriesPath"
                    placeholder="Target Categories Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('TargetFeatureImagePath'), 'warning')">
                  <label class="label"><span class="valid">Target Feature Image Selector</span><span
                      class="invalid">Target Feature Image Selector
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetFeatureImagePath"
                    placeholder="Target Feature Image Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('TargetAuthorPath'), 'warning')">
                  <label class="label"><span class="valid">Target Author Selector</span><span class="invalid">Target
                      Author Selector
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetAuthorPath"
                    placeholder="Target Author Selector">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('TargetImageSrc'), 'warning')">
                  <label class="label"><span class="valid">Target Image Src</span><span class="invalid">Target Image Src
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="TargetImageSrc" placeholder="src, data-src,...">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('ExcludeContentElements'), 'warning')">
                  <label class="label"><span class="valid">Exclude Content Elements</span><span class="invalid">Exclude
                      Content Elements là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="ExcludeContentElements"
                    placeholder="jquery selectors">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Frequency'), 'warning')">
                  <label class="label"><span class="valid">Frequency (*)</span><span class="invalid">Frequency
                      là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Frequency" placeholder="Frequency">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('State'), 'warning')">
                  <label class="label"><span class="valid">State</span><span class="invalid">State
                      là bắt buộc
                      (*)</span></label>
                  <nb-select size="medium" formControlName="State" fullWidth selected="INSTANT">
                    <nb-option value="INSTANT">Thực thi</nb-option>
                    <nb-option value="SCHEDULE">Lên lịch</nb-option>
                    <nb-option value="PENDDING">Chờ</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Strategy'), 'warning')">
                  <label class="label"><span class="valid">Strategy</span><span class="invalid">Strategy
                      là bắt buộc
                      (*)</span></label>
                  <nb-select size="medium" formControlName="Strategy" fullWidth selected="CRAWLNEW">
                    <nb-option value="CRAWLNEW">Cào mới</nb-option>
                    <nb-option value="CRAWlHALL">Cào tất cả</nb-option>
                  </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('LastPublished'), 'warning')">
                  <label class="label"><span class="valid">LastPublished</span><span class="invalid">LastPublished
                      là bắt buộc
                      (*)</span></label>
                      <input type="number" nbInput fullWidth formControlName="LastPublished" placeholder="Ngày đăng không được trễ quá x ngày">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('NumOfThread'), 'warning')">
                  <label class="label"><span class="valid">NumOfThread</span><span class="invalid">NumOfThread
                      là bắt buộc
                      (*)</span></label>
                      <input type="number" nbInput fullWidth formControlName="NumOfThread" placeholder="Số tiến trình cùng lúc">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DefaultCategory'), 'warning')">
                  <label class="label"><span class="valid">DefaultCategory</span><span class="invalid">DefaultCategory
                      là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="DefaultCategory" placeholder="Danh mục mặc định">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Proxies'), 'warning')">
                  <label class="label"><span class="valid">Proxies</span><span class="invalid">Proxies
                      là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="Proxies" [select2Option]="select2ProxiesOption">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('RequestHeaders'), 'warning')">
                  <label class="label"><span class="valid">Request Headers</span><span class="invalid">Request Headers
                      là bắt buộc
                      (*)</span></label>
                  <textarea nbInput fullWidth formControlName="RequestHeaders" placeholder="Request Headers"
                    rows="10"></textarea>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('AllowPaths'), 'warning')">
                  <label class="label"><span class="valid">AllowPaths</span><span class="invalid">AllowPaths
                      là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="AllowPaths" [select2Option]="select2AllowPathOption">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DenyPaths'), 'warning')">
                  <label class="label"><span class="valid">DenyPaths</span><span class="invalid">DenyPaths
                      là bắt buộc
                      (*)</span></label>
                  <ngx-select2 formControlName="DenyPaths" [select2Option]="select2DenyPathOption">
                  </ngx-select2>
                </div>
              </div>
            </div>

            <hr>
            <label class="label">Danh sách crawl bot</label>
            <div class="row">
              <div class="col-sm-1">STT</div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-5">Crawl bot</div>
                  <div class="col-sm-3">Action</div>
                  <div class="col-sm-2">State</div>
                  <div class="col-sm-1">Active</div>
                  <div class="col-sm-1">Main bot</div>
                </div>
              </div>
              <div class="col-sm-1" style="text-align: center;">
                <button nbButton status="success" (click)="addBotFormGroup(i)" size="small"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>

            <hr>
            <div formArrayName="Bots" class="form-details">
              <div class="form-detail-item" *ngFor="let bot of getBots(i).controls; let ic=index" [formGroup]="bot">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled="true" value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label class="label">Crawl bot</label>
                          <nb-select size="medium" formControlName="Bot" fullWidth selected="">
                            <nb-option value="">Chọn bot</nb-option>
                            <nb-option *ngFor="let bot of botList" [value]="bot.Code"> {{ bot.Name }}</nb-option>
                          </nb-select>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">Action</label>
                          <div class="row">
                            <div class="col-sm-4">
                              <button fullWidth nbButton status="success" hero
                                (click)="onTestCrawlClick($event, formItem, bot, bot.get('Bot').value)">Test</button>
                            </div>
                            <div class="col-sm-4">
                              <button fullWidth nbButton status="danger" hero
                                (click)="onStartCrawlClick($event, formItem, bot, bot.get('Bot').value)">Start</button>
                            </div>
                            <div class="col-sm-4">
                              <button fullWidth nbButton status="warning" hero
                                (click)="onStopCrawlClick($event, formItem, bot, bot.get('Bot').value)">Stop</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label class="label">State</label>
                          <input nbInput fullWidth disabled="true" formControlName="State">
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <label class="label">Main bot</label>
                          <nb-checkbox formControlName="IsMain"></nb-checkbox>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <label class="label">Active</label>
                          <nb-checkbox formControlName="Active"></nb-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">CM</label> -->
                      <button nbButton status="danger" hero size="small" (click)="removeBotGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="col-sm-12">
                    <nb-progress-bar style="margin-bottom: 1rem;"
                      [value]="progressBarMap[bot.get('TargetSite').value]?.percent"
                      [status]="progressBarMap[bot.get('TargetSite').value]?.status">
                      {{progressBarMap[bot.get('TargetSite').value]?.label}}</nb-progress-bar>
                  </div> -->
                </div>

              </div>
            </div>

            <hr>
            <label class="label">Danh sách site lưu trữ</label>
            <div class="row">
              <div class="col-sm-1">STT</div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-11">Target Site</div>
                  <div class="col-sm-1">Active</div>
                </div>
              </div>
              <div class="col-sm-1" style="text-align: center;">
                <button nbButton status="success" (click)="addStoreFormGroup(i)" size="tiny"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>

            <hr>
            <div formArrayName="Stores" class="form-details">
              <div class="form-detail-item" *ngFor="let store of getStores(i).controls; let ic=index"
                [formGroup]="store">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled="true" value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-11">
                        <div class="form-group">
                          <label class="label">Site lưu trữ</label>
                          <nb-select size="medium" formControlName="Site" fullWidth selected="">
                            <nb-option value="">Chọn site</nb-option>
                            <nb-option *ngFor="let site of wpSiteList" [value]="site.Code"> {{ site.Name }}</nb-option>
                          </nb-select>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <label class="label">Active</label>
                          <nb-checkbox formControlName="Active"></nb-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">CM</label> -->
                      <button nbButton status="danger" hero size="small" (click)="removeStoreGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="col-sm-12">
                    <nb-progress-bar style="margin-bottom: 1rem;"
                      [value]="progressBarMap[store.get('TargetSite').value]?.percent"
                      [status]="progressBarMap[store.get('TargetSite').value]?.status">
                      {{progressBarMap[store.get('TargetSite').value]?.label}}</nb-progress-bar>
                  </div> -->
                </div>

              </div>
            </div>


          </div>
        </nb-card-body>
        <nb-card-footer>


          <!-- <select style="width: 100%;" size="10" #logsEle>
            <option *ngFor="let log of logs">{{log}}</option>
            <option>Nhật ký tiến trình</option>
          </select>
          <br>
          <br> -->

          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
            <!-- <button (click)="startCopy(formItem)" nbButton status="danger" [disabled]="!form.valid"
              *ngIf="!isSynchroizing()" style="float: right;" hero>Sync</button> -->
            <!-- <button (click)="stopCopy()" nbButton status="warning" [disabled]="!form.valid" *ngIf="isSynchroizing()"
              style="float: right;" hero>Stop</button> -->
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
