import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogRef } from "@nebular/theme";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../../../../../environments/environment.prod";
import { AppModule } from "../../../../../../app.module";
import { DataManagerPrintComponent } from "../../../../../../lib/data-manager/data-manager-print.component";
import { ProcessMap } from "../../../../../../models/process-map.model";
import { SalesMasterPriceTableDetailModel } from "../../../../../../models/sales.model";
import { ApiService } from "../../../../../../services/api.service";
import { CommonService } from "../../../../../../services/common.service";
import { RootServices } from "../../../../../../services/root.services";
import { DialogFormComponent } from "../../../../../../lib/component/dialog/dialog-form/dialog-form.component";
import * as XLSX from 'xlsx';
import { Model } from "../../../../../../models/model";
import { CollaboratorPublisherListCommissionReportFormComponent } from "../list-commission-report-form/list-commission-report-form.component";
import { CollaboratorStateMap } from "../../../../collaborator.state.map";

@Component({
  selector: 'ngx-publisher-list-commission-report-print',
  templateUrl: './list-commission-report-print.component.html',
  styleUrls: ['./list-commission-report-print.component.scss'],
  providers: [CurrencyPipe]
})
export class CollaboratorPublisherListCommissionReportPrintComponent extends DataManagerPrintComponent<Model> implements OnInit {

  /** Component name */
  componentName = 'CollaboratorPublisherListCommissionReportPrintComponent';
  title = 'LIST BÁO CÁO CHIẾT KHẤU CTV';
  env = environment;
  apiPath = '/collaborator/report/publisher/list-commissions';

  @Input() onChange? = (data: Model, instance?: DataManagerPrintComponent<Model>) => {
    this.refresh();
  };

  processMapList: ProcessMap[] = [];
  formDialog = CollaboratorPublisherListCommissionReportFormComponent;

  showPicture = true;

  constructor(
    public rsv: RootServices,
    public cms: CommonService,
    public router: Router,
    public apiService: ApiService,
    public ref: NbDialogRef<CollaboratorPublisherListCommissionReportPrintComponent>,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rsv, cms, router, apiService, ref);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    const result = await super.init().then(rs => {
      this.actionButtonList.unshift({
        name: 'showPicture',
        label: 'Hình ảnh',
        title: 'H.Thị hình',
        status: 'info',
        size: 'medium',
        icon: 'eye-outline',
        click: () => {
          this.showPicture = !this.showPicture;
          return true;
        }
      });
      this.actionButtonList.unshift({
        name: 'downloadPdf',
        label: 'PDF',
        title: 'Download PDF',
        status: 'danger',
        size: 'medium',
        icon: 'download-outline',
        click: () => {
          this.downloadPdf(this.id);
          return true;
        }
      });
      return rs;
    });

    return result;
  }

  // Fix for state not update after change voucher state
  prepareData(data) {
    data = this.summaryCalculate(data);
    return data;
  }

  renderTitle(data: Model) {
    return `PhieuDatHangNCC_${this.getIdentified(data).join('-')}` + (data.DateOfPurchase ? ('_' + this.datePipe.transform(data.DateOfPurchase, 'short')) : '');
  }

  close() {
    this.ref.close();
  }

  renderValue(value: any) {
    if (value && value['text']) {
      return value['text'];
    }
    return value;
  }

  toMoney(detail: Model) {
    if (detail.Type === 'PRODUCT') {
      let toMoney = detail['Quantity'] * detail['Price'];
      detail.Tax = typeof detail.Tax === 'string' ? (this.cms.taxList?.find(f => f.Code === detail.Tax) as any) : detail.Tax;
      if (detail.Tax) {
        if (typeof detail.Tax.Tax == 'undefined') {
          throw Error('tax not as tax model');
        }
        toMoney += toMoney * detail.Tax.Tax / 100;
      }
      return toMoney;
    }
    return 0;
  }

  getTotal() {
    let total = 0;
    return total;
  }

  saveAndClose() {
    if (this.onSaveAndClose) {
      // this.onSaveAndClose(this.data.Code);
    }
    this.close();
    return false;
  }

  exportExcel(type: string) {
    this.close();
    return false;
  }

  get identifier() {
    // return this.data.Code;
    return '';
  }

  async getFormData(ids: string[]) {
    return this.apiService.getPromise<Model[]>(this.apiPath, { id: ids, includeSummary: true, includeRelativeVouchers: true }).then(async data => {
      // this.summaryCalculate(data);

      for (const i in data) {
        //   this.setDetailsNo(item.Details, (detail: Model) => detail.Type !== 'CATEGORY');
        data[i].Details = await this.apiService.getPromise<Model[]>('/collaborator/report/publisher/commissions', {
          limit: 'nolimit',
          sort_Id: 'desc',
          includeContact: true,
          includeObject: true,
          includePublisher: true,
          includeSeller: true,
          includeSalesManager: true,
          includePublisherSupporter: true,
          includeCreator: true,
          includeRelativeVouchers: true,
          includeStateLogs: true,
          eq_ReportList: data[i].Code,
        });
        for (let j in data[i].Details) {

          for (const key in data[i].Details[j].ReportData) {
            if (['ReportData', 'Id', 'Code', 'SystemUuid', 'State'].indexOf(key) < 0) {
              data[i].Details[j][key] = data[i].Details[j].ReportData[key];
            }
          }
          data[i].Details[j] = {
            ...data[i].Details[j],
            // ...data[i].Details[j].ReportData,
            ...(data[i].Details[j].ReportData?.Summary && data[i].Details[j].ReportData?.Summary['REPORTBYVOUCHER'][data[i].Details[j].MainReportPart] || {}),
          };

          data[i].Details[j].State = CollaboratorStateMap.publisherCommissionStateMap[this.cms.getObjectId(data[i].Details[j].State)] || data[i].Details[j].State;
        }
      }

      return data;
    });
  }

  approvedConfirm(data: Model, index: number) {
    if (['COMPLETE'].indexOf(data.State) > -1) {
      this.cms.showDialog(this.cms.translateText('Common.approved'), this.cms.translateText('Common.completedAlert', { object: this.cms.translateText('Sales.PriceReport.title', { definition: '', action: '' }) + ': `' + data.Title + '`' }), [
        {
          label: this.cms.translateText('Common.close'),
          status: 'success',
          action: () => {
            this.onClose(data);
          },
        },
      ]);
      return;
    }

    const params = { id: [data.Code] };
    const processMap = CollaboratorStateMap.publisherCommissionListStateMap[data.State || ''];
    params['changeState'] = this.processMapList[index]?.nextState;

    this.cms.showDialog(this.cms.translateText('Common.confirm'), this.cms.translateText(processMap?.confirmText, { object: this.cms.translateText('Sales.PriceReport.title', { definition: '', action: '' }) + ': `' + data.Title + '`' }), [
      {
        label: this.cms.translateText('Common.cancel'),
        status: 'primary',
        action: () => {

        },
      },
      {
        label: this.cms.translateText(data.State == 'APPROVED' ? 'Common.complete' : 'Common.approve'),
        status: 'danger',
        action: () => {
          this.loading = true;
          this.apiService.putPromise<Model[]>(this.apiPath, params, [{ Code: data.Code }]).then(rs => {
            this.loading = false;
            this.onChange && this.onChange(data);
            this.onClose && this.onClose(data);
            this.close();
            this.cms.toastService.show(this.cms.translateText(processMap?.responseText, { object: this.cms.translateText('Purchase.PrucaseVoucher.title', { definition: '', action: '' }) + ': `' + data.Title + '`' }), this.cms.translateText(processMap?.responseTitle), {
              status: 'success',
            });
          }).catch(err => {
            this.loading = false;
          });
        },
      },
    ]);
  }

  getItemDescription(item: Model) {
    return item?.Title;
  }

  summaryCalculate(data: Model[]) {
    for (const i in data) {
      const item = data[i];
      item['Total'] = 0;
      // item['Title'] = this.renderTitle(item);
      item['AllowTransferToOrder'] = (item['State'] == 'HOT') && ((item.RelativeVouchers || []).findIndex(f => this.cms.getObjectId(f.type) == 'CLBRTORDER') < 0);
      // for (const detail of item.Details) {

      //   // Generate barcode
      //   // detail['SkuBarcode'] = JsBarcode(detail.Product?.Sku, 'text');

      //   item['Total'] += (detail.GenerateCredit - detail.GenerateDebit);
      // }
      this.processMapList[i] = CollaboratorStateMap.publisherCommissionListStateMap[item.State || ''];
    }
    return data;
  }


  downloadPdf(ids: string[]) {
    window.open(this.apiService.buildApiUrl(this.apiPath, { id: ids, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }), '__blank');
  }

  renderCurrency(money: number) {
    if (typeof money == 'undefined' || money === null) return this.currencyPipe.transform(0, 'VND');
    if (money < 0) {
      let text = this.currencyPipe.transform(-money, 'VND');
      return `<span class="text-color-danger">(${text})</span>`;
    } else {
      return this.currencyPipe.transform(money, 'VND');
    }
  }
}
