import { Model } from "./model";

export class WhDatabaseUserModel extends Model {

  database_user_id?: string;
  server_id?: string;
  database_user?: string;
  database_password?: string;

}
