<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('ZaloOa.Follower.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Title'), 'warning')">
                  <label class="label"><span class="valid">Danh xưng</span><span class="invalid">Danh xưng là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Title" placeholder="Danh xưng">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ShortName'), 'warning')">
                  <label class="label"><span class="valid">Tên rút gọn</span><span class="invalid">Tên rút gọn là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="ShortName" placeholder="Tên rút gọn">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Name">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Organizations'), 'warning')">
                  <label class="label"><span class="valid">Tổ chức</span><span class="invalid">Tổ chức là bắt buộc
                      (*)</span></label>
                      <ngx-select2 [select2Option]="select2Option" formControlName="Organizations"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Groups'), 'warning')">
                  <label class="label"><span class="valid">Nhóm (tự động tạo nhóm)</span><span class="invalid">Nhóm là bắt buộc
                      (*)</span></label>
                      <ngx-select2 [select2Option]="select2GroupsOption" formControlName="Groups"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Email'), 'warning')">
                  <label class="label"><span class="valid">Email</span><span class="invalid">Email là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Email" placeholder="Email">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Phone'), 'warning')">
                  <label class="label"><span class="valid">Số điện thoại</span><span class="invalid">Số điện thoại
                      là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Phone" placeholder="Phone">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Address'), 'warning')">
                  <label class="label"><span class="valid">Địa chỉ</span><span class="invalid">Địa chỉ là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Address" placeholder="Địa chỉ">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Note'), 'warning')">
                  <label class="label"><span class="valid">Ghi chú</span><span class="invalid">Ghi chú là bắt buộc
                      (*)</span></label>
                  <textarea nbInput fullWidth formControlName="Note" placeholder="Ghi chú"></textarea>
                </div>
              </div>
              <!-- <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Enabled'), 'warning')">
                  <label class="label"><span class="valid">Kích hoạt (*)</span><span class="invalid"></span></label>
                  <nb-checkbox formControlName="Enabled" >Kích hoạt</nb-checkbox>
                </div>
              </div> -->
              <div class="col-sm-12">

                <!-- Detail form -->
                <!-- <hr> -->
                <div class="row" style="align-items: flex-end;">
                  <div class="col-sm-3 label">{{'Common.type' | translate | headtitlecase}}</div>
                  <div class="col-sm-8 label">{{'Common.detail' | translate | headtitlecase}}</div>
                  <div class="col-sm-1" style="text-align: right;">
                    <nb-icon pack="eva" icon="plus-circle-outline" (click)="addDetailFormGroup(formItem)" status="success"
                      style="margin-right: 0.8rem;"></nb-icon>
                  </div>
                </div>

                <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)"
                  [sortablejsOptions]="{ handle: '.sorting-handle' }">
                  <!-- <div formArrayName="Details" class="form-details"> -->
                  <hr>
                  <div class="form-detail-item"
                    *ngFor="let detail of getDetails(formItem).controls; let ic=index" [formGroup]="detail">
                    <input type="hidden" formControlName="Id">

                    <div class="row">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="label">{{'Common.user' | translate | headtitlecase}}</label>
                          <ngx-select2 formControlName="Type" [select2Option]="select2OptionForType" [data]="typeList">
                          </ngx-select2>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="form-group">
                          <label class="label">{{'Common.detail' | translate | headtitlecase}}</label>
                          <input nbInput fullWidth formControlName="Detail" placeholder="{{'Common.detail' | translate | headtitlecase}}">
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <label class="label">CM</label>
                          <button nbButton status="danger" hero size="medium"
                            (click)="removeDetailGroup(formItem, detail, ic)" style="margin-bottom: 3px; float: right;">
                            <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Detail form -->
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
