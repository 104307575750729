<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Collaborator.Order.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div class="print-title">{{ 'Collaborator.Order.title' | translate:{action: '', definition: ''} |
                        uppercase }}
                    </div>
                    <div class="under-title">{{ data.Code }} - {{ data.DateOfOrder | date:'short' }}</div>
                </div>
                <div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="under-line">{{'Kính gửi' | translate | headtitlecase}} :
                                {{renderValue(data.Object.Title)}} {{renderValue(data.Object.Name)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'Common.customerId' | translate | headtitlecase}} :
                                {{renderValue(data.Object | objectid)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'Số điện thoại' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectPhone)}}
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="under-line">{{'Địa  chỉ' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectAddress)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'Email' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectEmail)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'Số tổng đài' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectCallcenterNumber)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <table style="width: 100%;" class="print-voucher-detail-table">
                            <tr class="print-voucher-detail-header">
                                <th>#</th>
                                <td>{{'Hình' | translate | headtitlecase}}</td>
                                <td>{{'Sku' | translate | headtitlecase}}</td>
                                <th>{{'Sales.technicalDescription' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Voucher.shortQuantity' | translate | headtitlecase}}
                                </th>
                                <th class="text-align-right">{{'Voucher.shortUnit' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Voucher.price' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</th>
                            </tr>
                            <ng-container *ngFor="let detail of data.Details; let i = index;">
                                <tr class="print-voucher-detail-line" *ngIf="detail.Type === 'CATEGORY'">
                                    <td colspan="7" style="font-weight: bold;" [innerHTML]="renderValue(detail.Description)"></td>
                                </tr>
                                <tr class="print-voucher-detail-line" *ngIf="detail.Type !== 'CATEGORY'">
                                    <td>{{detail.No}}</td>
                                    <td>
                                        <img *ngIf="detail?.Image && detail?.Image[0] && detail?.Image[0] != 'null' && detail?.Image[0]?.SmallImage" style="width: 150px;" [src]="detail?.Image[0]?.SmallImage">
                                    </td>
                                    <td>
                                        <ngx-barcode [bc-value]="detail.Product?.Sku" [bc-display-value]="false" [bc-format]="'CODE128'" [bc-height]="30" [bc-width]="1" [bc-margin]="0"></ngx-barcode>
                                        <div style="margin-left: 10px;" class="nowrap">
                                            <span>{{detail.Product?.Sku}}</span>
                                            <span style="font-style: italic; font-size: 0.5rem;"> - ID: {{renderValue(detail.Product | objectid)}}</span>
                                        </div>
                                        <div style="margin-left: 10px" class="nowrap">
                                            <span *ngIf="detail.CustomerSku">Sku KH: {{detail.CustomerSku}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span [innerHTML]="renderValue(detail.Description)"></span>
                                    </td>
                                    <td class="text-align-right">{{renderValue(detail.Quantity) | number:'1.0-2'}}</td>
                                    <td class="text-align-right">{{detail.Unit | objecttext}}</td>
                                    <td class="text-align-right">{{renderValue(detail.Price) | currency:'VND':'symbol':'1.0-2'}}</td>
                                    <td class="text-align-right">{{detail['ToMoney'] | currency:'VND':'symbol':'1.0-2'}}</td>
                                </tr>
                                <!-- <tr class="print-voucher-detail-line" *ngIf="detail?.Product?.Description">
                                    <td>&nbsp;</td>
                                    <td colspan="7" [innerHTML]="'Mô tả: <br>'+detail?.Product?.Description"></td>
                                </tr> -->
                                <tr class="print-voucher-detail-line" *ngIf="detail?.Product?.Technical">
                                    <td>&nbsp;</td>
                                    <td colspan="7" [innerHTML]="'Thông số kỹ thuật: <br>'+detail?.Product?.Technical"></td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="4">{{'Common.note' | translate | headtitlecase}}</td>
                                <td colspan="3" class="text-align-right">{{'Tiền hàng' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{data['Total'] | currency:'VND':'symbol':'1.0-2'}}</td>
                            </tr>
                            <!-- <tr>
                                <td colspan="7" class="text-align-right">{{'Phí vận chuyển' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{data['DeliveryCost'] | currency:'VND'}}</td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-align-right">{{'Phí triển khai' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{0 | currency:'VND'}}</td>
                            </tr> -->
                            <tr>
                                <td colspan="7" class="text-align-right"><b>{{'Tổng cộng' | translate | headtitlecase}}</b></td>
                                <td class="text-align-right"><b>{{(data['Total'] + data['DeliveryCost']) | currency:'VND':'symbol':'1.0-2'}}</b></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <hr>
                <div *ngIf="data.TransportPoints && data.TransportPoints.length  > 0">
                    <label style="font-size: 16px; font-weight: bold;">Hành trình vận chuyển</label>
                    <table style="width: 100%;" class="print-voucher-detail-table">
                        <thead class="print-voucher-detail-header">
                            <td>#</td>
                            <td>{{'Đơn vị vận chuyển' | translate | headtitlecase}}</td>
                            <td>{{'SĐT Đơn vị vận chuyển' | translate | headtitlecase}}</td>
                            <td>{{'Địa chỉ giao hàng' | translate | headtitlecase}}</td>
                            <td>{{'Bên trả phí' | translate | headtitlecase}}</td>
                            <td class="text-align-right">{{'Phí' | translate | headtitlecase}}</td>
                        </thead>
                        <ng-container *ngFor="let transportPoint of data.TransportPoints; let t = index">
                            <tr class="print-voucher-detail-line">
                                <td>{{transportPoint.No}}</td>
                                <td><span [innerHTML]="transportPoint.ShippingUnitName"></span></td>
                                <td><span [innerHTML]="transportPoint.ShippingUnitPhone"></span></td>
                                <td><span [innerHTML]="transportPoint.DestinationFullAddress"></span></td>
                                <td><span [innerHTML]="transportPoint.SideOfPayment | objecttext"></span></td>
                                <td class="text-align-right"><span [innerHTML]="transportPoint.TransportCost | currency:'VND':'symbol':'1.0-2'"></span></td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="4"></td>
                            <td class="text-align-right"><span style="font-weight: bold">Tổng: </span></td>
                            <td class="text-align-right"><span style="font-weight: bold" [innerHTML]="sumOfColumn(data.TransportPoints, 'TransportCost') | currency:'VND':'symbol':'1.0-2'"></span></td>
                        </tr>
                    </table>
                    <hr>
                </div>

                <div>
                    <div class="under-line"><span [innerHTML]="renderValue(data.Note)"></span></div>
                    <div class="under-line">{{'Sales.deliveryAddress' | translate | headtitlecase}}:
                        {{renderValue(data.FullDeliveryAddress)}}
                    </div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="under-line">{{'Người trực tiếp nhận hàng'}} :
                                {{renderValue(data.DirectReceiver | objecttext)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'Số điện thoại'}} :
                                {{renderValue(data.DirectReceiverPhone)}}
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Common.employee' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Common.customer' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                </div>
                <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
            </div>
        </nb-card-body>
        <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
            <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
                <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
            </span>
        </nb-card-footer>
        <nb-card-footer>
            <div class="buttons-row">
                <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
                <button *ngIf="mode === 'preview'" nbButton hero status="danger" (click)="close()">
                    <nb-icon pack="eva" icon="chevron-left"></nb-icon>{{'Common.goback' | translate | headtitlecase}}
                </button>
                <button *ngIf="mode === 'print'" nbButton hero status="primary" (click)="prepareCopy(data);">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button>
                <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
                    <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
                <!-- <button *ngIf="mode === 'print'" nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

                <!--State process buttons-->
                <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
                <!--End State process buttons-->

                <button *ngIf="mode === 'preview'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button>
                <button *ngIf="mode === 'print'" nbButton hero status="warning" (click)="edit(data);" style="float: left">
                    <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>