import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { IGetRowsParams } from 'ag-grid-community';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { ColDef } from '@ag-grid-community/core';
import { RootServices } from '../../../../services/root.services';
import { MenuItemModel } from '../../../../models/menu-item.model';
import { MenuFormComponent } from '../menu-form/menu-form.component';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { DialogSortingFormComponent } from '../../../../lib/component/dialog/sorting-form/sorting-form.component';

@Component({
  selector: 'ngx-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent extends AgGridDataManagerListComponent<MenuItemModel, MenuFormComponent> implements OnInit {

  componentName: string = 'MenuListComponent';
  formPath = 'menu/manager/form';
  apiPath = '/menu/menu-items';
  idKey = ['Code'];
  formDialog = MenuFormComponent;

  reuseDialog = true;
  static _dialog: NbDialogRef<MenuListComponent>;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<MenuListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;

  }

  async init() {
    return super.init().then(async state => {

      // const processingMap = AppModule.processMaps['cashVoucher'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: '#',
          field: 'Id',
          width: 80,
          valueGetter: 'node.data.Code',
        },
        {
          headerName: 'Thứ tự',
          field: 'No',
          width: 100,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
          initialSort: 'asc',
        },
        {
          headerName: 'ID',
          field: 'Code',
          width: 100,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
          initialSort: 'asc',
        },
        {
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 600,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Menu cha',
          field: 'Parent',
          width: 320,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/menu/menu-items', { includeIdText: true }, {
                placeholder: 'Chọn menu cha...', limit: 10, prepareReaultItem: (item) => {
                  item['id'] = item.Code;
                  item['text'] = this.cms.translateText(item.Title);
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Link',
          field: 'Link',
          width: 250,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Icon',
          field: 'Icon',
          width: 100,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);



      this.actionButtonList.unshift({
        type: 'button',
        name: 'sortBtn',
        status: 'info',
        label: 'Sắp xếp',
        title: 'Sắp xếp các mục đã chọn',
        size: 'medium',
        icon: 'list-outline',
        disabled: () => this.selectedIds.length == 0,
        click: () => {
          this.cms.openDialog(DialogSortingFormComponent, {
            context: {
              title: 'Cập nhật thứ tự',
              data: this.selectedItems.map(m => ({ id: m.Code, text: this.cms.translateText(m.Title), no: parseInt(m.No) })).sort((a, b) => a.no - b.no),
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'info',
                  action: async (form) => {
                    return true;
                  },
                },
                {
                  label: 'Cập nhật',
                  icon: 'generate',
                  status: 'success',
                  action: async (form) => {
                    const data = (form.value['array'] || []).map((m, i) => {
                      const item = this.selectedItems.find(f => f.Code == m.id);
                      return { Code: item.Code, No: parseInt(i) + 1 };
                    });
                    if (data) {
                      this.loading = true;
                      this.apiService.putPromise<MenuItemModel[]>(this.apiPath, {}, data).then(rs => {
                        this.cms.showToast('Đã cập nhật lại thứ tự thành công', 'Cập nhật thứ tự', { status: 'success' });
                        this.refresh().then(() => {
                          this.loading = false;
                        }).catch(err => {
                          this.loading = false;
                        });
                      }).catch(err => {
                        this.loading = false;
                      });;
                    }
                    return true;
                  },
                },
              ],
            },
          });
        }
      });

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeParent'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: MenuItemModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(MenuFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: MenuItemModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  prepareItem(item: MenuItemModel): MenuItemModel {

    item.Title = item.Title ? this.cms.translateText(item.Title, null, 'title') : item.Title;
    // item.ParentTitle = item.ParentTitle ? this.cms.translateText(item.ParentTitle, null, 'title') : item.ParentTitle;
    item.Parent = {
      id: item.Parent,
      text: item.ParentTitle ? this.cms.translateText(item.ParentTitle, null, 'title') : item.ParentTitle,
    };

    return super.prepareItem(item);
  }
}
