import { PromotionComponent } from './promotion.component';
import { PromotionListComponent } from './promotion/promotion-list/promotion-list.component';
import { PromotionFormComponent } from './promotion/promotion-form/promotion-form.component';
import { NgModule } from '@angular/core';
import { commonModules, commonProviders } from '../../common-modules';
import { PromotionRoutingModule } from './promotion-routing.module';

export const promotionComponents = [
  PromotionComponent,
  PromotionListComponent,
  PromotionFormComponent
];

@NgModule({
  declarations: promotionComponents,
  exports: promotionComponents,
  imports: [
    ...commonModules,
    PromotionRoutingModule,
  ],
  bootstrap: [PromotionComponent],
  providers: [
    ...commonProviders,
  ],
})
export class PromotionModule { }