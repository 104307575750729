import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { DataManagerListComponent, SmartTableSetting } from '../../../../lib/data-manager/data-manger-list.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { PbxModel } from '../../../../models/pbx.model';
import { PbxFormComponent } from '../pbx-form/pbx-form.component';

@Component({
  selector: 'ngx-pbx-list',
  templateUrl: './pbx-list.component.html',
  styleUrls: ['./pbx-list.component.scss'],
})
export class PbxListComponent extends DataManagerListComponent<PbxModel> implements OnInit {

  componentName = 'PbxListComponent';
  formPath: string = '/ivoip/pbxs/form';
  apiPath: string = '/ivoip/pbxs';
  idKey = ['Code'];
  formDialog = PbxFormComponent;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService);
    // this.apiPath = '/user/groups';
    // this.idKey = 'Code';
  }

  loadListSetting(): any {
    return this.configSetting({
      mode: 'external',
      selectMode: 'multi',
      actions: {
        position: 'right',
      },
      // add: {
      //   addButtonContent: '<i class="nb-edit"></i> <i class="nb-trash"></i> <i class="nb-plus"></i>',
      //   createButtonContent: '<i class="nb-checkmark"></i>',
      //   cancelButtonContent: '<i class="nb-close"></i>',
      // },
      // edit: {
      //   editButtonContent: '<i class="nb-edit"></i>',
      //   saveButtonContent: '<i class="nb-checkmark"></i>',
      //   cancelButtonContent: '<i class="nb-close"></i>',
      // },
      // delete: {
      //   deleteButtonContent: '<i class="nb-trash"></i>',
      //   confirmDelete: true,
      // },
      // pager: {
      //   display: true,
      //   perPage: 9999999,
      // },
      columns: {
        No: {
          title: 'Stt',
          type: 'text',
          width: '5%',
          class: 'no',
          filter: false,
        },
        Code: {
          title: 'Mã',
          type: 'string',
          width: '15%',
        },
        Name: {
          title: 'Tên',
          type: 'string',
          width: '30%',
          filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
        },
        Description: {
          title: 'Mô tả',
          type: 'string',
          width: '50%',
          filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
        },
        ApiVersion: {
          title: 'Version',
          type: 'string',
          width: '10%',
        },
      },
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  onDeclareNewDomainForPbx(): false {

    return false;
  }

}
