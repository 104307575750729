import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-core-connection',
  template: `<router-outlet></router-outlet>`,
})
export class CoreConnectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
