import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { DeploymentVoucherFormComponent } from "./deployment-voucher/deployment-voucher-form/deployment-voucher-form.component";
import { DeploymentVoucherListComponent } from "./deployment-voucher/deployment-voucher-list/deployment-voucher-list.component";
import { NgModule } from "@angular/core";

export const deploymentRoutes: Routes = [
    {
        path: 'voucher/list',
        canActivate: [AuthGuardService],
        component: DeploymentVoucherListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'voucher/form',
        canActivate: [AuthGuardService],
        component: DeploymentVoucherFormComponent,
    },
    {
        path: 'voucher/form/:id',
        canActivate: [AuthGuardService],
        component: DeploymentVoucherFormComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(deploymentRoutes)],
    exports: [RouterModule],
})
export class DeploymentRoutingModule {
}
